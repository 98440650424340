import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { Product } from '@app/courses/models/games/supermarket/product';
import { NotificationSummary } from '@app/common/models/notification-summary';
import { PlatformLocation } from '@angular/common';
import { GameConfiguration } from '@app/courses/models/games/game-configuration';

@Component({
  selector: 'ebs-supermarket-game',
  templateUrl: './supermarket-game.component.html',
  styleUrls: ['./supermarket-game.component.scss']
})
export class SupermarketGameComponent implements OnInit {
  @ViewChild('unityView', { static: true }) unityView;
  @Input() gameConfiguration: GameConfiguration; 
  unityIsHidden: boolean = true;
  isChallengeSelect: boolean = true;
  isLoading: boolean;
  isAnalysis: boolean;
  products: Array<Product> = new Array<Product>();
  baseUrl: string;
  project: string;
  title="EBS Unity";
  notifications: Array<NotificationSummary> = new Array<NotificationSummary>();
  private callFailed: boolean;
  showSaved: boolean;

  constructor(platformLocation: PlatformLocation) { 
    const location = (platformLocation as any).location;
  }
  
  ngOnInit() {
    this.isAnalysis=false;
    this.isLoading=true;
    this.load("Supermarket");
  }

  showUnity() {
    this.isChallengeSelect = false;
    this.unityIsHidden = false;
  }

  startChallengeEvent(id: number) {
    this.isChallengeSelect = false;
    this.unityIsHidden = false;
    this.send("ChallengeGlobal", "SetId", id.toString());
  }

  unityStartedEvent(event: string) {
    this.isLoading = false;
  }

  unityCheckoutEvent(event: string) {
    this.products = JSON.parse(event);
    this.isAnalysis = true;
  }

  load(name: string) {
    this.project = name;
    let json = JSON.stringify(this.gameConfiguration);
    let file = new File([json],'game.json');
    this.unityView.loadProject(URL.createObjectURL(file));
  }

  send(objectName: string, methodName: string, messageValue: string) {
    this.showSaved = false;
    this.unityView.sendMessageToUnity(objectName, methodName, messageValue);
  }

  ChallengeClicked() {
    this.isLoading = false;
    this.isChallengeSelect = true;
    this.isAnalysis = false;
    this.unityIsHidden = true;
    this.send("GameViewModel", "Restart", "Challenge1");
  }



}
