import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class StateService {
    private _authSectionChange: boolean;
    get authSectionChange(): boolean {
      return this._authSectionChange;
    }
    set authSectionChange(hasChanged: boolean) {
      this._authSectionChange = hasChanged;
    }

    private _intervalId: number = 0; 
    get intervalId(): number {
      return this._intervalId;
    }
    set intervalId(hasChanged: number) {
      this._intervalId = hasChanged;
    }

    private _numberOfLoginScreens: number = 0;
    get numberOfLoginScreens(): number {
      return this._numberOfLoginScreens;
    }
    set numberOfLoginScreens(addOrMinus: number) {
      this._numberOfLoginScreens = addOrMinus;
    }
}
