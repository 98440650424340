<ng-container *ngIf="hasAttachments()">
  <ng-container *ngFor="let attachment of attachments">
    <div>
      <a target="_self" tooltip='download file' class="link mb-1 mr-1"
                              [href]="getDownloadUri(attachment)">
                              <i class="fal fa-download fa-fw fa-lg"></i>{{attachment.name}}
                              
                             
      </a>
      &nbsp;
  </div>
  </ng-container>
</ng-container>

