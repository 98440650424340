import { LearningObject } from "@app/common/models/learning-object";

export class QuizDetail extends LearningObject{
    public personId: string;
    public courseCode: string;
    public quizId: number;
    public quizType: number;
    public score: number;
    public feedbackOption: number;
    public essayText: string;
    public availableMarks: number;
}