<div class="user-row d-flex flex-row align-items-center flex-sm-column align-items-sm-start">
  <div class="user-badges" *ngIf="userCountryFlag || isFaculty">
    <img alt="user flag" src="{{userCountryFlag}}" class="user-flag" *ngIf="!isFaculty" />
    <div class="user-badge" *ngIf="isFaculty">FACULTY</div>
  </div>
  <div class="user-avatar-container">
    <img alt="user avatar" src="data:image/jpg;base64,{{userAvatar}}" class="avatar-responsive-profile rounded-circle"
      *ngIf="userAvatar" />
    <img alt="user avatar" src="../../../../assets/img/avatar-temp.svg" class="avatar-responsive-profile rounded-circle"
      *ngIf="!userAvatar" />
  </div>
  <div class="user-details text-muted mt-1">
    {{displayName}}
    <div> {{displayDate}} </div>
  </div>
</div>