<div class="modal-header px-4">
  <h4 class="modal-title pull-left">{{dialogFullTitle()}}</h4>
  <button type="button" class="close pull-right" aria-label="Close" (click)="closePopup()" style="font-size: 2rem;">
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<div class="modal-body px-4" style="padding-bottom: 5px;padding-top: 10px;">
    <div *ngIf="isEligibileToRegister() === false && isCourseAvaialbleForRegistration() === true">
    <p>
      Entry to this MSc programme of study is based on the successful completion of the two programme entry courses 
      indicated in your student handbook. You must complete these two programme entry courses before you can start studying 
      on any further courses.
    </p>
    </div>
    <div *ngIf="isEligibileToRegister() === false && isCourseAvaialbleForRegistration() === false">
      This course is still in development and is not available to study at this time.
    </div>
    <div *ngIf="isEligibileToRegister() === true">
      <P>
        I confirm I want to start studying for this course and be automatically entered for the next available
        assessment session (scheduled at least three months from today). 
      </P>
      <p *ngIf="isForcedManualRegistrationProcessWithNoRestrictions() === false">
        Once you have registered for this course, there will be six assessment sessions available when the assessment for this 
        course can be taken. This is over a two year period.
      </p>
      <ul class="list-unstyled" >
        <li class="list-group-item">
          <div class="row">
            <div class="col-8 col-sm-10">
              I confirm
            </div>
            <div class="col-4 col-sm-2" style="text-align: center;">
              <label class="switch">
                <input type="checkbox" [checked]="hasAcknowledgedCourseRegistrationTerms" 
                  (change)="hasAcknowledgedCourseRegistrationTerms = !hasAcknowledgedCourseRegistrationTerms" >
                <span class="slider round"></span>
              </label>
            </div>
          </div>
        </li>
      </ul>
    </div>   

    <div class="alert alert-error beta-version " role="error" *ngIf="hasErrors" >
      <p style="padding-top:10px;">
        <i class="fas fa-fw fa-exclamation-circle fa-lg"></i>Sorry there has been an error, please contact the Student Engagement team at ebs.enquiries@hw.ac.uk
      </p>        
    </div> 
</div>


<div class="modal-footer">
  
      
        <button type="button" class="btn btn-primary text-white float-right btn-block mt-0"
          (click)="register()" *ngIf="isEligibileToRegister() === true && hasErrors === false"
          [disabled]="!hasAcknowledgedCourseRegistrationTerms" >
          Start Studying
        </button>

        <div class="float-right" *ngIf="registering">
          <ebs-spinner [spinnerMessage]="''"></ebs-spinner>
        </div>
        <button type="button" class="btn btn-secondary float-right btn-block mt-0" (click)="closePopup()" >
          {{closeButtonText()}}
        </button>
    
         

  </div>  