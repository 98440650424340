<div class="Card">
  <h1>Simulation/Game</h1>
  <p>Example showing a unity webGL view inside Angular</p>
  <ebs-unity-web-gl #unityView></ebs-unity-web-gl>

  <p></p>
  <div style="text-align:center" class="buttons">
    <button class="btn btn-primary" (click)="send('Cube','ReceiveMessageFromWeb', 'blue')">Blue</button>
    <button class="btn btn-primary" (click)="send('Cube','ReceiveMessageFromWeb', 'green')">Green</button>
    <button class="btn btn-primary text-align:left"
      (click)="send('Cube','ReceiveMessageFromWeb', 'yellow')">Yellow</button>
    <button class="btn btn-primary text-align:left" (click)="saveState()">Save State</button>
    <p></p>
    <div *ngIf="showSaved">State successfully saved</div>
  </div>

</div>

