<div class="login-wrapper main-content">
  <div class="scrollable full-height-minus-header">
    <div class="container">
    <div class="card-body col-sm-8 offset-sm-2 col-xl-6 offset-xl-3">
    <h2 class="d-none d-xl-block not-modal">Welcome to the Edinburgh Business School Student Portal</h2>
    <h3 class="d-xl-none not-modal">Student Portal</h3>
    <p class="py-1 ebs-text d-none d-xl-block not-modal">
      For support and information regarding your study experience visit <a href='https://www.hw.ac.uk/ebs/'>www.hw.ac.uk/ebs</a></p>
    <p class="ebs-text d-xl-none not-modal">
      For support: <a href='https://www.hw.ac.uk/ebs/'>www.hw.ac.uk/ebs</a></p>
    <div class="border-panel">
      <div class="main-div">
        <div class="panel">
          <h3>Offline Study</h3>
          <p>There is no Internet connection available.
            <span *ngIf="offlineModeEnabled && hasDownloads">However, you can access course content that you have
              downloaded to this device. To access this content, click 'Go to dashboard'.</span>
            <span *ngIf="(!offlineModeEnabled || !hasDownloads)">When you are next online, you can set up this device for
              offline access, which will allow you to study with no Internet connection. Please go to the course
              dashboard for instructions.</span>

              <!-- <span *ngIf="userLoggedOff">You have logged off while in offline mode. You will be unable to return to offline mode until you next log in while online</span> -->
          </p>
        </div>
        <div class="row" *ngIf="offlineModeEnabled && hasDownloads">
          <div class="col-5">
            <div class="d-flex flex-row align-items-center">
              <button id="loginButton" class="btn btn-primary" (click)="redirectToDashboard()">Go to dashboard</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>  
</div>
</div>
