<ul role="menu">

    <ng-container *ngIf="displayMenu && topMenu">
        <li #menubutton id="section-{{i}}" *ngFor="let section of topMenu.sections; let i= index" class="navbar-item top">
            
            <a [routerLink]="[ '/', 'dashboard' ]" class="navbar-link" role="menuitem" aria-expanded="false" *ngIf="!section.groups">
                <span class="header-icon-large {{section.icon}}"></span>
                <span class="menu-label">{{section.title}}</span>
            </a>

            <div class="btn-group" dropdown  *ngIf="section.groups">
                <button dropdownToggle type="button" class="btn btn-light btn-sm preferences-button navbar-link"
                    [attr.aria-controls]="'button-group-' + i">
                    <span *ngIf="!section.usephoto" class="header-icon-large {{section.icon}}"></span>
                    <img *ngIf="section.usephoto" class="avatar rounded-circle" alt="View account"
                        [src]="avatar| domSanitizerPipe: 'src'" />
                    <div>
                        <span class="menu-label">{{section.title}} <span
                                class="header-icon-small icon-gecko-scroll-down"></span>
                        </span>
                    </div>
                </button>


                <ul #submenus id="submenu-{{i}}" *dropdownMenu class="dropdown-menu dropdown-menu-dynamic"
                    [ngClass]="menuCssClass" role="menu" aria-labelledby="button-studies">
                    <div [ngStyle]="{'min-width': section.groups.length*250+ 'px', 'overflow': 'hidden'}"
                        style="min-width: 900px;" class="row studies-row">
                        <div *ngFor="let group of section.groups" class="col-12"
                            [ngClass]="(section.groups.length==2)? 'col-lg-6': (section.groups.length==3)? 'col-lg-4' : 'col-lg-3'">
                            <li class="column"><span
                                    [ngStyle]="{'border-bottom': !group.items? 'none': '1px solid #5b6770'}"
                                    [ngClass]="{'menu-header': group.title}" data-toggle="tooltip"
                                    data-placement="bottom">{{group.title}}</span>
                                <div *ngIf="group.text" [innerHTML]="group.text"></div>
                                <ul *ngIf="group.items">
                                    <li *ngFor="let item of group.items" class="navbar-item subitem" role="none">
                                        <a *ngIf="!item.isConnectUsLink" role="menuitem"
                                            href="{{item.link}}">{{item.title}}</a>
                                        <a *ngIf="item.isConnectUsLink" role="menuitem"
                                            [routerLink]="[item.link]">{{item.title}}</a>
                                    </li>
                                </ul>
                            </li>
                        </div>
                    </div>
                </ul>
            </div>

        </li>
    </ng-container>


    <li class="navbar-item top left-border" *ngIf="displayOfflineMenu">
        <div class="btn-group" dropdown [ngStyle]="{'display': isOffline ? '' : '' }" [@offlineTriggered]="isOffline">
            <button id="button-offline" dropdownToggle type="button"
                class="btn btn-light btn-sm preferences-button navbar-link" aria-controls="dropdown-offline"
                style="min-width: 80px;">
                <img *ngIf="isOffline" class="avatar" src="../../../../assets/img/offline-col.svg" alt="offline image">
                <img *ngIf="!isOffline" class="avatar" src="../../../../assets/img/online-col.svg" alt="online image">
                <div>
                    <span *ngIf="!isOffline" class="menu-label">Online
                        <span class="header-icon-small icon-gecko-scroll-down"></span>
                    </span>
                    <span *ngIf="isOffline" class="menu-label">Offline
                        <span class="header-icon-small icon-gecko-scroll-down"></span>
                    </span>
                </div>
            </button>
            <ul id="dropdown-offline-dropdown" *dropdownMenu class="dropdown-menu dropdown-menu-right special-dropdown"
                role="menu" aria-labelledby="button-offline">
                <li class="column">
                    <ul>
                        <div *ngIf="!isOffline" class="dropdown-header" style="padding-top: 18px !important;">
                            <strong>You are in online
                                mode</strong></div>
                        <div *ngIf="isOffline" class="dropdown-header" style="padding-top: 18px !important;"><strong>You
                                are in offline
                                mode</strong></div>

                        <li *ngIf="isOffline && isWorkingOffline && !noInternetConnection" role="menuitem" class="link"
                            style="padding-left: 20px !important;" (click)="goOnline()">
                            <img class="avatar" src="../../../../assets/img/online-col.svg" alt="online image"
                                style="max-height: 25px; max-width: 30px"> Go
                            online now
                        </li>
                        <li *ngIf="noInternetConnection" role="menuitem"
                            style="padding-left: 23px !important; padding-bottom: 10px !important;">
                            No internet connection available
                        </li>
                        <li *ngIf="!isOffline" role="menuitem" class="link" style="padding-left: 20px !important;"
                            (click)="goOffline()">
                            <img class="avatar" src="../../../../assets/img/offline-col.svg" alt="offline image"
                                style="max-height: 25px; max-width: 30px">
                            Go
                            offline now
                        </li>
                        <li *ngIf="!isOffline">
                            <div style="text-align: center;" [ngClass]="{'online-offline-options': !isOffline}">
                                <span *ngIf="supportsServiceWorkers">
                        <li role="none" role="menuitem" class="link" (click)="popUpOffline()" *ngIf="!isOffline">
                            <a style="margin-top: 5px;" class="menuitem">Offline options</a>
                        </li>
                        </span>
        </div>
    </li>
</ul>
</li>
</ul>
</div>
</li>
</ul>