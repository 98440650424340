import { Injectable } from '@angular/core';
import { AppConfigService } from './app-config.service';

@Injectable({
    providedIn: 'root'
})
export class ConfigurationHelperService {

    constructor() {
    }

    public getApiServerUri(): string {
        return AppConfigService.settings.apiServer.prefix;
    }

    public getKortextFormUrl(): string {
        return AppConfigService.settings.kortext.formUrl;
    }

    public getKortextOAuthConsumerKey(): string {
        return AppConfigService.settings.kortext.oauthConsumerKey;
    }

    public getKortextOAuthConsumerSecret(): string {
        return AppConfigService.settings.kortext.oauthConsumerSecret;
    }

    public getCourseraFormUrl(): string {
        return AppConfigService.settings.coursera.formUrl;
    }

    public getCourseraOAuthConsumerKey(): string {
        return AppConfigService.settings.coursera.oauthConsumerKey;
    }

    public getCourseraOAuthConsumerSecret(): string {
        return AppConfigService.settings.coursera.oauthConsumerSecret;
    }

    public getDiscussionItemsPerPage(): number {
        return AppConfigService.settings.discussion.itemsPerPage;
    }

    public getPollingInterval(): number {
        return AppConfigService.settings.polling.interval;
    }

    public getHeaderLinks(): any {
        return AppConfigService.settings.headerLinks;
    }

    public getPlaceholderBefore(): string {
        return AppConfigService.settings.placeholderUrls.placeholderBefore;
    }

    public getPlaceholderAfter(): string {
        return AppConfigService.settings.placeholderUrls.placeholderAfter;
    }

    public getStudentDashboardUri(): string {
        return AppConfigService.settings.studentDashboard.uri;
    }

    public getStudentDashboardRedirectIsOn(): boolean {
        return AppConfigService.settings.studentDashboard.redirectIsOn;
    }

    public getVideoCacheKey(): string {
        return AppConfigService.settings.videoCache.key;
    }

    public getVideoSourcePrefix(): string {
        return AppConfigService.settings.videoStorage.prefix;
    }

    public getPasswordResetUri(): string {
        return AppConfigService.settings.accountServices.passwordResetUri;
    }

    public getTermsAndConditionsAgreementUri(): string {
        return AppConfigService.settings.accountServices.termsAndConditionsAgreementUri;
    }

    public getForgottenUsernameUri(): string {
        return AppConfigService.settings.accountServices.forgottenUsernameUri;
    }

	public getForgottenPasswordUri(): string {
	  return AppConfigService.settings.accountServices.forgottenPasswordUri;
    }
    
    public getDownloadRetry(): number {
        return AppConfigService.settings.downloads.individualRetriesNo;
    }

    public getDownloadTakesLongerThanExpectedPopupTimeMinutes(): number {
        return AppConfigService.settings.downloads.downloadTakesLongerThanExpectedPopupTimeMinutes;
    }

    public getDownloadTakesLongerThanExpectedWarningTimeMinutes(): number {
        return AppConfigService.settings.downloads.downloadTakesLongerThanExpectedWarningTimeMinutes;
    }    

    public getFileUploadMaxSize(): number  {
        return AppConfigService.settings.fileUploads.maxFileSize;
    }
}