import { Injectable } from "@angular/core";
import { StorableObject } from "./storable-object";
import { HttpHeaders } from "@angular/common/http";
import { KeyValuePair } from "@app/common/models/key-value-pair";
import { StringLiterals } from "@app/common/string-literals";

@Injectable({
    providedIn: 'root'
})
export class ServiceBaseHelpers {
    public pendingDeletionUri = '/deleted-objects/awaiting-synchronization';

    constructor() {
    }

    public getNumberOfRouteSegments(uri : string):number{
        let segmentCount = 0;
    
        if (uri == null || uri.trim() ==='') return segmentCount;
    
        let segments = uri.trim().split('/');
        
        segments.forEach(element => {
            if (element != null && element.trim() !== '') segmentCount++;
        });
    
        return segmentCount;
    }
    
    public isUriChildOfParentUri(childUri:string, parentUri:string):boolean{
        if (childUri == null || childUri.trim() ==='') return false;
        if (parentUri == null || parentUri.trim() ==='') return false;
    
        let parentSegmentCount = this.getNumberOfRouteSegments(parentUri);
        let childSegmentCount = this.getNumberOfRouteSegments(childUri);
    
        return parentSegmentCount + 1 === childSegmentCount;
    }
    
    public filterStorableObjectByRootUri<T extends StorableObject>(rootUri:string, storableObjects: Array<T>) : Array<T>{
        let result :Array<T> = new Array<T>();
    
        if (rootUri == null || rootUri.trim() ==='') return result;
    
        if (storableObjects == null || storableObjects.length === 0) return result;
    
        for (let storableObject of storableObjects){
            if (this.isUriChildOfParentUri(storableObject.uri, rootUri) === false) continue;
                
            result.push(storableObject);
        }
    
        return result;
    }

    public addDeletedObject(uri:string, awaitingDeletionSynchronization : Array<string>) : boolean {
        if (awaitingDeletionSynchronization == null) return false;
        if (uri == null || uri == '') return false;

        if (awaitingDeletionSynchronization.findIndex(x => x === uri) >-1) return true;

        awaitingDeletionSynchronization.push(uri);

        return true;
    }

    public synchronizeDeletedObject(uri:string, awaitingDeletionSynchronization : Array<string>) : boolean {
        if (awaitingDeletionSynchronization == null) return false;
        if (uri == null || uri == '') return false;

        const syncObjectIndex = awaitingDeletionSynchronization.indexOf(uri);
        if (syncObjectIndex < 0) return true;

        awaitingDeletionSynchronization.splice(syncObjectIndex, 1);

        return true;
    }


    public buildInterceptorBypassHeader() : HttpHeaders{
        let result = new HttpHeaders().set(StringLiterals.interceptorBypassHeaderKey, 'true');
        
        return result;
    }
}