<div id="{{linkId}}" class="card-header">
    <div class="row">
        <div class="col-sm-10">
            <span class="content-type-label" *ngIf="activityType">{{activityType}}</span>
            <h1 class="mt-2" *ngIf="sectionHeader">{{activityTitle}}</h1>
            <h2 class="mt-2" *ngIf="!sectionHeader">{{activityTitle}}</h2>
        </div>
        <div class="col-sm-2 time-indicator"  *ngIf="activitySize || activityDifficulty || activityDuration">
            <div class="time-container">
                <span class="icon-gecko-time text-info"></span>
                <span class="text-info" *ngIf="activitySize">Size {{activitySize}}</span> 
                <span class="text-info" *ngIf="activityDifficulty">Difficulty {{activityDifficulty}}</span>
                <span class="text-info" *ngIf="activityDuration">{{activityDuration}}</span>
            </div>
        </div>
    </div>
</div>