import { Component, OnInit, Input, ViewChild, OnDestroy } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { DiscussionType } from '../../models/discussion-type';
import { Tag } from '../../models/tag';
import { ForumService } from '../../services/forum.service';
import { UserService } from '@app/common/security/user.service';
import { UserProfile, UserRole, } from '@app/common/models/user';
import { StaticText } from '@app/common/static-text';
import { ForumAdd } from '../../models/forum-add';
import { Router, ActivatedRoute } from '@angular/router';
import { TagsComponent } from '../tags/tags.component';
import { FormMode } from '../../models/form-mode';
import { ForumEditorConfig } from '../../models/forum-editor-config';
import { OperationResponse } from '@app/common/models/operation-response';
import { OnlineStatusService } from '@app/common/online-status/online-status.service';
import { OnlineStatusType } from '@app/common/online-status/online-status-type.enum';
import { Subscription } from 'rxjs';
import { Title } from '@angular/platform-browser';
import { PagedRequest } from '@app/common/models/paged-request';
import { SearchItem } from '../../models/search-item';

@Component({
  selector: 'ebs-forum-add',
  templateUrl: './forum-add.component.html',
  styleUrls: ['./forum-add.component.scss']
})
export class ForumAddComponent implements OnInit, OnDestroy {
  timerId: any;
  currentSearchText: string;
  discussionForm: FormGroup;
  @Input() courseCode: string;
  @Input() formMode: FormMode;
  discussionForum: ForumAdd = new ForumAdd();
  activeTagList: Tag[] = []; initialTags: Tag[] = [];
  newSubscription: boolean = false;
  displayMessage: string;
  loading = true; loadingDiscussion = true; showUserError = false; isAnAdmin = false;
  gdprMessage = StaticText.gdprMessage; forumOfflineMessage = StaticText.forumOfflineMessage;
  @ViewChild(TagsComponent, { static: false }) tagComp: TagsComponent;
  creatingDiscussion = false;
  subPersonId: string; discussionId: string; errorMessage: string; listLink: string;
  allTagsAreSelected = false;
  titleCharacterLimit = 300; descriptionCharacterLimit = 50000;
  showError = false; showNoPermission = false; checkingUserDetails = true; showIdError = false;
  isOffline: boolean;
  theSubscription: Subscription;
  editorConfig = ForumEditorConfig;
  noDiscussionIdMessage = StaticText.noDiscussionIdMessage;
  noTag = StaticText.noTag;
  facultyFavourite = false;
  pagedRequest = new PagedRequest();
  searchItems: SearchItem[] = [];
  hideInput: boolean = true;
  counter: number = 3;
  counting: boolean = false;
  itemsFound: boolean = false;
  isWaiting: boolean;

  constructor(
    private titleService: Title,
    private fb: FormBuilder,
    private forumService: ForumService,
    private userService: UserService,
    private router: Router,
    private route: ActivatedRoute,
    private onlineStatusService: OnlineStatusService
  ) {
    this.onlineStatusService.isOffline().subscribe(x => {
      this.isOffline = x;
    }, error => {
      this.isOffline = false;
      console.log(error);
    });
    this.theSubscription = this.onlineStatusService.status.subscribe(
      event => {
        if (event === OnlineStatusType.OFFLINE)
          this.isOffline = true;
        if (event === OnlineStatusType.ONLINE)
          this.isOffline = false;
      }
    )
  }

  changed($event) {
    // this.itemsFound = false;
    // if ($event.target.value === "") {
    //   this.currentSearchText = " ";
    // } else {
    //   this.currentSearchText = $event.target.value;
    // }
  }

  keyPressed($event) {
    
    this.itemsFound=false;
    if ($event.target.value === "") {
      this.currentSearchText = " ";
    } else {
      clearTimeout(this.timerId);
      this.waitThenFire($event.target.value);
      if ($event.code === "Space") {
        this.currentSearchText = $event.target.value;
      }
      if ($event.code === "Enter") {
        this.currentSearchText = $event.target.value;
      }
    }
  }

  setItemsFound($event){
    this.itemsFound = true;
  }

  waitThenFire(value: string){
    this.isWaiting = true;
    this.timerId = setTimeout (()=>{
      console.log("finished " + value);
      this.isWaiting = false;
      this.currentSearchText = value;
    },2000)
  }

  ngOnInit() {
    this.pagedRequest.currentPage = 1;
    this.pagedRequest.itemsPerPage = 10;
    this.showUserError = false;
    this.checkingUserDetails = true;
    this.userService.getCurrentUser().subscribe(
      (x: UserProfile) => {
        this.displayMessage = 'Posting as ' + this.userService.getDisplayName(x.personalDetails);
        this.loading = false;
        this.showUserError = false;
        this.checkIfAdmin(x.userAccount.roles)
        this.subPersonId = x.userAccount.username
        this.checkingUserDetails = false;

        this.route.queryParams.subscribe(params => {
          if (params && params['discussionId']) {
            this.discussionId = params['discussionId']
            this.setUpTheForm()
            this.titleService.setTitle('Edit a forum');
          } else {
            //error as no discussion id, unless add
            if (this.formMode != undefined) {
              this.showIdError = true;
            } else {
              this.setUpTheForm();
              this.titleService.setTitle('Add a forum');
            }
          }
        })
      },
      error => {
        this.loading = false;
        this.showUserError = true;
        this.checkingUserDetails = false;
        console.log(error);
      })

    this.listLink = this.router.url.substring(0, this.router.url.lastIndexOf('/') + 1) + '-4';
  }

  ngOnDestroy(): void {
    if (this.theSubscription)
      this.theSubscription.unsubscribe();
  }

  areTagsExist() {
    return this.activeTagList.length > 0
  }

  setUpTheForm() {
    this.showIdError = false;
    if (this.formMode === FormMode.Edit) {
      this.forumService.getDiscussion(this.courseCode, this.discussionId).subscribe(
        response => {
          if (response.operationSucceeded) {
            // highlight appr tags
            response.data.tags.forEach(tag => {
              this.initialTags.push(tag);
            });

            response.data.isFacultyFavourite ? this.facultyFavourite = true : this.facultyFavourite = false;

            this.discussionForm = this.fb.group({
              title: [response.data.title, [Validators.required, Validators.maxLength(this.titleCharacterLimit)]],
              description: [response.data.description, [Validators.required, Validators.maxLength(this.descriptionCharacterLimit)]],
            });
          } else {
            this.showIdError = true;
          }

          this.loadingDiscussion = false;
        },
        error => {
          this.loadingDiscussion = false;
          console.log(error)
        }
      )
    } else {
      this.discussionForm = this.fb.group({
        title: ['', [Validators.required, Validators.maxLength(this.titleCharacterLimit)]],
        description: ['', [Validators.required, Validators.maxLength(this.descriptionCharacterLimit)]],
      });
      this.loadingDiscussion = false;
    }
  }

  submitForm() {
    this.creatingDiscussion = true;
    this.discussionForum = this.createDiscussionObject();
    if (this.formMode === FormMode.Edit) {
      this.updateDiscussion();
    } else {
      this.addDiscussion();
    }
  }

  addDiscussion() {
    this.forumService.addDiscussion(this.courseCode, this.discussionForum).subscribe(
      (addResponse: OperationResponse<ForumAdd>) => {
        //need to check for operation succeeded
        if (addResponse.operationSucceeded) {
          this.clearTheForm();
          this.creatingDiscussion = false;
          this.routeToListPage(addResponse.data.discussionId);
        } else {
          console.log('add discussion fail', addResponse.notifications)
          this.showError = true;
          this.errorMessage = 'Error updating forum';
          this.creatingDiscussion = false;
        }
      },
      error => {
        console.log('added discussion ERROR', error)
        this.creatingDiscussion = false;
      }
    )
  }

  updateDiscussion() {
    this.discussionForum.isFacultyFavourite = this.facultyFavourite;
    this.forumService.updateDiscussion(this.courseCode, this.discussionForum).subscribe(
      (updateResponse: OperationResponse<ForumAdd>) => {
        if (updateResponse.operationSucceeded) {
          // subscription will handle itself
          this.clearTheForm();
          this.creatingDiscussion = false;
          this.routeToListPage(updateResponse.data.discussionId);
        } else {
          this.showError = true;
          this.errorMessage = 'Error updating forum';
          this.creatingDiscussion = false;
        }
      },
      error => {
        console.log('updated discussion ERROR', error)
        this.creatingDiscussion = false;
      }
    )
  }

  createDiscussionObject() {
    let forum = new ForumAdd();
    forum = {
      id: this.formMode === FormMode.Edit ? this.discussionId : null,
      discussionId: this.formMode === FormMode.Edit ? this.discussionId : null,
      description: this.discussionForm.get('description').value,
      title: this.discussionForm.get('title').value,
      tags: this.activeTagList,
      type: DiscussionType.courseForum,
      isAwaitingSynchronization: false,
      moduleId: 0,
      sectionId: 0,
      courseCode: this.courseCode,
      version: '',
      uri: '',
      isFacultyFavourite: this.facultyFavourite
    }

    return forum;
  }

  clearTheForm() {
    this.newSubscription = false;
    this.activeTagList = []
    this.discussionForm.reset();
    this.tagComp.clearTags();
  }

  checkIfAdmin(roles: UserRole[]) {
    let roleMatch = roles.find(role => (role === UserRole.Faculty) || (role === UserRole.PortalAdministrator));
    roleMatch ? this.isAnAdmin = true : this.isAnAdmin = false;
  }

  updateTagsToSave(tags) {
    this.activeTagList = tags;
  }

  updateSubStatus(subscribing) {
    this.newSubscription = subscribing;
  }

  routeToListPage(id: string) {
    if (this.router.url != '/') {
      let link = this.router.url.substring(0, this.router.url.lastIndexOf('/') + 1) + '-6';
      this.router.navigate([link], { queryParams: { discussionId: id } });
    }
  }

  isEditModeAndIsNotAdmin() {
    return (this.formMode === FormMode.Edit) && !this.isAnAdmin
  }

  switchFacultyFav() {
    this.facultyFavourite = !this.facultyFavourite
  }
}
