import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { OperationResponse } from '@app/common/models/operation-response';
import { ServiceBase } from '@app/core/service-base';
import { DashboardText } from './dashboard-text';

@Injectable({
    providedIn: 'root'
})
export class DashboardService extends ServiceBase {
    private _dashboardTextUriUri: string;

    get dashboardTextUri(): string {
        this._dashboardTextUriUri = 'personalisation/dashboardtext';
        return this._dashboardTextUriUri;
    }
    set dashboardTextUri(theUri: string) {
        this._dashboardTextUriUri = theUri;
    }

    public getDashboardText(): Observable<OperationResponse<DashboardText>> {
        return new Observable<OperationResponse<DashboardText>>(subscriber => {
            this.getObjectRemoteStoreFirst(this.dashboardTextUri, subscriber);
        });
    }
}
