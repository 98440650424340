<h1> Course Forum</h1>
<div class="p-4" *ngIf="isOffline">
  <ebs-offline-message [isOffline]="isOffline" [offlineMessage]="forumOfflineMessage"></ebs-offline-message>
</div>

<ebs-spinner [spinnerMessage]="'loading....'" *ngIf="checkingUserDetails"></ebs-spinner>
<div *ngIf="isEditModeAndIsNotAdmin() && !checkingUserDetails">
  <ebs-message-simple [iconCssClasses]="'text-info fa fa-fw fa-exclamation-circle fa-lg'" [textCssClasses]="'text-info'"
    [displayMessage]="'You do not have permission to perform this action'" [displayOnRight]="false">
    </ebs-message-simple>
</div>
<div class="card" *ngIf="(!isEditModeAndIsNotAdmin() && !loading && !isOffline)">
  <div class="card-body">
    <h2 class="ml-3"> 
      <span *ngIf="formMode != 'edit'">
        Create new</span> 
      <span *ngIf="formMode === 'edit'">
        Update</span> 
        topic
      </h2>

    <ebs-message-simple [iconCssClasses]="'text-info fa fa-fw fa-exclamation-circle fa-lg'"
      [textCssClasses]="'text-info'" [displayMessage]="'Unable to retrieve user details'" [displayOnRight]="false"
      *ngIf="showUserError">
    </ebs-message-simple>

    <ebs-message-simple [displayMessage]="noDiscussionIdMessage" [displayOnRight]="true" *ngIf="showIdError">
  </ebs-message-simple>

    <ebs-spinner [spinnerMessage]="'loading....'" *ngIf="loading"></ebs-spinner>
    <div *ngIf="!loading && !loadingDiscussion">
      <form class="form-group" [formGroup]="discussionForm" (ngSubmit)="submitForm()" style="margin-bottom: 20px;border-radius: 6px;
        padding: 1rem;">
        <label>
          Title:
        </label>
        <label>
          <input (change)="changed($event)" (keyup)="keyPressed($event)" class="form-control" type="text" formControlName="title">
          <div
            *ngIf="discussionForm.controls.title.invalid && (discussionForm.controls.title.dirty || discussionForm.controls.title.touched)"
            class="alert alert-danger">
            <div *ngIf="discussionForm.controls.title.errors.required"> Title is required. </div>
            <div *ngIf="discussionForm.controls.title.errors.maxlength"> Title must be {{titleCharacterLimit}}
              characters or less.
            </div>
          </div>
        </label>
        <div *ngIf="itemsFound">
          <p></p>
          <i class="text-info fa fa-fw fa-exclamation-circle ng-star-inserted" ng-reflect-ng-class="text-info fa fa-fw fa-exclamat"></i>
          <span class="text-info ng-star-inserted">We found the following forum search results that might be related to your topic</span>
        </div>
        <ebs-forum-search *ngIf="formMode != 'edit'" (itemsFoundEvent)="setItemsFound($event)" [hideInput]="hideInput" [topicSearchText] = "currentSearchText" [courseCode]="courseCode"></ebs-forum-search>

        <label class="mb-3">
          Description:
          <editor [init]="editorConfig" formControlName="description"></editor>
          <div
            *ngIf="discussionForm.controls.description.invalid && (discussionForm.controls.description.dirty || discussionForm.controls.description.touched)"
            class="alert alert-danger">
            <div *ngIf="discussionForm.controls.description.errors.required"> Description is required. </div>
            <div *ngIf="discussionForm.controls.description.errors.maxlength"> Description must be
              {{descriptionCharacterLimit}} characters or less.</div>
          </div>
        </label>

        <ebs-tags [tagsMarkedAsActive]="initialTags" [courseCode]="courseCode" (tagsToSave)="updateTagsToSave($event)"
          [canAddTag]='isAnAdmin'></ebs-tags>

        <ebs-message-simple [displayMessage]="noTag" [displayOnRight]="true" [textCssClasses]="'text-warning'"
          [iconCssClasses]="'text-warning fa fa-fw fa-exclamation-circle'" *ngIf="!areTagsExist()">
        </ebs-message-simple>

          <div class="d-flex flex-row-reverse mt-3 faculty-fav" *ngIf="isAnAdmin">
            <label class="switch float-right ml-2 mr-3 mt-2" >
              <input type="checkbox" [checked]="facultyFavourite" (click)="switchFacultyFav()">
              <span class="slider round"></span>
            </label>
            <p>Pin as a Faculty Favourite</p>
          </div>
          
        <div class="row mt-2">
          <div class="col-6">
            <a class="btn btn-light btn-block" [routerLink]="listLink">Cancel</a>
          </div>
          <div class="col-6">
            <button id="submit-button" class="btn btn-primary btn-block" type="submit"
              [disabled]="!discussionForm.valid || !areTagsExist()" *ngIf="!creatingDiscussion">
              <span *ngIf="formMode != 'edit'; then addMode else editMode"></span>
              <ng-template #addMode>
                <span class="d-none d-xl-block"> Create Topic</span>
                <span class="d-xl-none"> Create</span>
              </ng-template>
              <ng-template #editMode>
                <span class="d-none d-xl-block"> Update Topic</span>
                <span class="d-xl-none"> Update</span>
              </ng-template>
            </button>
            <ebs-message-simple [displayMessage]="errorMessage" *ngIf="showError"></ebs-message-simple>
            <ebs-spinner [spinnerMessage]="'processing..'" *ngIf="creatingDiscussion"></ebs-spinner>
          </div>
          <div class="col-12">
            <span class="pt-2 float-right gdpr-message" tooltip="{{gdprMessage}}" containerClass="customTooltip">
              <ebs-message-simple [iconCssClasses]="'text-info fa fa-fw fa-exclamation-circle fa-lg'"
                [textCssClasses]="'text-info'" [displayMessage]="displayMessage" [displayOnRight]="false">
              </ebs-message-simple>
            </span>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>