import { Injectable } from "@angular/core";
import { ModuleDownloadMonitoring } from "../models/module-download-monitoring";
import { ModuleStructure } from "../models/module-structure";
import { ContentItemInfo } from "../models/content-item-info";

@Injectable({
    providedIn: 'root'
})
export class SynchronisingDownloadsService {
    adjustLatestModulestructure(itemListName: string, uri: string, moduleStructure: ModuleStructure, isDownloadAdjustment: boolean = true): void {
        if (moduleStructure == null || uri == null || uri == '' || itemListName == null || itemListName === '') {
          return;
        }
    
        const items: Array<ContentItemInfo> = moduleStructure[itemListName];
        if (items == null || items.length === 0) { return; }
    
        const item = items.find(x => x.uri === uri);
    
        if (item == null) { return; }
    
        item.version = isDownloadAdjustment === true ? item.availableServerVersion : null;
        item.availableServerVersion = null;
    }
    
    adjustLatestModulestructureSeq(uri: string, moduleStructure: ModuleStructure, isDownloadAdjustment: boolean = true): void {
        
        if (moduleStructure == null || uri == null || uri == '') {
          return;
        }
    
        if (uri.indexOf('/files') > -1){
          uri = uri.replace('/files', '/files/details');
        }
    
        let item = moduleStructure.activityItems.find(x => x.uri === uri);
    
        if (item == null) { 
          item = moduleStructure.sections.find(x => x.uri === uri);
        }
    
        if (item == null) { 
          item = moduleStructure.files.find(x => x.uri === uri);
        }
    
        if (item == null) { 
          item = moduleStructure.videos.find(x => uri.indexOf(x.uri) > -1);
        }
    
        if (item == null) { return; }
    
        item.version = isDownloadAdjustment === true ? item.availableServerVersion : null;
        item.availableServerVersion = null;    
    }
    
    adjustMonitoringSeq(uri: string, monitoring: ModuleDownloadMonitoring, isDownloadAdjustment: boolean = true): void {
        
        if (monitoring == null || uri == null || uri == '') {
          return;
        }
    
        if (uri.indexOf('/files') > -1){
          uri = uri.replace('/files', '/files/details');
        }
    
        let item = monitoring.activityItems.find(x => x.uri === uri);
    
        if (item == null) { 
          item = monitoring.sections.find(x => x.uri === uri);
        }
    
        if (item == null) { 
          item = monitoring.files.find(x => x.uri === uri);
        }
    
        if (item == null) { 
          item = monitoring.videos.find(x => uri.indexOf(x.uri) > -1);
        }
    
        if (item == null) { return; }
    
        item.isDownloaded = isDownloadAdjustment;
    }
}