<div class="main-content">
  <ebs-spinner class="px-3 py-1" [spinnerMessage]="'dashboard loading..'" [faColour]="'#8031A7'" [boxColour]="'#8031A7'"
    *ngIf="checkingOfflineStatus" [boxPadding]="'5px 15px'"></ebs-spinner>

  <div class="scrollable full-height-minus-header" *ngIf="!checkingOfflineStatus">
    <div class="container px-md-0">
      <h1 *ngIf="!isDemoUser" class="my-3">Welcome {{userPreferredName}}</h1>
      <h1 *ngIf="isDemoUser" class="my-3">Welcome to the Edinburgh Business School Student Portal</h1>

      <ebs-spinner class="px-3 py-1" [spinnerMessage]="'loading dashboard info..'" [faColour]="'#8031A7'"
      [boxColour]="'#8031A7'" *ngIf="loadingHeaderFooter" [boxPadding]="'5px 15px'"></ebs-spinner>
      <div *ngIf="!loadingHeaderFooter && !loadingCourses" class="card dashboard-card">
        <div [innerHTML]='header'></div>
      </div>

      <div class="card" *ngIf="isOffline">
        <div class="card-body">
          <p class="text-info mb-0 d-flex">
            <span class="h3 icon-gecko-offline mr-3"></span>
            Some features of your Student Portal may be unavailable offline.
          </p>
        </div>
      </div>
      <ebs-spinner class="px-3 py-1" [spinnerMessage]="'courses loading..'" [faColour]="'#8031A7'"
        [boxColour]="'#8031A7'" *ngIf="loadingCourses" [boxPadding]="'5px 15px'"></ebs-spinner>
      <div *ngIf="!loadingCourses"></div>
      <ng-container *ngIf="userProfile !== undefined">
        <h2>Courses</h2>
        <div class="card-deck" [ngClass]="{ 'card-deck-compressed': useCompressedLayout }">
          <ng-container *ngFor="let courseEnrolment of courseEnrolments">
            <div *ngIf="courseEnrolment" class="card course-card">
              <div class="card-body">
                <a *ngIf="(!isOffline || courseEnrolment.isDownloaded) && courseEnrolment.isLearningPlatformEnabled && courseEnrolment.studentIsRegistered" class="link-area"
                  [routerLink]="'/courses/' + courseEnrolment.courseCode">
                  <div class="title link" [ngClass]="{ 'h4': !useCompressedLayout }">{{courseEnrolment.courseName}}</div>
                  <div class="button" *ngIf="courseEnrolment.studentIsRegistered">
                    <span class="btn btn-primary" role="button" aria-label="load course button">
                      <span class="icon-gecko-direction-right"></span>
                    </span>
                  </div>
                </a>
                <a *ngIf="(!isOffline || courseEnrolment.isDownloaded) && !courseEnrolment.isLearningPlatformEnabled && courseEnrolment.studentIsRegistered" class="link-area"
                  [href]="courseEnrolment.fullLink">
                  <div class="title link" [ngClass]="{ 'h4': !useCompressedLayout }">{{courseEnrolment.courseName}}</div>
                  <div class="button" *ngIf="courseEnrolment.studentIsRegistered">
                    <span class="btn btn-primary" role="button" aria-label="load course button">
                      <span class="icon-gecko-direction-right"></span>
                    </span>
                  </div>
                </a>
                <div *ngIf="(isOffline && !courseEnrolment.isDownloaded) || !courseEnrolment.studentIsRegistered">
                  <div class="title" [ngClass]="{ 'h4': !useCompressedLayout }">{{courseEnrolment.courseName}}</div>
                  <div class="button" *ngIf="courseEnrolment.studentIsRegistered">
                    <span class="btn btn-primary disabled" role="button" aria-label="load course button disabled">
                      <span class="icon-gecko-direction-right"></span>
                    </span>
                  </div>
                </div>
                <div class="btn-block" *ngIf="!isOffline && !courseEnrolment.studentIsRegistered">
                  <span class="btn {{registrationRequiredButtonClass(courseEnrolment)}} btn-min-width" role="button" aria-label="load course button"
                    (click)="registrationRequiredOpenPopup(courseEnrolment)">
                    {{registrationRequiredButtonMessage(courseEnrolment)}}
                  </span>
                </div>
                <ng-container *ngIf="isOffline">
                  <div class="text mt-2 mt-sm-3" *ngIf="isOffline">
                    <span
                      *ngIf="courseEnrolment.isLearningPlatformEnabled && !courseEnrolment.isDownloaded && !courseModuleLoading">
                      <span class="text-warning icon-gecko-unavailable mr-1"></span>
                      No course modules have been downloaded for offline viewing on this device.
                    </span>
                    <span class="mr-1"
                      *ngIf="courseEnrolment.isLearningPlatformEnabled &&courseEnrolment.isDownloaded && courseEnrolment.numberOfDownloadedModules > 1">
                      {{courseEnrolment.numberOfDownloadedModules}} modules available for offline study
                    </span>
                    <span class="mr-1"
                      *ngIf="courseEnrolment.isLearningPlatformEnabled &&courseEnrolment.isDownloaded && courseEnrolment.numberOfDownloadedModules === 1">
                      {{courseEnrolment.numberOfDownloadedModules}} module available for offline study
                    </span>
                    <span class="mr-1" *ngIf="isOffline && !courseEnrolment.isLearningPlatformEnabled">
                      <span class="text-warning icon-gecko-unavailable mr-1"></span>
                      This course is not available for offline access via your student portal. Please download the
                      course
                      text via PDF or ebook format when you have internet access.
                    </span>
                  </div>

                </ng-container>
              </div>
            </div>
          </ng-container>
        </div>
        <ebs-coursera-lti [useCompressedLayout]="useCompressedLayout"></ebs-coursera-lti>
        <ebs-dashboard-menu [isWorkingOffline]="isOffline" [useCompressedLayout]="useCompressedLayout">
        </ebs-dashboard-menu>
      </ng-container>
      <div *ngIf="footer && footer !=''" class="card">
        <div class="card-body">
          <div [innerHTML]='footer' class="container px-md-0"></div>
        </div>
      </div>
    </div>
  </div>
</div>