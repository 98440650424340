<div id="progress-complete-id" class="card" *ngIf="courseOutline">
  <div class="card-header">
    <h2>{{progressHeader}}</h2>
  </div>
  <!--not complete-->
  <ebs-message-simple [displayMessage]="errorMessage" *ngIf="errorOccured">
    {{errorLocation}}
  </ebs-message-simple>


  <div class="card-body" *ngIf="!showProgress" [@enterOrLeave]="showProgress">
    <ebs-offline-message [isOffline]='isOffline' [offlineMessage]='offlineMessage'></ebs-offline-message>
    <ng-container *ngIf="!isOffline">
      <div class="row">
        <div class="col-sm-12">
          <p> {{progressIncompleteTextLine1}}</p>
        </div>
      </div>
      <div class="row complete-row">
        <div class="col-sm-12 col-lg-6 offset-lg-6 text-center">
          <button class="btn btn-primary btn-block btn-complete" (click)="markAsComplete(true)">
            <i class="far fa-fw fa-check fa-lg"></i>
            <span style="padding-left:6px;">Mark as complete</span></button>
        </div>
      </div>
    </ng-container>


  </div>
  <!--Complete-->
  <div class="card-body" *ngIf="showProgress" [@enterOrLeave]="!showProgress">
    <ebs-offline-message [isOffline]='isOffline' [offlineMessage]='offlineMessage'></ebs-offline-message>
    <ng-container *ngIf="!isOffline">
      <div *ngIf="progressData">
        <div class="row progress-row">
          <div class="col-sm-4 float-right">
            Module: {{progressData.moduleName}}
          </div>
          <div class="col-sm-4 progress-row-bar">
            <ebs-progress-bar [progressWrapperWidth]="100" [showValueInBar]="false"
              [progressValue]="getProgressValue()"></ebs-progress-bar>
          </div>

          <div class="col-sm-4 float-left">
            {{getProgressValue()}}% Complete <br>
            {{progressData.moduleSectionsIncomplete}} sections remaining
          </div>
        </div>
        <hr>
        <div class="row progress-row">
          <div class="col-sm-4 float-right">
            Overall
          </div>
          <div class="col-sm-4 progress-row-bar">
            <ebs-progress-bar [progressWrapperWidth]="100" [showValueInBar]="false"
              [progressValue]="getOverallProgress()"></ebs-progress-bar>
          </div>
          <div class="col-sm-4 float-left">
            {{getOverallProgress()}}% Complete
          </div>
        </div>

        <div class="row congrats-row">
          <div class="col-xs-12 col-sm-3 col-md-2 text-success text-center" *ngIf="!isIEOrEdge">
            <svg version="1.1" viewBox="0 0 150 150">
              <circle class="path circle" fill="none" stroke="#97D700" stroke-width="6" stroke-miterlimit="10" cx="65.1"
                cy="65.1" r="62.1" />
              <polyline class="path check" fill="none" stroke="#97D700" stroke-width="12" stroke-linecap="round"
                stroke-miterlimit="10" points="100.2,40.2 51.5,88.8 29.8,67.5" />
            </svg>
          </div>
          <div class="col-xs-12 col-sm-3 col-md-2 text-success text-center" *ngIf="isIEOrEdge">
            <i class="fas fa-check-circle fa-3x"></i>
          </div>
          <div class="col-xs-12 col-sm-9 col-md-10 congrats-text">
            <span> {{sectionCongrats}} </span> <br>
            <span class="btn btn-link" (click)="markAsComplete(false)"> {{resetStatus}} </span>
          </div>
        </div>
      </div>
      <div *ngIf="!progressData">
        <h6 class="text-danger">Sorry, there is no progress data. Please contact an administrator</h6>
      </div>
    </ng-container>
  </div>

</div>