<div *ngIf="postingData">
  <ebs-spinner [spinnerMessage]="'sending reply...'"></ebs-spinner>
</div>
<div *ngIf="dataBeingDeleted">
  <ebs-spinner class="px-3 py-1" [spinnerMessage]="'deleting item...'"></ebs-spinner>
</div>

<div *ngIf="!postingData">
  <div class="discussion-item d-flex flex-column flex-sm-row discussion-item-post"
    [class.is-my-entry]="discussionPostOrReplyItem.isMyEntry" [class.reply-row]="isAReply"
    [class.discussion-item]="!isAReply">

    <div class="comment-box-user d-flex flex-row align-items-center flex-sm-column align-items-sm-start">
      <ebs-discussion-userprofile [userCountryFlag]="discussionPostOrReplyItem.person.countryOfResidence"
        [userAvatar]="discussionPostOrReplyItem.person.photo" [isFaculty]="discussionPostOrReplyItem.person.isFaculty"
        [displayName]="discussionPostOrReplyItem.isMyEntry ? 'posted by me' : discussionPostOrReplyItem.person.name"
        [displayDate]="discussionPostOrReplyItem.clientModifiedAt">
      </ebs-discussion-userprofile>
    </div>
    <div class="comment-box-main flex-grow-1">
      <div class="row">
        <div class="col-12">
          <div [innerHTML]="discussionPostOrReplyItem.content"></div>
        </div>
      </div>
      <div class="row mt-2">
        <div class="col-12">
          <ebs-attachments [attachments]="discussionPostOrReplyItem.attachments"></ebs-attachments>
        </div>
      </div>
      <div class="row mt-2">
        <div class="col-12 offset-lg-4 col-lg-8 reply-like-button">
          <!--delete-->
          <button class="btn btn-sm btn-link float-right" (click)="confirm()" *ngIf="canDelete() && !isOffline">
            <i class="fal fa-trash-alt fa-2x"></i>
            <span class="sr-only">Delete Button</span></button>
          <!--LIke-->
          <ebs-discussion-like class="float-right" (updateLikeEvent)="handleLikeBubbleEvent($event)" *ngIf="currentUser"
            [passedInlike]="getMyLike()" [isMyPostOrReply]="discussionPostOrReplyItem.isMyEntry" [currentUser]="currentUser">
          </ebs-discussion-like>
        </div>
      </div>
    </div>
  </div>

  <ebs-notification-multiple [notifications]="notifications" *ngIf="showError"></ebs-notification-multiple>
</div>