import { ServiceBase } from './service-base';
import { Injectable, Inject, EventEmitter, OnDestroy } from '@angular/core';
import { OfflineModeStatus } from './offline-mode-status';
import { Observable, Subscription } from 'rxjs';
import { ApplicationEventDispatcher } from './application-event-dispatcher.service';
import { ApplicationEventType } from './application-event-type';
import { StaticText } from '@app/common/static-text';
import { ApplicationEvent } from './application-event';
import { ObjectStoreBase } from './object-store-base';

@Injectable({
    providedIn: 'root'
})
export class OfflineModeStatusService implements OnDestroy {
    
    private offlineEventSubscription: Subscription;
    
    private offlineModeStatusUri = StaticText.offlineModeStatus;

    public offlineModeEnabledStatus = new EventEmitter<boolean>(true);
    
    constructor(private objectStore: ObjectStoreBase,
    @Inject(ApplicationEventDispatcher) private applicationEventDispatcher: ApplicationEventDispatcher) {
        this.offlineEventSubscription = this.applicationEventDispatcher.offlineModeRelatedEvent.subscribe((event: ApplicationEvent) => {
            if (event.type === ApplicationEventType.IndexedDbCleared) {
                this.offlineModeEnabledStatus.emit(false);
            }});
    }

    ngOnDestroy(): void {
        this.offlineEventSubscription.unsubscribe();
    }

    public enable(): Observable<OfflineModeStatus> {
        const offlineModeStatus = new OfflineModeStatus();
        offlineModeStatus.uri = this.offlineModeStatusUri;
        offlineModeStatus.isEnabled =  true;
        offlineModeStatus.hasAcceptedTermsAndConditions =  true;

        const enableObs = new Observable<OfflineModeStatus>(observer => {

            this.objectStore.addOrUpdate(offlineModeStatus.uri, offlineModeStatus).then(x => {
                this.offlineModeEnabledStatus.emit(true);
                observer.next(offlineModeStatus);
                observer.complete();
            },
                err => {
                    observer.error(err);
                    observer.complete();
                });
        });

        return enableObs;

    }

    public disable(): void {       
        this.applicationEventDispatcher.processOfflineModeRelatedEvent(ApplicationEventType.ClearIndexedDb);
    }

    public isEnabled(): Observable<boolean> {
        const isEnabledObs = new Observable<boolean>(subscriber => {

            this.objectStore.get(this.offlineModeStatusUri)
            .then((offlineModeStatus: OfflineModeStatus) => {
                if (offlineModeStatus && offlineModeStatus.isEnabled) {
                    subscriber.next(true);
                } else {
                    subscriber.next(false);
                }
                subscriber.complete();
            },
                err => {
                    console.log('Could not retrieve offline mode status: ' + err);
                    subscriber.next(false);
                    subscriber.complete();
                });
        });
        return isEnabledObs;
    }

    public hasAcceptedTermsAndConditions(): Observable<boolean> {

        const hasAcceptedTermsAndConditionsObs = new Observable<boolean>(subscriber => {

            this.objectStore.get(this.offlineModeStatusUri)
            .then((offlineModeStatus: OfflineModeStatus) => {
                if (offlineModeStatus && offlineModeStatus.hasAcceptedTermsAndConditions) {
                    subscriber.next(true);
                } else {
                    subscriber.next(false);
                }
                subscriber.complete();
            },
                err => {
                    console.log('Could not retrieve offline mode status: ' + err);
                    subscriber.next(false);
                    subscriber.complete();
                });
        });
        return hasAcceptedTermsAndConditionsObs;
    }
}
