import { Injectable } from '@angular/core';
import { VideoActivity } from '../models/video-activity';
import { ServiceBase } from '@app/core/service-base';
import { Observable } from 'rxjs';
import { OperationResponse } from '@app/common/models/operation-response';

@Injectable({
    providedIn: 'root',
  })
export class MediaActivityService extends ServiceBase {
    downloadedVideoContent: ArrayBuffer;

    getMediaActivity(itemUrl, treatAsOffline): Observable<OperationResponse<VideoActivity>> {
        return this.getObject<VideoActivity>(itemUrl, treatAsOffline);
    }

}
