import { Injectable } from '@angular/core';
import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse
} from '@angular/common/http';

import { Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { ApplicationEventDispatcher } from './application-event-dispatcher.service';
import { StringLiterals } from '@app/common/string-literals';

@Injectable()
export class HttpRequestInterceptor implements HttpInterceptor {

  hasBypassHeader : boolean = false;
  isDownloadRequest : boolean = false;

  constructor(public applicationEventDispatcher: ApplicationEventDispatcher) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (req.headers && req.headers.has(StringLiterals.interceptorBypassHeaderKey)){
      this.hasBypassHeader = true;      
    }

    if (req.headers && req.headers.has(StringLiterals.interceptorDownloadHeaderKey)){
      this.isDownloadRequest = true;
      this.applicationEventDispatcher.processDownloadRequest(true, false);
    }

    return next.handle(req).pipe(
      map((event: HttpEvent<any>) => {
       
        if (event instanceof HttpResponse && this.hasBypassHeader === false) {

          this.applicationEventDispatcher.processHttpResponseStatus(event.status);
        }

        if (event instanceof HttpResponse && this.isDownloadRequest === true){
          this.applicationEventDispatcher.processDownloadRequest(true, true);
        }
        return event;
      }),
      catchError((err: HttpErrorResponse) => {
        console.info('INTERCEPTED HttpErrorResponse with error =', err, ';');

        if (this.hasBypassHeader === false){
          this.applicationEventDispatcher.processHttpResponseStatus(err.status > 0 ? err.status : 503);
        }

        if (this.isDownloadRequest === true){
          this.applicationEventDispatcher.processDownloadRequest(false, false);
        }

        return throwError(err);
      })) as Observable<HttpEvent<any>>;
  }
}