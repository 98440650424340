import { EventEmitter, Injectable, OnDestroy, Inject } from '@angular/core';
import { OnlineStatusType } from './online-status-type.enum';
import { ObjectStore } from '@app/core/object-store';
import { IsWorkingOffline } from '../models/is-working-offline';
import { StaticText } from '../static-text';
import { Observable } from 'rxjs';
import { ObjectStoreBase } from '@app/core/object-store-base';

@Injectable({
  providedIn: 'root'
})
export class OnlineStatusService implements OnDestroy {
  public status = new EventEmitter<OnlineStatusType>(true);
  public internetConnectionStatus = new EventEmitter<OnlineStatusType>(true);

  static EVENT_TYPE_ONLINE = 'online';
  static EVENT_TYPE_OFFLINE = 'offline';

  isWorkingOfflineUri = StaticText.isWorkingOffline;

  constructor(private objectStore: ObjectStoreBase) {
    this.bind();
    window.addEventListener(OnlineStatusService.EVENT_TYPE_ONLINE, this.onOnline);
    window.addEventListener(OnlineStatusService.EVENT_TYPE_OFFLINE, this.onOffline);
  }

  setIsWorkingOffline():Observable<boolean>{
    
    let isWorkingOffline = new IsWorkingOffline();
    isWorkingOffline.uri = this.isWorkingOfflineUri;

    const setIsWorkingOfflineObs = new Observable<boolean>(observer => {
      this.objectStore.addOrUpdate(StaticText.isWorkingOffline, isWorkingOffline)
      .then(x => {
        observer.next(true);
        this.status.emit(OnlineStatusType.OFFLINE);
        observer.complete();
      }, onRejected => {
        observer.next(false);
        observer.complete();
      });
    });
    return setIsWorkingOfflineObs;
  }

  removeIsWorkingOffline():Observable<boolean>{

    const removeIsWorkingOfflineObs = new Observable<boolean>(observer => {
      this.objectStore.remove(this.isWorkingOfflineUri)
      .then(x => {
        observer.next(true);
        this.status.emit(OnlineStatusType.ONLINE);
        observer.complete();
      }, onRejected => {
        observer.next(false);
        observer.complete();
      });
    });
    return removeIsWorkingOfflineObs;
  }

  isWorkingOffline():Observable<boolean>{
    const isWorkingOfflineObs = new Observable<boolean>(observer => {
      this.objectStore.get(this.isWorkingOfflineUri)
      .then(x => {
        if (x != null)
          observer.next(true);
        else
          observer.next(false);
        observer.complete();
      }, onRejected => {
        observer.next(false);
        observer.complete();
      });
    });
    return isWorkingOfflineObs;
  }

  ngOnDestroy() {
    window.removeEventListener(OnlineStatusService.EVENT_TYPE_ONLINE, this.onOnline);
    window.removeEventListener(OnlineStatusService.EVENT_TYPE_OFFLINE, this.onOffline);
  }

  public getWindowNavigatorStatus():OnlineStatusType{
    return window.navigator.onLine ? OnlineStatusType.ONLINE : OnlineStatusType.OFFLINE;
  }

  public getCurrentStatus(): Observable<OnlineStatusType> {
    const getCurrentStatusObs = new Observable<OnlineStatusType>(observer => {

      this.isWorkingOffline().subscribe(x => {
          if (x === true){
            observer.next(OnlineStatusType.OFFLINE);            
          }
          else{
            observer.next(this.getWindowNavigatorStatus());
          }

          observer.complete();
      }, error =>{
        observer.next(this.getWindowNavigatorStatus());
        observer.complete();
      });
    });

    return getCurrentStatusObs;
  }

  private bind() {
    this.onOnline = this.onOnline.bind(this);
    this.onOffline = this.onOffline.bind(this);
  }

  private onOnline() {
    this.internetConnectionStatus.emit(OnlineStatusType.ONLINE);
    this.isWorkingOffline().subscribe(x=>{
      if (x){
        this.status.emit(OnlineStatusType.OFFLINE);
      }
      else{
        this.status.emit(OnlineStatusType.ONLINE);
      }
    }, error=>{
      this.status.emit(OnlineStatusType.ONLINE);
    });
    
  }

  private onOffline() {
    this.status.emit(OnlineStatusType.OFFLINE);
    this.internetConnectionStatus.emit(OnlineStatusType.OFFLINE);
  }

  public isOffline(): Observable<boolean> {
    const isOfflineObs = new Observable<boolean>(observer => {
      this.getCurrentStatus().subscribe(x => {
        if (x === OnlineStatusType.OFFLINE){
          observer.next(true);
        } else {
          observer.next(false);
        }
        observer.complete();
      }, error=>{
        observer.next(false);
        observer.complete();
      });
    });

    return isOfflineObs;
  }

  public isIEOrEdge(): boolean {
    return /msie\s|trident\/|edge\//i.test(window.navigator.userAgent);
  }

  public isFireFox(): boolean {
    return navigator.userAgent.toLowerCase().indexOf('firefox') > -1;
  }

  public isIos(): boolean {
    //!window.MSStream fails prod build so checking for 'like iphone' whoch can be in IE user agent
    //return /iPad|iPhone|iPod/.test(window.navigator.userAgent) && !(navigator.userAgent.toLowerCase().indexOf('like iphone') > -1);
    return true;
    //return false;
  }
}
