import { Injectable } from '@angular/core';
import { CourseStructure } from '@app/courses/models/course-structure';
import { Observable, Subscriber } from 'rxjs';
import { ServiceBase } from '@app/core/service-base';
import { OperationResponse } from '@app/common/models/operation-response';
import { DataStoreStrategy } from '@app/core/data-store-strategy';
import { ModuleStructure } from '@app/downloads/models/module-structure';
import { Module } from '../models/module';
import { ModuleDownloadMonitoring } from '@app/downloads/models/module-download-monitoring';
import { ModuleDeletiondMonitoring } from '@app/downloads/models/module-deletion-monitoring';
import { map } from 'rxjs/operators';
import { StorableObject } from '@app/core/storable-object';

@Injectable({
  providedIn: 'root'
})
export class CourseNavigationService extends ServiceBase {
  // remove when app-context is working, or just store url
  private _courseOutline: CourseStructure;
  get courseOutline(): CourseStructure {
    return this._courseOutline;
  }

  set courseOutline(theCourseOutline: CourseStructure) {
    this._courseOutline = theCourseOutline;
  }

  public getApiServerUri(): string {
    return super.getApiServerUri();
  }

  getCourseUri(courseCode: string): string {
    return `courses/${courseCode}`;
  }

  // when we get the course structure we need to calculate the progress and store it locally
  getCourseStructure(courseCode: string, treatAsOffline: boolean): Observable<OperationResponse<CourseStructure>> {
    return this.getObject(this.getCourseUri(courseCode), treatAsOffline).pipe(map(
      res => {
        let ammendedCourse = <OperationResponse<CourseStructure>>res; let completeSections = 0; let totalSections = 0;

        ammendedCourse.data.modules.forEach(
          (mod) => {
            let sectionsCompleteLength = mod.sections.filter(section => section.completed === true).length
            mod.progressValue = sectionsCompleteLength / mod.sections.length * 100;
            completeSections += sectionsCompleteLength;
            totalSections += mod.sections.length;
            mod.progressMax = 100;
            mod.progressMin = 0;
          }
        );
        ammendedCourse.data.sectionsComplete = completeSections;
        ammendedCourse.data.sectionsInTotal = totalSections;
        return ammendedCourse;
      }
    ))
  }

  getLocalCourseStructure(courseCode: string): Observable<CourseStructure> {
    return this.getObjectFromLocalStoreOnly(this.getCourseUri(courseCode));
  }

  setLocalCourseStructure(courseCode: string, courseStructure: CourseStructure): Observable<CourseStructure> {
    const courseStructureUri = this.getCourseUri(courseCode);
    courseStructure.uri = courseStructureUri;
    return this.setObjectInLocalStore(courseStructure, courseStructureUri);
  }

  //G.M. needs to syncronise with local db version or we will overwrite 
  //M.S. We need an extra method that does what you say; this method only fethces from the server, and does not write anything to indexed DB
  getLatestCourseStructure(courseCode: string): Observable<OperationResponse<CourseStructure>> {
    return this.getObjectFromRemoteStoreOnly(this.getCourseUri(courseCode));
  }

 
  getModuleStructureUri(courseCode: string, moduleId: number): string {
    return `courses/${courseCode}/modules/${moduleId}/structure`;
  }
 
  getLatestModuletructure(courseCode: string, moduleId: number, moduleStructure: ModuleStructure): Observable<ModuleStructure> {
    let downloadModuleContentObs = new Observable<ModuleStructure>(subscriber => {
      this.postObjectToRemoteStoreOnly<ModuleStructure>(moduleStructure, this.getApiServerUri() + this.getModuleStructureUri(courseCode, moduleId))
        .subscribe(x => {
          if (x && x.operationSucceeded === true && x.data) {
            subscriber.next(x.data);
            this.setObjectInLocalStore(x.data, this.getModuleStructureUri(courseCode, moduleId))
            .subscribe(x => {
              subscriber.complete();
            }, error => {
              subscriber.error(error);
              subscriber.complete();
            });
          } else {
            subscriber.error(moduleStructure);
            subscriber.complete();
          }
        }, error => {
          subscriber.error(error);
          subscriber.complete();
        });
    });
    return downloadModuleContentObs;
  }
  
  getLocalModuleStructure(courseCode: string, moduleId: number): Observable<ModuleStructure> {
    return this.getObjectFromLocalStoreOnly(this.getModuleStructureUri(courseCode, moduleId));
  }

  
  setLocalModuleStructure(courseCode: string, moduleId: number, moduleStructure: ModuleStructure): Observable<ModuleStructure> {
    const courseStructureUri = this.getModuleStructureUri(courseCode, moduleId)
    moduleStructure.uri = courseStructureUri;
    return this.setObjectInLocalStore(moduleStructure, courseStructureUri);
  }

  updateCourseStructureWithOfflineContentAvailability(downloadMonitoring: ModuleDownloadMonitoring,
    deletionMonitoring: ModuleDeletiondMonitoring,
    includeVideos: boolean, subscriber: Subscriber<string>): void {

    const courseCode: string = downloadMonitoring != null
      ? downloadMonitoring.courseCode
      : (deletionMonitoring != null ? deletionMonitoring.courseCode : null);

    this.getLocalCourseStructure(courseCode) //M.S. are we sure about this? what happens if it is missing from indexed db???
      .subscribe(courseStructure => {
        if (courseStructure != null) {

          if (downloadMonitoring != null) {
            this.synchroniseCourseStructure(courseStructure, downloadMonitoring, includeVideos);
          }

          if (deletionMonitoring != null) {
            this.synchroniseCourseStructureModuleDeletion(courseStructure, deletionMonitoring, includeVideos);
          }

          this.setLocalCourseStructure(courseCode, courseStructure)
            .subscribe(x => {
              subscriber.next(`Updated course structure with latest download/deletion info for course: ${courseCode}`);
              subscriber.complete();
            }, error => {
              subscriber.next(`Failed to locally store updated course structure with latest download/deletion info for course: ${courseCode}`);
              subscriber.complete();
            });
        } else {
          subscriber.next(`Failed to find course structure to update with latest download/deletion info for course: ${courseCode}`);
          subscriber.complete();
        }
      }, error => {
        subscriber.next(`Failed to update course structure with latest download/deletion info for course: ${courseCode}`);
        subscriber.complete();
      });
  }

  updateCourseStructureWithOfflineVideoAvailability(downloadMonitoring: ModuleDownloadMonitoring,
    deletionMonitoring: ModuleDeletiondMonitoring,
    subscriber: Subscriber<string>): void {

    const courseCode: string = downloadMonitoring != null
      ? downloadMonitoring.courseCode
      : (deletionMonitoring != null ? deletionMonitoring.courseCode : null);

    this.getLocalCourseStructure(courseCode) //M.S. are we sure about this? what happens if it is missing from indexed db???
      .subscribe(courseStructure => {
        if (courseStructure != null) {

          if (downloadMonitoring != null) {
            this.synchroniseCourseStructureForVideos(courseStructure, downloadMonitoring);
          }

          if (deletionMonitoring != null) {
            this.synchroniseCourseStructureModuleDeletionForVideos(courseStructure, deletionMonitoring);
          }

          this.setLocalCourseStructure(courseCode, courseStructure)
            .subscribe(x => {
              subscriber.next(`Updated course structure with latest download/deletion video info for course: ${courseCode}`);
              subscriber.complete();
            }, error => {
              subscriber.next(`Failed to locally store updated course structure with latest download/deletion video info for course: ${courseCode}`);
              subscriber.complete();
            });
        } else {
          subscriber.next(`Failed to find course structure to update with latest download/deletion video info for course: ${courseCode}`);
          subscriber.complete();
        }
      }, error => {
        subscriber.next(`Failed to update course structure with latest download/deletion video info for course: ${courseCode}`);
        subscriber.complete();
      });
  }

  synchroniseCourseStructureModuleDeletion(courseStructure: CourseStructure, deletionMonitoring: ModuleDeletiondMonitoring, includeVideos: boolean) {
    if (courseStructure == null || deletionMonitoring == null || courseStructure.modules == null) { return; }

    const moduleToBeSynchronised: Module = <Module>courseStructure.modules.find(x => x.id == deletionMonitoring.moduleId);

    if (moduleToBeSynchronised == null) { return; }

    if (deletionMonitoring.isCompletelyDeleted() === true && 
    deletionMonitoring.sections.length === moduleToBeSynchronised.sections.length) {

      for (let section of moduleToBeSynchronised.sections) {
        section.availableOffline = false;
        section.version = null;
      }
  
      moduleToBeSynchronised.isContentDownloaded = false;

      //if (includeVideos === false) { return; }

      moduleToBeSynchronised.isVideoListDownloaded = false;
    }
  }

  synchroniseCourseStructureModuleDeletionForVideos(courseStructure: CourseStructure, deletionMonitoring: ModuleDeletiondMonitoring) {
    if (courseStructure == null || deletionMonitoring == null || courseStructure.modules == null) { return; }

    const moduleToBeSynchronised: Module = <Module>courseStructure.modules.find(x => x.id == deletionMonitoring.moduleId);

    if (moduleToBeSynchronised == null) { return; }

    if (deletionMonitoring.isVideoCompletelyDeleted() === true) {

      moduleToBeSynchronised.isVideoListDownloaded = false;
    }
  }
//TODO: M.S. - write unit tests;
  synchroniseCourseStructure(courseStructure: CourseStructure, downloadMonitoring: ModuleDownloadMonitoring, includeVideos: boolean) {
    if (courseStructure == null || downloadMonitoring == null || courseStructure.modules == null) { return; }

    const moduleToBeSynchronised: Module = <Module>courseStructure.modules.find(x => x.id == downloadMonitoring.moduleId);

    if (moduleToBeSynchronised == null) { return; }

    if (downloadMonitoring.isCompletelyDownloaded() === true) {
      for (const section of moduleToBeSynchronised.sections) {
        let downloadedSection = downloadMonitoring.sections.find(x => x.sectionId === section.id);
        if (downloadedSection == null) continue;
        section.version = downloadedSection.version;
        section.availableOffline = true;
      } 

      const unAvailableSection = moduleToBeSynchronised.sections.find(x => x.availableOffline != null && x.availableOffline === false);

      if (unAvailableSection == null) {
        moduleToBeSynchronised.isContentDownloaded = true;
      }

      if (includeVideos === false) { return; }

      moduleToBeSynchronised.isVideoListDownloaded = true;
    }
  }

  synchroniseCourseStructureForVideos(courseStructure: CourseStructure, downloadMonitoring: ModuleDownloadMonitoring) {
    if (courseStructure == null || downloadMonitoring == null || courseStructure.modules == null) { return; }

    const moduleToBeSynchronised: Module = <Module>courseStructure.modules.find(x => x.id == downloadMonitoring.moduleId);

    if (moduleToBeSynchronised == null) { return; }

    if (downloadMonitoring.isVideoCompletelyDownloaded() === true) {
      moduleToBeSynchronised.isVideoListDownloaded = true;
    }
  }
}