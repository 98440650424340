import { Injectable } from '@angular/core';
import { TextActivity } from '../models/text-activity';
import { Observable } from 'rxjs';
import { ServiceBase } from '@app/core/service-base';
import { OperationResponse } from '@app/common/models/operation-response';

@Injectable({
    providedIn: 'root'
})
export class TextActivityService extends ServiceBase {

    getTextActivity(itemUrl, treatAsOffline): Observable<OperationResponse<TextActivity>> {
        return this.getObject<TextActivity>(itemUrl, treatAsOffline);
    }
}
