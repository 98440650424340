import { Injectable } from '@angular/core';
import { ServiceBase } from '@app/core/service-base';
import { SystemNotifications } from '../models/system-notifications';
import { DataStoreStrategy } from '@app/core/data-store-strategy';

@Injectable({
  providedIn: 'root'
})
export class SystemNotificationService extends ServiceBase {
//G.M. this should really just call if online only as admin components wont be run in offline mode
  getNotifications(itemUrl, treatAsOffline){
    return this.getObject<SystemNotifications>(itemUrl, treatAsOffline);
  }
 
}
