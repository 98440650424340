import { Component, OnInit, OnDestroy } from '@angular/core';
import { OnlineStatusService } from '@app/common/online-status/online-status.service';
import { Router } from '@angular/router';
import { OnlineStatusType } from '@app/common/online-status/online-status-type.enum';
import { OfflineModeStatusService } from '@app/core/offline-mode-status.service';
import { DownloadsService } from '@app/downloads/services/downloads.service';
import { StateService } from '@app/core/state.service';
import { Subscription } from 'rxjs';
import { UserService } from '@app/common/security/user.service';

@Component({
  selector: 'ebs-login-offline',
  templateUrl: './login-offline.component.html',
  styleUrls: ['./login-offline.component.scss']
})
export class LoginOfflineComponent implements OnInit, OnDestroy {
  offlineModeEnabled: boolean;
  hasDownloads: boolean;
  theOnlineSubscription: Subscription;
  //userLoggedOff: boolean;

  constructor(
    public onlineStatusService: OnlineStatusService,
    public offlineModeStatusService: OfflineModeStatusService,
    //private stateService: StateService,
    public downloadsService: DownloadsService,
    private router: Router,
    //private userService: UserService
  ) { }

  ngOnInit() {
    this.onlineStatusService.getCurrentStatus().subscribe(x => {
      if (x === OnlineStatusType.ONLINE) {
        this.redirectToOnlineLogin();
      }
    }, error => {
    });

    this.theOnlineSubscription = this.onlineStatusService.status.subscribe(status => {
      if (status === OnlineStatusType.ONLINE) {
        this.redirectToOnlineLogin();
      }
    });

    let root = this; //delay to allow indexeddb to be updated
    setTimeout(function () {
      root.offlineModeStatusService.isEnabled().subscribe(isEnabled => {
        root.offlineModeEnabled = isEnabled === true;
      });

      root.downloadsService.hasDownloads().subscribe(hasDownloads => {
        root.hasDownloads = hasDownloads === true;
      });

      // root.userService.getUserFromLocalStore().subscribe(
      //   userProfileResponse => {
      //     root.userLoggedOff = userProfileResponse.authenticationDetails.isLoggedOut;
      //     console.log('user logged off: ', root.userLoggedOff)
      //   },
      //   error => { console.log('error getting local user: ', error) }
      // )
    }, 750)
  }

  public redirectToOnlineLogin() {
    this.router.navigate(['/authentication']);
  }

  public redirectToDashboard() {
    this.router.navigate(['/dashboard']);
  }

  ngOnDestroy(): void {
    // this.stateService.numberOfLoginScreens -= 1;
    if (this.theOnlineSubscription) {
      this.theOnlineSubscription.unsubscribe();
    }
  }

}
