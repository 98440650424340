import { Component, OnInit, Output, EventEmitter, Input, OnDestroy, HostListener, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { Router, ActivatedRoute } from '@angular/router';
import { User, UserProfile } from '@app/common/models/user';
import { AuthenticationService } from '@app/common/security/authentication.service';
import { UserService } from '@app/common/security/user.service';
import { NotificationSummary } from '@app/common/models/notification-summary';
import { SeverityLevel } from '@app/common/models/severity-level';
import { ClearCacheService } from '@app/core/clear-cache.service';
import { StateService } from '@app/core/state.service';
import { ConfigurationHelperService } from '@app/common/app-configuration/configuration-helper.service';
import { OnlineStatusService } from '@app/common/online-status/online-status.service';
import { OnlineStatusType } from '@app/common/online-status/online-status-type.enum';
import { Subscription } from 'rxjs';
import { ApplicationEventDispatcher } from '@app/core/application-event-dispatcher.service';
import { ApplicationEventType } from '@app/core/application-event-type';
import { OperationResponse } from '@app/common/models/operation-response';

@Component({
  selector: 'ebs-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit, OnDestroy {
  isAuthenticating = false;
  loginLoaded = false;
  user: User = new User();
  notifications: NotificationSummary[] = new Array<NotificationSummary>();
  @Output() loginSuccess = new EventEmitter<boolean>();
  @Input() isAModalLogin = false;
  pollingInterval = 5000;
  stopPolling = true;
  theSubscription: Subscription;
  theOnlineSubscription: Subscription;
  localUser = new UserProfile();
  forgottenPasswordLink: string;
  forgottenUsernameLink: string;
  loadingExternalDocument: boolean;

  constructor(
    public authenticationService: AuthenticationService,
    public userService: UserService,
    public onlineStatusService: OnlineStatusService,
    public clearCacheService: ClearCacheService,
    private router: Router,
    private route: ActivatedRoute,
    @Inject(DOCUMENT) private document: Document,
    private stateService: StateService,
    private configurationHelperService: ConfigurationHelperService,
    private applicationEventDispatcher: ApplicationEventDispatcher) {
    this.pollingInterval = this.configurationHelperService.getPollingInterval();

  }

  ngOnInit() {
    this.user.username = '';
    this.user.password = '';
    this.forgottenPasswordLink = this.configurationHelperService.getForgottenPasswordUri();
    this.forgottenUsernameLink = this.configurationHelperService.getForgottenUsernameUri();

    this.onlineStatusService.isOffline().subscribe(x => {
      if (x === true) {
        this.redirectToOfflineLogin();
      } else {
        this.userService.getCurrentUser().subscribe(currentUser => {
          if (currentUser && currentUser.authenticationDetails && currentUser.authenticationDetails.isLoggedOut === false) {
            this.router.navigate(['/dashboard']);
          } else {
            this.setSubscriptionToOnlineStatus();
          }
        });
      }
    }, error => {
      this.setSubscriptionToOnlineStatus();
    });
    this.loginLoaded = true;
    // dont want to poll until user has logged in or if the pop up is in action
    this.stateService.numberOfLoginScreens += 1;
  }

  setSubscriptionToOnlineStatus() {
    this.theOnlineSubscription = this.onlineStatusService.status.subscribe(status => {
      if (status === OnlineStatusType.OFFLINE) {
        this.redirectToOfflineLogin();
      }
    });
  }

  ngOnDestroy(): void {
    this.stateService.numberOfLoginScreens -= 1;
    if (this.theOnlineSubscription) {
      this.theOnlineSubscription.unsubscribe();
    }
  }

  public login() {
    this.userService.getUserFromLocalStore()
      .subscribe(
        res => {
          this.localUser = res;
          this.isAuthenticating = true;
          this.notifications = new Array<NotificationSummary>();

          this.authenticationService.logIn(this.user, (response: OperationResponse<UserProfile>) => {
            if (response != null && response.data != null && response.data.authenticationDetails != null) {
              if (response.data.authenticationDetails.isAuthenticated) {
                this.loginCompleted();
              } else {
                this.notifications = response.notifications;
                this.isAuthenticating = false;
              }
            }
          }, () => { this.loginError(); });
        }
      );
  }

  private loginCompleted() {
    // this.userService.resetUser();

    this.userService.getCurrentUser().subscribe((x: UserProfile) => {
      if (x != null && x.authenticationDetails != null && x.authenticationDetails.isAuthenticated) {
        // check if user is different and clear if true
        // G.M. Mirela to add functionality to clear videos etc then will need to refactor this
        if (this.localUser && this.localUser.userAccount) {
          if (x.userAccount.username !== this.localUser.userAccount.username) {
            this.clearCacheService.emptyCache(false);
          }
        }
        this.loginSuccess.emit(true);
        this.applicationEventDispatcher.processAuthoriseModeRelatedEvent(ApplicationEventType.Authorised);
        if (!this.isAModalLogin) {
          this.route.queryParamMap.subscribe(params => {
            if (!params.get('ReturnUrl')) {
              if (this.isAModalLogin) {
                if (this.stateService.authSectionChange) {
                  this.stateService.authSectionChange = false;
                  this.isAuthenticating = false;
                  this.router.navigate([this.router.url]);
                  location.reload();
                } else {
                  this.router.navigate([this.router.url]);
                }
              } else {
                this.router.navigate(['/dashboard']);
              }
            } else {
              const returnUrl = params.get('ReturnUrl');
              if (this.checkWhetherLinkIsExternal(returnUrl)) {
                this.loadingExternalDocument = true;
                this.document.location.href = returnUrl;
              } else {
              this.router.navigateByUrl(params.get('ReturnUrl'));
            }
            }
          });
        }
        this.isAuthenticating = false;
      }
    });
  }

  protected checkWhetherLinkIsExternal(link) {
    return (link.startsWith('http'));
  }

  protected loginError() {
    this.notifications.push(new NotificationSummary('Could not login you in at this time. Please try again later.',
      null, SeverityLevel.Error));
    this.isAuthenticating = false;
  }

  // public workOffline() {
  //   this.userService
  //     .setWorkingConnectivityMode(this.userService.currentUser, true)
  //     .subscribe(next => {
  //       console.log('is now working offline');
  //       this.router.navigate(['/dashboard']);
  //     },
  //     error => {
  //       // display some message
  //     });
  // }

  public redirectToOfflineLogin() {
    this.router.navigate(['/authentication/login-offline']);
  }

}
