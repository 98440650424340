import { Component, OnInit, Input } from '@angular/core';
import { StaticText } from '@app/common/static-text';
import { EmailsSubsService } from '@app/courses/services/emails-subs.service';
import { Title } from '@angular/platform-browser';
import { EmailSubscription } from '@app/courses/models/email-subscription';
import { NotificationSummary } from '@app/common/models/notification-summary';
import { SeverityLevel } from '@app/common/models/severity-level';
import { OnlineStatusService } from '@app/common/online-status/online-status.service';
import { OnlineStatusType } from '@app/common/online-status/online-status-type.enum';
import { Subscription } from 'rxjs';

@Component({
  selector: 'ebs-email-subscription',
  templateUrl: './email-subscription.component.html',
  styleUrls: ['./email-subscription.component.scss']
})
export class EmailSubscriptionComponent implements OnInit {
  @Input() courseCode: string = "";
  gettingEmailSubDetails = false; isOffline = false; showError = false;
  alreadySubscribedToFullSummary = true;
  alreadySubscribedToPersonalisedSummary = true;
  forumOfflineMessage = StaticText.subsOfflineMessage;
  returnLink = `/courses/${this.courseCode}/modules/0/sections/0`;
  notifications: NotificationSummary[] = [];
  spinnerText = 'loading subscription status....';
  offlineSubscription: Subscription;

  constructor(
    private emailSubsService: EmailsSubsService,
    private titleService: Title,
    private onlineStatusService: OnlineStatusService
  ) {
    this.offlineSubscription = this.onlineStatusService.status.subscribe(
      event => {
        this.isOffline = (event === OnlineStatusType.OFFLINE) ? true : false
      }
    )
  }

  ngOnInit() {
    this.onlineStatusService.isOffline().subscribe(x => {
      this.isOffline = x;
      this.getEmailSubStatus();
    }, error => {
      console.log('offline status error: ', error)
      this.isOffline = false;
      this.getEmailSubStatus();
    });
    this.titleService.setTitle('Email notification subscriptions')
  }

  // API should really be returning a collection of subs so as we don't have to rewrite this if it is extended
  getEmailSubStatus() {
    this.resetBeforeHttpCall('loading subscription status....');
    //get status and update variable
    this.emailSubsService.getEmailSubStatus(this.courseCode).subscribe(
      response => {
        if (response.operationSucceeded) {
          this.alreadySubscribedToFullSummary = response.data.isSubscribedToFullSummary;
          this.alreadySubscribedToPersonalisedSummary = response.data.isSubscribedToPersonalisedSummary;
        } else {
          this.notifications = response.notifications;
          this.showError = true;
        }
        this.gettingEmailSubDetails = false;
      },
      error => {
        this.handleError(error, 'Error getting subscription status: ');
      }
    )
  }

  updateEmailSubToFullSummary() {
    this.resetBeforeHttpCall('updating subscription status....');
    let subObj: EmailSubscription = { courseCode: this.courseCode, isAwaitingSynchronization: false, isSubscribedToFullSummary: !this.alreadySubscribedToFullSummary, isSubscribedToPersonalisedSummary:this.alreadySubscribedToPersonalisedSummary, moduleId: 0, personId: '', sectionId: 0, uri: 'some-url', version: '' }

    this.emailSubsService.updateEmailSub(subObj).subscribe(
      response => {
        if (response.operationSucceeded) {
          // output success message?
          this.alreadySubscribedToFullSummary = !this.alreadySubscribedToFullSummary
        } else {
          this.notifications = response.notifications;
          this.showError = true;
        }
        this.gettingEmailSubDetails = false;
      },
      error => {
        this.handleError(error, 'Error updating subscription status: ');
      }
    )
  }

  updateEmailSubToPersonalisedSummary() {
    this.resetBeforeHttpCall('updating subscription status....');
    let subObj: EmailSubscription = { courseCode: this.courseCode, isAwaitingSynchronization: false, isSubscribedToFullSummary: this.alreadySubscribedToFullSummary,isSubscribedToPersonalisedSummary:!this.alreadySubscribedToPersonalisedSummary, moduleId: 0, personId: '', sectionId: 0, uri: 'some-url', version: '' }

    this.emailSubsService.updateEmailSub(subObj).subscribe(
      response => {
        if (response.operationSucceeded) {
          // output success message?
          this.alreadySubscribedToPersonalisedSummary = !this.alreadySubscribedToPersonalisedSummary
        } else {
          this.notifications = response.notifications;
          this.showError = true;
        }
        this.gettingEmailSubDetails = false;
      },
      error => {
        this.handleError(error, 'Error updating subscription status: ');
      }
    )
  }

  resetBeforeHttpCall(spinnerText: string) {
    this.notifications = [];
    this.gettingEmailSubDetails = true;
    this.showError = false;
    this.spinnerText = spinnerText;
  }

  handleError(error, errorMessage) {
    console.log('error', error)
    this.notifications.push(new NotificationSummary(errorMessage + error, 'Http', SeverityLevel.Error));
    this.gettingEmailSubDetails = false;
    this.showError = true;
  }
}