<div *ngIf="activity" class="discussion-item d-flex flex-column flex-sm-row discussion-item-post">
    <div class="comment-box-user d-flex flex-row align-items-center flex-sm-column align-items-sm-start">
        <ebs-discussion-userprofile [userCountryFlag]="activity.countryOfResidence" [userAvatar]="activity.photograph"
            [isFaculty]="activity.isFaculty" [displayName]="activity.name" [displayDate]="activity.dateAndTimeCreated">
        </ebs-discussion-userprofile>




        <!-- <div class="avatar position-relative mb-3 mr-2 activity-avatar">
            <img alt="user avatar" src="../../../../assets/img/avatar-temp.svg"
                class="avatar-responsive-profile rounded-circle" *ngIf="!activity.photograph" />
            <img *ngIf="activity.photograph" src="{{image}}" alt="" class="avatar-image rounded-circle">
            <span *ngIf="isFaculty || activity.isFaculty" class="avatar-status badge-info badge-font">Faculty</span>
        </div>

        <div class="align-items-center flex-sm-column align-items-sm-start mr-3">
            <div class="user-name-flag text-muted">
                <img alt="user flag" src="{{userCountryFlag}}" class="activity-flag"
                    *ngIf="userCountryFlag && !isFaculty && !activity.isFaculty" />
                {{activity.name}}
                <div> {{humanReadableDate}} </div>
            </div>
        </div> -->
    </div>

    <div class="comment-box-main flex-grow-1">
        <div class="row">
            <div class="col-12">
                <small *ngIf="isReply() && isDiscussionContent">Replied to: {{activity.repliedToName}}</small>
                <small *ngIf="!isReply() && isDiscussionContent">New Post</small>
                <br *ngIf="isDiscussionContent" />
                <strong> {{activity.discussionTitle}}</strong>
                <div class="mb-2 pt-2">
                    <span
                        [innerHTML]="showFullContent? activity.content : (activity.content | texttruncate: truncateContentLength)"
                        class="snapshot-text"></span>
                    <br>
                    <a href="#" *ngIf="enableShowAndHideContent()"
                        (click)="showAndHide($event)">{{showFullContent? 'Show less' : 'Show more' }}</a> <span
                        *ngIf="enableShowAndHideContent()">&nbsp; &nbsp; &nbsp;</span>
                    <a [routerLink]="[getPostOrReplyLink()]" [queryParams]="getQueryParams()"
                        [fragment]="getFragment()">View discussion</a>
                </div>
            </div>
        </div>
    </div>
</div>