import { ApplicationEventType } from "./application-event-type";

export class ApplicationEvent{
    message: string;
    type: ApplicationEventType;
    courseCode: string;
}

export class TypedApplicationEvent<T> extends ApplicationEvent{
    data : T;
}