import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap';
import { ModalButton } from '@app/common/models/modal-button';

@Component({
  selector: 'ebs-modal-simple',
  templateUrl: './modal-simple.component.html',
  styleUrls: ['./modal-simple.component.scss']
})
export class ModalSimpleComponent implements OnInit {
  title: string;
  message: string;
  options: ModalButton[];
  answer = '';

  constructor(
    public bsModalRef: BsModalRef,
  ) { }

  ngOnInit() {}

  respond(answer: string) {
    this.answer = answer;
    this.bsModalRef.hide();
  }

}
