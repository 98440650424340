import { Component, OnInit, Input } from "@angular/core";
import { PostAndReplySearchResultDto } from '../../models/course-activity/post-and-reply-search-result-dto';
import { Event, Router } from '@angular/router';
import { timeagoTransform } from "@app/common/timeago-transform";
import { DiscussionType } from "../discussion-forum/models/discussion-type";

@Component({
    selector: 'ebs-reply',
    templateUrl: './reply.component.html',
    styleUrls: ['./reply.component.scss']
})

export class ReplyComponent implements OnInit {

    @Input() isFaculty = false;

    @Input() activity: PostAndReplySearchResultDto;

    @Input() courseCode: string;

    @Input() isDiscussionContent: boolean = false;

    private messageType: string;

    private image: string;

    private userCountryFlag: string;

    @Input() truncateContentLength: number = 200;

    public showFullContent: boolean = false;

    private humanReadableDate: string;

    constructor(
        private router: Router
    ) { }

    ngOnInit(): void {
        if (this.activity) {
            this.image = "data:image/jpg;base64," + this.activity.photograph;
            this.messageType = this.activity.messageType;
            this.userCountryFlag = this.activity.countryOfResidence;

            if (this.userCountryFlag) {
                this.userCountryFlag = '../../../../assets/img/flags/' + this.userCountryFlag.toLowerCase() + '.png'
            } else {
                this.userCountryFlag = null;
            }
            this.humanReadableDate = timeagoTransform(this.activity.dateAndTimeCreated);
        }
    }

    getQueryParams() {
            var queryParams = {  
                discussionId:  this.activity.discussionType == DiscussionType.courseForum ?  this.activity.discussionId : this.activity.externalDiscussionId ,
                postId: this.activity.postId
            };
            if (this.isReply) {
                queryParams["replyId"] = this.activity.replyId;
            }
            return queryParams;
        
    }

    getFragment() {
        if (this.activity.discussionType == DiscussionType.courseForum)
            return '';
           
        return "discussion-" + (this.activity.externalDiscussionId);            
    }

    getPostOrReplyLink() {
        if (this.activity.discussionType == DiscussionType.courseForum)
            return this.activity && this.activity.uri ?  '/' + this.activity.uri.split('?')[0] : '/';


        return '/courses/' + this.courseCode + "/modules/" + this.activity.moduleId + "/sections/" + this.activity.sectionId;
    }

    enableShowAndHideContent() {
        if (this.truncateContentLength < this.activity.content.length) {
            return true;
        }
    }

    isReply() {
        return this.messageType == "Reply";
    }

    showAndHide(event) {
        if (event) {
            event.preventDefault();
            event.stopPropagation();
        }
        this.showFullContent = !this.showFullContent;
    }
}