<div class="d-none d-print-block">
  <p style="padding-left: 30px !important; padding-top: 30px !important">
    <i class="fas fa-exclamation-circle fa-fw"></i> Please go to your student portal to participate in this discussion
  </p>
</div>

<div class="pl-2">
  <ebs-offline-message [isOffline]='isOffline' [offlineMessage]='offlineMessage'></ebs-offline-message>
</div>

<div class="card-body discussion-wrapper" *ngIf="!isOffline && courseCode">
  <ebs-notification-multiple [notifications]="notifications" *ngIf="showError"></ebs-notification-multiple>
  <div class="discussion-create-wrapper">
    <div class="row" *ngIf="!showReply">
      <div class="col-12 reply-like-button">
      </div>
    </div>

    <div class="row mb-2">
      <div class="col-12">
        <ebs-wysiwyg *ngIf="!isOffline && currentUser" [parentIsMyPost]="false" [editorText]="''" [buttonText]="'Post'"
          [discussionId]="discussionId" [courseCode]="courseCode" [currentUser]="currentUser"
          (addPostEvent)="handleNewPost($event)" [placeholderText]="'Create new post...'">
        </ebs-wysiwyg>
      </div>
    </div>
  </div>
  <div class="no-post-message pl-2">
    <ebs-message-simple [iconCssClasses]="'text-info fa fa-fw fa-exclamation-circle'" [textCssClasses]="'text-info'"
      [wrapperCssClasses]="''" [displayMessage]="'Be the first to post a message'"
      *ngIf="showFirstPostMessage() && !isOffline">
    </ebs-message-simple>
  </div>
  <ebs-spinner [spinnerMessage]="'loading posts'" *ngIf="loadingPosts"></ebs-spinner>
  <a class="discussion-anchor" href="#" id="discussion-{{discussionId}}"></a>

  <div *ngFor="let post of posts; index as i">
    <!--posts-->
    <div id="single-post-filter" class="col-12" *ngIf="selectedPost" style="padding: 0;">
      <div class="alert alert-warning alert-discussion" role="alert"><i
          class="fa fa-lg fa-exclamation-circle fa-fw"></i> Viewing post from {{selectedPost.person.name}}
        <button class="btn btn-light btn-sm ml-3" (click)="removeSelectedPost($event)">Show full discussion</button>
      </div>
    </div>

    <ebs-discussion-postorreply-item [defaultPostShowHideStatus]="!displayParticularPostOrReply()"
      [currentUser]="currentUser" [discussionPostOrReplyItem]="post" [isAReply]="false"
      (bubbleReplacePostUpdatedLikeEvent)="handleUpdatedPost($event)"
      (deletedPostOrReplyEvent)="handleDeletedPostOrReply($event)" [postDisplayIndex]="i" *ngIf="currentUser">
    </ebs-discussion-postorreply-item>

    <!--replies... replies have an extra postdetails that is passed down the chain-->
    <div>
      <div *ngFor="let reply of post.replies; let j = index">
        <ebs-discussion-postorreply-item [defaultPostShowHideStatus]="!displayParticularPostOrReply()"
          [currentUser]="currentUser" [discussionPostOrReplyItem]="reply" [isAReply]="true"
          (bubbleReplacePostUpdatedLikeEvent)="handleUpdatedPost($event)"
          (deletedPostOrReplyEvent)="handleDeletedPostOrReply($event)" [postDetails]="post" *ngIf="currentUser">
        </ebs-discussion-postorreply-item>
        <div *ngIf="(j + 1) === post.replies.length" class="reply-row">
          <ebs-wysiwyg *ngIf="!isOffline && currentUser" [parentIsMyPost]="post.isMyEntry" [editorText]="''"
            [buttonText]="'Post'" [discussionId]="discussionId" [courseCode]="courseCode" [currentUser]="currentUser"
            (updatePostEvent)="handleUpdatedPost($event)" [placeholderText]="'Create new reply...'"
            [postDetails]="post">
          </ebs-wysiwyg>
        </div>
      </div>
      <!--still need an editor if no replies-->
      <div *ngIf="!post.replies || post.replies.length === 0" class="reply-row">
        <ebs-wysiwyg *ngIf="!isOffline && currentUser" [parentIsMyPost]="post.isMyEntry" [editorText]="''"
          [buttonText]="'Post'" [discussionId]="discussionId" [courseCode]="courseCode" [currentUser]="currentUser"
          (updatePostEvent)="handleUpdatedPost($event)" [placeholderText]="'Create new reply...'" [postDetails]="post">
        </ebs-wysiwyg>
      </div>
    </div>

  </div>

  <ebs-spinner [spinnerMessage]="'getting more posts'" *ngIf="loadingMorePosts"></ebs-spinner>
  <!-- Load More -->
  <div class="row mt-3" *ngIf="postsLoadedLessThanTotal() && !isOffline">
    <div class="col-12 col-sm-6 offset-sm-3 text-center">
      <span (click)="loadMorePosts()" class="btn btn-light btn-sm">
        <i class="fas fa-arrow-down"></i> Load more posts
      </span>
    </div>
  </div>
  <div class="row" *ngIf="postsLoaded() && !displayParticularPostOrReply()">
    <div class="col-12 col-sm-6 offset-sm-3 text-center loaded-style">
      Loaded {{posts.length}} of {{totalItems }}
    </div>
  </div>

</div>