import { DownloadResult } from "./download-result";
import { ModuleStructure } from "./module-structure";
import { ActivityItemType } from "@app/courses/models/activity-item-type";

export class ModuleDownloadMonitoring {
    sections: Array<DownloadResult> = new Array<DownloadResult>();
    activityItems: Array<DownloadResult> = new Array<DownloadResult>();
    files: Array<DownloadResult> = new Array<DownloadResult>();
    videos: Array<DownloadResult> = new Array<DownloadResult>();
    moduleId: number;
    courseCode: string;
    isBeingDownloaded = false;

    getTotal(): number {
        return this.sections.length + this.files.length + this.videos.length + this.activityItems.length;
    }

    getVideoTotal(): number {
        return this.videos.length;
    }


    getTotalSize():number{
        let totalSize : number = 0;

        //this.sections.forEach(x => { totalSize += x.size; });
        this.activityItems.forEach(x => { totalSize += x.size; });
        this.files.forEach(x => { totalSize += x.size; });
        this.videos.forEach(x => { totalSize += x.size; });

        return totalSize;
    }

    getVideoTotalSize():number{
        let totalSize : number = 0;

        this.videos.forEach(x => { totalSize += x.size; });

        return totalSize;
    }

    getDownloadedSize():number{
        let totalSize : number = 0;

        //this.sections.forEach(x => { if(x.isDownloaded === true) totalSize += x.size; });
        this.activityItems.forEach(x => { if(x.isDownloaded === true) totalSize += x.size; });
        this.files.forEach(x => { if(x.isDownloaded === true) totalSize += x.size; });
        this.videos.forEach(x => { if(x.isDownloaded === true) totalSize += x.size; });
        
        return totalSize;
    }

    getVideoDownloadedSize():number{
        let totalSize : number = 0;

        this.videos.forEach(x => { if(x.isDownloaded === true) totalSize += x.size; });
        
        return totalSize;
    }

    getDownloadProgress(): number {

        let downloadProgress: number = 0;
        this.isBeingDownloaded = true;

        downloadProgress += this.sections.filter(x=>x.isDownloaded === true).length;
        downloadProgress += this.activityItems.filter(x=>x.isDownloaded === true).length;
        downloadProgress += this.files.filter(x=>x.isDownloaded === true).length;
        downloadProgress += this.videos.filter(x=>x.isDownloaded === true).length;

        console.log('Processing level ' + downloadProgress);

        return downloadProgress;
    }

    getVideoDownloadProgress(): number {

        let downloadProgress: number = 0;
        this.isBeingDownloaded = true;

        downloadProgress += this.videos.filter(x=>x.isDownloaded === true).length;

        console.log('Video Download level ' + downloadProgress);

        return downloadProgress;
    }

    getProcessingProgress(): number {
        let processingProgress: number = 0;

        processingProgress += this.sections.filter(x=>x.hasBeenProcessed === true).length;
        processingProgress += this.activityItems.filter(x=>x.hasBeenProcessed === true).length;
        processingProgress += this.files.filter(x=>x.hasBeenProcessed === true).length;
        processingProgress += this.videos.filter(x=>x.hasBeenProcessed === true).length;
      
        console.log('Processing level ' + processingProgress);

        return processingProgress;
    }

    getVideoProcessingProgress():number {
        let processingProgress: number = 0;
        processingProgress += this.videos.filter(x=>x.hasBeenProcessed === true).length;

        console.log('Video processing level ' + processingProgress);

        return processingProgress;
    }

    isCompletelyDownloaded(): boolean {

        const isDownloadProgressEqualToDownload = this.getTotal() === this.getDownloadProgress();
        if (isDownloadProgressEqualToDownload === true)
            this.isBeingDownloaded = false;

        console.log('Download level == Total ? ' + isDownloadProgressEqualToDownload);
        return isDownloadProgressEqualToDownload;
    }

    isVideoCompletelyDownloaded(): boolean {

        const isDownloadProgressEqualToDownload = this.getVideoTotal() === this.getVideoDownloadProgress();
        if (isDownloadProgressEqualToDownload === true)
            this.isBeingDownloaded = false;

        console.log('Download video level == Total ? ' + isDownloadProgressEqualToDownload);
        return isDownloadProgressEqualToDownload;
    }

    createFromModuleStructure(moduleStructure: ModuleStructure, includeVideos: boolean) {
        if (moduleStructure == null) return;

        this.moduleId = moduleStructure.moduleId;
        this.courseCode = moduleStructure.courseCode;
        
        this.sections = new Array<DownloadResult>();
        this.activityItems = new Array<DownloadResult>();
        this.files = new Array<DownloadResult>();
        this.videos = new Array<DownloadResult>();

        if (moduleStructure.files && moduleStructure.files.length > 0) {
            for (let file of moduleStructure.files) {
                if (file.version !== file.availableServerVersion)
                    this.files.push(new DownloadResult(file.uri, moduleStructure.moduleId, null, null, file.availableServerVersion, file.size));
            }
        }

        if (moduleStructure.sections && moduleStructure.sections.length > 0) {
            for (let section of moduleStructure.sections) {
                if (section.version !== section.availableServerVersion)
                    this.sections.push(new DownloadResult(section.uri, moduleStructure.moduleId, null, section.sectionId, section.availableServerVersion, section.size));
            }
        }

        if (moduleStructure.activityItems && moduleStructure.activityItems.length > 0) {
            for (let activityItem of moduleStructure.activityItems) {
                if (activityItem.version !== activityItem.availableServerVersion
                    && activityItem.type !== ActivityItemType.Discussion
                    && activityItem.type !== ActivityItemType.FileIFrame)
                    this.activityItems.push(new DownloadResult(activityItem.uri, moduleStructure.moduleId, activityItem.type, activityItem.sectionId, activityItem.availableServerVersion, activityItem.size));
            }
        }

        if (includeVideos === true && moduleStructure.videos && moduleStructure.videos.length > 0) {
            for (let video of moduleStructure.videos) {
                if (video.version !== video.availableServerVersion)
                    this.videos.push(new DownloadResult(video.uri, moduleStructure.moduleId, null, null, video.availableServerVersion, video.size, video.accessToken));
            }
        }
    }
}