<div class="modal-header px-4">
    <h4 class="modal-title pull-left">Coursera Account Creation</h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)="closePopup()" style="font-size: 2rem;">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>

  <div class="modal-body px-4" style="padding-bottom: 5px;padding-top: 10px;">
    <div>
      <p>
        To create your Coursera Career Academy login, we will need to share the following 
        information with our partner Coursera Career Academy:
      </p>
      <ul>
        <li>First Name</li>
        <li>Last Name</li>
        <li>Personal Email address</li>
      </ul>
      <p>
        If you do not wish to share this information then please select the "Cancel” option below 
        and your information will not be shared; you will not have access to the Coursera Career 
        Academy offering through Heriot-Watt University.
      </p>
      <ul class="list-unstyled" >
        <li class="list-group-item">
          <div class="row">
            <div class="col-8 col-sm-10">
              I agree to share my information listed above with Coursera for the purposes of account creation
            </div>
            <div class="col-4 col-sm-2" style="text-align: center;">
              <label class="switch">
                <input type="checkbox" [checked]="hasAcknowledgedCourseraRegistrationTerms" 
                  (change)="hasAcknowledgedCourseraRegistrationTerms = !hasAcknowledgedCourseraRegistrationTerms" >
                <span class="slider round"></span>
              </label>
            </div>
          </div>
        </li>
      </ul>
    </div>
</div>

<div class="modal-footer">
    <button type="button" class="btn btn-primary text-white float-right btn-block mt-0"
      (click)="register()"
      [disabled]="!hasAcknowledgedCourseraRegistrationTerms" >
      Create my Coursera account
    </button>
    <button type="button" class="btn btn-secondary float-right btn-block mt-0" (click)="closePopup()" >
        Cancel
    </button>
</div>  