import { Injectable } from "@angular/core";
import { ServiceBase } from "@app/core/service-base";
import { PagedRequest } from "@app/common/models/paged-request";
import { OperationResponse } from "@app/common/models/operation-response";
import { PagedResult } from "@app/common/models/paged-result";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { DiscussionLike } from "../models/like";
import { DiscussionPostOrReplyItem } from "../models/discussion-postorreply-item";

@Injectable({
    providedIn: 'root'
})
export class DiscussionService extends ServiceBase {

    private _discussionUri: string;
    get discussionUri(): string {
        return this._discussionUri;
    }
    set discussionUri(theUri: string) {
        this._discussionUri = theUri;
    }

    public addPost(post: DiscussionPostOrReplyItem, treatAsOffline: boolean): Observable<OperationResponse<DiscussionPostOrReplyItem>> {
        let uri = (post.uri == null || post.uri === '')
            ? `${this._discussionUri}discussions/${post.discussionId}/posts`
            : post.uri;
        post.uri = uri;
        return this.setObjectInAvailableStores<DiscussionPostOrReplyItem>(post, uri, treatAsOffline);
    }

    public addReply(reply: DiscussionPostOrReplyItem, treatAsOffline: boolean): Observable<OperationResponse<DiscussionPostOrReplyItem>> {
        let uri = (reply.uri == null || reply.uri === '')
            ? `${this._discussionUri}discussions/${reply.discussionId}/posts/${reply.postId}/replies`
            : reply.uri;
        reply.uri = uri;
        return this.setObjectInAvailableStores<DiscussionPostOrReplyItem>(reply, uri, treatAsOffline);
    }

    // doesn't matter if this post or reply as the url will be correct
    public deleteComment(uri: string, treatAsOffline: boolean): Observable<OperationResponse<DiscussionPostOrReplyItem>> {
        return this.deleteObjectFromAvailableStores<DiscussionPostOrReplyItem>(uri, treatAsOffline);
    }

    public likeComment(like: DiscussionLike, treatAsOffline: boolean): Observable<OperationResponse<DiscussionLike>> {
        let uri = '';
        // first time there will be no ID so it calls "/likes" other wise it calls "/likes/id" - this is how the API works
        // is either a reply like or a post like and calls appropriate api 
        like.replyId ?
        (like.id ?
            uri = `${this._discussionUri}discussions/${like.discussionId}/posts/${like.postId}/replies/${like.replyId}/likes/${like.id}` :
            uri = `${this._discussionUri}discussions/${like.discussionId}/posts/${like.postId}/replies/${like.replyId}/likes`)
            : (like.id ?
                uri = `${this._discussionUri}discussions/${like.discussionId}/posts/${like.postId}/likes/${like.id}` :
                uri = `${this._discussionUri}discussions/${like.discussionId}/posts/${like.postId}/likes`)

        like.uri = uri;
        return this.setObjectInAvailableStores<DiscussionLike>(like, uri, treatAsOffline);
    }

    public unlikeComment(like: DiscussionLike, treatAsOffline: boolean): Observable<OperationResponse<DiscussionLike>> {
        let uri = '';
        like.replyId ?
            uri = `${this._discussionUri}discussions/${like.discussionId}/posts/${like.postId}/replies/${like.replyId}/likes/${like.id}`
            : uri = `${this._discussionUri}discussions/${like.discussionId}/posts/${like.postId}/likes/${like.id}`;

        like.uri = uri;
        return this.setObjectInAvailableStores<DiscussionLike>(like, uri, treatAsOffline);
    }

    public getPosts(request: PagedRequest<DiscussionPostOrReplyItem>, treatAsOffline: boolean): Observable<OperationResponse<PagedResult<DiscussionPostOrReplyItem>>> {
        return this.getPagedObjects<DiscussionPostOrReplyItem>(request, treatAsOffline).pipe(
            map(
                res => {
                    res.data.items.forEach(
                        (post) => {
                            if (post.replies) {
                                post.replies.forEach((reply) => {
                                    reply.discussionId = post.discussionId;
                                })
                            }
                        }
                    )
                    return res;
                }
            )
        );
    }

    public getPost(discussionId: string, postId: string): Observable<OperationResponse<DiscussionPostOrReplyItem>> {
        let uri = '';
        if (postId) {
            uri = `${this._discussionUri}discussions/${discussionId}/posts/${postId}`;

            let getCommentByServerFirstStrategy = new Observable<OperationResponse<DiscussionPostOrReplyItem>>(subscriber => {
                this.getObjectRemoteStoreFirst(uri, subscriber);
            });
            
            return getCommentByServerFirstStrategy;
        }
    }

    //M.S. these two methods should really be calling public methods should they not?
    public deletePostFromLocalStore(post: DiscussionPostOrReplyItem ){
        let deleteFromIndexedDb = new Observable<OperationResponse<DiscussionPostOrReplyItem>>(subscriber => {
            this.deleteObjectFromLocalStore(post.uri, subscriber);
        });

        return deleteFromIndexedDb
    }
}