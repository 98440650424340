import { Injectable } from '@angular/core';
import { ServiceBase } from '@app/core/service-base';
import { QuizActivity } from '../models/quiz/quiz-activity';
import { Observable } from 'rxjs';
import { OperationResponse } from '@app/common/models/operation-response';
import { QuizDetail } from '../models/quiz/quiz-detail';
import { DataStoreStrategy } from '@app/core/data-store-strategy';

@Injectable({
  providedIn: 'root'
})
export class QuizActivityService extends ServiceBase {

  getQuizActivity(itemUrl: string, treatAsOffline: boolean): Observable<OperationResponse<QuizActivity>> {
    return this.getObject<QuizActivity>(itemUrl, treatAsOffline);
  }

  setQuizDetail(itemUrl: string, quizDetail: QuizDetail, treatAsOffline: boolean): Observable<OperationResponse<QuizDetail>> {
    return this.setObjectInAvailableStores(quizDetail, itemUrl, treatAsOffline); 
  }

  getQuizDetail(itemUrl: string, treatAsOffline: boolean): Observable<OperationResponse<QuizDetail>> {
    return this.getObject<QuizDetail>(itemUrl, treatAsOffline);
  }

}
