import { Component, OnInit, Input } from '@angular/core';
import { QuizStatistics } from '@app/courses/models/quiz/quiz-statistics';
import { QuizStatisticsMockService } from '@app/common/mock/services/quiz-statstics-mock.service';

@Component({
  selector: 'ebs-quiz-statistics-details',
  templateUrl: './quiz-statistics-details.component.html',
  styleUrls: ['./quiz-statistics-details.component.scss']
})
export class QuizStatisticsDetailsComponent implements OnInit {
  @Input() quizStatistics: QuizStatistics;
  bstyle:string ="25%";
  constructor(private quizStatisticsMockService: QuizStatisticsMockService){
    
  }

  ngOnInit() {
    //this.quizStatistics.quizStatisticsModules[0].statisticsQuizes[0].isNoQuizInModule
  }


}
