import { Injectable } from '@angular/core';
import { ServiceBase } from '@app/core/service-base';
import { EmailSubscription } from '../models/email-subscription';
import { Observable, of } from 'rxjs';
import { OperationResponse } from '@app/common/models/operation-response';
import { NotificationSummary } from '@app/common/models/notification-summary';
import { SeverityLevel } from '@app/common/models/severity-level';

@Injectable({
  providedIn: 'root'
})
export class EmailsSubsService extends ServiceBase { 
    
  getEmailSubStatus(courseCode: string): Observable<OperationResponse<EmailSubscription>> {
    // not saure this is standard format api uri?
    let uri =  `subscriptions/` + courseCode;
    return this.getObjectFromRemoteStoreOnly(uri)
  }

  updateEmailSub(sub: EmailSubscription): Observable<OperationResponse<EmailSubscription>> {
    let uri = this.getApiServerUri() +  `subscriptions`;
    sub.uri = uri;
    return this.postObjectToRemoteStoreOnly<EmailSubscription>(sub, uri)
  }

}
