<h3 *ngIf="!isDiscussionContent" class="small h6">FACULTY LATEST REPLIES</h3>

<ebs-spinner [spinnerMessage]="''" [faColour]="'#fff'" [boxColour]="'#fff'" *ngIf="loadingData"
  [boxPadding]="'5px 15px'" [faFont]="'2.2rem'"></ebs-spinner>

<ul *ngIf="postAndReplySearchResults && !loadingData" class="list-group mb-3">
  <li *ngFor="let activity of postAndReplySearchResults" href="#"
    class="list-group-item list-group-item-action flex-column align-items-start"
    [ngClass]="{'snapshot-text': isDiscussionContent}">
    <ebs-reply [truncateContentLength]="truncateContentLength" [isDiscussionContent]="isDiscussionContent"
      [courseCode]="courseCode" *ngIf="activity" [activity]="activity" [isFaculty]="isFaculty"></ebs-reply>
  </li>
</ul>