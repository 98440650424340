import { StorableObject } from "@app/core/storable-object";
import { DiscussionType } from "./discussion-type";
import { Tag } from "./tag";
import { Person } from "../../discussion-course/models/person";

export class NewForum extends StorableObject {
    id: string;
    courseCode: string;
    discussionId: string;
    title: string;
    description: string;
    person: Person;
    type: DiscussionType;
    tags: Tag[];
    uri: string;
    group: any;
    isFacultyFavourite: boolean;
}
