import { Component, OnInit, Input, Output, EventEmitter, OnDestroy } from '@angular/core';
import { CourseActivitySideBarService } from '../../services/course-activity-sidebar.service';
import { OperationResponse } from '@app/common/models/operation-response';
import { PagedRequest } from '@app/common/models/paged-request';
import { PostAndReplySearchResultDto } from '@app/courses/models/course-activity/post-and-reply-search-result-dto';
import { PagedResult } from '@app/common/models/paged-result';
import { UserActivitySearchResultDto } from '@app/courses/models/course-activity/user-activity-search-result-dto';
import { UserActivityLastVisitDto } from '../../models/course-activity/user-activity-last-visit-dto';
import { Router, NavigationEnd } from '@angular/router';
import { OnlineStatusService } from '../../../common/online-status/online-status.service';
import { OnlineStatusType } from '../../../common/online-status/online-status-type.enum';
import { StaticText } from '../../../common/static-text';
import { Subscription } from 'rxjs';
import { NotificationSummary } from '@app/common/models/notification-summary';
import { AuthenticationService } from '@app/common/security/authentication.service';
import { UserService } from '@app/common/security/user.service';

@Component({
  selector: 'ebs-course-activity-sidebar',
  templateUrl: './course-activity-sidebar.component.html',
  styleUrls: ['./course-activity-sidebar.component.scss']
})
export class CourseActivitySidebarComponent implements OnInit, OnDestroy {

  @Input() public courseCode: string;
  @Output() newActivityStatusChanged = new EventEmitter<boolean>();
  @Output() offlineChanged = new EventEmitter<boolean>();

  public isOffline: boolean;
  public lastItemId: string;
  public errorMessage: string;
  public discussionType: string = "0";
  public facultyUrl: string;
  public userActivityUrl: string;
  public offlineMessage: string = StaticText.activityStreamOfflineMessage;
  public getOrSetUserActivityAsViewedUrl: string;
  public lastViewedTime: UserActivityLastVisitDto;
  public facultyPostAndReplyPagedRequest: PagedRequest<PostAndReplySearchResultDto> = new PagedRequest<PostAndReplySearchResultDto>();
  public postAndReplyPagedRequest: PagedRequest<PostAndReplySearchResultDto> = new PagedRequest<PostAndReplySearchResultDto>();
  //maybe not needed in future
  public lastFacultyPostAndReplySearchResults: PagedResult<PostAndReplySearchResultDto>;
  public lastPostAndReplySearchResults: PagedResult<PostAndReplySearchResultDto>;
  public facultyPostAndReplySearchResults: Array<PostAndReplySearchResultDto> = new Array<PostAndReplySearchResultDto>();
  public postAndReplySearchResults: Array<PostAndReplySearchResultDto> = new Array<PostAndReplySearchResultDto>();
  public loadingPostAndReplyData: boolean = true;
  public loadingFacultyPostAndReplyData: boolean = true;
  gettingUserActivityLastViewed = false;
  public theSubscription: Subscription;
  public routerSubscription: Subscription;
  public truncateContentLength: number = 100;
  notifications: NotificationSummary[] = new Array<NotificationSummary>();


  constructor(
    private courseActivitySideBarService: CourseActivitySideBarService,
    private router: Router,
    private onlineStatusService: OnlineStatusService
  ) {
    this.facultyPostAndReplyPagedRequest.currentPage = 1;
    this.facultyPostAndReplyPagedRequest.itemsPerPage = 2;
    this.postAndReplyPagedRequest.currentPage = 1;
    this.postAndReplyPagedRequest.itemsPerPage = 2;
  }
  ngOnDestroy(): void {
    if (this.theSubscription)
      this.theSubscription.unsubscribe()

    if (this.routerSubscription)
      this.routerSubscription.unsubscribe()
  }

  ngOnInit() {
    this.facultyUrl = "courses/" + `${this.courseCode}` + "/discussions/faculty/search";
    this.userActivityUrl = "courses/" + `${this.courseCode}` + "/discussions/user/activity";
    this.getOrSetUserActivityAsViewedUrl = "courses/" + `${this.courseCode}` + "/discussions/user/activity/viewed";

    this.onlineStatusService.isOffline().subscribe(x => {
      this.isOffline = x;
    }, (error) => {
      this.isOffline = false;
    }, () => {
      this.requestUserActivitySteam();
    });

    this.theSubscription = this.onlineStatusService.status.subscribe(
      event => {
        if (event === OnlineStatusType.OFFLINE) {
          this.isOffline = true;
          this.pubblishOfflineEvent();

        }
        if (event === OnlineStatusType.ONLINE) {
          this.isOffline = false;
          this.pubblishDisableActivityEvent();
          this.getUserActivityLastViewed();
        }
      }
    )
  }

  requestUserActivitySteam() {
    if (!this.isOffline) {
      this.routerSubscription = this.router.events.subscribe((val) => {
        if (!this.isLoading() && val instanceof NavigationEnd) {
          if (val.url.indexOf("authentication") === -1) {
            this.getUserActivityLastViewed();
          }
        }
      });
      this.getUserActivityLastViewed();
    }
  }

  getFacultyPostAndReplySearchResults() {
    this.loadingFacultyPostAndReplyData = true;
    this.facultyPostAndReplyPagedRequest.uriFilter = this.facultyUrl;

    this.errorMessage = '';
    this.courseActivitySideBarService.getFacultyActivity(this.facultyPostAndReplyPagedRequest).subscribe((postAndReplySearchResultsOperationResponse: OperationResponse<PagedResult<PostAndReplySearchResultDto>>) => {
      if (postAndReplySearchResultsOperationResponse.operationSucceeded) {
        this.lastFacultyPostAndReplySearchResults = postAndReplySearchResultsOperationResponse.data;

        if (this.lastFacultyPostAndReplySearchResults.totalItems > 0) {
          this.checkIfNewFacultyActivity();
        }
        if (this.facultyPostAndReplySearchResults.length <= this.facultyPostAndReplyPagedRequest.itemsPerPage) {
          this.facultyPostAndReplySearchResults = postAndReplySearchResultsOperationResponse.data.items;
        } else {
          this.facultyPostAndReplySearchResults = this.facultyPostAndReplySearchResults.concat(postAndReplySearchResultsOperationResponse.data.items);
        }

      } else {
        //raise an error?
      }
      this.loadingFacultyPostAndReplyData = false;
    });
  }

  getPostAndReplySearchResults() {
    this.loadingPostAndReplyData = true;
    this.postAndReplyPagedRequest.uriFilter = this.userActivityUrl;
    this.errorMessage = '';

    this.courseActivitySideBarService.getLatestUserActivity(this.postAndReplyPagedRequest).subscribe(
      (postAndReplySearchResultsOperationResponse: OperationResponse<PagedResult<UserActivitySearchResultDto>>) => {
        if (postAndReplySearchResultsOperationResponse.operationSucceeded) {
          this.lastPostAndReplySearchResults = postAndReplySearchResultsOperationResponse.data;
          if (this.lastPostAndReplySearchResults && this.lastPostAndReplySearchResults.totalItems > 0) {
            this.checkIfNewUserActivity();
          }
          if (this.postAndReplySearchResults.length <= this.postAndReplyPagedRequest.itemsPerPage) {
            this.postAndReplySearchResults = postAndReplySearchResultsOperationResponse.data.items;
          } else {
            this.postAndReplySearchResults = this.postAndReplySearchResults.concat(postAndReplySearchResultsOperationResponse.data.items);
          }
        } else {
          //raise an error?
        }
        this.loadingPostAndReplyData = false;
      });
  }

  isLoading() {
    return this.loadingPostAndReplyData && this.loadingFacultyPostAndReplyData;
  }

  checkIfNewFacultyActivity() {
    var lastViewedAt = this.lastViewedTime.lastViewedAt;
    if (lastViewedAt)
      var lastViewedDatetime = Date.parse(lastViewedAt);
    if (this.lastFacultyPostAndReplySearchResults && this.lastFacultyPostAndReplySearchResults.totalItems > 0) {
      var lastActivityDateAndTimeCreated = this.lastFacultyPostAndReplySearchResults.items[0].dateAndTimeCreated;
      if (lastActivityDateAndTimeCreated) {
        var temp = Date.parse(lastActivityDateAndTimeCreated);
        if (temp > lastViewedDatetime) {
          this.pubblishNewActivityEvent();
        }
      }
    }
  }

  checkIfNewUserActivity() {
    let lastViewedAt

    // if person not found eg Demo user
    if (this.lastViewedTime) {
      lastViewedAt = this.lastViewedTime.lastViewedAt;
    } else {
      lastViewedAt = Date.now();
    }

    if (lastViewedAt) var lastViewedDatetime = Date.parse(lastViewedAt);

    if (this.lastPostAndReplySearchResults && this.lastPostAndReplySearchResults.totalItems > 0) {
      var lastActivityDateAndTimeCreated = this.lastPostAndReplySearchResults.items[0].dateAndTimeCreated;

      if (lastActivityDateAndTimeCreated) {

        var temp = Date.parse(lastActivityDateAndTimeCreated);
        if (temp > lastViewedDatetime) {
          this.pubblishNewActivityEvent();
        }
      }
    }
  }

  pubblishNewActivityEvent() {
    this.newActivityStatusChanged.emit(true)
  }

  pubblishDisableActivityEvent() {
    this.newActivityStatusChanged.emit(false)
  }

  pubblishOfflineEvent() {
    this.offlineChanged.emit(true);
  }

  setUserActivityAsViewed(firstTime: boolean) {
    if (this.isOffline) {
      return;
    }
    this.courseActivitySideBarService.setUserActivityAsViewed(this.getOrSetUserActivityAsViewedUrl, this.courseCode).subscribe((userActivityLastVisitDtoOperationResponse: OperationResponse<UserActivityLastVisitDto>) => {
      if (userActivityLastVisitDtoOperationResponse.operationSucceeded) {
        this.lastViewedTime = userActivityLastVisitDtoOperationResponse.data;
        if (firstTime) {
          this.getFacultyPostAndReplySearchResults();
          this.getPostAndReplySearchResults();
        }
      }
    });
  }

  getUserActivityLastViewed() {
    this.notifications = [];
    this.gettingUserActivityLastViewed = true;

    if (this.isOffline === true) return;

    this.courseActivitySideBarService.getUserActivityLastViewed(this.getOrSetUserActivityAsViewedUrl).subscribe(
      (userActivityLastVisitDtoOperationResponse: OperationResponse<UserActivityLastVisitDto>) => {
        this.getFacultyPostAndReplySearchResults();
        this.getPostAndReplySearchResults();
        if (userActivityLastVisitDtoOperationResponse.operationSucceeded) {
          this.lastViewedTime = userActivityLastVisitDtoOperationResponse.data;
          if (this.lastViewedTime) {
            this.getFacultyPostAndReplySearchResults();
            this.getPostAndReplySearchResults();
          }
        } else {
          this.notifications = userActivityLastVisitDtoOperationResponse.notifications;
        }
        this.gettingUserActivityLastViewed = false;
      },
      error => {
        this.gettingUserActivityLastViewed = false;
        console.log('There has been an error getting the last time you viewed your activity', error)
      }
    );
  }
}