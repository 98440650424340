import { Component, OnInit, Input, ChangeDetectorRef } from '@angular/core';
import { DeviceDetectorService } from 'ngx-device-detector';

@Component({
  selector: 'app-player',
  templateUrl: './media-player.component.html',
  styleUrls: ['./media-player.component.scss'],
  providers: [DeviceDetectorService]
})
export class MediaPlayerComponent implements OnInit {

  private player;

  // HLS
  // private song = 'http://media.crave.fm:1935/vod/mp3:TIAr0000000196Al0000000001So0000006243.mp3/playlist.m3u8';
  // private song = 'http://db3.indexcom.com/bucket/ram/00/05/64k/05.m3u8'; // Working on FireFox
  // DASH
  // private song = 'http://media.crave.fm:1935/vod/TIAr0000000196Al0000000001So0000006243.mp3/manifest.mpd';
  // private song = 'http://www.bok.net/dash/tears_of_steel/cleartext/stream.mpd';
  // RTMP
  // private song = 'rtmp://media.crave.fm:1935/vod/mp3:TIAr0000000196Al0000000001So0000006243.mp3';
  // private song = 'rtsp://media.crave.fm:1935/vod/sample.mp4'
  @Input() src : string;
  @Input() videoId : string;

  // https://github.com/mediaelement/mediaelement/issues/643
  private playFlashBack = {
    mode: 'auto_plugin',
    // shows debug errors on screen
    enablePluginDebug: true,
    plugins: ['flash', 'silverlight'],
    features: ['playpause', 'current', 'progress', 'duration', 'tracks', 'volume','fullscreen'],
    useDefaultControls: true,
    pluginPath: '/assets/mejs/swf/',
    success: function (mediaElement, originalNode) {
      console.log('Media eleent Initialized');
    }
  };

  private play = {
    features: ['playpause', 'current', 'progress', 'duration', 'tracks', 'volume','fullscreen'],
    //features: ['playpause', 'progress', 'current', 'duration', 'tracks', 'volume'],
    useDefaultControls: true,
    pluginPath: '/assets/mejs/swf/',
    success: function (mediaElement, originalNode) {
      console.log('Media eleent Initialized');
    }
  };

  constructor(private deviceService: DeviceDetectorService, private changeDetectorRef: ChangeDetectorRef) {

  }

  ngOnInit() {
    this.changeDetectorRef.detectChanges();
    this.loadVideo(this.src);
  }

  getPlayerId():string{
    return `player${this.videoId}`;
  }

  loadVideo(source: string) {
    const browser = this.deviceService.browser;



    switch (browser) {
      case 'firefox':
      case 'Chrome':

        // RTMP
        // this.src = 'rtmp://media.crave.fm:1935/vod/mp3:TIAr0000000196Al0000000001So0000006243.mp3';
        this.player = new MediaElementPlayer(this.getPlayerId(), this.playFlashBack);
        //this.player = new MediaElementPlayer('player', this.playFlashBack);

        console.log('Chrome and Firefox - Player');
        console.log('Passed src - ' + this.src);

        break;

      case 'edge':
      case 'safari':

        // HLS
        // this.src = 'http://media.crave.fm:1935/vod/mp3:TIAr0000000196Al0000000001So0000006243.mp3/playlist.m3u8';
        this.player = new MediaElementPlayer(this.getPlayerId(), this.play);
        //this.player = new MediaElementPlayer('player', this.play);
        alert('Edge and Safari - Player');
        console.log('Passed src - ' + this.src);

        break;


      default:
        this.player = new MediaElementPlayer(this.getPlayerId(), this.play);
        //this.player = new MediaElementPlayer('player', this.play);

    
        console.log('Other browser');
        console.log('Passed src - ' + this.src);

        break;
    }

    console.log('player is initialised: ' + (this.player != null))
    //this.player.setSrc(source + "#t=1.0");
    this.player.setSrc(source);
    this.player.load();

  }

}

declare const MediaElementPlayer: any;
