import { Metadata } from './metadata';
import { ActivityItemType } from './activity-item-type';
import { LearningObject } from '@app/common/models/learning-object';


export class ActivityItem extends LearningObject {
    public metadata: Metadata;
    public identity: string;

    public constructor(public id: number, public name: string, public url: string,
        public type: ActivityItemType, public number?: number, public size?: number, public availableUpdateVersion?: string) {
        super();
    }

}