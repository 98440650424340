import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'ebs-offline-message',
  templateUrl: './offline-message.component.html',
  styleUrls: ['./offline-message.component.scss']
})
export class OfflineMessageComponent implements OnInit {
  @Input() isOffline = false;
  @Input() offlineMessage = 'Currently offline';
  constructor() { }

  ngOnInit() {
  }

}
