import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { NotificationSummary } from '@app/common/models/notification-summary';
import { PlatformLocation } from '@angular/common';
import { GameConfiguration } from '@app/courses/models/games/game-configuration';

@Component({
  selector: 'ebs-webgl-demo',
  templateUrl: './webgl-demo.component.html',
  styleUrls: ['./webgl-demo.component.scss']
})
export class WebGlDemoComponent implements OnInit {
  @Input() gameConfiguration: GameConfiguration;
  ngOnInit(): void {
    this.load("WebGL");
  }
  @ViewChild('unityView', { static: true }) unityView;
  baseUrl: string;
  project: string;
  title="EBS Unity";
  notifications: Array<NotificationSummary> = new Array<NotificationSummary>();
  showSaved: boolean;

  constructor(platformLocation: PlatformLocation) {
    const location = (platformLocation as any).location;
    this.baseUrl = location.origin + location.pathname;
  }

  load(name: string) {
    this.project = name;
    let json = JSON.stringify(this.gameConfiguration);
    let file = new File([json],'game.json');
    this.unityView.loadProject(URL.createObjectURL(file));
  }

  send(objectName: string, methodName: string, messageValue: string) {
    this.showSaved = false;
    this.unityView.sendMessageToUnity(objectName, methodName, messageValue);
  }

  saveState(){
    this.send('GameViewModel','SaveState','');
    this.showSaved = true;
  }

}
