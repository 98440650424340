import { StorableObject } from "./storable-object";

export class PendingDeletion extends StorableObject{

    private static pendingDeletionUri = '/deleted-objects/awaiting-synchronization';

    constructor(uri: string){
        super();
        this.uri = uri != null && uri.trim() !== '' ? uri : PendingDeletion.pendingDeletionUri;
    }

    public awaitingDeletionSynchronization : Array<string> = new Array<string>();
  
}