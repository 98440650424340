import { Injectable } from '@angular/core';
import { SearchState } from '../models/search-state';
import { FilterState } from '../models/filter-state';

@Injectable({
  providedIn: 'root'
})
export class ForumStateService {

  fromPost: boolean = false;

  // have we set any state
  private _currentState: boolean;
  public get currentState(): boolean {
    return this._currentState;
  }
  public set currentState(value: boolean) {
    this._currentState = value;
  }

  // we are either searching or filtering
  private _currentlySearching: boolean;
  public get currentlySearching(): boolean {
    return this._currentlySearching;
  }
  public set currentlySearching(value: boolean) {
    this._currentlySearching = value;
  }

  // state for forum search
  private _searchState: SearchState;
  public get searchState(): SearchState {
    return this._searchState;
  }
  public set searchState(value: SearchState) {
    this._searchState = value;
  }

  // state for forum filter
  private _filterState: FilterState;
  public get filterState(): FilterState {
    return this._filterState;
  }
  public set filterState(value: FilterState) {
    this._filterState = value;
  }

  //specific for tags.. if setting up state then we don't need to tell parent to filter on tags as we already have the filtered list
  private _dontEmitTags : boolean;
  public get dontEmitTags() : boolean {
    return this._dontEmitTags;
  }
  public set dontEmitTags(value : boolean) {
    this._dontEmitTags = value;
  }
  

  constructor() {
    this._searchState = new SearchState();
    this._filterState = new FilterState();
    this._dontEmitTags = false;
  }

  resetState(){
    this._searchState = new SearchState();
    this._filterState = new FilterState();
    this._currentState = false;
    this._currentlySearching = false;
    this._dontEmitTags = false;
  }
}
