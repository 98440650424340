import { ModuleDiscussionEngagementStatistic } from '@app/courses/models/discussion/module-discussion-engagement-statistic';
import { StorableObject } from '@app/core/storable-object';

export class CourseDiscussionEngagementStatistics extends StorableObject {
    public moduleDiscussionEngagementStatisticsList: Array<ModuleDiscussionEngagementStatistic> = new Array<ModuleDiscussionEngagementStatistic>();
    public totalScore = 0;
    public totalAwardedScore = 0;
    public totalDiscussions = 0;
    public personId: string;
    public courseCode: string;
    public getMark() {
        this.totalAwardedScore = 0;
        this.totalScore = 0;
        this.totalDiscussions = 0;

        this.moduleDiscussionEngagementStatisticsList.forEach(moduleDiscussionEngagementStatistic => {
            var temp = new ModuleDiscussionEngagementStatistic();
            Object.assign(temp, moduleDiscussionEngagementStatistic);
            moduleDiscussionEngagementStatistic.discussionEngagementStatisticList.forEach(discussionEngagementStatistic =>{
                if(!discussionEngagementStatistic.isNoDiscussionInModule && discussionEngagementStatistic.includeInCalculation){
                    this.totalDiscussions++;
                }
            })
        });
        if(this.totalDiscussions == 0)this.totalDiscussions = 1;
        this.moduleDiscussionEngagementStatisticsList.forEach(moduleDiscussionEngagementStatistic => {

            var temp = new ModuleDiscussionEngagementStatistic();
            Object.assign(temp, moduleDiscussionEngagementStatistic);
            if (!temp.discussionEngagementStatisticList[0].isNoDiscussionInModule) {
                this.totalAwardedScore += temp.getEngagementScore();
                this.totalScore = this.totalDiscussions * 100;
            }
        });
    }
}