//default edtiro
export const editorConfig = {
  base_url: '/assets/tinymce', // Root for resources
  suffix: '.min',
  selector: "textarea",
  menubar: "",
  toolbar: "",
  browser_spellcheck: true,
  plugins: [
    'advlist', 'autolink', 'lists', 'link', 'image', 'searchreplace',
    'insertdatetime', 'media', 'table'
  ],
  promotion: false,
  smart_paste: true,
  newline_behavior: 'linebreak',
  paste_as_text: true,
  paste_merge_formats: true,
  branding: false,
  elementpath: false,
  default_link_target: "_blank",
  inline_styles: true,
  formats: {
    underline: { inline: 'u', exact: true }
  },
  setup: function (editor) {
    editor.on('init', function (e) {
      editor.focus();
    });
  },
};

//config for essay editor
export const essayEditorConfig = {
  base_url: '/assets/tinymce', // Root for resources
  suffix: '.min',
  selector: "textarea",
  menubar: "",
  toolbar: "",
  browser_spellcheck: true,
  plugins: [
    'advlist', 'autolink', 'lists', 'link', 'image', 'searchreplace',
    'insertdatetime', 'media', 'table'
  ],
  promotion: false,
  smart_paste: true,
  newline_behavior: 'linebreak',
  paste_as_text: true,
  paste_merge_formats: true,
  branding: false,
  elementpath: false,
  default_link_target: "_blank",
  inline_styles: true,
  formats: {
    underline: { inline: 'u', exact: true }
  },
};

