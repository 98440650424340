import { ChangeDetectorRef, Component, Inject, Input, NgZone, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { ConfigurationHelperService } from "@app/common/app-configuration/configuration-helper.service";
import { LtiResponse } from "@app/common/lti/lti-response";
import { OperationResponse } from "@app/common/models/operation-response";
import { OnlineStatusType } from "@app/common/online-status/online-status-type.enum";
import { OnlineStatusService } from "@app/common/online-status/online-status.service";
import { UserService } from "@app/common/security/user.service";
import { StaticText } from "@app/common/static-text";
import { CourseraRegistration } from "@app/coursera-registration/models/coursera-registration";
import { CourseraLtiForm } from "@app/courses/models/coursera/coursera-lti-form";
import { CourseraOAuthService } from "@app/courses/services/coursera-oauth.service";
import { Subscription } from "rxjs";
import { v4 as uuidv4 } from "uuid";
import { CourseraRegistrationModalService } from "../coursera-registration-modal/coursera-registration-modal.service";
import { CourseraRegistrationService } from "@app/coursera-registration/services/coursera-registration.service";

@Component({
    selector: 'ebs-coursera-lti',
    templateUrl: './coursera-lti.component.html',
    styleUrls: ['./coursera-lti.component.scss'],
  })
  export class CourseraLtiComponent implements OnInit, OnDestroy
  {
    @Input() useCompressedLayout: boolean = false;

    public loadingData = false;

    public disabledButton: boolean = true;

    public formActionUrl: string;
    public name: string;
    public email: string;

    public oauthConsumerKey: string;
    public oauthTimestamp: number;
    public oauthNonce: string;
    public oauthSignatureMethod: string = 'HMAC-SHA1';
    public oauthSignature: string;

    latestTimestamp: number;
    latestSignature: string;

    private courseraIntervalTimerId: any;

    @ViewChild("CourseraForm", { static: false }) courseraForm;

    isOffline: boolean;
    theSubscription: Subscription;
    offlineMessage = StaticText.courseraOfflineMessage;

    checkingCourseraRegistraton: boolean = true;
    courseraRegistration: CourseraRegistration;

    constructor(@Inject(CourseraOAuthService) private courseraOAuthService: CourseraOAuthService,
    @Inject(ConfigurationHelperService) private configurationHelperService: ConfigurationHelperService,
    @Inject(UserService) private contextService: UserService,
    @Inject(CourseraRegistrationService) private courseraRegistrationService: CourseraRegistrationService,
    private changeDetectorRef: ChangeDetectorRef,
    private onlineStatusService: OnlineStatusService,
    private courseraRegistrationModalService: CourseraRegistrationModalService
  ) {
    this.theSubscription = this.onlineStatusService.status.subscribe(
      event => {
        this.isOffline = (event === OnlineStatusType.OFFLINE) ? true : false
      }
    )
  }

    ngOnDestroy(): void {
        clearInterval(this.courseraIntervalTimerId);
        if (this.theSubscription)
          this.theSubscription.unsubscribe();
    }

    ngOnInit(): void {
        this.onlineStatusService.isOffline().subscribe(x => {
            this.isOffline = x;
            if (!this.isOffline) {
              this.initialisingCoursera();

              this.courseraRegistrationService.getCourseraRegistration().subscribe(registration => {
                if(registration.operationSucceeded) {
                  this.courseraRegistration = registration.data;
                }
                this.checkingCourseraRegistraton = false;
              });
            }
        }, error=>{
            this.isOffline = false;
            this.initialisingCoursera();
        });
    }

    initialisingCoursera(){
        this.formActionUrl = this.configurationHelperService.getCourseraFormUrl();
        this.oauthConsumerKey = this.configurationHelperService.getCourseraOAuthConsumerKey();
        this.name = `${this.contextService.currentUser.personalDetails.givenNames} ${this.contextService.currentUser.personalDetails.familyName}`;
        this.email = this.contextService.currentUser.personalDetails.emailAddress;
        this.disabledButton = true;
      }

      public setTimeStampAndNonce(): void {
        this.latestTimestamp = Math.round(Date.now() / 1000);
        this.oauthNonce = uuidv4().replaceAll("-", "");
      }

      public submitCourseraForm(): void {
        if (this.loadingData === true || this.latestSignature == null || this.latestTimestamp == null) {
          this.courseraOAuthService.notifyApplicationError('You might not be able to access coursera at this time. Please try again later', 'Something went wrong');
        }
    
        this.oauthTimestamp = this.latestTimestamp;
        this.oauthSignature = this.latestSignature;

        this.changeDetectorRef.detectChanges();
    
        this.courseraForm.nativeElement.submit();
      }
      
      public completeFormDataWithTimeStampAndSignature(): void {
        this.setTimeStampAndNonce();
        this.generateSignature();
      }

      public generateSignature(): void {
        this.loadingData = true;
    
        let ltiForm = new CourseraLtiForm(this.name,
          this.email, this.oauthConsumerKey,
          this.latestTimestamp == null ? "" : this.latestTimestamp.toString(),
          this.oauthNonce, this.oauthSignatureMethod);
    
        let uri = this.configurationHelperService.getApiServerUri();

        this.courseraOAuthService.generateOAuthSignature(uri, ltiForm)
          .subscribe(
            (response: OperationResponse<LtiResponse>) => {
              if (response.operationSucceeded) {
                this.latestSignature = response.data.signature;
                this.loadingData = false;
                this.submitCourseraForm();
              }
              else {
              }
            },
            error => {
              console.error('There has been an error accessing the data service : ' + error, 'Data Access Error');
            }
          );
      }

      accessCoursera() {
        if(!this.checkingCourseraRegistraton) {
          if(this.courseraRegistration == null) {
            this.courseraRegistrationModalService.confirm(this.courseraRegistration).subscribe((answer: CourseraRegistration) => {
              if (answer != null){
                this.courseraRegistration = answer;
                this.completeFormDataWithTimeStampAndSignature();
            }
            })
          } else {
                this.completeFormDataWithTimeStampAndSignature();
          }
        }
      }
  }