<div class="mb-2">
  <a [routerLink]="[returnLink]" class="link mb-1 mr-1">
    <i class="fas fa-chevron-left fa-1x fa-fw"></i>Return to course dashboard
  </a>
</div>
<h1>Manage course subscriptions </h1>

<div class="p-4" *ngIf="isOffline">
  <ebs-offline-message [isOffline]="isOffline" [offlineMessage]="forumOfflineMessage"></ebs-offline-message>
</div>

<div *ngIf="!isOffline">


  <div class="card">
    <h4>
      Manage your subscriptions to daily email notifications for this course
    </h4>
    <ebs-spinner [spinnerMessage]="spinnerText" *ngIf="gettingEmailSubDetails"></ebs-spinner>
    <div class="card-body">
      <div class="d-flex flex-column flex-lg-row mt-3 faculty-fav" *ngIf="!gettingEmailSubDetails && !showError">
        <p style="margin-top: 0.75rem">Full daily update: Subscribe to all posts, replies and forum topics </p>
        <label class="switch float-left ml-2 mr-3 mt-2">
          <input type="checkbox" [checked]="alreadySubscribedToFullSummary" (click)="updateEmailSubToFullSummary()">
          <span class="slider round"></span>
        </label>
      </div>
      <div class="d-flex flex-column flex-lg-row mt-3 faculty-fav" *ngIf="!gettingEmailSubDetails && !showError">
        <p style="margin-top: 0.75rem">Replies only: Replies to any post you have created, or posts to forums you created.</p>
        <label class="switch float-left ml-2 mr-3 mt-2">
          <input type="checkbox" [checked]="alreadySubscribedToPersonalisedSummary" (click)="updateEmailSubToPersonalisedSummary()">
          <span class="slider round"></span>
        </label>
      </div>
    </div>
  </div>
  <div class="card" *ngIf="!gettingEmailSubDetails && showError">
    <ebs-notification-multiple [notifications]="notifications">
    </ebs-notification-multiple>
  </div>
</div>