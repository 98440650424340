import { Injectable } from '@angular/core';
import { ServiceBase } from '@app/core/service-base';
import { Observable } from 'rxjs';
import { OperationResponse } from '@app/common/models/operation-response';
import { QuizStatistics } from '../models/quiz/quiz-statistics';

@Injectable({
  providedIn: 'root'
})
export class QuizStatisticsService extends ServiceBase {

  public getQuizStatistics(itemUrl: string, treatAsOffline: boolean): Observable<OperationResponse<QuizStatistics>> {
    var observable: Observable<OperationResponse<QuizStatistics>>

    if (!treatAsOffline) {
      observable = new Observable<OperationResponse<QuizStatistics>>(observer => {
        this.getObjectRemoteStoreFirst(itemUrl, observer);
      });
    } else {
      observable = new Observable<OperationResponse<QuizStatistics>>(observer => {
        this.getObjectFromLocalStore(itemUrl, observer);
      })
    }

    return observable;
  }
}
