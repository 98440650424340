<div class="like-wrapper">
  <div [class.is-liked]="passedInlike.likedByMe" style="margin-bottom:5px">

    <ebs-spinner [spinnerMessage]="''" [faColour]="'#000'" [faFont]="'0.9'" [boxPadding]="'0'" *ngIf="updatingLike">
    </ebs-spinner>
    <div class="spinner-wrapper" *ngIf="!updatingLike">
      <i class="btn fa fa-thumbs-up fa-1x fa-border" role="button" aria-label="like button" (click)="clickedLike()"
        aria-hidden="true" *ngIf="!isMyPostOrReply"></i>

      <span class="like-text" role="button"> {{passedInlike.numberOfLikes ? passedInlike.numberOfLikes : 0}}
        <span *ngIf="passedInlike.numberOfLikes != 1">likes</span>
        <span *ngIf="passedInlike.numberOfLikes === 1">like</span>
      </span>
    </div>

  </div>
  <ebs-message-simple [displayMessage]="errorMessage" *ngIf="showError"></ebs-message-simple>
</div>