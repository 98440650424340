<!-- M.S. for G.M. - a nice improvement here would be the use of  *ngIf, Then and Else templates system of Angular -->
<div *ngIf="postingData">
  <ebs-spinner [spinnerMessage]="'sending reply...'"></ebs-spinner>
</div>
<div class="row" *ngIf="!postingData">
  <div *ngIf="!showFullEditor" class="col-12">
    <button type="submit" class="btn btn-primary post-to-discussion"
      (click)="setShowFullEditor()">{{placeholderText}}</button>
    <span class="sr-only">{{placeholderText}}</span>
  </div>

  <div *ngIf="showFullEditor" class="col-12">
    <div class="row full-editor-row">
      <div class="col-12 ngx-editor-parent">
        <span class="text-mute" style="font-size: 0.7rem; float:right;color: #333;padding-right: 5px;">*when
          adding a link use the full url eg https://bbc.co.uk/news </span>
        <br />
        <form #theform>
          <editor [init]="editorConfig" name="editorText" (onDirty)="editing()" outputFormat="text"
            [(ngModel)]="editorText"></editor>
          <br />
        </form>
      </div>
    </div>
    <div class="row"  *ngIf="showError">
      <div class="col-12">
        <ebs-notification-multiple [notifications]="notifications"></ebs-notification-multiple>
      </div>
    </div>
    
    <ebs-file-uploader  [attachments]="attachments" (uploadInProgress)="onFileUploadInProgress($event)"></ebs-file-uploader>
    
   
    <div class="row">
      <div class="col-12 postas-box" *ngIf="!isOffline">
        <button type="submit" class="btn btn-sm btn-primary btn-min-width float-right"
          (click)="sendReply()" [disabled]="isPostButtonDisabled">{{buttonText}}</button>
        <button type="button" class="btn btn-sm btn-secondary btn-min-width float-right mr-2 mb-2"
          (click)="showFullEditor=false">Cancel</button>
        <span class="pr-3 float-right gdpr-message" tooltip="{{gdprMessage}}" containerClass="customTooltip"
          style=" cursor: help;">
          <ebs-message-simple [iconCssClasses]="'text-info fa fa-fw fa-exclamation-circle fa-lg'"
            [textCssClasses]="'text-info'" [displayMessage]="displayMessage" [displayOnRight]="false">
          </ebs-message-simple>
        </span>
      </div>
    </div>
  </div>
</div>