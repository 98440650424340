import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { NotificationSummary } from '@app/common/models/notification-summary';
import { SeverityLevel } from '@app/common/models/severity-level';
import { CourseStructure } from '@app/courses/models/course-structure';
import { ConfigurationHelperService } from '@app/common/app-configuration/configuration-helper.service';
import { PrintableMaterialsService } from '@app/courses/services/printable-materials.service';
import { PrintableMaterials } from '@app/common/models/printable-materials';
import { PrintableMaterialsMock } from '@app/common/mock/data/printable-materials-mock';
import { OnlineStatusService } from '@app/common/online-status/online-status.service';
import { OnlineStatusType } from '@app/common/online-status/online-status-type.enum';
import { Subscription } from 'rxjs';
import { StaticText } from '@app/common/static-text';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'ebs-printable-materials',
  templateUrl: './printable-materials.component.html',
  styleUrls: ['./printable-materials.component.scss']
})
export class PrintableMaterialsComponent implements OnInit, OnDestroy {
  @Input() courseCode = '';
  notifications: NotificationSummary[];
  gettingAvailableMaterials = false;
  downloadsError = false;
  courseStructure: CourseStructure;
  baseUrlForPrintableMaterials: string;
  printableMaterialsList: PrintableMaterials[] = [];
  returnLink: string;
  isOffline: boolean;
  theOfflineSubscription: Subscription;
  offlineMessage = StaticText.downloadsOfflineMessage;

  constructor(
    private titleService: Title,
    private configurationHelperService: ConfigurationHelperService,
    private printableMaterialsService: PrintableMaterialsService,
    private onlineStatusService: OnlineStatusService,
  ) {
    this.onlineStatusService.isOffline().subscribe(x => {
        this.isOffline = x;
    }, error=>{
        this.isOffline = false;
    });
    this.theOfflineSubscription = this.onlineStatusService.status.subscribe(
      event => {
        this.isOffline = (event === OnlineStatusType.OFFLINE) ? true : false
      }
    )
  }

  ngOnInit() {
    this.baseUrlForPrintableMaterials = this.configurationHelperService.getApiServerUri();
    this.getPrintableMaterials();
    this.returnLink = `/courses/${this.courseCode}/modules/0/sections/0`;
    this.titleService.setTitle('Download printable course materials');
  }

  getPrintableMaterials() {
    this.gettingAvailableMaterials = true;

    this.printableMaterialsService.getPrintableMaterialList(this.courseCode).subscribe(
      response => {
        if (response.operationSucceeded) {
          this.downloadsError = false;
          this.printableMaterialsList = response.data;
          //Object.assign(this.printableMaterialsList, PrintableMaterialsMock)
        } else {
          this.notifications = response.notifications;
          this.downloadsError = true;
        }

        this.gettingAvailableMaterials = false;
      },
      error => {
        console.log(error)
        if (this.notifications === undefined || this.notifications === null) {
          this.notifications = new Array<NotificationSummary>();
        }
        this.notifications.push(new NotificationSummary(
          'There has been an error getting the list of printable materials', 'Available Printable Materials Error', SeverityLevel.Error));
        this.downloadsError = true;
        this.gettingAvailableMaterials = false;
      }
    )
  }

  ngOnDestroy(): void {
    if (this.theOfflineSubscription)
      this.theOfflineSubscription.unsubscribe();
  }

}
