import { ChoiceOption } from './choice-option';
import { OptionsQuestion } from './options-question';

export class MultipleSelectQuestion extends OptionsQuestion {

    constructor(title: string, questionText: string, options: Array<ChoiceOption>) {
        super(title, questionText, options, 'multiple-select');
    }

    private getCorrectOptionsCount():number{
        let count=0;
        for (let option of this.options){
           if (option.isCorrect){
               count++;
           }
        }
        return count;
    }

    private getCorrectAnsweredCount():number{
        let count = 0;
        for (let option of this.options){
            if(option.isCorrect && option.isSelected){
                count++;
            }
        }
        return count;
    }

    public getMark(): number {
        let correctSelectionsCount = this.getCorrectOptionsCount();
        let correctAnsweredCount = this.getCorrectAnsweredCount();    

        if(correctSelectionsCount == correctAnsweredCount){
            return this.pointsAvailable;
        }

        return 0;
    }
}
