import { Injectable } from "@angular/core";
import { OperationResponse } from "@app/common/models/operation-response";
import { ServiceBase } from "@app/core/service-base";
import { Observable } from "rxjs";
import { CourseraRegistration } from "../models/coursera-registration";

@Injectable({
    providedIn: 'root'
  })
  export class CourseraRegistrationService extends ServiceBase {
  
    courseraRegistrationUrl: string = 'courseraregistration';

    getCourseraRegistration(): Observable<OperationResponse<CourseraRegistration>> {
      return this.getObjectFromRemoteStoreOnly<CourseraRegistration>(this.courseraRegistrationUrl);
    }
  
    setCourseraRegistration(): Observable<OperationResponse<CourseraRegistration>> {
      return this.setObjectInAvailableStores(new CourseraRegistration(""), this.courseraRegistrationUrl, false)
    }
  }
  