import { ActivityItem } from '../activity-item';

export class KortextActivity extends ActivityItem {
    public bookId :string;
    public pageNumber : number;
    public bookTitle : string;


    public constructor(bookId :string, pageNumber : number, bookTitle : string) {
        super(1, '', '', null);        
        this.bookId = bookId;
        this.pageNumber = pageNumber;
        this.bookTitle = bookTitle;
    }   

    public hasNoBookInfo():boolean{
        return (this.bookId === null && this.pageNumber === null && this.bookTitle === null);
    }
}