import { ApplicationRef, Injectable } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';
import { concat, interval } from 'rxjs';
import { first, take } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';

@Injectable({
    providedIn: 'root'
})
@Injectable()
export class SwManagerService {

    constructor(
        private appRef: ApplicationRef,
        private updates: SwUpdate,
        private toastr: ToastrService) {
    }


    public CheckForUpdatesOnceAppIsStabilized(): void {
        // Allow the app to stabilize first, before starting polling for updates with `interval()`.

        const appIsStable = this.appRef.isStable.pipe(first(isStable => isStable === true));
        const everyMinute = interval(60 * 1000);
        const everyMinuteOnceAppIsStable = concat(appIsStable, everyMinute);

        everyMinuteOnceAppIsStable.subscribe(() => this.updates.checkForUpdate());
    }

    public NotifyAvailableAndActivatedUpdates(): void {

        this.updates.versionUpdates.subscribe(event => {

            if(event.type === 'VERSION_READY'){
                console.log('A newer version is now available. Refresh the page now to update the cache');
                console.log('current version is', event.currentVersion);
                console.log('available version is', event.latestVersion);
                this.toastr.warning(
                    'A new version of the Learning Platform is available. Please tap or click on this message to activate the new version.',
                    'New version available.', {
                    disableTimeOut: true
                }).onTap
                    .pipe(take(1))
                    .subscribe(() => {
                        console.log('old version was', event.currentVersion);
                        console.log('new version is', event.latestVersion);
                        window.location.reload();
                    });
            }
        });
    }

    checkBrowserSupportsServiceWorkers() {
        if ('serviceWorker' in navigator)
            return true;

        return false;
    }
}
