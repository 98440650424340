<div *ngIf="dashboardMenu">
  <ng-container *ngFor="let group of dashboardMenu.groups">
    <div *ngIf="group != null">
      <h2 class="title h2" *ngIf="!isOffline || group.title">{{group.title}}</h2>
      <div class="card-deck" [ngClass]="{ 'card-deck-compressed': useCompressedLayout }">
        <div *ngFor="let item of group.items" class="card course-card">
          <div class="card-body">
            <div class="title" [ngClass]="{ 'h4': !useCompressedLayout }">
              <a class="link" [routerLink]="[item.link]" *ngIf="!isWorkingOffline && item.isConnectUsLink"> {{item.text}} </a>
              <a class="link" *ngIf="!isWorkingOffline && !item.isConnectUsLink" href="{{item.link}}">{{item.text}}</a>
              <span *ngIf="isWorkingOffline">{{item.text}}</span>
            </div>
            <div class="button">
              <a class="btn btn-primary" [routerLink]="[item.link]" role="button" aria-label="load button" *ngIf="!isWorkingOffline && item.isConnectUsLink">
                <span class="icon-gecko-direction-right"></span>
              </a>
              <a class="btn btn-primary" href="{{item.link}}" role="button" aria-label="load button" *ngIf="!isWorkingOffline && !item.isConnectUsLink">
                <span class="icon-gecko-direction-right"></span>
              </a>
              <span class="btn btn-primary disabled" role="button" aria-label="load button disabled"
                *ngIf="isWorkingOffline">
                <span class="icon-gecko-direction-right"></span>
              </span>

            </div>

          </div>
        </div>
      </div>
    </div>
  </ng-container>
</div>