<div class="hidden-print">
    <ebs-spinner [spinnerMessage]="'Quiz loading..'" [faColour]="'#fff'" [boxColour]="'#fff'" *ngIf="loadingData"
        [boxPadding]="'5px 15px'"></ebs-spinner>
    <div *ngIf="callFailed && !isOffline">
        <i class="fa fa-fw fa-lg fa-exclamation-circle"> </i> {{failedMessage}}
    </div>
    <ebs-offline-message [isOffline]='isOffline' [offlineMessage]='offlineMessage'></ebs-offline-message>
    <div class="row">
        <div class="col-sm-12">
            <!--another fudge for this editor... wont let me dynamically set the editable value... maybe just disble the save button-->
            <form #theessayform>
                <editor [init]="editorConfig" *ngIf="!isOffline" [disabled]="isOffline" (onDirty)="editing()"
                    id="essay-question-{{question.id}}" name="dataGuardModel" (onFocus)="onFocus()" (onKeyPress)="onKey()" [(ngModel)]="dataGuardModel"></editor>
            </form>
            <br/>
            <textarea #textareabox style="height: 100%" *ngIf="isOffline"
                disabled>{{question?.answer?.answerText}}</textarea>
        </div>
    </div>

    <div class="row">
        <div class="col-12">
            <ebs-notification-multiple [notifications]="notifications" *ngIf="callFailed"></ebs-notification-multiple>
        </div>
    </div>

    <div class="row" *ngIf="showAnswer && !callFailed">
        <div class="col-sm-12">
            <div ebsTechnicalContent class="text-info" [innerContent]="question.neutralCommentsHtml"></div>
        </div>
    </div>


    <div *ngIf="!isOffline">
        <div style="color: green" *ngIf="essaySaved && !callFailed">Your answer has been saved</div>
        <hr *ngIf="showAnswer">
        <button style="margin-left: 5px" class="btn btn-primary float-right" (click)="saveAnswer()" *ngIf="!isOffline">
            Save</button>
        <button id="cancelButton" class="btn btn-primary float-right" (click)="revertAnswer()"
            *ngIf="showCancel && !isOffline"> Cancel</button>
    </div>
    <div class="container" style="padding-bottom: 50px"></div>
</div>
<div class="d-none d-print-block">
    <h5>Notes:</h5>
    <p class="essay-question-print-box">

    </p>
</div>