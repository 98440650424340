export class PlasticTypeSummary {
    public plasticTypeId: number;
    public productQuantity: number;
    public itemQuantity: number = 0;
    public customersPerWeek: number = 24000;
    public percentCustomersToUse: number = 10;
    public purchaseFrequencyPerWeek: number = 1;
    public totalSupermarkets: number = 5000;  


    public get totalItemsPerWeek():number{
        return this.customersPerWeek * this.itemQuantity;
    }

    public get totalItemsPerYear():number{
        return this.totalItemsPerWeek * 52;
    }

    public get grandTotalItemsPerYear():number{
        return this.totalItemsPerYear * this.totalSupermarkets;
    }

}

