import { Injectable, ApplicationRef, OnDestroy } from '@angular/core';
import { ApplicationEventDispatcher } from './application-event-dispatcher.service';
import { Observable, of, Subscription } from 'rxjs';
import { UserService } from '@app/common/security/user.service';
import { StateService } from './state.service';
import { ConfigurationHelperService } from '@app/common/app-configuration/configuration-helper.service';
import { OnlineStatusService } from '@app/common/online-status/online-status.service';
import { OnlineStatusType } from '@app/common/online-status/online-status-type.enum';

// M.S. for G.M. - we'd need to write some unit tests for this service (for each method)
@Injectable({
    providedIn: 'root'
})
export class PollingService implements OnDestroy {
    pollingInterval = 5000;
    isOffline = false;
    theSubscription: Subscription;

    constructor(
        public applicationEventDispatcher: ApplicationEventDispatcher,
        private userService: UserService,
        private stateService: StateService,
        private configurationHelperService: ConfigurationHelperService,
        private onlineStatusService: OnlineStatusService,
        private applicationRef: ApplicationRef
    ) {
        this.pollingInterval = this.configurationHelperService.getPollingInterval();
        this.onlineStatusService.isOffline().subscribe(x => {
            this.isOffline = x;
        }, error=>{
            this.isOffline = false;
        });
        this.theSubscription = this.onlineStatusService.status.subscribe(
            event => {
                this.isOffline = (event === OnlineStatusType.OFFLINE) ? true : false;
            }
          );
    }

    pollTheServer() {
        return this.startStop();
    }

    startStop() {
        const root = this;
        clearInterval(this.stateService.intervalId);

        this.applicationRef.isStable.subscribe((isStable) => {
            if (isStable) {
                this.stateService.intervalId = window.setInterval(function () {
                    if (!root.userService.currentUserIsAuthenticatedOnRemoteServer() && !root.isOffline) {
                        root.applicationEventDispatcher.processHttpResponseStatus(405);
                        return 405;
                    } else {
                        // Fire event to signal everything is OK.
                        root.applicationEventDispatcher.processHttpResponseStatus(200);
                    }
                    return 200;
                }, this.pollingInterval);
                    }
          });

        return 200;
    }

    ngOnDestroy(): void {
        this.theSubscription.unsubscribe();
    }
}
