PackageItems<div class="container">
  <div *ngIf="isCalculating==false">
    <h3>Your shopping was successful. Read the plastic analysis below. Click the next challenge button to shop again
    </h3>
    <div>
      <button type="button" (click)="ChallangeClicked()" class="btn btn-primary float-right">Next Challange</button>
    </div>
    <p></p>
    <!-- <h4>Analysis of your shopping</h4> -->

    <!-- <p>Your total packaging by plastic type</p>
    <div *ngFor="let items of packageItemsByType;let i=index">
      <h4>Total plastic type {{i+1}}</h4>

      {{items.length}} items

      <div *ngFor="let item of items">
        <div class="row">
          <div class="col-sm">{{item.Name}}</div>
          <div class="col-sm">{{item.Issues}}</div>
        </div>
      </div>

    </div>

    <p>Black plastic trays, used for ready meals and meat products to make them look delicious, are really hard to
      recyle</p> -->


    <h4>Your plastic analysis</h4>
      <p>
      You chose products with <span class="highlight">{{plasticTypeSummary.itemQuantity}}</span> plastic items in total. 
      On average a single supermarket can have around <span class="highlight">{{plasticTypeSummary.customersPerWeek}}</span> customers per week.</p>  
  
      <p>If only <span class="highlight">{{plasticTypeSummary.percentCustomersToUse}}%</span> of other customers make the same choices as you, that will result in 
        <span class="highlight">{{plasticTypeSummary.totalItemsPerWeek}}</span> plastic items per week.</p>
      
      <p>If you all decide to buy the product once a week for a year, that will result in <span class="highlight">{{plasticTypeSummary.totalItemsPerYear}}</span> plastic items.</p>

      <p>There are about <span class="highlight">{{plasticTypeSummary.totalSupermarkets}}</span> in the UK, so there may be <span class="highlight">{{plasticTypeSummary.grandTotalItemsPerYear}}</span> plastic items
        produced as waste in one year in the UK alone.
      </p>

      <p>While recycling and recyclable materials are becoming more common, it makes great sense to reduce your consumption significantly. It's better on your health and on your pocket.
      Excessive consumption does not make you happier!</p>
      <!-- <h4>What can you do?</h4>
      <p>Black plastic trays, used for ready meals and meat products to make them look delicious, are really hard to recyle</p>
      <div>
        <button type="button" (click)="ChallangeClicked()" class="btn btn-primary float-right">Next Challange</button>
      </div> -->

  </div>
  <p></p>
  
  <h3>Product breakdown</h3>
  <p></p>
  
  <div *ngFor="let product of products">
    <h4>{{product.ProductName}}</h4>
    {{product.Issues}}
    <div class="table-responsive">
      <table class="table">
        <thead class="thead-dark">
          <tr>
            <th>Name</th>
            <th>Description</th>
            <th>Plastic Type</th>
            <th>Issue</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let item of product.PackageItems">
            <td>
              {{item.Name}}
            </td>
            <td>
              {{item.Description}}
            </td>
            <td>{{item.PlasticType}}</td>
            <td>{{item.Issues}}</td>
            <td>{{item.WhatYouCanDo}}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
  <div>
    <button type="button" (click)="ChallangeClicked()" class="btn btn-primary float-right">Next Challange</button>
  </div>

</div>