<div class="intro">
  <h1>Download your e-books</h1>
  <p>You can read the course ebooks via the Kortext website or the Kortext app.</p>

  <div class="card">
      <div class="card-header">
          <div class="row">
              <div class="col-sm-10">
                  <h2 class="mt-2">Two steps to offline e-books</h2>
              </div>              
          </div>
      </div>

      <div class="card-body">
          <!-- <h2 class="card-title h4">1. Get the books linked to your Kortext account</h2> -->
          <h2 class="card-title h4">1. Get the books linked to your Kortext account</h2>

          <div class="section-widget mb-4">
            <div>Just click the link below to get the ebooks associated to this course. If this is the first time you have used Kortext, 
              you will be asked to register for a Kortext account (you must register using the <strong>same email address</strong> you use for your 
              Edinburgh Business School account).
            </div>
            <!-- <a class="btn btn-md d-block d-md-inline-block  btn-primary mt-3" style="color: #fff;">Get the ebooks or register for your Kortext account</a> -->
            <div class="pl-2"><ebs-offline-message [isOffline]='isOffline' [offlineMessage]='offlineMessage'></ebs-offline-message></div>
            <ebs-kortext-activity [activityItem]="kortextActivityItem" *ngIf="isOffline"></ebs-kortext-activity>
          </div>

          <h2 class="card-title h4">2. Install your e-book reader</h2>

          <div class="section-widget mb-4">
              <div>This course uses the Kortext eBook reader for access to your course books. 
                To access these books offline you must install the Kortext e-book reader app. This app is free to install and your access to course books is included. 
                You can install the app from your app store:
              </div>              
              <ul>
                <li><a  href="https://www.kortext.com/ios" target="_blank" >Apple store</a></li>
                <li><a href="https://www.kortext.com/android" target="_blank" >Android store</a></li>
                <li><a href="https://www.kortext.com/windows" target="_blank" >Microsoft store</a></li>
              </ul>
          </div>
      </div>
  </div>
</div>