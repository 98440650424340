import Dexie from "dexie";
import { StorableObject } from "./storable-object";
import { Injectable } from "@angular/core";
import { IndexedDbFilter } from "./delegates";

@Injectable()
export class ObjectStoreBase{
    public get(uri) : Dexie.Promise<StorableObject>{
        return Dexie.Promise.resolve(<StorableObject>null);
    }

    public getAll() : Dexie.Promise<StorableObject[]>{
        return Dexie.Promise.resolve(<StorableObject[]>null);
    }
    
    public getAllPrimaryKeys() : Dexie.Promise<string[]>{
        return Dexie.Promise.resolve(<string[]>null);
    }

    public getAllBy(uriFilter:string) : Dexie.Promise<StorableObject[]>{
        return Dexie.Promise.resolve(<StorableObject[]>null);
    }

    public add(data : StorableObject):Dexie.Promise<string>{
        return Dexie.Promise.resolve(<string>null);
    }

    public update(uri : string, data : StorableObject): Dexie.Promise<number>{
        return Dexie.Promise.resolve(<number>null);
    }

    public addOrUpdate(uri: string, data: StorableObject): Promise<StorableObject>{
        return Dexie.Promise.resolve(<StorableObject>null);
    }

    public remove(uri  :string):Dexie.Promise<void>{
        return Dexie.Promise.resolve(<void>null);
    }

    public filter(delegate: IndexedDbFilter<StorableObject>) : Dexie.Promise<StorableObject[]>{
        return Dexie.Promise.resolve(<StorableObject[]>null);
    }
}