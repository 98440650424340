import { Component, OnInit, Input, Output , EventEmitter} from '@angular/core';
import { MatchedPairSubQuestion } from '@app/courses/models/quiz/matched-pair-subquestion';
import { ChoiceOption } from '@app/courses/models/quiz/choice-option';

@Component({
  selector: 'ebs-match-the-pairs-subquestion',
  templateUrl: './match-the-pairs-subquestion.component.html',
  styleUrls: ['./match-the-pairs-subquestion.component.scss']
})
export class MatchThePairsSubquestionComponent implements OnInit {
  @Input() question: MatchedPairSubQuestion;
  @Input() showFeedback: boolean;
  @Input() identifier: string;
  @Output() notify: EventEmitter<boolean> = new EventEmitter<boolean>();
  public incorrectComments: string; //M.S. fo D.C. - this looks like it is not used
  public correctComments: string;
  public isCorrect: boolean;
  public option: ChoiceOption;
  constructor() { }

  ngOnInit() {
  }

  //this needs to be renamed. Its getting the general comments 
  // attributed on the correct option
  getCorrectAnswerComment(): string{
    
    for(let option of this.question.options){
      if(option.isCorrect)
      {
        return option.commentsHtml;
      }
    }
    return "";
  }

  selectionChanged(selectedOption: ChoiceOption) {
    this.question.isComplete = true;
    for (let option of this.question.options) {
      if (option.text == selectedOption.text) {
        this.isCorrect = selectedOption.isCorrect;
        selectedOption.isSelected = true;
        this.correctComments = selectedOption.correctComments;
        this.incorrectComments = selectedOption.incorrectComments;
      }
    }
    this.notify.emit(true);
  }

}
