import { GameActivity } from "@app/courses/models/games/game-activity";
import { Observable, of } from "rxjs";
import { Metadata } from "@app/courses/models/metadata";
import { ActivityItemType } from "@app/courses/models/activity-item-type";
import { OperationResponse } from "@app/common/models/operation-response";
import { Injectable } from "@angular/core";

@Injectable({
    providedIn: 'root'
  })
export class GameActivityMockService{
    private gameActivity:GameActivity;

    public getGameActivity(): Observable<OperationResponse<GameActivity>>{

        this.gameActivity.metadata = new Metadata("S","Game Activity");
        this.gameActivity.name = "WebGL Demo";
        this.gameActivity.type = ActivityItemType.Game;
        this.gameActivity.id = 1;
        this.gameActivity.uri = "courses/blah/blah"

        const observable = new Observable<OperationResponse<GameActivity>>(observer => {
            this.gameActivity;
        })
        return observable;
    }
}