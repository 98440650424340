import { Component, OnInit, Input, HostListener, ViewChild, ChangeDetectorRef, ElementRef, ViewChildren, QueryList } from '@angular/core';
import { TopMenu } from './top-menu';
import { OnlineStatusService } from '../../online-status/online-status.service';
import { offlineTriggered } from '@app/common/animations/animations';
import { TopMenuService } from './top-menu.service';
import { ModalOfflineService } from '../modal-offline/modal-offline.service';
import { ApplicationEventDispatcher } from '@app/core/application-event-dispatcher.service';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
import { ApplicationEvent } from '@app/core/application-event';

@Component({
  selector: 'ebs-top-menu',
  templateUrl: './top-menu.component.html',
  styleUrls: ['./top-menu.component.scss'],
  animations: [offlineTriggered]
})
export class TopMenuComponent implements OnInit {
  topMenu: TopMenu;
  windowSize: number;


  @Input()
  supportsServiceWorkers: boolean = true;

  @Input()
  displayOfflineMenu: boolean = false;

  @Input()
  displayMenu: boolean;

  @Input()
  noInternetConnection: boolean = false;

  @Input()
  isOffline: boolean = false;

  @Input()
  isWorkingOffline: boolean = false;

  @Input()
  avatar: string;

  menuCssClass: string = 'special-dropdown-right-0px';

  @ViewChildren('submenus')
  private submenus: QueryList<ElementRef>;

  @ViewChildren('menubutton')
  private menubuttons: QueryList<ElementRef>;

  widthObservable: any;

  constructor(
    private topMenuService: TopMenuService,
    private onlineStatusService: OnlineStatusService,
    private modalOfflineService: ModalOfflineService,
    private applicationEventDispatcher: ApplicationEventDispatcher,
    private changeDetector: ChangeDetectorRef
  ) {
    this.applicationEventDispatcher.authorisationEvent.subscribe(
      (status: ApplicationEvent) => {
        this.refreshMenu();
      });

  }

  ngAfterViewChecked(): void {
    //Called after every check of the component's view. Applies to components only.
    //Add 'implements AfterViewChecked' to the class.
    this.submenus.forEach((element) => { this.listenForOffsetChanges(element) })
  }

  private listenForOffsetChanges(element: ElementRef) {
    if (!element) {
      return;
    }
    this.widthObservable = new BehaviorSubject<number>
      (element.nativeElement.offsetWidth);
    this.widthObservable.asObservable().subscribe(offsetWidth => {
      if (offsetWidth == 0) {
        return;
      }
      this.shouldMenuHugRight(offsetWidth, element.nativeElement.id);
    });
  }


  ngOnInit() {
    this.refreshMenu();
  }


  ngAfterViewInit() {
    this.windowSize = window.innerWidth;
  }

  private refreshMenu() {
    this.getMenu().subscribe(
      (result) => {
        this.topMenu = result.data;
      }, error => {
        console.log('top menu error', error)
      }
    );
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.windowSize = event.target.innerWidth;
  }

  getMenu() {
    return this.topMenuService.getTopMenu();
  }

  goOnline() {
    this.onlineStatusService.removeIsWorkingOffline().subscribe(x => {
      this.isWorkingOffline = false;
    }, error => { });
  }

  goOffline() {
    this.onlineStatusService.setIsWorkingOffline().subscribe(x => {
      this.isWorkingOffline = true;
    }, error => { });
  }

  popUpOffline() {
    this.modalOfflineService.confirm('Offline Options')
      .subscribe((answer) => {
      });
  }

  shouldMenuHugRight(submenuoffset: number, id: string) {
    this.menubuttons.forEach((element) => {
      if (element.nativeElement.id == "section-" + id.split('-')[1]) {
        if (this.windowSize - element.nativeElement.offsetLeft >= submenuoffset) {
          this.menuCssClass = 'special-dropdown';
        } else {
          this.menuCssClass = 'special-dropdown-right-0px';
        }
      }
    });
    this.changeDetector.detectChanges();
  }
}
