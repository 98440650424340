<div class="mb-2">
  <a [routerLink]="[returnLink]" class="link mb-1 mr-1"><i
      class="fas fa-chevron-left fa-1x fa-fw"></i>Return to list of topics</a>
</div>

<div class="p-4" *ngIf="isOffline">
  <ebs-offline-message [isOffline]="isOffline" [offlineMessage]="forumOfflineMessage"></ebs-offline-message>
</div>
<div class="p-4" *ngIf="idError">
  <ebs-message-simple [displayMessage]="noDiscussionIdMessage"></ebs-message-simple>
</div>

<ebs-spinner [spinnerMessage]="'loading....'" *ngIf="loadingForumDetails || loadingUserDetails"></ebs-spinner>
<div class="discussion-wrapper forum-wrapper" *ngIf="!isOffline">
  <div class="card" *ngIf="!loadingForumDetails && !loadingUserDetails && !idError">
    <div class="card-header">
      <div class="row" *ngIf="canDelete">
        <div class="col-12 pr-1" style="padding-top: 3px;">
          <button class="btn btn-sm btn-link float-right" (click)="delete()" style="padding-bottom: 5px;">
            <i class="fal fa-trash-alt fa-2x pr-1"></i>
            <span class=""> Delete</span>
          </button>
          <a class="btn btn-sm btn-link float-right" [routerLink]="[getLink()]" [queryParams]="getQueryParams()"
            style="padding-bottom: 8px;">
            <i class="fal fa-edit fa-2x pr-1"></i>
            <span class=""> Edit </span> </a>
        </div>
      </div>
      <hr style="margin-top: 0.5rem;" *ngIf="canDelete">
      <div class="row mt-2">
        <div class="col-12">
          <span class="content-type-label ng-star-inserted">Topic</span>
          <h2>{{forumDetails.title}} </h2>
        </div>
      </div>
    </div>
    <div class="card-body">
      <div class="row mb-1">
        <div class="col-12">
          <span class="font-weight-bold"> Tags:</span> <span class="font-italic"> {{tagList}}</span>
        </div>
      </div>
      <ebs-user-profile-flat [userCountryFlag]="forumDetails.person?.countryOfResidence" [boldText]="'Created by: '"
        [userAvatar]="forumDetails.person?.photo" [isFaculty]="forumDetails.person?.isFaculty"
        [displayName]="forumDetails.person?.name" [displayDate]="forumDetails.lastPostDate" *ngIf="forumDetails.person">
      </ebs-user-profile-flat>

      <div class="row">
        <div class="col-12">
          <ebs-message-simple [displayMessage]="'There was an error deleteing this discussion. Please try later.'"
            *ngIf="showDeleteError"></ebs-message-simple>
        </div>
      </div>

      <div class="row mt-2">
        <div class="col-12" innerHTML="{{forumDetails.description}}"> </div>
      </div>

      <ebs-discussion-manager [configItemsPerPage]="10" [discussionType]="'CourseForum'"  [courseCode]="courseCode"  [activityItem]="discussionActivity">
      </ebs-discussion-manager>    
    </div>
  </div>
</div>