import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap';

@Component({
  selector: 'ebs-modal-login',
  templateUrl: './modal-login.component.html',
  styleUrls: ['./modal-login.component.scss']
})
export class ModalLoginComponent implements OnInit {
  title: string;
  isAModalLogin = true;
  
  constructor(public bsModalRef: BsModalRef) { }

  ngOnInit() { }

  catchLoginSuccess(e: boolean) {
    if (e) {
      this.bsModalRef.hide();
    }
  }
}
