import { Injectable } from '@angular/core';
import { ServiceBase } from '@app/core/service-base';
import { Observable } from 'rxjs';
import { OperationResponse } from '@app/common/models/operation-response';
import { Tag } from '../models/tag';

@Injectable({
  providedIn: 'root'
})
export class TagsService extends ServiceBase {
  uri: string;

  getTagList(courseCode: string): Observable<OperationResponse<Tag[]>> {
    this.uri = `courses/${courseCode}/tags`;
    return this.getObjects<Tag>(this.uri, false);
  }
}