import { Component, OnInit, Input } from "@angular/core";
import { PostAndReplySearchResultDto } from "@app/courses/models/course-activity/post-and-reply-search-result-dto";
import { PagedResult } from "@app/common/models/paged-result";

@Component({
    selector: 'ebs-group-activity',
    templateUrl: './group-activity.component.html',
    styleUrls: ['./group-activity.component.scss']
})

export class GroupActivityComponent implements OnInit {

    @Input() courseCode: string;

    @Input() loadingData: boolean = true;

    @Input() isDiscussionContent: boolean = false;

    @Input() truncateContentLength: number = 200;

    @Input() postAndReplySearchResults: PagedResult<PostAndReplySearchResultDto>;

    ngOnInit(): void {

    }
}