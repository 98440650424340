import { Directive, ElementRef, OnChanges, Input } from '@angular/core';

import * as katex from 'katex';


@Directive({
    selector: '[ebsTechnicalContent]'
})
export class TechnicalContentDirective implements OnChanges {
    @Input() innerContent: string;

    constructor(private el: ElementRef) { }

    ngOnChanges() {
        this.el.nativeElement.innerHTML = this.innerContent;

        const spans = this.el.nativeElement.getElementsByClassName('math-content');
        for (let i = 0; i < spans.length; i++) {
            const equationToUse = spans[i].innerText;
            katex.render(equationToUse, spans[i]);
        }
    }
}
