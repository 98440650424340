<div *ngIf="!loadingData">
    <div class="mb-5 border-bottom hidden-print">
        <div *ngIf="isOffline === true; else elseBlock">   
            <app-player *ngIf="!isiOS() && hasValidOfflineUri()" [videoId]="videoId" [src]="offlineVideoUrl" type="video/mp4"></app-player>
            <app-player *ngIf="isiOS() && hasCachedVideo()" [videoId]="videoId" [src]="offlineIosVideoBlobUrl" type="video/mp4"></app-player>
            <!-- <app-player [videoId]="videoId" [src]="offlineIosVideoBlobUrl" type="video/mp4"></app-player> -->
            <div class="img-placeholder" *ngIf="!hasCachedVideo() && !hasValidOfflineUri()">
               <img src="./assets/img/placeholder.svg"/>
               <p></p>
            </div>           
        </div>
        <ng-template #elseBlock>
            <p style="text-align:center" *ngIf="isOffline === false">
                <iframe [src]="videoUrl | domSanitizerPipe: 'resourceUrl'" frameborder="0" title="video media iframe"
                    class="embed-responsive-item embed-responsive-16by9 ebs-media-response"
                    allowfullscreen="allowfullscreen" webkitallowfullscreen="webkitallowfullscreen"
                    mozallowfullscreen="mozallowfullscreen"></iframe>
            </p>           
        </ng-template>
       
    </div>

    <!-- <div class="img-placeholder d-none d-print-block" >
        <img src="./assets/img/placeholder.svg"/>
        <p></p>
     </div> -->

     <div style="display: none;" class="d-none d-print-block" style="width: 60%; height: auto; padding-left: .8rem;">
        <p>
            <a [href]="videoUrl | domSanitizerPipe: 'resourceUrl'">
                <img style="border: 1px solid #ddd" src="{{videoThumbNail}}" />
                <i class="fas fa-caret-square-right fa-3x" style="margin-left: -335px;"></i></a>
        </p>
        <div style="display: none;" class="d-none d-print-block">
            <a [href]="videoUrl | domSanitizerPipe: 'resourceUrl'">{{videoUrl}}</a>
        </div>
    </div>

</div>