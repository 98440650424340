import { StorableObject } from "@app/core/storable-object";
import { PagedRequest } from "./paged-request";

export class PagedResult<T extends StorableObject>{
    public currentPage:number;
    public itemsPerPage:number;
    public totalItems:number;
    public totalPages:number;
    public items: Array<T> = new Array<T>();
    public uri: string;
    
    public fromExistingArrayBasedOnRequest(request: PagedRequest<T>, storableObjects: Array<T>): void
    {
        if (storableObjects == null) return;

        this.items = storableObjects;
        this.totalItems = storableObjects.length;
        this.totalPages = 1;
        this.currentPage = 1;
        this.itemsPerPage = storableObjects.length;

        if (request == null || storableObjects.length < 1) return;

        if (request.sortOperator != null) storableObjects.sort(request.sortOperator);

        if (request.currentPage == null || request.currentPage < 1 || 
            request.itemsPerPage == null || request.itemsPerPage < 1) return;

        let startIndex : number = (request.currentPage - 1) * request.itemsPerPage;

        if (request.lastItemId != null && request.lastItemId !== '') {
            
            let lastItemFilter = `${request.getUriFilter()}/${request.lastItemId}`;            
            let lastItemIndex = storableObjects.findIndex(x => x.uri === lastItemFilter);

            if (lastItemIndex > -1 && lastItemIndex < storableObjects.length - 1){
                startIndex = lastItemIndex + 1;
            }
        }
            

        let endIndex : number = startIndex + request.itemsPerPage - 1;

        if (endIndex >= storableObjects.length) endIndex = storableObjects.length - 1;

        this.items = new Array<T>();
        this.itemsPerPage = request.itemsPerPage;
        this.currentPage = request.currentPage;

        this.totalPages = Math.ceil(storableObjects.length / request.itemsPerPage);

        for (let i = startIndex; i <= endIndex; i++) this.items.push(storableObjects[i]);
    }
}