    <div class="form-group form-row">
        <label class="col-sm-3" for="{{identifier}}"><strong>{{question.questionText}}</strong></label>
        <div class="col-sm-4 hidden-print" >
            <select (change)="selectionChanged(option)" class="form-control" id="{{identifier}}" [(ngModel)]="option">
                <option [disabled]='showFeedback' [ngValue]="option" *ngFor="let option of question.options">{{option.text}}</option>
            </select>
        </div>
        <div class="col-sm-4 d-none d-print-block">
                <div *ngFor="let option of question.options">
                    <span class="">{{option.text}}</span>
                </div>
        </div>
        <div class="col-sm-5 d-flex align-items-center">
            <span *ngIf="showFeedback && !isCorrect">
                <span class="icon-gecko-delete text-warning ml-1"></span>
                <span [innerHTML]="getCorrectAnswerComment()" id="correctComments" class="text-info small">
                </span>
            </span>
            <span *ngIf="showFeedback && isCorrect" >
                <span class="icon-gecko-check text-success mr-1" id="incorrectComments"></span>
            </span>
        </div>
    </div>