import { Component, OnInit, Input, Output, EventEmitter, OnDestroy } from '@angular/core';
import { NumericalQuestion } from '@app/courses/models/quiz/numerical-question';
import { Subscription } from 'rxjs';
import { StaticText } from '@app/common/static-text';
import { OnlineStatusService } from '@app/common/online-status/online-status.service';
import { OnlineStatusType } from '@app/common/online-status/online-status-type.enum';

@Component({
  selector: 'ebs-numerical-question',
  templateUrl: './numerical-question.component.html',
  styleUrls: ['./numerical-question.component.scss']
})
export class NumericalQuestionComponent implements OnInit, OnDestroy {
  @Input() question: NumericalQuestion;
  @Input() showFeedback: boolean;
  @Output() notify: EventEmitter<boolean> = new EventEmitter<boolean>();
  public isCorrect: boolean;
  theSubscription: Subscription;
  isOffline: boolean;
  offlineMessage = StaticText.quizOfflineMessage;

  constructor(private onlineStatusService: OnlineStatusService) {
    this.onlineStatusService.isOffline().subscribe(x => {
      this.isOffline = x;
    }, error => {
      this.isOffline = false;
    });
    this.theSubscription = this.onlineStatusService.status.subscribe(
      event => {
        this.isOffline = (event === OnlineStatusType.OFFLINE) ? true : false
      }
    )
  }

  ngOnInit() {
  }

  onValueChanged(event: any) {
    this.question.isComplete = true;
    this.question.score = 0;

    for (let answer of this.question.answers) {
      this.question.answer = answer;
      if (this.question.answer.margin === null) {
        if (this.question.answer.start === null) {
          if (event.target.value === this.question.answer.exact.toString()) {
            this.isCorrect = true;
            this.question.score = this.question.pointsAvailable;
          }
        } else {
          if (event.target.value >= this.question.answer.start.toString() && event.target.value <= this.question.answer.end.toString()) {
            this.isCorrect = true;
            this.question.score = this.question.pointsAvailable;
          }
        }
      } else {
        if (event.target.value >= (this.question.answer.exact - this.question.answer.margin) && event.target.value <= (this.question.answer.exact + this.question.answer.margin)) {
          this.isCorrect = true;
          this.question.score = this.question.pointsAvailable;
        }
      };
    }

    this.notify.emit(true);
  }

  ngOnDestroy(): void {
    this.theSubscription.unsubscribe();
  }


}