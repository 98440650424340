import { OnInit, Component, Input } from "@angular/core";
import { UserActivityLastVisitDto } from "@app/courses/models/course-activity/user-activity-last-visit-dto";
import { PostAndReplySearchResultDto } from "@app/courses/models/course-activity/post-and-reply-search-result-dto";
import { PagedRequest } from "@app/common/models/paged-request";
import { PagedResult } from "@app/common/models/paged-result";
import { CourseActivitySideBarService } from "@app/courses/services/course-activity-sidebar.service";
import { OperationResponse } from "@app/common/models/operation-response";
import { UserActivitySearchResultDto } from "@app/courses/models/course-activity/user-activity-search-result-dto";
import { OnlineStatusService } from '../../../common/online-status/online-status.service';
import { Subscription } from 'rxjs';
import { OnlineStatusType } from "@app/common/online-status/online-status-type.enum";
import { StaticText } from '../../../common/static-text';
import { Title } from "@angular/platform-browser";

@Component({
    selector: 'ebs-discussion-content',
    templateUrl: './discussion-content.component.html',
    styleUrls: ['./discussion-content.component.scss']
})
export class DiscussionContentComponent implements OnInit {

    @Input()
    public courseCode: string;

    @Input()
    public activityType: string;
    public lastItemId: string;
    public errorMessage: string;
    public discussionType: string = "0";
    public facultyUrl: string;
    public userActivityUrl: string;
    public returnLink: string;
    public getOrSetUserActivityAsViewedUrl: string;
    public lastViewedTime: UserActivityLastVisitDto;
    public totalFacultyItems: number;
    public totalItems: number;
    public facultyPostAndReplyPagedRequest: PagedRequest<PostAndReplySearchResultDto> = new PagedRequest<PostAndReplySearchResultDto>();
    public postAndReplyPagedRequest: PagedRequest<PostAndReplySearchResultDto> = new PagedRequest<PostAndReplySearchResultDto>();
    //maybe not needed in future
    public lastFacultyPostAndReplySearchResults: PagedResult<PostAndReplySearchResultDto>;
    public lastPostAndReplySearchResults: PagedResult<PostAndReplySearchResultDto>;
    public facultyPostAndReplySearchResults: Array<PostAndReplySearchResultDto> = new Array<PostAndReplySearchResultDto>();
    public postAndReplySearchResults: Array<PostAndReplySearchResultDto> = new Array<PostAndReplySearchResultDto>();
    public loadingMorePosts: boolean = false;
    public loadingMoreFacultyPosts: boolean = false;
    public isOffline: boolean = false;
    public theSubscription: Subscription;
    public offlineMessage: string = StaticText.activityStreamOfflineMessage;
    public isDiscussionContent: boolean = true;
    public truncateContentLength: number = 500;

    ngOnInit(): void {
        
        this.facultyUrl = "courses/" + `${this.courseCode}` + "/discussions/faculty/search";
        this.userActivityUrl = "courses/" + `${this.courseCode}` + "/discussions/user/activity";
        this.getOrSetUserActivityAsViewedUrl = "courses/" + `${this.courseCode}` + "/discussions/user/activity/viewed";
        this.returnLink = `/courses/${this.courseCode}/modules/0/sections/0`;
        this.getFacultyPostAndReplySearchResults(this.lastFacultyPostAndReplySearchResults == null);
        this.getPostAndReplySearchResults(this.lastPostAndReplySearchResults == null);
        this.onlineStatusService.isOffline().subscribe(x => {
            this.isOffline = x;
        }, error => {
            this.isOffline = false;
        });

        this.theSubscription = this.onlineStatusService.status.subscribe(
            event => {
                if (event === OnlineStatusType.OFFLINE) {
                    this.isOffline = true;
                }
                if (event === OnlineStatusType.ONLINE) {
                    this.isOffline = false;
                }
            }
        )
        this.setTheTitle();
    }

    setTheTitle() {
        if (this.isFacultyActivities()) {
            this.titleService.setTitle(StaticText.postAndRepliesFaculty);
        } else {
            this.titleService.setTitle(StaticText.postAndReplies);
        }
    }

    constructor(
        private titleService: Title,
        private courseActivitySideBarService: CourseActivitySideBarService,
        private onlineStatusService: OnlineStatusService) {
        this.facultyPostAndReplyPagedRequest.currentPage = 1;
        this.facultyPostAndReplyPagedRequest.itemsPerPage = 10;
        this.postAndReplyPagedRequest.currentPage = 1;
        this.postAndReplyPagedRequest.itemsPerPage = 10;
    }

    isFacultyActivities() {
        return "faculty" == this.activityType;
    }

    getFacultyPostAndReplySearchResults(isFirstTime: boolean = false) {
        if (!isFirstTime) {
            if (this.facultyPostAndReplyPagedRequest.currentPage < this.lastFacultyPostAndReplySearchResults.totalPages) {
                this.facultyPostAndReplyPagedRequest.currentPage++;
            } else {
                return;
            }
        }
        this.facultyPostAndReplyPagedRequest.uriFilter = this.facultyUrl;
        this.errorMessage = '';
        this.loadingMoreFacultyPosts = true;
        this.courseActivitySideBarService.getFacultyActivity(this.facultyPostAndReplyPagedRequest).subscribe((postAndReplySearchResultsOperationResponse: OperationResponse<PagedResult<PostAndReplySearchResultDto>>) => {
            if (postAndReplySearchResultsOperationResponse.operationSucceeded) {
                this.lastFacultyPostAndReplySearchResults = postAndReplySearchResultsOperationResponse.data;
                this.facultyPostAndReplySearchResults = [...this.facultyPostAndReplySearchResults, ...postAndReplySearchResultsOperationResponse.data.items];
                if (isFirstTime) {
                    this.totalFacultyItems = this.lastFacultyPostAndReplySearchResults.totalItems;
                }
                this.loadingMoreFacultyPosts = false;
            }
        });
    }

    getPostAndReplySearchResults(isFirstTime: boolean = false) {
        if (!isFirstTime) {
            if (this.postAndReplyPagedRequest.currentPage < this.lastPostAndReplySearchResults.totalPages) {
                this.postAndReplyPagedRequest.currentPage++;
            } else {
                return;
            }
        }
        this.postAndReplyPagedRequest.uriFilter = this.userActivityUrl;
        this.errorMessage = '';
        this.loadingMorePosts = true;
        this.courseActivitySideBarService.getLatestUserActivity(this.postAndReplyPagedRequest).subscribe((postAndReplySearchResultsOperationResponse: OperationResponse<PagedResult<UserActivitySearchResultDto>>) => {
            if (postAndReplySearchResultsOperationResponse.operationSucceeded) {
                this.lastPostAndReplySearchResults = postAndReplySearchResultsOperationResponse.data;
                this.postAndReplySearchResults = [...this.postAndReplySearchResults, ...postAndReplySearchResultsOperationResponse.data.items];
                if (isFirstTime) {
                    this.totalItems = this.lastPostAndReplySearchResults.totalItems;
                }
                this.loadingMorePosts = false;
            }
        });
    }

    isLastPostAndReplyPage() {
        if (this.lastPostAndReplySearchResults) {
            return this.postAndReplyPagedRequest.currentPage == this.lastPostAndReplySearchResults.totalPages;
        }
        return false;
    }

    isLastFacultyPostAndReplyPage() {
        if (this.lastFacultyPostAndReplySearchResults) {
            return this.facultyPostAndReplyPagedRequest.currentPage == this.lastFacultyPostAndReplySearchResults.totalPages;
        }
    }



    loadMoreFacultyPostAndReply(event) {
        event.preventDefault();
        event.stopPropagation();
        this.getFacultyPostAndReplySearchResults();
    }

    loadMorePostAndReply(event) {
        event.preventDefault();
        event.stopPropagation();
        this.getPostAndReplySearchResults();
    }
}