export function timeagoTransform(transformString: string): string {

    let now = new Date();
    let compareDate = new Date(transformString + 'Z');
    let secondsPast = (now.getTime() - compareDate.getTime()) / 1000;

    if (secondsPast < 60) {
        let amount = Math.floor(secondsPast);
        if (amount > 0){
            return amount + ' seconds ago' //its 'never' going to be 1 second!
        }else{
            return '1 second ago'
        }
        
    }

    if (secondsPast < 3600) {
        let amount = Math.floor(secondsPast / 60);
        if (amount === 1) {
            return amount + ' min ago'
        } else {
            return amount + ' mins ago'
        }
    }

    if (secondsPast <= 86400) {
        let amount = Math.floor(secondsPast / 3600);
        if (amount === 1) {
            return amount + ' hour ago'
        } else {
            return amount + ' hours ago'
        }
    }

    if (secondsPast > 86400) {
        let amount = Math.floor(secondsPast / 86400);
        if (amount === 1) {
            return amount + ' day ago'
        } else {
            return amount + ' days ago'
        }
    }
}