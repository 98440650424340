import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { LearningActivityItemComponentBase } from '@app/courses/models/dynamic-load/learning-activity-item-component-base';
import { FileIFrameActivity } from '@app/courses/models/file-iframe-activity';
import { StaticText } from '@app/common/static-text';
import { OperationResponse } from '@app/common/models/operation-response';
import { FileIFrameActivityService } from '@app/courses/services/file-iframe-activity.service';
import { OnlineStatusService } from '@app/common/online-status/online-status.service';
import { OnlineStatusType } from '@app/common/online-status/online-status-type.enum';
import { Subscription } from 'rxjs';

@Component({
  selector: 'ebs-file-iframe-activity',
  templateUrl: './file-iframe-activity.component.html',
  styleUrls: ['./file-iframe-activity.component.scss']
})
export class FileIFrameActivityComponent implements OnInit, LearningActivityItemComponentBase<FileIFrameActivity>, OnDestroy {
  @Input() activityItem: FileIFrameActivity;
  @Input() isOffline: boolean;
  offlineMessage = StaticText.fileIFrameMessageOffline;
  onlineMessage = StaticText.fileIFrameMessageOnline;
  gettingFileIFrameActivity: boolean;
  theSubscription: Subscription;

  constructor(private fileIFrameservice: FileIFrameActivityService,
    private onlineStatusService: OnlineStatusService,
  ) {
  
    this.theSubscription = this.onlineStatusService.status.subscribe(
      event => {
       if (event === OnlineStatusType.OFFLINE) {
          this.isOffline = true;
        } else {
          this.isOffline = false
          this.getData();
        }
      }
    )
  }

  ngOnInit() {
      this.onlineStatusService.isOffline().subscribe(x => {
        this.isOffline = x;
        if (!this.isOffline)
          this.getData();
    }, error=> {
      this.isOffline = false;
        this.getData();
    });
  }

  ngOnDestroy(): void {
    if (this.theSubscription)
      this.theSubscription.unsubscribe()
  }

  canDeactivate(): boolean {
    return true;
  }
  getData() {
    this.gettingFileIFrameActivity = true;
    this.fileIFrameservice.getFileIFrameActivity(this.activityItem.uri, this.isOffline)
      .subscribe(
        (response: OperationResponse<FileIFrameActivity>) => {
          this.activityItem = response.data;
          this.gettingFileIFrameActivity = false;
        },
        error => {
          console.log('FileIFrameActivity component error :' + error);
          this.gettingFileIFrameActivity = false;
        }
      );
  }
}
