import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { OperationResponse } from '@app/common/models/operation-response';
import { CourseForAdmin } from '../models/course-for-admin';
import { ServiceBase } from '@app/core/service-base';
import { CoursesForAdmin } from '../models/courses-for-admin';
import { DataStoreStrategy } from '@app/core/data-store-strategy';

@Injectable({
  providedIn: 'root'
})
export class CourseAdminService extends ServiceBase {
  
  allCoursesUrl:string = 'courses/allcourses'

  getCourses(): Observable<OperationResponse<CourseForAdmin[]>>{
    return this.getObjects<CourseForAdmin>(this.allCoursesUrl, false);
  }

  cacheCourse(course, itemUrl){
    return this.setObjectInAvailableStores<CoursesForAdmin>(course, itemUrl,false);
  }
  
}