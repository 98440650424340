  <div class="mb-4 hidden-print">
        <div class="form-group form-row">
            <div class="col-9 col-sm-6 quiz-numerical-input">
                <input [disabled]="showFeedback || isOffline" (change)="onValueChanged($event)" aria-label="numerical question feedback"
                 class="form-control" type="number" id="{{question.id}}">
            </div>
            <div class="col-3 col-sm-6 d-flex align-items-center">
                <span *ngIf="showFeedback && isCorrect" class="icon-gecko-check text-success ml-1"></span>
                <span *ngIf="showFeedback && !isCorrect" class="icon-gecko-delete text-warning ml-1"></span>
            </div> 
        </div>
        <div ebsTechnicalContent id="correctFeedback" class="text-info small" *ngIf="showFeedback && isCorrect && question.correctCommentsHtml!=null" [innerContent]="question.correctCommentsHtml"></div>
        <div ebsTechnicalContent id="incorrectFeedback" class="text-info small" *ngIf="showFeedback && !isCorrect && question.incorrectCommentsHtml!=null" [innerContent]="question.incorrectCommentsHtml"></div>
        <div ebsTechnicalContent id="neutralFeedback" class="text-info small" *ngIf="showFeedback && question.neutralCommentsHtml!=null" [innerContent]="question.neutralCommentsHtml"></div>
  </div>
<div class="d-none d-print-block">
    <p class="numerical-question-print-box">
    </p>
</div>
  
  

 