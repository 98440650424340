<hr>
<h3 class="h6">Test mark per module</h3>

<table class="table table-sm table-borderless stats-table shadow-none">
  <tbody>
    <tr *ngFor="let module of quizStatistics.quizStatisticsModules;let i = index">
      <th>
        <small>{{module.number}}</small>
      </th>
      <td style="width: 95%">
        <small
          *ngIf="!module.statisticsQuizes[0].isNoQuizInModule && !module.hasParticipated">{{module.attemptedMessage}}</small>
        <small *ngIf="!module.statisticsQuizes[0].isNoQuizInModule && module.hasParticipated"><strong
            id="avgscore">{{module.averageScore}}%</strong> {{module.attemptedMessage}}</small>
        <small *ngIf="module.statisticsQuizes[0].isNoQuizInModule">No test in this module</small>
        <div class="progress" style="height: 6px">
          <div class="progress-bar bg-blue" role="progressbar" [style.width]="module.barStyle" aria-valuenow="25"
            aria-valuemin="0" aria-valuemax="100"></div>
        </div>
      </td>
    </tr>
  </tbody>
</table>