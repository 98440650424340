<div class="text-activity-wrapper">
  <ebs-spinner [spinnerMessage]="'loading..'" [faColour]="'#999'" [boxColour]="'#999'" [faFont]="'2.2rem'"
    [boxFont]="'1.2rem'" [boxPadding]="'5%'" *ngIf="gettingGame"></ebs-spinner>

  <div *ngIf="!gettingGame">

    <div class="card-body">
      <div *ngIf="activityItem.gameName=='EBSDemo'">
        <ebs-webgl-demo [gameConfiguration] = "activityItem.gameConfiguration"></ebs-webgl-demo>
      </div>
      <div *ngIf="activityItem.gameName=='WebVR'">
        <ebs-webvr-demo [gameConfiguration] = "activityItem.gameConfiguration"></ebs-webvr-demo>
      </div>
      <div *ngIf="activityItem.gameName=='Supermarket'">
        <ebs-supermarket-game [gameConfiguration] = "activityItem.gameConfiguration"></ebs-supermarket-game>
      </div>
    </div>
  </div>
</div>