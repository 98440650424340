import { Component, OnInit, Input } from '@angular/core';
import { StaticText } from '@app/common/static-text';

@Component({
  selector: 'ebs-spinner',
  templateUrl: './spinner.component.html'
})
export class SpinnerComponent implements OnInit {
@Input()  spinnerMessage = StaticText.defaultSpinnerMessage;
@Input()  boxPadding = StaticText.defaultSpinnerPadding;
@Input()  boxFont = (StaticText.defaultSpinnerFont * 0.9) + 'rem';
@Input()  boxColour = StaticText.bodyColour;
@Input()  boxWidth = '100%';
@Input()  faFont = (StaticText.defaultSpinnerFont * 1.5) + 'rem';
@Input()  faColour = StaticText.bodyColour;

  constructor() { }

  ngOnInit() {
  }

}
