import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

import { Product } from '@app/courses/models/games/supermarket/product';
import { PlasticTypeSummary } from '@app/courses/models/games/supermarket/plastic-type-summary';
import { PackageItem } from '@app/courses/models/games/supermarket/package-item';
import { PackagingItemSummary } from '@app/courses/models/games/supermarket/packaging-Item-Summary';

@Component({
  selector: 'app-analysis',
  templateUrl: './analysis.component.html',
  styleUrls: ['./analysis.component.css']
})
export class AnalysisComponent implements OnInit {
  @Input() products: Array<Product>;
  @Output() nextChallengeEvent: EventEmitter<boolean> = new EventEmitter<boolean>();
  public plasticTypeSummary: PlasticTypeSummary;
  public packageItemsByType = new Array<Array<PackageItem>>();
  public packageItemsByTypeSummary = new Array<PackagingItemSummary>();
  public totalPlasticSummary: PlasticTypeSummary;

  isCalculating: boolean = true;
  testSummary: PackagingItemSummary;

  constructor() { }

  ngOnInit() {
    this.isCalculating = true;
    this.packageItemsByType = new Array<Array<PackageItem>>();
    this.calculateStatistics();
    this.isCalculating = false;
  }

  ChallangeClicked() {
    //fire event back up to event component
    this.nextChallengeEvent.emit(true);
  }

  public GetAllPackageItemCount(): number{
    let count=0;
    for(let product of this.products){
      product.PackageItems.forEach(item => {
        if (item.PlasticType!=0){
          count++;
        }
      });
    }
    return count;
  }

  public GetPackageItemsByType(product: Product, plasticType: number): Array<PackageItem> {
    let packageItemsArray = new Array<PackageItem>();
    product.PackageItems.forEach(item => {
      if (item.PlasticType === plasticType) {
        packageItemsArray.push(item);
      }
    });
    return packageItemsArray;
  }

  calculateStatistics() {
    for (let product of this.products) {
      for (let i = 1; i < 8; i++) {
        let items = new Array<PackageItem>();
        for (let item of this.GetPackageItemsByType(product, i)) {
          items.push(item);
        }
        this.packageItemsByType.push(items);
      }
    }

    this.plasticTypeSummary = new PlasticTypeSummary();
    this.plasticTypeSummary.itemQuantity = this.GetAllPackageItemCount();

    
  }

}
