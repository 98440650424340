import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'ebs-meta-header',
  templateUrl: './meta-header.component.html',
  styleUrls: ['./meta-header.component.scss']
})
export class MetaHeaderComponent implements OnInit {
  @Input() activityType: string;
  @Input() activityTitle: string;
  @Input() activitySize: string;
  @Input() activityDifficulty: number;
  @Input() activityDuration: number;
  @Input() linkId: string;
  @Input() sectionHeader: boolean = false;

  constructor() { }

  ngOnInit() {
  }

}
