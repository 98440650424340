import { Component, OnInit, HostListener, OnDestroy, Input } from '@angular/core';
import { StaticText } from '@app/common/static-text';
import { OfflineModeStatusService } from '@app/core/offline-mode-status.service';
import { ModalOfflineService } from '@app/common/components/modal-offline/modal-offline.service';
import { ApplicationEventDispatcher } from '@app/core/application-event-dispatcher.service';
import { ApplicationEvent } from '@app/core/application-event';
import { ApplicationEventType } from '@app/core/application-event-type';
import { enterOrLeave } from '@app/common/animations/animations';
import { ModalSimpleService } from '@app/common/components/modal-simple/modal-simple.service';
import { Subscription } from 'rxjs';
import { OnlineStatusType } from '@app/common/online-status/online-status-type.enum';
import { OnlineStatusService } from '@app/common/online-status/online-status.service';
import { CourseNavigationService } from '@app/courses/services/course-navigation.service';
import { map } from 'rxjs/operators';
import { SwManagerService } from '@app/common/service-workers/sw-manager.service';
import { Title } from '@angular/platform-browser';
import { UserProfile, UserRole } from '@app/common/models/user';

@Component({
  selector: 'ebs-downloads',
  templateUrl: './downloads.component.html',
  styleUrls: ['./downloads.component.scss'],
  animations: [enterOrLeave]
})
export class DownloadsComponent implements OnInit, OnDestroy {
  @Input() currentUser: UserProfile;
  
  para1 = StaticText.downloadsPara1;
  para2 = StaticText.downloadsPara2;
  option1 = StaticText.downloadsOption1;
  option2 = StaticText.downloadsOption2;
  option3 = StaticText.downloadsOption3;
  gdprMessage = StaticText.gdprMessage;
  hasUserAcceptedTerms = false;
  comeFromClosedPopup = false;
  theSubscription: Subscription;
  @Input() courseCode = '';
  returnLink: string;
  isOffline: boolean;
  theOfflineSubscription: Subscription;
  offlineMessage = StaticText.downloadsOfflineMessage;
  supportsServiceWorkers = true;

  constructor(
    private titleService: Title,
    private offlineModeStatusService: OfflineModeStatusService,
    private modalOfflineService: ModalOfflineService,
    private applicationEventDispatcher: ApplicationEventDispatcher,
    private courseNavigationService: CourseNavigationService,
    private modalSimpleService: ModalSimpleService,
    private onlineStatusService: OnlineStatusService,
    private swManagerService: SwManagerService
  ) {
    if (this.swManagerService.checkBrowserSupportsServiceWorkers()) {
      this.theSubscription = this.applicationEventDispatcher.offlineModeRelatedEvent.subscribe((event: ApplicationEvent) => {
        if (event.type === ApplicationEventType.OfflinePopupClosed) {
          //recheck and show screen if they have enabled
          this.comeFromClosedPopup = true;
          this.checkIsEnabled();
        }
        if (event.type === ApplicationEventType.IndexedDbCleared) {
          this.courseNavigationService.getCourseStructure(this.courseCode, false)
            .subscribe(
              courseResponse => {
                this.courseNavigationService.setLocalCourseStructure(this.courseCode, courseResponse.data).subscribe(
                  res => {
                    this.checkIsEnabled();
                  }
                )
              },
              err => { console.log('Error getting course structure offline comp: ', err) }
            ) // have to get new from api
        }
      });

      this.onlineStatusService.isOffline().subscribe(x => {
        this.isOffline = x;
      }, error => {
        this.isOffline = false;
      });

      this.theOfflineSubscription = this.onlineStatusService.status.subscribe(
        event => {
          this.isOffline = (event === OnlineStatusType.OFFLINE) ? true : false
        }
      )
      this.supportsServiceWorkers = true;
    } else {
      this.supportsServiceWorkers = false;
    }
  }

  enableVideoDownload():boolean{
    
      if (this.currentUser == null) return false;

      const canDownoadVid = this.currentUser.userAccount.roles
        .find(x =>  x === UserRole.PortalAdministrator);

      return canDownoadVid != null;
  }

  ngOnInit() {
    //check user is allowed to view this page
    if (this.supportsServiceWorkers) {
      this.checkIsEnabled();
      this.returnLink = `/courses/${this.courseCode}/modules/0/sections/0`;
    }
    this.titleService.setTitle('Manage offline modules');
  }

  ngOnDestroy(): void {
    if (this.theSubscription)
      this.theSubscription.unsubscribe();

    if (this.theOfflineSubscription)
      this.theOfflineSubscription.unsubscribe();
  }

  checkIsEnabled() {
    this.offlineModeStatusService.isEnabled().subscribe(
      response => {
        this.hasUserAcceptedTerms = response

        if (!this.comeFromClosedPopup) {
          if (!this.hasUserAcceptedTerms) {
            this.modalOfflineService.confirm('Offline Options')
              .subscribe((answer) => {
                this.comeFromClosedPopup = false;
              });
          }
        }
      },
      error => {
        console.log("error getting is enable from offline service")
      }
    )
  }

  disableOffline() {
    this.offlineModeStatusService.disable();
    this.applicationEventDispatcher.processOfflineModeRelatedEvent(ApplicationEventType.OfflinePopupClosed);
  }

  switchClick() {
    // checkboxes are the opposite    
    this.modalSimpleService.confirm(
      'Disable offline mode',
      StaticText.offlineDeleteConfirmation,
      [{ buttonText: 'No', buttonStyle: 'btn btn-secondary float-right btn-block' }, 
      { buttonText: 'Yes', buttonStyle: 'btn btn-primary float-right btn-block mt-0 ml-2' }])
      .subscribe((answer) => {
        if (answer === 'Yes') {
          this.disableOffline();
        }
      });
  }

}