import { Injectable } from "@angular/core";
import { CourseraRegistration } from "@app/coursera-registration/models/coursera-registration";
import { BsModalRef, BsModalService } from "ngx-bootstrap";
import { Observable } from "rxjs";
import { CourseraRegistrationModalComponent } from "./coursera-registration-modal.component";

@Injectable({
    providedIn: 'root'
  })
  export class CourseraRegistrationModalService {
    bsModalRef: BsModalRef;
  
    constructor(
      private bsModalService: BsModalService,
    ) { }

    confirm(courseraRegistration: CourseraRegistration): Observable<CourseraRegistration> {
      const initialState = {
        courseraRegistration: courseraRegistration,
        answer: new Array<CourseraRegistration>(),
      };
  
      this.bsModalRef = this.bsModalService.show(CourseraRegistrationModalComponent, 
        { initialState, class: 'modal-dialog-centered', backdrop: 'static' });
  
      return new Observable<CourseraRegistration>(this.getConfirmSubscriber());
    }

    private getConfirmSubscriber() {
      return (observer) => {
        const subscription = this.bsModalService.onHidden.subscribe((reason: string) => {
          observer.next(this.bsModalRef.content.answer);
          observer.complete();
        });
  
        return {
          unsubscribe() {
            subscription.unsubscribe();
          }
        };
      };
    }
  }