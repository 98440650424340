<div class="downloads-current-wrapper">
  <ebs-spinner [spinnerMessage]="'loading downloads'" *ngIf="gettingDownloads"></ebs-spinner>

  <div class="table-responsive">
    <table class="table table-sm">
      <thead>
        <tr>
          <th>Module</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngIf="showNoDownloadsMessage()">
          <td> No current downloads </td>
        </tr>
        <tr *ngFor="let download of currentDownloads">
          <td style="width: 70%">{{download.number}} - {{download.name}}</td>
        </tr>
      </tbody>
    </table>
  </div>

  <ebs-notification-multiple [notifications]="notifications" *ngIf="downloadsError"> </ebs-notification-multiple>
</div>