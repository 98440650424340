import { Injectable } from '@angular/core';
import { ServiceBase } from '@app/core/service-base';
import { Observable } from 'rxjs';
import { OperationResponse } from '@app/common/models/operation-response';
import { PagedRequest } from '@app/common/models/paged-request';
import { ForumAdd } from '../models/forum-add';
import { ForumListItem } from '../models/forum';
import { PagedResult } from '@app/common/models/paged-result';
import { ForumViewed } from '../models/forum-viewed';
import { DiscussionPostOrReplyItem } from '../../discussion-course/models/discussion-postorreply-item';
import { NewForum } from '../models/new-forum';

@Injectable({
  providedIn: 'root'
})
export class ForumService extends ServiceBase {
  uri: string
  
  //NOTE: when forums become offline compatible pass in the offline value from the component calls
  getDiscussions(courseCode: string, pagedRequest: PagedRequest<ForumListItem>): Observable<OperationResponse<PagedResult<ForumListItem>>> {
    pagedRequest.uriFilter = `courses/${courseCode}/discussions`;
    return this.getPagedObjects<ForumListItem>(pagedRequest, false)
  }

  getDiscussion(courseCode: string, discussionId: string): Observable<OperationResponse<NewForum>> {
    let uri = `courses/${courseCode}/discussions/${discussionId}`;
    return this.getObject(uri, false);
  }
 

  getForumPosts(courseCode: string, discussionId: string, pagedRequest: PagedRequest<DiscussionPostOrReplyItem>): Observable<OperationResponse<PagedResult<DiscussionPostOrReplyItem>>>{
    pagedRequest.uriFilter = `courses/${courseCode}/discussions/${discussionId}/posts`;
    return this.getPagedObjects<DiscussionPostOrReplyItem>(pagedRequest, false)
  }
  
  addDiscussion(courseCode: string, discussion: ForumAdd): Observable<OperationResponse<ForumAdd>> {
    this.uri = this.getApiServerUri() +  `courses/${courseCode}/discussions`;
    return this.postObjectToRemoteStoreOnly<ForumAdd>(discussion, this.uri);
  }

  updateDiscussion(courseCode: string, discussion: ForumAdd): Observable<OperationResponse<ForumAdd>> {
    this.uri = this.getApiServerUri() +  `courses/${courseCode}/discussions/${discussion.id}`;
    return this.postObjectToRemoteStoreOnly<ForumAdd>(discussion, this.uri);
  }

  updateDiscussionAsViewed(courseCode: string, discussionId: string){
    this.uri = this.getApiServerUri() +  `courses/${courseCode}/discussions/${discussionId}/viewed`;
    return this.postObjectToRemoteStoreOnly<ForumViewed>(discussionId, this.uri);
  }
  
  deleteDiscussion(courseCode: string, discussionId: string): Observable<OperationResponse<any>> {
    let discussionUri = `courses/${courseCode}/discussions/${discussionId}`; //service base adds delete to the end
    return this.deleteObjectFromAvailableStores(discussionUri, false)
  }
}