import { Component, OnInit, HostListener, ɵConsole, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Helpers } from '@app/common/helpers';
import { ApplicationContext } from '@app/common/current-context/application-context';
import { OnlineStatusService } from '@app/common/online-status/online-status.service';
import { CanComponentDeactivate } from '@app/common/security/can-deactivate.guard';
import { CourseContentComponent } from '@app/courses/components/course-content/course-content.component';
import { CourseActivitySidebarComponent } from '../courses/components/course-activity-sidebar/course-activity-sidebar.component';
import { UserService } from '@app/common/security/user.service';
import { UserProfile } from '@app/common/models/user';

@Component({
  selector: 'ebs-course-page',
  templateUrl: './course-page.component.html',
  styleUrls: ['./course-page.component.scss']
})
export class CoursePageComponent implements OnInit, CanComponentDeactivate {
  courseCode: string;
  moduleId = 0;
  sectionId = 0;
  sectionChangeObject: any;
  menuChangePropertySetByPage: number;
  courseName: string;
  courseLink: string;
  isItLeftIcon: boolean;
  showLinesIcon = true;
  showRightActivityIcon = true;
  hideMenu = true;
  showActivity = true;
  windowWidth: number;
  menuComplete = false;
  isIEOrEdge: boolean;
  isFireFox: boolean;
  menuHasFailed: any;
  wantToPrint = false;
  printOnly = 'none';
  newActivity = false;
  discussionId: string;
  showActivities: Boolean = false;
  activityType: string;
  courseActivityChildReady: Boolean = false; 
  isDemoUser = false;

  @ViewChild(CourseContentComponent, { static: false }) child: CourseContentComponent
  @ViewChild(CourseActivitySidebarComponent, { static: true }) courseActivityChild: CourseActivitySidebarComponent

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.setWindowWidth();
    this.workoutComponentDisplay();
  }

  constructor(
    private route: ActivatedRoute,
    private applicationContext: ApplicationContext,
    private onlineStatusService: OnlineStatusService,
    private userService: UserService
  ) {
    this.setWindowWidth();
  }

  ngAfterViewInit() {
    //this only get trigger when full refresh on dashboard page
    if (!this.isDemoUser) this.setUserActivityAsViewed();
    this.courseActivityChildReady = true;
  }

  ngOnInit() {
    this.userService.getCurrentUser().subscribe((userProfileObs: UserProfile) => {
      userProfileObs.userAccount.roles.find(x => x.toString() == "Demo") ? this.isDemoUser = true : this.isDemoUser = false;
    }, error => {
      console.log(error);
    },
    );


    //show discussions or show course content
    this.workoutComponentDisplay();
    this.showFullSteamOfActivities();
    //all pages should hide activity menu by default

    this.route.paramMap.subscribe(params => {
      this.courseCode = params.get('courseCode');
      this.moduleId = +params.get('moduleId');
      this.sectionId = +params.get('sectionId');
      this.closeActivityMenu();
      if (!this.isDemoUser) this.setUserActivityAsViewed();
    });

    if (this.route.pathFromRoot) {
      this.route.pathFromRoot[1].url.subscribe(val => {
        if (val.length >= 3 && val[2].path === 'ebooks') {
          this.sectionId = -1;
        }
        if (val.length >= 3 && val[2].path === 'printable-materials') {
          this.sectionId = -2;
        }
      });
    }

    this.isIEOrEdge = this.onlineStatusService.isIEOrEdge();
    this.isFireFox = this.onlineStatusService.isFireFox();
  }

  setUserActivityAsViewed() {
    //dashboard should open activity menu by default
    if (this.moduleId == 0 && this.sectionId == 0) {
      this.openActivityMenu();
      if (this.courseActivityChildReady) {
        if (!this.isDemoUser) this.courseActivityChild.setUserActivityAsViewed(false);
      }
    }
  }

  closeActivityMenu() {
    this.showActivity = false;
    this.showRightActivityIcon = true;
  }

  openActivityMenu() {
    this.showActivity = true;
    this.showRightActivityIcon = false;
  }

  showFullSteamOfActivities() {
    if (this.route && this.route.snapshot) {
      if (this.route.snapshot.url.length >= 3) {
        this.showActivities = this.route.snapshot.url[2].path == "activities";
        if (this.route.snapshot.url.length >= 4)
          this.activityType = this.route.snapshot.url[3].path;
      }
    }
  }

  canDeactivate(): boolean {
    // if it doesn't exist we dont care
    if (this.child) {
      return this.child.canDeactivate();
    } else {
      return true;
    }
  }

  handleMenuUpdate(e: number) {
    this.menuChangePropertySetByPage = e;
  }

  passOnMenuConnectionFail(e) {
    this.menuHasFailed = e;
  }

  workoutComponentDisplay() {
    // check width of screen... show both if > 1600, show menu if <1200
    // the naming is rubbish as it makes gecko's naming easier to follow (should be both hide or both show)
    if (Helpers.showMenuAndActivity(this.windowWidth)) {
      this.hideMenu = false; //show = true
      this.isItLeftIcon = true;
    } else {
      // hide both
      this.hideMenu = true; //show = false
      this.isItLeftIcon = false;
      this.closeActivityMenu();
    }
  }

  hideMenuSidebar(e: boolean) {
    if (e === true) {
      this.hideMenu = true;
      this.isItLeftIcon = false;
    } else {
      this.hideMenu = false;
      this.isItLeftIcon = true;
    }
  }

  hideActivitybar(e: boolean) {
    if (e === true) {
      if (this.showActivity) {
        this.showActivity = !this.showActivity;
        this.showActivity = false;
        this.showRightActivityIcon = !this.showRightActivityIcon;
      }
    }
  }

  setCourseName(e) {
    this.courseName = e.name;
    this.courseLink = `/${e.link}`;
    this.applicationContext.setCurrentCourse(this.courseCode, this.courseName);
    this.menuComplete = true;
  }

  toggleActivityBar() {
    this.showActivity = !this.showActivity;
    this.showRightActivityIcon = !this.showRightActivityIcon;
    this.showLinesIcon = true;

    //is the menu open and is between mobile and desktop
    if (Helpers.isItBetweenMobileAndDesktop(this.windowWidth)) {
      this.hideMenu = true;
      this.isItLeftIcon = false;
    }
    if (this.showActivity == true) {
      if (!this.isDemoUser) this.courseActivityChild.setUserActivityAsViewed(false);
      this.newActivity = false;
    }
  }

  setWindowWidth() {
    this.windowWidth = window.innerWidth;
  }

  handleMenuOnMobileClick(e) {
    this.hideMenu = false;
    this.showActivity = false;
    this.showRightActivityIcon = true;
    this.showLinesIcon = true; // this is weird!
  }

  mobileMenuClicked() {
    this.hideMenu = false;
    this.showActivity = false;
    this.showRightActivityIcon = true;
    this.showLinesIcon = !this.showLinesIcon;
  }

  updateNewActivityStatus(event) {
    this.newActivity = event;
  }

  offlineChanged(event) {
    if (event) {
      this.showActivity = false;
      this.showRightActivityIcon = true;
    }
  }
}
