import { Component, OnInit, HostListener, OnDestroy } from '@angular/core';
import { CourseForAdmin } from '@app/admin/models/course-for-admin';
import { CourseAdminService } from '@app/admin/services/course-admin.service';
import { OperationResponse } from '@app/common/models/operation-response';
import { CoursesForAdmin } from '@app/admin/models/courses-for-admin';
import { StaticText } from '@app/common/static-text';
import { OnlineStatusService } from '@app/common/online-status/online-status.service';
import { OnlineStatusType } from '@app/common/online-status/online-status-type.enum';
import { Subscription } from 'rxjs';
import { Title } from '@angular/platform-browser';
import { DialogService } from '@app/courses/services/dialog-service';

@Component({
  selector: 'ebs-course-admin',
  templateUrl: './course-admin.component.html',
  styleUrls: ['./course-admin.component.scss'],
  providers: [CourseAdminService]
})
export class CourseAdminComponent implements OnInit, OnDestroy {
  public courseList: CourseForAdmin[] = [];
  failedMessage = 'The courses have failed to load. Please try again';
  isLoading = false;
  hasFailed = false;
  cacheSuccess = false;
  public isSynching = false;
  public notificationMessages: Array<string> = new Array<string>();
  public selectedCourse: string;
  public spinnerMessage: StaticText = "Synching. This may take some time..";
  isOffline: boolean;
  theSubscription: Subscription;
  adminOfflineMessage = StaticText.adminOfflineMessage;

  constructor(
    private titleService: Title,
    private courseAdminService: CourseAdminService,
    private onlineStatusService: OnlineStatusService,
    private dialogService: DialogService
  ) {
    this.onlineStatusService.isOffline().subscribe(x => {
        this.isOffline = x;
    }, error=>{
        this.isOffline = false;
    });
    this.theSubscription = this.onlineStatusService.status.subscribe(
      event => {
        if (event === OnlineStatusType.OFFLINE)
          this.isOffline = true;

        if (event === OnlineStatusType.ONLINE) {
          this.isOffline = false;
          this.getCourses();
        }
      }
    )
  }

  ngOnInit() {
    if (!this.isOffline)
      this.getCourses();

      this.titleService.setTitle('Course administration');
  }

  getCourses() {
    this.isSynching = false;
    this.isLoading = true;

    this.courseAdminService.getCourses()
      .subscribe((coursesResponse: OperationResponse<CourseForAdmin[]>) => {
        if (coursesResponse.operationSucceeded) {
          this.courseList = coursesResponse.data;
        } else {
          // do something with the notifications
          for (let i = 0; i < coursesResponse.notifications.length; i++) {
            this.failedMessage += ' | ' + coursesResponse.notifications[i].message;
          }
        }
        this.isLoading = false;
      }, error => {
        this.isLoading = false;
      }

      );
  }

  cacheCourse(course: CourseForAdmin) {
    // popup dialog to confirm
    if(! this.dialogService.confirm(`Are you sure you want to synchronise ${course.courseCode}`)){return};
    this.isSynching = true;
    this.cacheSuccess = false;
    this.hasFailed = false;
    this.courseAdminService.cacheCourse(course,`courses/${course.courseCode}/cache`)
      .subscribe((cacheResponse: OperationResponse<CoursesForAdmin>) => {
        if (cacheResponse.operationSucceeded) {
          for (let i = 0; i < cacheResponse.notifications.length; i++) {
            this.notificationMessages.push(cacheResponse.notifications[i].message);
          }
          this.cacheSuccess = true;
        } else {
          // do something with the notifications
          for (let i = 0; i < cacheResponse.notifications.length; i++) {
            this.failedMessage += ' | ' + cacheResponse.notifications[i].message; 
            this.notificationMessages.push(cacheResponse.notifications[i].message);
          }
          this.hasFailed = true;
        }
        this.isSynching = false;
      }, error => {
        this.notificationMessages.push('There has been an error trying to cache this course!')
        console.log('course admin error', error)
        this.isSynching = false;
      });
  }

  ngOnDestroy(): void {
    if (this.theSubscription)
      this.theSubscription.unsubscribe();
  }

}
