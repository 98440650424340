export class Helpers {

    static showMenuAndActivity(width) {
        if (width > 1599) {
            return true;
        } else {
            return false;
        }
    }

    static isItBetweenMobileAndDesktop(width) {
        if ((width < 1600) && (width > 767)) {
            return true;
        } else {
            return false;
        }
    }

    static isItMobile(width) {
        if (width < 768) {
            return true;
        }else{
            return false;
        }
    }
}
