import { Injectable } from '@angular/core';
import { ServiceBase } from '@app/core/service-base';
import { PrintableMaterials } from '@app/common/models/printable-materials';
import { Observable } from 'rxjs';
import { OperationResponse } from '@app/common/models/operation-response';

@Injectable({
  providedIn: 'root'
})
export class PrintableMaterialsService extends ServiceBase {
  uri = 'courses/{courseCode}/printablematerials/list';

  getPrintableMaterialList(courseCode: string): Observable<OperationResponse<PrintableMaterials[]>> {
    return this.getObjects(this.uri.replace('{courseCode}', courseCode), false);
  }
}
