import { Component, Input, OnInit, Output, EventEmitter, OnDestroy } from '@angular/core';
import { Attachment } from '../../models/attachment';
import { UploadedFile } from '../../models/uploaded-file';
import { FileSelectDirective, FileDropDirective, FileUploader, FileItem, ParsedResponseHeaders, FileLikeObject } from 'ng2-file-upload'
import { KeyValuePair } from '@app/common/models/key-value-pair';
import { ConfigurationHelperService } from '@app/common/app-configuration/configuration-helper.service';
import { OperationResponse } from '@app/common/models/operation-response';
import { fileIFrameActivityMockData } from '@app/common/mock/data/file-iframe-activity';

@Component({
  selector: 'ebs-file-uploader',
  templateUrl: './file-uploader.component.html',
  styleUrls: ['./file-uploader.component.scss']
})
export class FileUploaderComponent implements OnInit, OnDestroy {

  @Input() attachments: Array<Attachment>;
  @Output() uploadInProgress = new EventEmitter<boolean>();


  public uploaders: Array<KeyValuePair<Attachment, FileUploader>> = new Array<KeyValuePair<Attachment, FileUploader>>();
  public isUploading: boolean;
  public displayFileIsTooLarge: boolean;
  public displayFileUploadFailed: boolean;
  public failedUploadedFileName: string;
  
  protected baseUrl: string;
  protected uploadUri: string; 
  protected maxFileSize: number; 
  public displayErrorMessage: string;

  constructor(private configurationHelperService: ConfigurationHelperService) { }

  ngOnDestroy(): void {
    for (let i=0; i < this.uploaders.length; i++){
      if (this.uploaders[i] && this.uploaders[i].value)
        this.uploaders[i].value.destroy();
    }
  }

  ngOnInit() {
    this.isUploading = false;
    this.displayFileIsTooLarge = false;
    this.displayFileUploadFailed = false;
    this.failedUploadedFileName = '';
    this.baseUrl = this.configurationHelperService.getApiServerUri();
    this.uploadUri = this.baseUrl + 'fileupload/upload';   
    this.maxFileSize = this.configurationHelperService.getFileUploadMaxSize();
    this.addAttachment();
  }

  displayAttachments():boolean{
    return this.uploaders.length > 0;
  }

  isNewUpload(attachment:Attachment):boolean{
    return attachment && attachment.uploadedFileId === 'new';
  }

  addAttachment(): void{
    //let newUploader = new FileUploader({ url: this.uploadUri, autoUpload: true, maxFileSize: this.maxFileSize,  });
    let newUploader = new FileUploader({ url: this.uploadUri, autoUpload: true,   maxFileSize: this.maxFileSize });
    
    let privateOnSuccessItemClosure = (fileItem: any, response: string, status: number, headers: any) => { 
      this.onSuccessItem(fileItem, response, status, headers);
    };

    newUploader.onSuccessItem = privateOnSuccessItemClosure;
    newUploader.onAfterAddingFile = (fileItem: any) => { this.onAfterAddingFile(fileItem); };
  
    let privateOnErrorItemClosure = (fileItem, response, status, headers) => { 
      this.onErrorItem(fileItem, response, status, headers);
    };

   

    newUploader.onWhenAddingFileFailed = (fileItem : FileLikeObject, filter, options) =>{
      console.log('file too large failed too add');
      this.isUploading = false;

      let activeUploaderKeyValuePair = this.uploaders.find(x => x.key && x.key.uploadedFileId === 'new');
  
      if(activeUploaderKeyValuePair == null || activeUploaderKeyValuePair.value == null){
        console.log('No active uploader found. Something went wrong');
        this.isUploading = false;
        this.displayErrorMessage = "Something went wrong with uploading";
        this.handleError(fileItem.name);
        return;
      }

      if (fileItem.size > this.maxFileSize) {        
        this.displayFileIsTooLarge = true;
        console.log('File too large');          
      }
    }
        
    newUploader.onErrorItem = privateOnErrorItemClosure;

    let newAttachment = new Attachment();
    newAttachment.uploadedFileId = 'new';

    let uploaderAttachmentPair = new KeyValuePair<Attachment, FileUploader>();
    uploaderAttachmentPair.key = newAttachment;
    uploaderAttachmentPair.value = newUploader;

    this.uploaders.push(uploaderAttachmentPair);

    this.displayFileUploadFailed = false;
    this.failedUploadedFileName = '';
    this.displayFileIsTooLarge = false;
    this.isUploading = false;
  }

  deleteAttachment(attachmentId):void{
    let uploaderIndex = this.uploaders.findIndex(x => x.key && x.key.uploadedFileId === attachmentId);

    if (uploaderIndex != -1){
      this.uploaders.splice(uploaderIndex, 1);
    }

    let attachmentIndex = this.attachments.findIndex(x => x.uploadedFileId === attachmentId);

    if (attachmentIndex != -1){
      this.attachments.splice(attachmentIndex, 1);
    }

    if (attachmentId === 'new'){
      this.displayFileIsTooLarge = false;
      this.isUploading = false;
    }
  }

  onSuccessItem(fileItem: any, response: string, status: number, headers: any): any {
    this.uploadInProgress.emit(false);
    this.isUploading = false;

    let attachmentReponse : OperationResponse<UploadedFile> = JSON.parse(response);

    if (attachmentReponse == null) {
      console.error('Could not deserialise response to Attachment');
      this.displayErrorMessage = "Something went wrong with uploading";
      this.handleError(fileItem.file.name);
      return;
    }

    if (!attachmentReponse.operationSucceeded){
      console.error('Upload operation failed');
      this.displayErrorMessage = "Something went wrong with uploading";
      if(attachmentReponse.notifications!=null){
      if(attachmentReponse.notifications.length>0)
        this.displayErrorMessage = attachmentReponse.notifications[0].message;
      }
      this.handleError(fileItem.file.name);
      return;
    }

    if (attachmentReponse.data == null){
      console.error('No uploaded file info!');
      this.displayErrorMessage = "Something went wrong with uploading";
      this.handleError(fileItem.file.name);
      return;
    }

    let activeUploaderKeyValuePair = this.uploaders.find(x => x.key && x.key.uploadedFileId === 'new');

    if(activeUploaderKeyValuePair == null || activeUploaderKeyValuePair.key == null){
      console.error('No active uploader found. Something went wrong');
      this.displayErrorMessage = "Something went wrong with uploading";
      this.handleError(fileItem.file.name);
      return;
    }

    activeUploaderKeyValuePair.key.name = attachmentReponse.data.name;
    activeUploaderKeyValuePair.key.size = attachmentReponse.data.size;
    activeUploaderKeyValuePair.key.type = attachmentReponse.data.type;
    activeUploaderKeyValuePair.key.uploadedFileId = attachmentReponse.data.id;

    let newAttachment = new Attachment();
    newAttachment.name = attachmentReponse.data.name;
    newAttachment.uploadedFileId = attachmentReponse.data.id;

    this.attachments.push(newAttachment);
    this.addAttachment();
    // console.log(response);
    // console.log(fileItem);
    // console.log(headers);

  };

  onAfterAddingFile(fileItem: any):any{

    this.isUploading = true;

    let activeUploaderKeyValuePair = this.uploaders.find(x => x.key && x.key.uploadedFileId === 'new');

    if(activeUploaderKeyValuePair == null || activeUploaderKeyValuePair.value == null){
      console.log('No active uploader found. Something went wrong');
      this.isUploading = false;
      this.displayErrorMessage = "Something went wrong with uploading";
      this.handleError(fileItem.file.name);
      return;
    }

    activeUploaderKeyValuePair.value.clearQueue();

      if (fileItem.file.size < this.maxFileSize) {        
        this.displayFileIsTooLarge = false;
        this.uploadInProgress.emit(true);
        activeUploaderKeyValuePair.value.queue.push(fileItem);
      } else {
        this.isUploading = false;
        this.displayFileIsTooLarge = true;
        console.log('File too large');          
      }
  }

  onErrorItem(fileItem: FileItem, response: string, status: number, headers: any): any {
    this.displayErrorMessage = "Something went wrong with uploading"
    this.handleError(fileItem.file.name);
  }

  handleError(fileName: string):void {
    this.uploadInProgress.emit(false);
    this.isUploading = false;

    this.displayFileUploadFailed = true;
    this.failedUploadedFileName = fileName;

    let activeUploaderKeyValuePairIndex = this.uploaders.findIndex(x => x.key && x.key.uploadedFileId === 'new');

    if(activeUploaderKeyValuePairIndex == null || activeUploaderKeyValuePairIndex < 0){
      console.log('No active uploader found. Nothing to be removed');
      return;
    }

    // this.uploaders.splice(activeUploaderKeyValuePairIndex, 1);
    let fileUploader = this.uploaders[activeUploaderKeyValuePairIndex].value;
    
    if (fileUploader && fileUploader.queue.length > 0){
      fileUploader.queue.pop();
    }
  }
}
