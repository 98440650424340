
<ebs-activity-group [isSectionHeader]='true' [activityGroup]='firstGroup'></ebs-activity-group>

<div *ngIf="section">
  <!-- <div *ngFor="let activityGroup of section.activityGroups">
    <ebs-activity-group [activityGroup]='activityGroup'></ebs-activity-group>
  </div> -->
  <div *ngFor="let activityGroup of activityGroupsWithoutHeader">
    <ebs-activity-group [activityGroup]='activityGroup'></ebs-activity-group>
  </div>
</div>