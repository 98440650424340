import { QuizAnswer } from './quiz-answer';

export class QuizQuestion {
    public id: number;
    public name: string;
    public text: string;
    public questionType: string;
    public score: number;
    public neutralComments: string;
    public neutralCommentsHtml: string;
    public isComplete = false;
    public pointsAvailable:number;
    public correctCommentsHtml: string;
    public incorrectCommentsHtml: string

    constructor(name: string, questionText: string, questionType: string) {
        this.name = name;
        this.text = questionText;
        this.questionType = questionType;
        this.score = 0;
        this.neutralComments = 'some neutral comments OK';
    }

    public getMark(): number {
        return 0;
    }
}
