<div class="downloads-available-wrapper">
  <ebs-spinner [spinnerMessage]="'loading downloads'" *ngIf="gettingDownloads"></ebs-spinner>
  <!-- <small class="float-right text-info" *ngIf="canCheckStorage"><b>{{estimate - usage | number:'1.0-0' }}</b> MB of
    storage space
    available</small> -->
  <div class="table-responsive" *ngIf="!gettingDownloads" style="overflow-x: visible">
    <table class="table">
      <thead>
        <tr>
          <th>Module</th>
          <th>Content</th>
          <th *ngIf="enableVideoDownload()">Videos</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let courseModule of avilableModuleDownloads" [ngStyle]="{'box-shadow': isCurrentlyBeingDownloadedOrDeleted(courseModule) ? '1px 1px 6px 3px #e7e7e7' : 'none',
          'opacity' : turnOffTheOtherRows(courseModule) ? '0.3' : '1' }">
          <td style="width: 50%">
            <i class="fas fa-check-circle fa-lg text-success" style="text-align: center; "
              title="module already downloaded" *ngIf="courseModule.isContentDownloaded"></i>
            <i class="far fa-circle fa-lg" style="color: #d7d7d7;text-align: center; " title="module not downloaded"
              *ngIf="!courseModule.isContentDownloaded"></i>
            &nbsp; {{courseModule.number }} - {{courseModule.name }} {{isUpdateAvailable(courseModule)}}
          </td>
          <td>
            <!--CONTENT ONLY-->
            <div class="d-flex flex-row" *ngIf="weHaveEnoughSpaceToShowContent(courseModule)">

              <div
                *ngIf="notCurrentlyBeingDeletedOrDownloaded(courseModule) && courseModule.standardContentSize > 0.99">
                {{courseModule.standardContentSize | number:'1.0-0'}} MB
              </div>
              <div
                *ngIf="notCurrentlyBeingDeletedOrDownloaded(courseModule) && courseModule.standardContentSize <= 0.99">
                &#60; 1 MB
              </div>
              <div *ngIf="showIcons(courseModule)">
                <span *ngIf="!courseModule.isContentDownloaded" role="button"
                  (click)="downloadModule(courseModule, false)" class="pl-1">
                  <i class="fal fa-download fa-fw fa-lg link" title="download module without videos"
                    *ngIf="!gettingDownloads"></i>
                </span>
                <span *ngIf="courseModule.isContentDownloaded" role="button" (click)="removeModule(courseModule, false)"
                  class="pl-1">
                  <i class="fal fa-trash-alt fa-fw fa-lg link" title="delete module without videos"></i>
                </span>
              </div>

              <div class="col-12 progress-col" *ngIf="isCurrentlyBeingDownloadedOrDeleted(courseModule)">
                <span *ngIf="showDownloadProgressContent(courseModule)" class="float-left pr-2 d-none .d-sm-block"><i
                    class="fal fa-download" [@flasher]="flashing"></i></span>
                <span *ngIf="showDeleteProgressContent(courseModule)" class="float-left pr-2 d-none .d-sm-block"><i
                    class="far fa-trash-alt" [@flasher]="flashing"></i></span>

                <div class="d-flex flex-row" *ngIf="showDownloadProgressContent(courseModule)">
                  <ebs-progress-bar [progressMax]="courseModule.monitor.getTotalSize()"
                    [progressValue]="courseModule.monitor.getDownloadedSize()" [progressWrapperWidth]="'100%'">
                  </ebs-progress-bar>
                  <small class="font-weight-bold pl-2">
                    &nbsp;{{courseModule.monitor.getDownloadedSize() / courseModule.monitor.getTotalSize() * 100  | number:'1.0-0' }}
                    %</small>
                </div>
                <div class="d-flex flex-row" *ngIf="showDeleteProgressContent(courseModule)">
                  <ebs-progress-bar [progressMax]="courseModule.deleteMonitor.getTotal()"
                    [progressValue]="courseModule.deleteMonitor.getTotal() - courseModule.deleteMonitor.getDeletionProgress()"
                    [progressWrapperWidth]="'100%'"></ebs-progress-bar>
                  <small class="font-weight-bold pl-2">
                    {{((courseModule.deleteMonitor.getTotal() - courseModule.deleteMonitor.getDeletionProgress()) 
                      / courseModule.deleteMonitor.getTotal())  * 100  | number:'1.0-0' }} % </small>
                </div>
              </div>
            </div>
            <div class="row"
              *ngIf="canCheckStorage && isItTooBig(courseModule.standardContentSize) && !courseModule.isContentDownloaded">
              <div class="col-12"> {{courseModule.standardContentSize | number:'1.0-0'}} MB </div>
              <small class="text-info">
                <div class="col-12 text-warning">
                  <i class="fas fa-exclamation-triangle"></i> Not enough space
                </div>
              </small>
            </div>
          </td>
          <td *ngIf="enableVideoDownload()">
            <!--VIDEO-->
            <div class="d-flex flex-row size-row-content"
              *ngIf="weHaveEnoughSpaceToShowVideo(courseModule) && !isItIos">
              <div *ngIf="notCurrentlyBeingDeletedOrDownloaded(courseModule) && courseModule.totalContentSize > 0.99">
                {{(courseModule.totalContentSize) | number:'1.0-0'}} MB
              </div>
              <div *ngIf="notCurrentlyBeingDeletedOrDownloaded(courseModule) && courseModule.totalContentSize <= 0.99">
                &#60; 1 MB
              </div>
              <div *ngIf="showIcons(courseModule)">
                <span *ngIf="!courseModule.isVideoListDownloaded && courseModule.isContentDownloaded" role="button"
                  (click)="downloadVideoModule(courseModule, true)" class="pl-1">
                  <i class="fal fa-download fa-fw fa-lg link" title="download module with videos"
                    *ngIf="!gettingDownloads"></i>
                </span>
                <span *ngIf="courseModule.isVideoListDownloaded" role="button"
                  (click)="removeModule(courseModule, true)" class="pl-1">
                  <i class="fal fa-trash-alt fa-fw fa-lg link" title="delete module with videos"></i>
                </span>
              </div>
              <div class="col-12 progress-col" *ngIf="isCurrentlyBeingDownloadedOrDeleted(courseModule)">
                <span *ngIf="showDownloadProgressVideos(courseModule)" class="float-left pr-2 d-none .d-sm-block"><i
                    class="fal fa-download" [@flasher]="flashing"></i></span>
                <span *ngIf="showDeleteProgressVideos(courseModule)" class="float-left pr-2 d-none .d-sm-block"><i
                    class="far fa-trash-alt" [@flasher]="flashing"></i></span>
                <div class="d-flex flex-row progress-row-content" *ngIf="showDownloadProgressVideos(courseModule)">
                  <ebs-progress-bar [progressMax]="courseModule.monitor.getVideoTotalSize()"
                    [progressValue]="courseModule.monitor.getVideoDownloadedSize()" [progressWrapperWidth]="'100%'">
                  </ebs-progress-bar>
                  <small class="font-weight-bold pl-2">
                    {{(courseModule.monitor.getVideoDownloadedSize() / courseModule.monitor.getVideoTotalSize()) * 100  | number:'1.0-0'}}%</small>
                </div>
                <div class="d-flex flex-row progress-row-content" *ngIf="showDeleteProgressVideos(courseModule)">
                  <ebs-progress-bar [progressMax]="courseModule.deleteMonitor.getTotal()"
                    [progressValue]="courseModule.deleteMonitor.getTotal() - courseModule.deleteMonitor.getDeletionProgress()"
                    [progressWrapperWidth]="'100%'"></ebs-progress-bar>
                  <small class="font-weight-bold pl-2">
                    {{((courseModule.deleteMonitor.getTotal() - courseModule.deleteMonitor.getDeletionProgress()) / courseModule.deleteMonitor.getTotal()) * 100  | number:'1.0-0'}}%
                  </small>
                </div>
                <div class="text-warning" *ngIf="checkIfModuleDownoadMayBeTakingTooLong(courseModule)">
                  <i class="far fa-fw fa-exclamation-circle"></i>
                  Your download is taking longer than expected
                </div>
              </div>
            </div>
            <div class="row"
              *ngIf="canCheckStorage && isItTooBig(courseModule.totalContentSize) && !courseModule.isVideoListDownloaded && !isItIos">
              <div class="col-12">{{(courseModule.totalContentSize) | number:'1.0-0'}} MB </div>
              <small class="text-info">
                <div class="col-12 text-warning">
                  <i class="fas fa-exclamation-triangle"></i> Not enough space
                </div>
              </small>
            </div>
            <div *ngIf="isItIos">
              <small class="text-info">
                <div class="col-12 text-info">
                  <i class="fas fa-road fa-lg fa-fw"></i> Coming soon
                </div>
              </small>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
  <ebs-notification-multiple [notifications]="notifications" *ngIf="downloadsError"> </ebs-notification-multiple>
</div>