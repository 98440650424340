import { Component, OnInit, ViewChild, Input, Output, EventEmitter } from '@angular/core';
import { SearchComponent } from '@app/common/components/search/search.component';
import { ForumSearchService } from '../../services/forum-search.service';
import { PagedRequest } from '@app/common/models/paged-request';
import { SearchItem } from '../../models/search-item';
import { LocationStrategy, PathLocationStrategy, Location } from '@angular/common';
import { ForumStateService } from '../../services/forum-state.service';
import { SearchState } from '../../models/search-state';
import { PagedResult } from '@app/common/models/paged-result';
import { Router } from '@angular/router';

@Component({
  selector: 'ebs-forum-search',
  providers: [Location, { provide: LocationStrategy, useClass: PathLocationStrategy }],
  templateUrl: './forum-search.component.html',
  styleUrls: ['./forum-search.component.scss']
})
export class ForumSearchComponent implements OnInit {
  @Input() hideInput: boolean=false;
  @Input() courseCode: string;
  currentSearchText: string;
  @ViewChild(SearchComponent, { static: true }) search: SearchComponent;
  pagedRequest = new PagedRequest();
  searchItems: SearchItem[] = [];
  gettingSearchItems = false;
  loadingMore = false;
  totalItems = 0;
  noItemsReturned = false;
  itemsLessThanItemsPerPage = false;
  loadedFromState = false;
  @Input() topicSearchText;

  inlineDiscussionId: string
  @Output() showingInline = new EventEmitter<boolean>()
  @Output() returnToSearch = new EventEmitter<boolean>()
  @Output() itemsFoundEvent = new EventEmitter<boolean>()

  constructor(
    private forumSearchService: ForumSearchService,
    private location: Location,
    private forumStateService: ForumStateService,
    private router: Router,
  ) { }

  ngOnInit() {
    this.pagedRequest.currentPage = 1;
    this.pagedRequest.itemsPerPage = 10;

    // if we have a current state then load it
    if (this.forumStateService.currentState && this.forumStateService.currentlySearching
      && this.forumStateService.searchState.pagedRequest.searchPhrase) {
      this.search.searchValueDisplay = this.forumStateService.searchState.pagedRequest.searchPhrase;
      this.successfulSearchResponse(this.forumStateService.searchState.pagedResults);
      this.loadedFromState = true;
      //scroll to the post we came from
      if (this.forumStateService.searchState.clickedItemIndex && this.forumStateService.searchState.clickedItemIndex > 0) {
        let root = this;
        setTimeout(() => {
          document.querySelector('#index' + root.forumStateService.searchState.clickedItemIndex).scrollIntoView({ behavior: 'smooth', block: "start", inline: "nearest" });
          root.forumStateService.searchState.clickedItemIndex = 0;
        }, 1000);
      }
    } else {
      this.forumStateService.currentState = true;
    }
    this.forumStateService.currentlySearching = true;
    this.forumStateService.dontEmitTags = true;
  }

  ngOnChanges(changes) {
    if (this.topicSearchText != undefined) {
      this.pagedRequest.currentPage = 1;
      this.pagedRequest.itemsPerPage = 2;
      
      this.currentSearchText = this.topicSearchText;
      this.getSearchList();
      this.forumStateService.fromPost = true;
      //raise event if items found
    }
  }

  getSearchList() {
    if (this.search) this.search.parentSearchHasComplete = false;

    if ((this.currentSearchText != this.pagedRequest.searchPhrase)
      || this.thereAreNoMore() || !this.loadingMore) {
      this.searchItems = [];
      this.pagedRequest.currentPage = 1;
    }

    this.pagedRequest.searchPhrase = this.currentSearchText
    this.pagedRequest.courseCode = this.courseCode

    this.forumSearchService.getSearchItems(this.courseCode, this.pagedRequest)
      .subscribe(
        searchResponse => {
          if (searchResponse.operationSucceeded) {
            this.successfulSearchResponse(searchResponse.data)
            this.loadedFromState = false;
            this.forumStateService.searchState.pagedRequest = this.pagedRequest;
            this.forumStateService.searchState.pagedResults = searchResponse.data
            this.forumStateService.searchState.pagedResults.items = this.searchItems
            if(this.searchItems.length>0){
              this.itemsFoundEvent.emit(true);
            }
          } else {
            alert('error')
          }
          this.loadingMore = false;
          this.gettingSearchItems = false;
        },
        error => {
          this.gettingSearchItems = false;
          this.loadingMore = false;
          console.log('There has been a search error', error)
        }
      )
  }

  successfulSearchResponse(searchData: PagedResult<SearchItem>) {
    this.search.parentSearchHasComplete = true;
    searchData.items.length === 0 ? this.noItemsReturned = true : this.noItemsReturned = false
    searchData.items.length < this.pagedRequest.itemsPerPage ? this.itemsLessThanItemsPerPage = true : this.itemsLessThanItemsPerPage = false
    this.search.searchResultCount = searchData.items.length
    this.searchItems = [...this.searchItems, ...searchData.items];
    this.search.searchTotalCount = this.searchItems.length
    this.totalItems = searchData.totalItems;
  }

  handleSearchText(searchText) {
    this.currentSearchText = searchText;
    if (searchText != '') {
      this.gettingSearchItems = true;
      this.getSearchList();
    } else {
      this.searchItems = [];
      // and clear state
      this.forumStateService.searchState = new SearchState();
      this.forumStateService.currentlySearching = false;
    }
  }

  loadMoreSearchItems() {
    this.loadingMore = true;
    if (this.loadedFromState) {
      this.pagedRequest = this.forumStateService.searchState.pagedRequest
      this.currentSearchText = this.forumStateService.searchState.pagedRequest.searchPhrase
      this.loadedFromState = false;
    }
    // if number of displayed items is 0  then essentially do a refresh    
    if (this.searchItems.length > 0) {
      this.pagedRequest.currentPage += 1;
      this.getSearchList();
    } else {
      this.pagedRequest.currentPage = 1;
      this.getSearchList();
    }
  }

  getLink(uri: string) {
    return '/' + uri.split('discussions/', 2)[0]
  }

  getQueryParams(uri: string) {
    var queryParams = {
      discussionId: uri.split('discussions/', 2)[1].split('/', 1)[0],
    };
    if (uri.split('posts/').length > 1) {
      queryParams["postId"] = uri.split('posts/', 2)[1].split('/', 1)[0]
    }
    if (uri.split('replies/').length > 1) {
      queryParams["replyId"] = uri.split('replies/', 2)[1].split('/', 1)[0];
    }
    return queryParams;
  }

  getFragment() {
    return '';
  }

  thereAreNoMore() {
    return (this.noItemsReturned || this.itemsLessThanItemsPerPage) && this.searchItems.length > 0
  }

  weMightHaveMore() {
    return !this.noItemsReturned && !this.itemsLessThanItemsPerPage
  }

  weHaveSearchItems() {
    return this.searchItems && this.searchItems.length > 0
  }

  routeFromSearchResult(uri, index) {
    // set index
    this.forumStateService.searchState.clickedItemIndex = index;
  }
}
