import { Component, OnInit, HostListener, AfterViewInit, Inject } from '@angular/core';
import { Helpers } from './common/helpers';
import { environment } from '../environments/environment';
import { SwManagerService } from './common/service-workers/sw-manager.service';
import { ApplicationEvent, TypedApplicationEvent } from './core/application-event';
import { ApplicationEventDispatcher } from './core/application-event-dispatcher.service';
import { ModalSimpleService } from './common/components/modal-simple/modal-simple.service';
import { ApplicationEventType } from './core/application-event-type';
import { ModalLoginService } from './common/components/modal-login/modal-login.service';
import { PollingService } from './core/polling.service';
import { UserService } from './common/security/user.service';
import { StateService } from './core/state.service';
import { ClearCacheService } from './core/clear-cache.service';
import { StaticText } from './common/static-text';
import { OfflineModeStatusService } from './core/offline-mode-status.service';
import { OnlineStatusService } from './common/online-status/online-status.service';
import { OnlineStatusType } from './common/online-status/online-status-type.enum';
import { PartialDownload } from './downloads/models/partial-download';
import { DeviceDetectorWrapperService } from './common/device-detector/device-detector-wrappe.servicer';
import { PersistentCacheManagerService } from './core/persistent-cache-manager.service';
import { GoogleTagManagerService } from './common/google-tag-manager/angular-google-tag-manager.service';
import { NavigationEnd, Router } from '@angular/router';

@Component({
  selector: 'ebs-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  isMobile = Helpers.isItMobile(window.innerWidth);
  numberOfErrorsBeingProcessed = 0;
  authorisedStatus: ApplicationEventType;
  weHaveAServerErrorDisplaying = false;
  noInternetConnection = false;
  isDownloadPopupActive : boolean = false;

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.isMobile = Helpers.isItMobile(window.innerWidth);
  }

  // M.S. Temporary disabling of the Service Workers
  // constructor(private swManagerService: SwManagerService) { }

  constructor(
    private applicationEventDispatcher: ApplicationEventDispatcher,
    private modalSimpleService: ModalSimpleService,
    private modalLoginService: ModalLoginService,
    private pollingService: PollingService,
    private stateService: StateService,
    public userService: UserService,
    private swManagerService: SwManagerService,
    private offlineModeStatusService: OfflineModeStatusService,
    private onlineStatusService: OnlineStatusService,
    private clearCacheService: ClearCacheService,
    @Inject(DeviceDetectorWrapperService) private deviceDetectorWrapperService: DeviceDetectorWrapperService,
    private persistentCacheManagerService:PersistentCacheManagerService,
    private gtmService: GoogleTagManagerService,
    private router: Router) { // M.S. - ClearCacheService needs to be alive at all times as it processes application globl events

    applicationEventDispatcher.httpResponseEvent.subscribe(
      event => {
        this.processApplicationEvent(event);
      }
    );

    applicationEventDispatcher.downloadEvent.subscribe(
      event => {
        this.processDownloadEvent(event);
      }
    );

    applicationEventDispatcher.authorisationEvent.subscribe(
      (status: ApplicationEvent) => {
        if (status.type === ApplicationEventType.Authorised) {
          this.authorisedStatus = ApplicationEventType.Authorised;
        }
      });
    this.onlineStatusService.internetConnectionStatus.subscribe(
      event => {
        this.noInternetConnection = (event === OnlineStatusType.OFFLINE) ? true : false;;
      }
    );
  }

  ngOnInit() {
    if (environment.production) {
      console.log('Environment set to Production mode');
      this.swManagerService.NotifyAvailableAndActivatedUpdates();
      this.swManagerService.CheckForUpdatesOnceAppIsStabilized();
    }
    this.pollingService.pollTheServer();

    //this.persistentCacheManagerService.ensureCacheWillNotBeEvicted();

    this.router.events.forEach(item => {
      console.log('ROOT router events for GMT');
    
      if (item instanceof NavigationEnd) {
        const gtmTag = {
          event: 'page',
          pageName: item.url
        };
        console.log('GMT');
        console.log(gtmTag);
        this.gtmService.pushTag(gtmTag);
      }
    });
  }


  public processDownloadEvent(applicationEvent: ApplicationEvent): void {
    if (this.isDownloadPopupActive === true) return;

    if (applicationEvent.type === ApplicationEventType.ModuleDownloadFailure){
      this.downloadsFailureModal(applicationEvent);
    }
    
    if (applicationEvent.type === ApplicationEventType.SuspiciousModuleDownload){
      this.suspiciousDownloadsModal(applicationEvent);
    }
  }

  public processApplicationEvent(applicationEvent: ApplicationEvent): void {
    this.numberOfErrorsBeingProcessed += 1;
    if (this.numberOfErrorsBeingProcessed === 1) {
      if (applicationEvent.type === ApplicationEventType.UnAuthorised) {
        if (this.stateService.numberOfLoginScreens < 1) {
          this.modalLoginService.confirm(' Authorisation Timeout')
            .subscribe((answer) => {
              this.numberOfErrorsBeingProcessed = 0; // Reset
            });
        }
      }  else {
        if (applicationEvent.type !== ApplicationEventType.Ok) {
          this.offlineModeStatusService.isEnabled().subscribe(x => {
            if (!this.weHaveAServerErrorDisplaying)
              this.serveApplicationErrorModal(applicationEvent, x);
          }, error => {

          });
        } else {
          this.numberOfErrorsBeingProcessed = 0; // reset for OK's that we arent showing
        }
      }
    }
    if (applicationEvent.type === ApplicationEventType.Ok) {
      this.numberOfErrorsBeingProcessed = 0;
      if (this.modalLoginService.bsModalRef && this.authorisedStatus === ApplicationEventType.Authorised) {
        this.modalLoginService.bsModalRef.hide();
      }
    }
  }

  serveApplicationErrorModal(applicationEvent: ApplicationEvent, hasOfflineModeEnabled: boolean) {
    let bodyMessage = '';
    let hasOfflineModeEnabledAndNoUserConnectivityIssue = (hasOfflineModeEnabled && !this.noInternetConnection);

    // is it a real no internet or is the api incorrect
    if (applicationEvent.type === ApplicationEventType.UserConnectivityIssue && this.noInternetConnection)
      bodyMessage += StaticText.noInternetError;

    if (applicationEvent.type === ApplicationEventType.ServerUnavailable)
      bodyMessage += StaticText.serverError;

    //make sure we have a message
    if (bodyMessage === '')
      bodyMessage = StaticText.serverError;

    this.weHaveAServerErrorDisplaying = true;

    //always display close, always display try again, 
    //only display workoffline if offline enabled and not a 'no internet connection' issue
    this.modalSimpleService.confirm(
      'Application Error',
      hasOfflineModeEnabled ? bodyMessage : applicationEvent.message,
      [
        {
          buttonText: StaticText.errorClose,
          buttonStyle: 'btn btn-primary float-right btn-block mt-0 '
        },
        {
          buttonText: StaticText.errorTryAgain,
          buttonStyle: 'btn btn-primary float-left btn-block mt-0 ml-2'
        },
        {
          buttonText: StaticText.errorWorkOffline,
          buttonStyle: hasOfflineModeEnabledAndNoUserConnectivityIssue ? 'btn btn-primary float-left btn-block mt-0 ml-2' : 'd-none'
        }])
      .subscribe((answer) => {

        if (answer === StaticText.errorWorkOffline) {
          this.onlineStatusService.setIsWorkingOffline().subscribe(x => { }, error => { });
        }
        this.weHaveAServerErrorDisplaying = false;
        this.numberOfErrorsBeingProcessed = 0; // reset

        if (answer === StaticText.errorTryAgain) {
          location.reload();
        }
      },
        error => {
          this.weHaveAServerErrorDisplaying = false;
        }
      );
  }

  downloadsFailureModal(applicationEvent: ApplicationEvent) {
    let bodyMessage = StaticText.downloadFailedMessage;

    this.weHaveAServerErrorDisplaying = true;

    const typedEvent : TypedApplicationEvent<PartialDownload> = applicationEvent as TypedApplicationEvent<PartialDownload>;

    if (typedEvent != null && this.deviceDetectorWrapperService.isiOS() === false){
      this.applicationEventDispatcher.processDownloadHttpRequest(typedEvent.data, ApplicationEventType.ClearPartialModule);
    }
    
    this.isDownloadPopupActive = true;
    //always display close, always display try again, 
    //only display workoffline if offline enabled and not a 'no internet connection' issue
    this.modalSimpleService.confirm(
      'Application Error',
      bodyMessage,
      [
        {
          buttonText: StaticText.errorClose, 
          buttonStyle: 'btn btn-primary float-right'
        },
      ])
      .subscribe((answer) => {
        this.isDownloadPopupActive = false;
        
        if (answer === StaticText.errorClose && this.deviceDetectorWrapperService.isiOS() === true) {          
          location.reload();
        }

        this.weHaveAServerErrorDisplaying = false;
        this.numberOfErrorsBeingProcessed = 0; // reset
      },
        error => {
          this.weHaveAServerErrorDisplaying = false;
        }
      );
  }

  suspiciousDownloadsModal(applicationEvent: ApplicationEvent) {
    let bodyMessage = StaticText.suspiciousDownloadMessage;

    this.weHaveAServerErrorDisplaying = true;

    const typedEvent : TypedApplicationEvent<PartialDownload> = applicationEvent as TypedApplicationEvent<PartialDownload>;
    
    this.isDownloadPopupActive = true;
    //always display close, always display try again, 
    //only display workoffline if offline enabled and not a 'no internet connection' issue
    this.modalSimpleService.confirm(
      'Download Warning',
      bodyMessage,
      [
        {
          buttonText: StaticText.downloadContinue, 
          buttonStyle: 'btn btn-primary float-right'
        },
        {
          buttonText: StaticText.downloadRestart, 
          buttonStyle: 'btn btn-primary float-right'
        },
      ])
      .subscribe((answer) => {
        
        if (answer === StaticText.downloadContinue) {
          this.applicationEventDispatcher.processDownloadHttpRequest(typedEvent.data, ApplicationEventType.ResetPartialModule);
        }

        if (answer === StaticText.downloadRestart) {
          this.applicationEventDispatcher.processDownloadHttpRequest(typedEvent.data, ApplicationEventType.ClearPartialModule);
          location.reload();
        }

        this.isDownloadPopupActive = false;
        this.weHaveAServerErrorDisplaying = false;
      },
        error => {
          this.weHaveAServerErrorDisplaying = false;
        }
      );
  }

  ensureAppCacheIsMarkedAsPersistent(){

  }
}
