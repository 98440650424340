<div class="p-4" *ngIf="isOffline">
  <ebs-offline-message [isOffline]='isOffline' [offlineMessage]='adminOfflineMessage'></ebs-offline-message>
</div>

<div class="course-admin-wrapper main-content scrollable full-height-minus-header" *ngIf="!isOffline">
  <div class="scrollbar">
    <div class="card" *ngIf="!isLoading">
      <div class="col-12 col-xl-8 offset-xl-2">
        <ebs-spinner *ngIf="isSynching" [spinnerMessage]="spinnerMessage" [boxPadding]="'15px'"></ebs-spinner>
        <div *ngIf="!isSynching" class="p-2">
          <div class="float-left" *ngFor="let message of notificationMessages">
            {{message}}
          </div>
          <span class="float-right">
            <a href="/system-notifications">Go to notifications <i class="fas fa-chevron-circle-right fa-lg"></i></a>
          </span>
        </div>
        <div class="table-responsive">
          <table class="table">
            <thead>
              <tr class="header-row">
                <th>Code</th>
                <th>Course name</th>
                <th><span class="float-right">Action</span></th>
              </tr>
            </thead>
            <tbody>
              <tr class="body-row" *ngFor="let course of courseList">
                <td>{{course.courseCode}}</td>
                <td>{{course.courseName}}</td>
                <td>
                  <button (click)="cacheCourse(course)" type="button" [disabled]="isSynching"
                    class="btn btn-light btn-sm float-right" style="padding: 6px 10px;">Sync now</button>
                </td>
              </tr>

            </tbody>
          </table>
        </div>
        <div *ngIf="cacheSuccess" class="alert alert-success">The cache job has successfully started</div>
        <div *ngIf="!cacheSuccess && !isSynching && hasFailed" class="alert alert-danger">Error updating cache</div>
      </div>
    </div>
  </div>
</div>