<div class="main-content">
<div class="course-header-bar">
    <div class="container-fluid px-0">
        <div class="header-container">
            <button type="button" id="sidebarToggle"
                class="btn btn-outline-secondary btn-sidebar-mobile-trigger d-block d-md-none"
                [ngClass]="{active: !showLinesIcon}" (click)="mobileMenuClicked()">
                <span class="icon-gecko-show-lines" *ngIf="showLinesIcon"></span>
                <span class="icon-gecko-delete" *ngIf="!showLinesIcon"></span>
                <span class="sr-only">Show hide sidebar</span>
            </button>

            <div class="course-title col"><a [routerLink]="courseLink">{{courseName}}</a></div>
            <div class="aside-collapse-button-wrapper hidden-print">
                <button type="button" id="asideCollapse" class="btn btn-outline-secondary"
                    (click)="toggleActivityBar()">
                    <span *ngIf="showRightActivityIcon"><span class="icon-gecko-chevron-left" style="position: relative; top: 1px;"></span>Show activity</span>
                    <span *ngIf="!showRightActivityIcon">Hide activity<span class="icon-gecko-chevron-right" style="position: relative; top: 1px;"></span></span>
                    <div *ngIf="newActivity" class="activity-indicator"></div>
                    <span class="sr-only">show hide activity sidebar</span>
                </button>
            </div>
            <div class="clearfix"></div>
        </div>
    </div>
</div>
<!--hide activity is the opposite (!) due to naming by gecko-->
<div id="content-wrapper" style="background-color:#eeeff0; box-shadow: 0 0 1px 1px #ccc;margin-top: 1px;"
    [class.sidebar-minimised]="hideMenu" [class.activity-show]="showActivity"
    [class.sidebar-mobile-show]="!showLinesIcon">
    <div class="d-flex flex-row my-flex-container">
        <div id="sidebar-wrapper" class="p-2 hidden-print scrollable-sidebar full-height-minus-header-and-course-header-bar">
            <div class="scrollbar" style="width:100%">
                <nav id="sidebar">
                    <ebs-course-menu-sidebar [menuUpdate]="menuChangePropertySetByPage"
                        (passBackCourseNameAndLink)="setCourseName($event)" [isItLeftIcon]="isItLeftIcon"
                        (tellParentMobileMenuClick)="handleMenuOnMobileClick($event)"
                        (tellParentCloseMenu)="hideMenuSidebar($event)"
                        (tellParentCloseActivity)="hideActivitybar($event)"
                        (menuNoConnection)="passOnMenuConnectionFail($event)" [courseCode]="courseCode">
                    </ebs-course-menu-sidebar>
                </nav>
            </div>
        </div>
        <div id="section-content-wrapper" class="p-2 scrollable full-height-minus-header-and-course-header-bar">
            <ebs-spinner *ngIf="!menuComplete" [spinnerMessage]="''" [faColour]="'#fff'" [boxColour]="'#fff'" 
                [boxPadding]="'5px 15px'" [faFont]="'2.2rem'"></ebs-spinner>
            <div class="scrollbar" style="width:100%">
                <div id="section-content">
                    <ebs-message-simple [displayMessage]="menuHasFailed.errorText"
                        *ngIf="menuHasFailed && menuHasFailed.showError"></ebs-message-simple>
                    <ebs-course-content (bubbleMenuUpdateFromProgressContent)="handleMenuUpdate($event)"
                        [postId]="postId" [discussionId]="discussionId" [replyId]="replyId" [courseId]="courseCode"
                        [sectionId]="sectionId" [moduleId]="moduleId" *ngIf="menuComplete && (!showActivities)">
                    </ebs-course-content>
                    <ebs-discussion-content [activityType]="activityType" *ngIf="menuComplete && showActivities"
                        [courseCode]="courseCode">
                    </ebs-discussion-content>
                </div>
            </div>
        </div>
        <div id="activity-wrapper" class="p-2 scrollable hidden-print full-height-minus-header-and-course-header-bar">
            <div class="scrollbar" style="width:100%">
                <div id="section-content">
                    <ebs-course-activity-sidebar (newActivityStatusChanged)="updateNewActivityStatus($event)"
                    (offlineChanged)="offlineChanged($event)" [courseCode]="courseCode"></ebs-course-activity-sidebar>
                </div>
            </div>
        </div>
    </div>
</div>
</div>