import { Injectable, Inject } from '@angular/core';
import { UserService } from '@app/common/security/user.service';
import { StaticText } from '@app/common/static-text';
import { ApplicationEventDispatcher } from './application-event-dispatcher.service';
import { ApplicationEvent } from './application-event';
import { ApplicationEventType } from './application-event-type';
import { VideoCachingService } from '@app/downloads/services/video-caching.service';
import { ObjectStoreBase } from './object-store-base';
import { DeviceDetectorWrapperService } from '@app/common/device-detector/device-detector-wrappe.servicer';

@Injectable({
    providedIn: 'root',
})
export class ClearCacheService {

    constructor(private objectStore: ObjectStoreBase,
        @Inject(UserService) private contextService: UserService,
        @Inject(ApplicationEventDispatcher) private appllicationEventDispatcher: ApplicationEventDispatcher,
        @Inject(VideoCachingService) private videoCachingService: VideoCachingService,
        @Inject(DeviceDetectorWrapperService) private deviceDetectorWrapperService: DeviceDetectorWrapperService
    ) {
        this.appllicationEventDispatcher.offlineModeRelatedEvent.subscribe((event: ApplicationEvent) => {

            if (event.type === ApplicationEventType.ClearIndexedDb)
                this.emptyCache(false);
        });
    }

    public emptyCache(excludeUserProfile: boolean): void {
        this.contextService.isOffline().subscribe(x => {
            if (x !== true) {
                this.objectStore.getAllPrimaryKeys()
                    .then((retrievedKeys: string[]) => this.clearCache(retrievedKeys, excludeUserProfile),
                        reason => console.log('Retrieving all cached items failed for: ' + reason))
                    .catch(console.error);
            }
        }
        )
    }

    public clearCache(retrievedObjectKeys: string[], excludeUserProfile: boolean): void {

        if (retrievedObjectKeys == null) { return; }

        let allPromises: Promise<void>[] = new Array<Promise<void>>();

        for (const key of retrievedObjectKeys) {
            const uri: string = key;   //G.M. for M.S. - do we need to do this ie why not just use key?
            if (excludeUserProfile === true &&
                (uri.includes(StaticText.currentUserNoSlash) //|| isAwaitingSynchronization 
                || uri.includes(StaticText.offlineModeStatus ))
            ) { continue; }

            allPromises.push(this.objectStore.remove(uri));
        }

        const isiOS = this.deviceDetectorWrapperService.isiOS();

        if (isiOS === false)
        {
            Promise.all(allPromises)
                .then(() => {
                    if (isiOS === false) {
                        this.videoCachingService.deleteVideoCache().subscribe(x => {
                            if (x.operationSucceeded) {
                                this.appllicationEventDispatcher.processOfflineModeRelatedEvent(ApplicationEventType.IndexedDbCleared);
                            }
                            else {
                                console.log(x.data);
                            }
                        }, error => {
                            console.log('Something went wrong whilst clearing Indexed DB');
                            console.log(error);
                        });
                    }
                }).catch(err => {
                    console.log('Something went wrong whilst clearing Indexed DB');
                    console.log(err);
                });
        }
    }

    public clearCache2(retrievedObjectKeys: string[], excludeUserProfile: boolean): void {

        if (retrievedObjectKeys == null) { return; }

        let allPromises: Promise<void>[] = new Array<Promise<void>>();

        for (const key of retrievedObjectKeys) {
            const uri: string = key;   //G.M. for M.S. - do we need to do this ie why not just use key?
            if (excludeUserProfile === true &&
                (uri.includes(StaticText.currentUserNoSlash) //|| isAwaitingSynchronization 
                || uri.includes(StaticText.offlineModeStatus) 
                || uri.includes(StaticText.currentCourseUrlNoSlash)
                || uri.includes('discussionstatistics')
                || uri.includes('quizstatistics')
                )
            ) { continue; }

            allPromises.push(this.objectStore.remove(uri));
        }

        const isiOS = this.deviceDetectorWrapperService.isiOS();

        if (isiOS === false)
        {
            Promise.all(allPromises)
                .then(() => {
                    this.videoCachingService.deleteVideoCache().subscribe(x => {
                        if (x.operationSucceeded) {
                            this.appllicationEventDispatcher.processOfflineModeRelatedEvent(ApplicationEventType.IndexedDbCleared);
                        }
                        else {
                            console.log(x.data);
                        }
                    }, error => {
                        console.log('Something went wrong whilst clearing Indexed DB');
                        console.log(error);
                    });


                }).catch(err => {
                    console.log('Something went wrong whilst clearing Indexed DB');
                    console.log(err);
                });
        }
    }
}