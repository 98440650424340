<div class="printable-materials">
    <!-- <ebs-spinner [spinnerMessage]="'loading..'" [faColour]="'#999'" [boxColour]="'#999'" [faFont]="'2.2rem'"
        [boxFont]="'1.2rem'" [boxPadding]="'5%'" *ngIf="gettingAvailableMaterials"></ebs-spinner> -->

    <div class="intro">
        <div class="mb-2">
            <a [routerLink]="[returnLink]" class="link mb-1 mr-1"><i class="fas fa-chevron-left fa-1x fa-fw"></i>Return to
                course dashboard </a>
        </div>
        <h1>Download printable course materials</h1>
      <p>You can download printable materials for the course. These include all of the textual content, 
        plus links to videos and other resources. They are intended to complement (rather than replace) the online course, 
        which provides the full set of learning materials including reading, videos, self-test questions and online discussion.</p>

      <p>Due to technical difficulties the Assessment preparation and practice modules have been omitted from the printable materials for some courses.</p>
      
      <div class="card">
            <div class="card-header">
                <h2 class="mt-2">Download PDFs</h2>
            </div>
            <ebs-spinner [spinnerMessage]="'loading modules..'" [faColour]="'#999'" [boxColour]="'#999'"
                [faFont]="'2.2rem'" [boxFont]="'1.2rem'" [boxPadding]="'5%'" *ngIf="gettingAvailableMaterials">
            </ebs-spinner>
            <div class="pl-2">
                    <ebs-offline-message [isOffline]='isOffline' [offlineMessage]='offlineMessage'></ebs-offline-message>
                  </div>
            <div class="card-body" *ngIf="!gettingAvailableMaterials && !isOffline">
                <div class="section-widget">
                    <div class="table-responsive">
                        <table class="table">
                            <tr>
                                <th scope="col" width="60%">Module</th>
                                <th scope="col">Version</th>
                                <th scope="col">Action</th>
                            </tr>
                            <tr *ngFor="let module of printableMaterialsList">
                                <td>{{module.moduleNumber}} - {{module.moduleName}}</td>
                                <td>{{module.dateAndTimeCreated | date:'dd-MMMM-y-HH:mm'}}</td>
                                <td>
                                    <a target="new" tooltip='download module as PDF'
                                        [href]="baseUrlForPrintableMaterials + module.uri + '.pdf'">
                                        <i class="fal fa-download fa-fw fa-lg"></i></a>
                                </td>
                            </tr>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
