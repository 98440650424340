import { Component, OnInit, Input } from '@angular/core';
import { LearningActivityItemComponentBase } from '@app/courses/models/dynamic-load/learning-activity-item-component-base';
import { ActivityItem } from '@app/courses/models/activity-item';

@Component({
  selector: 'ebs-audio-activity',
  templateUrl: './audio-activity.component.html',
  styleUrls: ['./audio-activity.component.scss']
})
export class AudioActivityComponent implements OnInit, LearningActivityItemComponentBase<ActivityItem> {
  @Input() activityItem: ActivityItem;

  constructor() { }

  ngOnInit() {
  }

  canDeactivate(): boolean {
    return true;
  }
}
