import { Component, OnInit, Input } from '@angular/core';
import { OnlineStatusService } from '../../online-status/online-status.service';
import { DashboardMenuService } from './dashboard-menu.service';
import { DashboardMenu } from './dashboard-menu';
import { ApplicationEventDispatcher } from '@app/core/application-event-dispatcher.service';
import { ApplicationEventType } from '@app/core/application-event-type';
import { ApplicationEvent } from '@app/core/application-event';

@Component({
    selector: 'ebs-dashboard-menu',
    templateUrl: './dashboard-menu.component.html',
    styleUrls: ['./dashboard-menu.component.scss']
})
export class DashboardMenuComponent implements OnInit {
    dashboardMenu: DashboardMenu;

    @Input() isWorkingOffline: boolean;
    @Input() useCompressedLayout: boolean;


    constructor(private dashboardMenuService: DashboardMenuService,
        private onlineStatusService: OnlineStatusService,
        private applicationEventDispatcher: ApplicationEventDispatcher) {
        applicationEventDispatcher.authorisationEvent.subscribe((status: ApplicationEvent) => {
            if (status.type === ApplicationEventType.Authorised) this.refreshMenu();
        });
    }

    ngOnInit() {
        this.refreshMenu();
    }

    private refreshMenu() {
        this.getMenu().subscribe((result) => {
            this.dashboardMenu = result.data;
            console.log(this.dashboardMenu);
        });
    }

    getMenu() {
        return this.dashboardMenuService.getDashboardMenu();
    }
}
