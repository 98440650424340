<ebs-offline-message [isOffline]='isOffline' [offlineMessage]='offlineMessage'></ebs-offline-message>
<div *ngIf="!isOffline">
    <aside id="sidebar-activity">
        <ebs-spinner [spinnerMessage]="'initialising sidebar...'" [faColour]="'#666'" [boxColour]="'#666'"
            [boxPadding]="'15px 25px'" [faFont]="'1.8rem'" *ngIf="gettingUserActivityLastViewed"></ebs-spinner>
        <div class="tab-content">
            <ebs-notification-multiple [notifications]="notifications"></ebs-notification-multiple>
            <div class="tab-pane active p-3 discussion-wrapper" id="activity" role="tabpanel"
                *ngIf="!gettingUserActivityLastViewed">


                <ebs-spinner [spinnerMessage]="'getting replies...'" [faColour]="'#666'" [boxColour]="'#666'"
                    [boxPadding]="'5px 15px'" [faFont]="'1.8rem'" *ngIf="loadingPostAndReplyData"></ebs-spinner>
                <div *ngIf="!loadingFacultyPostAndReplyData">
                    <ebs-group-activity [truncateContentLength]="truncateContentLength" [courseCode]="courseCode"
                        [postAndReplySearchResults]="postAndReplySearchResults" [loadingData]="loadingPostAndReplyData">
                    </ebs-group-activity>
                    <span *ngIf="postAndReplySearchResults.length==0">You have no replies.</span>
                    <a *ngIf="postAndReplySearchResults.length!=0"
                        [routerLink]="['/courses/'  + this.courseCode + '/activities']"
                        class="btn btn-sm btn-secondary btn-block mt-2">View more replies</a>
                </div>
                <hr>

                <ebs-spinner [spinnerMessage]="'getting faculty replies...'" [faColour]="'#666'" [boxColour]="'#666'"
                    [boxPadding]="'5px 15px'" [faFont]="'1.8rem'" *ngIf="loadingFacultyPostAndReplyData"></ebs-spinner>
                <div *ngIf="!loadingFacultyPostAndReplyData">
                    <ebs-faculty-activity [truncateContentLength]="truncateContentLength" [courseCode]="courseCode"
                        [postAndReplySearchResults]="facultyPostAndReplySearchResults"
                        [loadingData]="loadingFacultyPostAndReplyData"></ebs-faculty-activity>
                    <span *ngIf="facultyPostAndReplySearchResults.length==0">You have no faculty replies.</span>
                    <a *ngIf="facultyPostAndReplySearchResults.length!=0"
                        [routerLink]="['/courses/'+ this.courseCode + '/activities/faculty']"
                        class="btn btn-sm btn-secondary btn-block mt-2">View more faculty replies</a>
                    <hr>
                </div>
            </div>
        </div>
    </aside>
</div>