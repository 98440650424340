export class QuizStatisticsQuiz{
    public quizId:number;
    public score:number;
    public availableMarks: number;
    public isAttempted: boolean;
    public isNoQuizInModule: boolean;


    public get scoreAsDecimal():number{
        let scoreAsDecimal = 0;
        scoreAsDecimal = this.score/this.availableMarks;
        return scoreAsDecimal; 
    }
}