import { Injectable, EventEmitter } from "@angular/core";
import { ApplicationEvent, TypedApplicationEvent } from "./application-event";
import { ApplicationEventType } from "./application-event-type";
import { PartialDownload } from "@app/downloads/models/partial-download";
import { DownloadRequestInfo } from "@app/downloads/models/download-request-Info";

@Injectable({
  providedIn: 'root',
})
export class ApplicationEventDispatcher {
  public httpResponseEvent: EventEmitter<ApplicationEvent>;
  public downloadEvent: EventEmitter<ApplicationEvent>;
  public offlineModeRelatedEvent: EventEmitter<ApplicationEvent>;
  public authorisationEvent: EventEmitter<ApplicationEvent>;

  constructor() {
    this.httpResponseEvent = new EventEmitter<ApplicationEvent>();
    this.offlineModeRelatedEvent = new EventEmitter<ApplicationEvent>();
    this.authorisationEvent = new EventEmitter<ApplicationEvent>();
    this.downloadEvent = new EventEmitter<ApplicationEvent>();
  }

  public processDownloadRequest(downloadRequestInitiated:boolean, downloadRequestEnded:boolean){
    var event = new TypedApplicationEvent<DownloadRequestInfo>();
        event.message = 'Sorry - we could not download your course materials';
        event.type = ApplicationEventType.DownloadRequest;
                      
        event.data = new DownloadRequestInfo();
        event.data.isFinalised = downloadRequestEnded;
        event.data.isInitiated = downloadRequestInitiated && !downloadRequestEnded;

        //this.downloadEvent.emit(event);
  }

  public processDownloadHttpRequest(partialDownloadInfo: PartialDownload, applicationEventType:ApplicationEventType):void {
    var event = new TypedApplicationEvent<PartialDownload>();
        event.message = 'Sorry - we could not download your course materials';
        event.type = applicationEventType;                      
        event.data = partialDownloadInfo;

        this.downloadEvent.emit(event);
  }

  public processHttpResponseStatus(statusCode: number): void {
    switch (statusCode) {
      case 503: {
        var event = new ApplicationEvent();
        event.message = 'The server is currently down. Please try again later';
        event.type = ApplicationEventType.ServerUnavailable;
        this.httpResponseEvent.emit(event);
        break;
      }
      case 504: {
        var event = new ApplicationEvent();
        event.message = 'It looks like there is no internet connection available. Please try again later';
        event.type = ApplicationEventType.UserConnectivityIssue;        
        this.httpResponseEvent.emit(event);
        break;
      }
      case 505:
      case 502:
      case 501:
      case 500: {
        var event = new ApplicationEvent();
        event.message = 'We are experiencing some issues. Please try again later';
        event.type = ApplicationEventType.ErrorInResponse;
        this.httpResponseEvent.emit(event);
        break;
      }
      case 401:
      case 403:
      case 405: {
        var event = new ApplicationEvent();
        event.message = 'Unauthorised access. Please log in';
        event.type = ApplicationEventType.UnAuthorised;
        this.httpResponseEvent.emit(event);
        break;
      }
      case 200: {
        var event = new ApplicationEvent();
        event.message = 'All ok';
        event.type = ApplicationEventType.Ok;
        this.httpResponseEvent.emit(event);
      }
      default: {
        break;
      }
    }
  }

  public processOfflineModeRelatedEvent(eventType: ApplicationEventType): void {
    switch (eventType) {
      case ApplicationEventType.ClearIndexedDb:
      case ApplicationEventType.IndexedDbCleared:
        {
          let event = new ApplicationEvent();
          event.type = eventType;
          this.offlineModeRelatedEvent.emit(event);
          break;
        }
      case ApplicationEventType.OfflinePopupClosed:
        {
          let event = new ApplicationEvent();
          event.type = eventType;
          event.message = 'Popup closed event';
          this.offlineModeRelatedEvent.emit(event);
          break;
        }
      default: {
        break;
      }
    }
  }
  public processAuthoriseModeRelatedEvent(eventType: ApplicationEventType): void {
    switch (eventType) {
      case ApplicationEventType.UnAuthorised:
      case ApplicationEventType.Authorised:
      {
          const event = new ApplicationEvent();
          event.type = eventType;
          this.authorisationEvent.emit(event);
          break;
      }
      default: {
        break;
      }
    }
  }
}