import { Component, OnInit } from "@angular/core";
import { transformButton } from "@app/common/animations/animations";
import { OperationResponse } from "@app/common/models/operation-response";
import { CourseraRegistration } from "@app/coursera-registration/models/coursera-registration";
import { CourseraRegistrationService } from "@app/coursera-registration/services/coursera-registration.service";
import { BsModalRef } from "ngx-bootstrap";

@Component({
    selector: 'ebs-coursera-registration-modal',
    templateUrl: './coursera-registration-modal.component.html',
    styleUrls: ['./coursera-registration-modal.component.scss'],
    animations: [transformButton]
})
export class CourseraRegistrationModalComponent implements OnInit {
    hasErrors: boolean;
    hasAcknowledgedCourseraRegistrationTerms = false;

    answer: CourseraRegistration = null;

    constructor(
        public bsModalRef: BsModalRef,
        private courseraRegistrationService: CourseraRegistrationService
    ) {
    }

    ngOnInit() {
        this.hasErrors = false;
    }

    closePopup(answer: CourseraRegistration = null){
        this.answer = answer;
        this.bsModalRef.hide();
    }

    register() {
        this.courseraRegistrationService.setCourseraRegistration().subscribe((response: OperationResponse<CourseraRegistration>) => {
            if(response != null && response.operationSucceeded) {
                this.closePopup(response.data);
                console.log('Now registered for Coursera so invoke LTI integration here');
            } else {
                this.hasErrors = true;
                console.log('Something went wrong whilst registering the student for coursera');
                console.log(response?.notifications);
            }
        }, err => {
            this.hasErrors = true;
            console.log(err);
            console.log('Something went wrong whilst registering the student for coursera')
        })
    }
}
