import { Component, OnInit, Input } from '@angular/core';
import { timeagoTransform } from '@app/common/timeago-transform';

@Component({
  selector: 'ebs-user-profile-flat',
  templateUrl: './user-profile-flat.component.html',
  styleUrls: ['./user-profile-flat.component.scss']
})
export class UserProfileFlatComponent implements OnInit {
  @Input() userAvatar: string;
  @Input() userCountryFlag: string;
  @Input() displayName: string;
  @Input() displayDate: string;
  @Input() isFaculty: boolean;
  @Input() nonBoldText: string;
  @Input() boldText: string;

  constructor() { }

  ngOnInit() {
    if (this.userCountryFlag) {
      this.userCountryFlag = '../../../../assets/img/flags/' + this.userCountryFlag.toLowerCase() + '.png'
    } else {
      this.userCountryFlag = null;
    }

    if (!this.displayName) {
      this.displayName = 'name unavailable';
    }

    this.displayDate = timeagoTransform(this.displayDate);
  }
}