import { Module } from './module';
import { LearningObject } from '@app/common/models/learning-object';
import { CourseLink } from '@app/courses/models/course-link';

export class CourseStructure extends LearningObject {
    name: string;
    courseCode: string;
    sectionsComplete: number;
    sectionsInTotal: number;
    
    constructor(public id: string, public links: CourseLink[],  public modules: Array<Module>) {
        super();
    }
}
