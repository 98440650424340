import { Component, Input, OnInit, EventEmitter, Output } from '@angular/core';
// import { UnityLoader } from 'src/assets/unity/UnityLoader.js';
// import { UnityProgress } from 'src/assets/unity/UnityProgress.js';
import { GameState } from '@app/courses/models/games/game-state';
import { GameActivityService } from '@app/courses/services/game-activity.service';
import { OperationResponse } from '@app/common/models/operation-response';
import { NotificationSummary } from '@app/common/models/notification-summary';
import { SeverityLevel } from '@app/common/models/severity-level';
// import { UnityLoaderService } from '@app/courses/services/unity-loader.service.js';
import { ActivatedRoute } from '@angular/router';

declare var window: any;

@Component({
  selector: 'ebs-unity-web-gl',
  templateUrl: './unity-web-gl.component.html',
  styleUrls: ['./unity-web-gl.component.css']
})
export class UnityWebGLComponent implements OnInit {
  unityInstance: any;
  @Input() isHidden: boolean;// = true;
  @Input() appLocation: String;
  @Input() appWidth: String;
  @Input() appHeight: String;
  @Output() unityCheckoutEvent: EventEmitter<string> = new EventEmitter<string>();
  @Output() unityStartedEvent: EventEmitter<string> = new EventEmitter<string>();
  public showAlert: boolean;
  private courseCode: string;
  notifications: Array<NotificationSummary> = new Array<NotificationSummary>();

  constructor(private gameActivityService: GameActivityService, private route: ActivatedRoute, 
    //private unityLoaderService: UnityLoaderService
    ) { }

  ngOnInit() {
    this.showAlert = false;
    // window['UnityLoader'] = UnityLoader;
    // window['UnityProgress'] = UnityProgress;
    window['receiveMessageFromUnity'] = this.receiveMessageFromUnity;
    this.route.paramMap.subscribe(params => {
      this.courseCode = params.get('courseCode');
    });
  }

  public setFullScreen(mode: number) {
    if (mode != 0 && mode != 1) return;
    this.unityInstance.SetFullscreen(mode);
  }

  public loadProject(path) {
    //this.unityInstance = this.unityLoaderService.Instantiate(path);
  }

  public sendMessageToUnity(objectName: string, methodName: string, messageValue: string) {
    this.isHidden = false;
    //console.log('sendMessageToUnity', objectName, methodName, messageValue);
    this.showAlert = false;
    this.unityInstance.SendMessage(objectName, methodName, messageValue);
  }

  public receiveMessageFromUnity = (id: string, messageValue: string) => {
    this.showAlert = true;
    if (id == "greeting") {
      alert("Unity says" + messageValue);
    }
    //console.log(id + ": " + messageValue);

    if (id == "setstate")
      this.saveState(messageValue);

    if (id == "requeststate")
      this.getState();

    if (id == "started") {
      this.unityStartedEvent.emit(id)
    }
    if (id == "checkout") {
      this.unityCheckoutEvent.emit(messageValue)
      // temp for supermarket game
      this.isHidden = true;
    }
    if (id == "fullscreen") {
      if (messageValue == "1" || messageValue == "0") {
        let mode = parseInt(messageValue);
        this.setFullScreen(mode);
      }
    }
  }

  public getState() {
    this.gameActivityService.getGameState(`courses/${this.courseCode}/1/0/gamestate`, false).subscribe(
      (gameActivityResponse: OperationResponse<GameState>) => {
        if (gameActivityResponse.operationSucceeded) {
          this.sendMessageToUnity('GameViewModel', 'SetState', gameActivityResponse.data.state);
        } else {
          this.notifications = gameActivityResponse.notifications;
        }
      }, error => {
        this.notifications.push(new NotificationSummary(error, 'unknown', SeverityLevel.Error, 'unknown', 0));
      }
    )
  }

  public saveState(gameState: string) {
    // just for starters
    let state = new GameState();
    state.courseCode = this.courseCode;
    state.gameId = "1";
    state.gameType = 0;
    state.state = gameState;

    this.gameActivityService.setGameState(`courses/${this.courseCode}/1/0/gamestate`, state, false).subscribe(
      (gameActivityResponse: OperationResponse<GameState>) => {
        if (gameActivityResponse.operationSucceeded) {

        } else {
          this.notifications = gameActivityResponse.notifications;
        }
      }, error => {
        this.notifications.push(new NotificationSummary(error, 'unknown', SeverityLevel.Error, 'unknown', 0));
      }
    );
  }

}
