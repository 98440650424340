<div class="search-wrapper card-body m-1 pb-0">
  <div class="row">
    <div class="col-12 col-xl-8">
      <div class="mt-2" style="border-radius:6px;">
        <div *ngIf="hideInput!=true" class="input-group pt-2 mb-3">
          <input autofocus type="text" [(ngModel)]="searchValue" class="form-control" (keyup.enter)="searchClicked()" 
            placeholder="Enter search text here" aria-label="Enter search text here" aria-describedby="input-search"
            maxlength="searchCharacterLimit" style="border-radius: 3px;">
          <div class="input-group-append ml-2" (click)="searchClicked()" style="cursor: pointer;">
            <button class=" btn btn-primary" id="input-search" 
              [disabled]="isDisabled()" style="border-radius: 3px;">
              Search</button>
          </div>
        </div>
        <p class="text-warning" *ngIf="isGreaterThanMax()">
          Search value must be {{searchCharacterLimit}} characters or less</p>
      </div>
    </div>
  </div>

  <div class="row" *ngIf="parentSearchHasComplete">
    <div class="col-12 col-xl-8">
      <div *ngIf="hideInput!=true" class="alert alert-discussion" role="alert">
         <span *ngIf="searchTotalCount > 0 && hideInput!=true">Showing results for "{{searchValueDisplay}}"</span>
        <span *ngIf="(searchTotalCount === 0)  && hideInput!=true">
          <i class="fa fa-lg fa-exclamation-circle fa-fw"></i>
          No results found for "{{searchValueDisplay}}"
        </span>
        
      </div>
    </div>
  </div>
</div>