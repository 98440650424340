import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { SectionProgress } from '../models/section-progress';
import { sectionProgressMockData } from '@app/common/mock/data/section-progress';
import { OperationResponse } from '@app/common/models/operation-response';
import { ServiceBase } from '@app/core/service-base';
import { SectionCompleteStatus } from '../models/section-complete-status';

@Injectable({
  providedIn: 'root'
})
export class SectionProgressService extends ServiceBase {
  completeStatusDto = new SectionCompleteStatus();

  markSectionAsComplete(sectionId: number, complete: boolean, url: string, courseCode: string): Observable<OperationResponse<SectionCompleteStatus>> {
    this.completeStatusDto = this.createSectionCompleteDto(sectionId, complete, url, courseCode)
    return this.setObjectInAvailableStores(this.completeStatusDto, url, false)
  }

  createSectionCompleteDto(sectionId: number, complete: boolean, url: string, courseCode: string){
    this.completeStatusDto.courseCode =  courseCode;
    this.completeStatusDto.isCompleted = complete
    this.completeStatusDto.sectionId = sectionId;
    this.completeStatusDto.uri = url;

    return this.completeStatusDto;
  }
}
