import { Injectable, Inject } from '@angular/core';
import { ServiceBase } from '@app/core/service-base';
import { Section } from '@app/courses/models/section';
import { ActivityItemType } from '@app/courses/models/activity-item-type';

import { TextActivityComponent } from '@app/courses/components/text-activity/text-activity.component';
import { VideoActivityComponent } from '@app/courses/components/video-activity/video-activity.component';
import { AudioActivityComponent } from '@app/courses/components/audio-activity/audio-activity.component';
import { QuizActivityComponent } from '@app/courses/components/quiz/quiz-activity/quiz-activity.component';
import { ThirdPartyActivityComponent } from '../components/third-party-activity/third-party-activity.component';
import { KortextActivityComponent } from '../components/kortext/kortext-activity/kortext-activity.component';
import { FileIFrameActivityComponent } from '../components/file-iframe-activity/file-iframe-activity.component';
import { GameActivityComponent } from '../components/game/game-activity/game-activity.component';
import { DiscussionManagerComponent } from '../components/discussion-course/components/discussion-manager/discussion-manager.component';

@Injectable({
  providedIn: 'root'
})
export class SectionService extends ServiceBase {
  private _courseCode: string;
  get courseCode(): string {
    return this._courseCode;
  }
  set courseCode(theCode: string) {
    this._courseCode = theCode;
  }

  private _moduleId: number;
  get moduleId(): number {
    return this._moduleId;
  }
  set moduleId(theCode: number) {
    this._moduleId = theCode;
  }

  private _sectionId: number;
  get sectionId(): number {
    return this._sectionId;
  }
  set sectionId(theCode: number) {
    this._sectionId = theCode;
  }

  public getSectionSkeleton(courseCode: string, sectionId: number, moduleId: number, isOffline: boolean) {
    if (courseCode === undefined || courseCode === null || courseCode === '') {
      throw new Error('Invalid courseCode');
    }

    if (sectionId === undefined || sectionId === null || sectionId === 0) {
      throw new Error('Invalid sectionCode');
    }

    if (moduleId === undefined || moduleId === null || moduleId === 0) {
      throw new Error('Invalid moduleCode');
    }

    const endpointUri = `courses/${courseCode}/modules/${moduleId}/sections/${sectionId}`;
    return this.getObject<Section>(endpointUri, isOffline);
  }

  public getActivityItemComponent(activityItemType: ActivityItemType) {

    if (activityItemType === undefined || activityItemType === null) {
      throw new Error('Invalid activityItemType');
    }

    switch (activityItemType) {
      case ActivityItemType.Text:
        return TextActivityComponent;
      case ActivityItemType.Video:
        return VideoActivityComponent;
      case ActivityItemType.Audio:
        return AudioActivityComponent;
      case ActivityItemType.Quiz:
      case ActivityItemType.Feedback:
        return QuizActivityComponent;
      case ActivityItemType.Discussion:
        return DiscussionManagerComponent;
      case ActivityItemType.ThirdParty:
        return ThirdPartyActivityComponent;
      case ActivityItemType.Kortext:
        return KortextActivityComponent;
      case ActivityItemType.FileIFrame:
        return FileIFrameActivityComponent;
      case ActivityItemType.Game:
        return GameActivityComponent;
    }

    return TextActivityComponent;
  }
}
