import { Component, OnInit, Input, ViewChild, ElementRef, OnDestroy } from '@angular/core';
import { EssayQuestion } from '@app/courses/models/quiz/essay-question';
import { EssayAnswer } from '@app/courses/models/quiz/essay-answer';
import { OnlineStatusService } from '@app/common/online-status/online-status.service';
import { OperationResponse } from '@app/common/models/operation-response';
import { QuizDetail } from '@app/courses/models/quiz/quiz-detail';
import { ActivatedRoute } from '@angular/router';
import { UserService } from '@app/common/security/user.service';
import { QuizActivityService } from '@app/courses/services/quiz-activity.service';
import { NotificationSummary } from '@app/common/models/notification-summary';
import { SeverityLevel } from '@app/common/models/severity-level';
import { CanComponentDeactivate } from '@app/common/security/can-deactivate.guard';
import { DialogService } from '@app/courses/services/dialog-service';
import { OnlineStatusType } from '@app/common/online-status/online-status-type.enum';
import { StaticText } from '@app/common/static-text';
import { UserProfile } from '@app/common/models/user';
import { flasher } from '../../../../common/animations/animations';
import { essayEditorConfig } from '../../discussion-course/models/editorConfig';

@Component({
  selector: 'ebs-essay-question',
  templateUrl: './essay-question.component.html',
  styleUrls: ['./essay-question.component.scss']
})
export class EssayQuestionComponent implements OnInit, CanComponentDeactivate, OnDestroy {
  @Input() question: EssayQuestion;
  @Input() showFeedback: boolean;
  @Input() activityItemId: number;
  @ViewChild('textareabox', { static: false }) elTextArea: any;
  editorConfig = essayEditorConfig;
  initialResponse: string;
  showAnswer = false;
  showCancel = false;
  callFailed = false;
  essaySaved = false;
  showError = false;
  isIEOrEdge: boolean;
  notifications: Array<NotificationSummary> = new Array<NotificationSummary>();
  @ViewChild('theessayform', { static: true }) theessayform: ElementRef;
  public isCorrect: boolean;
  loadingData = false;
  loadingFailed: boolean;
  private courseCode: string;
  private personId: string;
  failedMessage = 'The essay has failed to load. Please try again';
  dataGuardModel = ' '; //not actually used for anything other than determining whether 
  theSubscription: any;
  isOffline: boolean = false;
  isEditing: boolean = false;
  offlineMessage = StaticText.quizOfflineMessage;
  userProfile: UserProfile;

  constructor(
    private onlineStatusService: OnlineStatusService,
    private quizActivityService: QuizActivityService,
    private route: ActivatedRoute,
    private userService: UserService,
    private dialogService: DialogService) {

    this.theSubscription = this.onlineStatusService.status.subscribe(
      event => {
        this.isOffline = (event === OnlineStatusType.OFFLINE) ? true : false
        if (event === OnlineStatusType.OFFLINE) {
          this.isOffline = true;
        } else {
          this.isOffline = false;
          this.getEssayText();
        }
      }
    )
  }

  ngOnInit() {
    this.loadingData = true;
    this.loadingFailed = false;
    this.userService.getCurrentUser().subscribe((x: UserProfile) => {
      this.userProfile = x;
      this.personId = x.userAccount.username;
    }, error => {
      console.log(error);
      this.notifications.push(new NotificationSummary('Error returning progress percent', 'Http', SeverityLevel.Error));
    }, () => {
      if (this.userProfile != null) {
        this.route.paramMap.subscribe(params => {
          this.courseCode = params.get('courseCode');
        });
      }

      this.isIEOrEdge = this.onlineStatusService.isIEOrEdge();

      this.onlineStatusService.isOffline().subscribe(x => {
        this.isOffline = x;
        this.getEssayText();
      }, error => {
        this.isOffline = false;
        this.getEssayText();
      });
    });
  }

  editing() {
    this.isEditing = true;
  }

  canDeactivate(): boolean {
    if (this.theessayform) {
      console.log(this.initialResponse);
      console.log(this.dataGuardModel);
      if ((this.isEditing && this.initialResponse != this.dataGuardModel)
        && this.dataGuardModel != null && this.dataGuardModel != '') {
        let answer = this.dialogService.confirm('It looks like you may have an unsaved essay question. If you leave this section before saving your data it will be lost. \n\nAre you sure you want to leave this section?');
        return answer;
      } else {
        return true;
      }
    }
  }

  getEssayText() {
    //if this quiz is feedback get the selected option
    this.loadingData = true;
    this.callFailed = false;
    //G.M. is the call casuing prolems on offline?
    this.quizActivityService
      .getQuizDetail(`courses/${this.courseCode}/${this.personId}/${this.activityItemId}/${this.question.id}/2/quiz`, this.isOffline)
      .subscribe(
        (quizDetailResponse: OperationResponse<QuizDetail>) => {
          if (quizDetailResponse.operationSucceeded) {
            let essayQuestion = this.question as EssayQuestion
            essayQuestion.answer = new EssayAnswer(1);
            essayQuestion.answer.answerText = "";
            essayQuestion.answer.answerText = quizDetailResponse.data.essayText;
            if (this.question.answer != undefined && this.question.answer.answerText != undefined) {
              this.initialResponse = this.question.answer.answerText;
            } else {
              this.question.answer = new EssayAnswer(1);
            }
            this.loadingData = false;
            this.dataGuardModel = quizDetailResponse.data.essayText;
            //this.elTextArea.nativeElement.innerHTML = quizDetailResponse.data.essayText;
          } else {
            // do something with the notifications
            this.question.answer = new EssayAnswer(1);
            this.notifications = quizDetailResponse.notifications;
            this.loadingFailed = true;
            this.callFailed = true;
          }
        }, error => {
          this.notifications.push(new NotificationSummary(error, 'unknown', SeverityLevel.Error, 'unknown', 0));
          this.callFailed = true;
          this.loadingData = false;
        }, () => {
        }
      );
  }

  saveAnswer() {
    if (this.dataGuardModel) {
      this.essaySaved = false;
      this.showAnswer = true;
      this.showCancel = false;
      this.dataGuardModel = this.dataGuardModel.replace(/&nbsp;/g, '').replace(/(<p><\/p>)+/g, '').replace(/(\r\n|\n|\r)/gm, "").trim();
      this.initialResponse = this.dataGuardModel;
      this.saveEssayQuiz(this.dataGuardModel);
    }
  }

  saveEssayQuiz(essay: string) {
    this.callFailed = false;
    let quizDetail = new QuizDetail();
    quizDetail.courseCode = this.courseCode;
    quizDetail.personId = this.personId;
    quizDetail.quizId = this.activityItemId;
    quizDetail.score = 0;
    quizDetail.essayText = essay;
    quizDetail.quizType = 2;

    //M.S. - for D.C. - the uri is not very clear
    this.quizActivityService
      .setQuizDetail(`courses/${this.courseCode}/${this.personId}/${this.activityItemId}/${this.question.id}/quiz`,
        quizDetail, this.isOffline)
      .subscribe(
        (quizDetailResponse: OperationResponse<QuizDetail>) => {
          if (quizDetailResponse.operationSucceeded) {
            this.essaySaved = true;
            //reset from dirty
            this.theessayform.nativeElement.className = 'ng-untouched ng-pristine ng-valid'
          } else {
            this.notifications = quizDetailResponse.notifications;
            this.essaySaved = false;
            this.callFailed = true;
          }
        }, error => {
          this.notifications.push(new NotificationSummary(error, 'unknown', SeverityLevel.Error, 'unknown', 0));
          this.essaySaved = true;
          this.callFailed = true;
        }, () => {
        }
      );
  }

  revertAnswer() {
    this.question.answer.answerText = this.initialResponse;
    this.dataGuardModel = this.initialResponse;
    this.showCancel = false;
    this.isEditing = false;
  }

  // if we don't add any text but make it bold then we have changes so we have to interogate the innerhtml
  onKey() {
    this.essaySaved = false;
    if (this.dataGuardModel != this.initialResponse) {
      this.showCancel = true;
    }
  }

  onFocus() {
    if (this.dataGuardModel != this.initialResponse) {
      this.showCancel = true;
    }
  }

  ngOnDestroy(): void {
    this.theSubscription.unsubscribe();
  }

}
