import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { PagedRequest } from '@app/common/models/paged-request';
import { ForumListItem } from '../../models/forum';
import { Tag } from '../../models/tag';
import { ForumService } from '../../services/forum.service';
import { Router } from '@angular/router';
import { PagedResult } from '@app/common/models/paged-result';
import { ForumStateService } from '../../services/forum-state.service';

@Component({
  selector: 'ebs-forum-filter',
  templateUrl: './forum-filter.component.html',
  styleUrls: ['./forum-filter.component.scss']
})
export class ForumFilterComponent implements OnInit {
  @Input() courseCode: string;
  pagedRequest: PagedRequest<ForumListItem> = new PagedRequest<ForumListItem>();
  forums: ForumListItem[] = [];
  activeTagList: Tag[] = [];
  totalItems = 0;
  loading = false; loadingMore = false; reFilter = false;
  favouritesCount = 0;
  forumLink: string;
  errorMessage: string; showError = false;
  loadedFromState = false;

  constructor(
    private forumService: ForumService,
    private router: Router,
    private forumStateService: ForumStateService,
  ) { }

  ngOnInit() {
    if (this.forumStateService.currentState && this.forumStateService.filterState.clickedItemIndex!=undefined) {
      this.successfulLoad(this.forumStateService.filterState.pagedResults)
      this.loadedFromState = true;
      //scroll to the post we came from
      if (this.forumStateService.filterState.clickedItemIndex && this.forumStateService.filterState.clickedItemIndex > 0) {
        let root = this;
        setTimeout(() => {
          document.querySelector('#index' + root.forumStateService.filterState.clickedItemIndex).scrollIntoView({ behavior: 'smooth', block: "start", inline: "nearest" });
          root.forumStateService.filterState.clickedItemIndex = 0; 
        }, 1000);
      }
    } else {
      this.forumStateService.currentState = true;
      this.forumStateService.dontEmitTags = true;
      this.pagedRequest.currentPage = 1;
      this.getDiscussionList();
    }
    this.forumStateService.currentlySearching = false;
    this.forumLink = this.router.url.substring(0, this.router.url.lastIndexOf('/') + 1) + '-6';
    //  this.tagsChild.currentlyUsingState = false;
  }

  getLink() {
    return this.forumLink;
  }

  getQueryParams(id) {
    let queryParams = { discussionId: id };
    return queryParams;
  }

  workOutFavouritesCount() {
    this.favouritesCount = 0;
    this.favouritesCount = this.forums.filter(x => x.isFacultyFavourite).length
  }

  getDiscussionList() {
    this.loading = true;
    this.pagedRequest.itemsPerPage = 10;

    if (this.activeTagList.length > 0) {
      this.pagedRequest.searchCriteria = Array.from(this.activeTagList, x => x.name).join();
    } else {
      this.pagedRequest.searchCriteria = '';
    }

    this.forumService.getDiscussions(this.courseCode, this.pagedRequest)
      .subscribe(
        filterResponse => {
          if (filterResponse.operationSucceeded) {
            this.successfulLoad(filterResponse.data);
            this.loadedFromState = false;
            this.forumStateService.filterState.pagedRequest = this.pagedRequest;
            this.forumStateService.filterState.pagedResults = filterResponse.data
            this.forumStateService.filterState.pagedResults.items = this.forums // store all page items
          } else {
            this.showError = true;
            this.errorMessage = 'Error getting forum topics';
          }
          this.reFilter = false;
          this.loading = false;
          this.loadingMore = false;
        },
        error => {
          this.showError = true;
          this.errorMessage = 'Error getting forum topics';
          this.loading = false;
          this.loadingMore = false;
        }
      );
  }

  successfulLoad(responseData: PagedResult<ForumListItem>) {
    if (this.reFilter) {
      this.forums = responseData.items;
    } else {
      this.forums = [...this.forums, ...responseData.items];
    }
    this.totalItems = responseData.totalItems;
    this.workOutFavouritesCount()
  }

  updateTagsToSave(tags) {
    this.activeTagList = tags;
    this.forumStateService.filterState.currentTags = tags;
    this.pagedRequest.currentPage = 1;
    this.reFilter = true;
    this.getDiscussionList();
  }

  postsLoadedLessThanTotal() {
    return this.forums.length < this.totalItems;
  }

  loadMorePosts() {
    this.loadingMore = true;
    if (this.loadedFromState) {
      this.pagedRequest = this.forumStateService.filterState.pagedRequest
      this.loadedFromState = false;
    }
    // if number of displayed items is 0  then essentially do a refresh
    if (this.forums.length > 0) {
      this.pagedRequest.lastItemId = this.forums[this.forums.length - 1].id;
      this.pagedRequest.currentPage += 1;
      this.getDiscussionList();
    } else {
      this.pagedRequest.lastItemId = null;
      this.pagedRequest.currentPage = 1;
      this.getDiscussionList();
    }
  }

  routeFromSearchResult(id, index) {
    // set index
    this.forumStateService.filterState.clickedItemIndex = index;
  }

}
