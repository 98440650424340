import { Component, OnInit, Input } from '@angular/core';
import { Download } from '@app/downloads/models/download';
import { NotificationSummary } from '@app/common/models/notification-summary';
import { SeverityLevel } from '@app/common/models/severity-level';
import { CourseStructure } from '@app/courses/models/course-structure';
import { CourseNavigationService } from '@app/courses/services/course-navigation.service';
import { Module } from '@app/courses/models/module';
import { OfflineModeStatusService } from '@app/core/offline-mode-status.service';

@Component({
  selector: 'ebs-downloads-current',
  templateUrl: './downloads-current.component.html',
  styleUrls: ['./downloads-current.component.scss']
})
export class DownloadsCurrentComponent implements OnInit {
  currentDownloads: Module[] = [];
  notifications: NotificationSummary[];
  gettingDownloads = false;
  downloadsError = false;
  returnLink: string;
  @Input() courseCode = '';

  constructor(
    private courseNavigationService: CourseNavigationService,
    private offlineModeStatusService: OfflineModeStatusService,
  ) { }

  ngOnInit() {
    this.offlineModeStatusService.isEnabled().subscribe(isEnabled => {
      if (isEnabled)
        this.getCurrentDownloads();
    }

    )
  }

  getCurrentDownloads() {
    this.gettingDownloads = true;
    // add delay as tries to load before course is entered in local db
    this.courseNavigationService.getLocalCourseStructure(this.courseCode)
      .subscribe(
        (courseStructure: CourseStructure) => {
          if (courseStructure) {
            courseStructure.modules.forEach(courseModule => {
              // don't think you can download videos without hte content?
              // if (courseModule && (courseModule.isContentDownloaded === true || courseModule.isVideoListDownloaded === true)) {
              if (courseModule && courseModule.isContentDownloaded === true)
                this.currentDownloads.push(courseModule);
            })
          }
          this.gettingDownloads = false;
          this.downloadsError = false;
        }, error => {
          console.log(error);
          this.notifications.push(new NotificationSummary('There has been an error getting the your current module downloads please try later', 'Current Module Downloads Error', SeverityLevel.Error))
          this.downloadsError = true;
          this.gettingDownloads = false;
        });
  }

  showDownloadedModules() {
    return !this.gettingDownloads && this.currentDownloads && this.currentDownloads.length > 0
  }

  showNoDownloadsMessage() {
    return !this.currentDownloads || this.currentDownloads.length === 0 || this.gettingDownloads
  }
}
