import { QuizStatisticsModule } from "./quiz-statistics-module";
import { StorableObject } from "@app/core/storable-object";

export class QuizStatistics extends StorableObject{
    public quizStatisticsModules: Array<QuizStatisticsModule> = new Array<QuizStatisticsModule>();
    public courseId: string;
    public personId: string;

    public get totalScore():number{
        let totalScore = 0;
        for (let quizModule of this.quizStatisticsModules){
            totalScore+=quizModule.totalScore;
        }
        return totalScore;
    }

    public get totalScoreAsDecimal():number{
        let totalScore = 0;
        for (let quizModule of this.quizStatisticsModules){
            totalScore+=quizModule.totalScoreAsDecimal;
        }
        return totalScore;
    }

    public get averageScore():number{
        let averageScore = 0;
        averageScore = Math.ceil((this.totalScoreAsDecimal/this.totalNumberOfquizAttempts) * 100);
        if (Number.isNaN(averageScore))averageScore=0;
        return averageScore; 
    }


    public get totalNumberOfquizAttempts(): number{
        let totalNumberOfQuizAttempts = 0;
        for(let quizModule of this.quizStatisticsModules){
            totalNumberOfQuizAttempts +=quizModule.totalNumberOfquizAttempts;
        }
        return totalNumberOfQuizAttempts;
    }

    public get totalAvaliableMarks():number{
        let totalAvaliableMarks = 0;
        for(let quizModule of this.quizStatisticsModules){
            totalAvaliableMarks+=quizModule.totalAvaliableMarks;
        }
        return totalAvaliableMarks;
    }


}