import { ModuleStructure } from "./module-structure";
import { ActivityItemType } from "@app/courses/models/activity-item-type";
import { DeletionResult } from "./deletion-result";

export class ModuleDeletiondMonitoring {
    sections: Array<DeletionResult> = new Array<DeletionResult>();
    activityItems: Array<DeletionResult> = new Array<DeletionResult>();
    files: Array<DeletionResult> = new Array<DeletionResult>();
    videos: Array<DeletionResult> = new Array<DeletionResult>();
    moduleId: number;
    courseCode: string;
    isBeingDeleted = false;

    getTotal(): number {
        return this.sections.length + this.files.length + this.videos.length + this.activityItems.length;
    }

    getContentTotal(): number {
        return this.sections.length + this.files.length + this.activityItems.length;
    }

    getVideoTotal(): number {
        return this.videos.length;
    }

    getDeletionProgress(): number {
        let deletionProgress: number = 0;
        this.isBeingDeleted = true;

        deletionProgress += this.sections.filter(x=>x.isDeleted === true).length;
        deletionProgress += this.activityItems.filter(x=>x.isDeleted === true).length;
        deletionProgress += this.files.filter(x=>x.isDeleted === true).length;
        deletionProgress += this.videos.filter(x=>x.isDeleted === true).length;

        return deletionProgress;
    }


    getContentDeletionProgress(): number {
        let deletionProgress: number = 0;
        this.isBeingDeleted = true;

        deletionProgress += this.sections.filter(x=>x.isDeleted === true).length;
        deletionProgress += this.activityItems.filter(x=>x.isDeleted === true).length;
        deletionProgress += this.files.filter(x=>x.isDeleted === true).length;

        return deletionProgress;
    }

    getDeletionProgressForVideos(): number {
        let deletionProgress: number = 0;
        this.isBeingDeleted = true;

        deletionProgress += this.videos.filter(x=>x.isDeleted === true).length;

        return deletionProgress;
    }

    getProcessingProgress(): number {
        let processingProgress: number = 0;

        processingProgress += this.sections.filter(x => x.hasBeenProcessed === true).length;
        processingProgress += this.activityItems.filter(x => x.hasBeenProcessed === true).length;
        processingProgress += this.files.filter(x => x.hasBeenProcessed === true).length;
        processingProgress += this.videos.filter(x => x.hasBeenProcessed === true).length;

        return processingProgress;
    }

    getProcessingProgressForVideos(): number {
        let processingProgress: number = 0;

        processingProgress += this.videos.filter(x => x.hasBeenProcessed === true).length;

        return processingProgress;
    }

    isCompletelyDeleted(): boolean {
        const isDeletionProcessCompleted = this.getContentTotal() === this.getContentDeletionProgress();

        if (isDeletionProcessCompleted)
            this.isBeingDeleted = false;

        return isDeletionProcessCompleted;
    }

    isVideoCompletelyDeleted(): boolean {
        const isDeletionProcessCompleted = this.getVideoTotal() === this.getDeletionProgressForVideos();

        if (isDeletionProcessCompleted)
            this.isBeingDeleted = false;

        return isDeletionProcessCompleted;
    }

    createFromModuleStructure(moduleStructure: ModuleStructure, includeVideos: boolean) {
        if (moduleStructure == null) return;

        this.moduleId = moduleStructure.moduleId;
        this.courseCode = moduleStructure.courseCode;

        this.sections = new Array<DeletionResult>();
        this.activityItems = new Array<DeletionResult>();
        this.files = new Array<DeletionResult>();
        this.videos = new Array<DeletionResult>();

        if (moduleStructure.files && moduleStructure.files.length > 0) {
            for (let file of moduleStructure.files) {
                this.files.push(new DeletionResult(file.uri, moduleStructure.moduleId));
            }
        }

        if (moduleStructure.sections && moduleStructure.sections.length > 0) {
            for (let section of moduleStructure.sections) {
                this.sections.push(new DeletionResult(section.uri, moduleStructure.moduleId, null, section.sectionId));
            }
        }

        if (moduleStructure.activityItems && moduleStructure.activityItems.length > 0) {
            for (let activityItem of moduleStructure.activityItems) {
                if (activityItem.type !== ActivityItemType.Discussion
                    && activityItem.type !== ActivityItemType.FileIFrame)
                    this.activityItems.push(new DeletionResult(activityItem.uri, moduleStructure.moduleId, activityItem.type, activityItem.sectionId));
            }
        }

        if (includeVideos === true && moduleStructure.videos && moduleStructure.videos.length > 0) {
            for (let video of moduleStructure.videos) {
                this.videos.push(new DeletionResult(video.uri, moduleStructure.moduleId));
            }
        }
    }
}