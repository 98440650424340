export class DiscussionEngagementStatistic {
    public personId: string;
    public number: number;
    public id: string;
    public moduleId: number;
    public discussionId: string;
    public postCount: number = 0;
    public replyCount: number = 0;
    public likeCount: number = 0;
    public includeInCalculation: boolean;
    public isNoDiscussionInModule:boolean;

    //this method was missing
    public getPoints(): number {
        var total = 0;
        // if (this.postCount >= 1) {
        //     total += 50;
        // }
        // if (this.replyCount == 1) {
        //     total += 20;
        // } else if (this.replyCount >= 2) {
        //     total += 40;
        // }
        // if (this.likeCount == 1) {
        //     total += 5;
        // } else if (this.likeCount >= 2) {
        //     total += 10;
        // }
        // return total;

        if (this.postCount >= 1) {
            total += 50;
        }
        if (this.replyCount >= 1) {
            total += 40;
        }
        if (this.likeCount >= 1) {
            total += 10;
        }
        return total;
    }
}