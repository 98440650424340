import { Component, OnInit, Input, HostListener } from '@angular/core';
import { TextActivity } from '@app/courses/models/text-activity';
import { TextActivityService } from '@app/courses/services/text-activity.service';
import { ServiceBase } from '@app/core/service-base';
import { OperationResponse } from '@app/common/models/operation-response';
import { LearningActivityItemComponentBase } from '@app/courses/models/dynamic-load/learning-activity-item-component-base';
import { map } from 'rxjs/operators';
import { DomSanitizer } from '@angular/platform-browser';
import { OnlineStatusService } from '@app/common/online-status/online-status.service';
import { Subscription } from 'rxjs';
import { OnlineStatusType } from '@app/common/online-status/online-status-type.enum';

@Component({
  selector: 'ebs-text-activity',
  templateUrl: './text-activity.component.html',
  styleUrls: ['./text-activity.component.scss'],
  providers: [ServiceBase]
})
export class TextActivityComponent implements OnInit, LearningActivityItemComponentBase<TextActivity> {
  @Input() activityItem: TextActivity;
  gettingText: boolean;
  isOffline: boolean;
  theSubscription: Subscription;
  isSuppressedInDemo = false;
  
  constructor(
    private textActivityService: TextActivityService,
    private sanitizer: DomSanitizer,
    private onlineStatusService: OnlineStatusService) { 
     
    this.theSubscription = this.onlineStatusService.status.subscribe(
      event => {
        this.isOffline = (event === OnlineStatusType.OFFLINE) ? true : false
      }
    )
    }

  ngOnInit() {
    this.onlineStatusService.isOffline().subscribe(x => {
        this.isOffline = x;
        this.getTextActivity();
    }, error=>{
        this.isOffline = false;
        this.getTextActivity();
    });
  }
  canDeactivate(): boolean {
    return true;
  }

  formatTranscriptText():string{
    return "<p></p>";
    //return "<p></p><div class='mb-5 border-bottom hidden-print'></div>";
  }

  getTextActivity() {
    this.gettingText = true;
    this.textActivityService.getTextActivity(this.activityItem.uri, this.isOffline )
      .pipe(map(
        res => {
          res.data.metadata = res.data.meta; //support change where api now returns meta instead of metadata
          // remove first h2 tag - there will only be 1 according to John
          res.data.body = res.data.body.replace(/<h2.*?<\/h2>/, '');
          //res.data.body = res.data.body.replace(/<strong.*?<\/strong>/, 'XXXXXXXXXXXX'); //M.S. added by graeme; this shows in the PDF!!!

          //check add image fluid so as the image doesn't oversize its parent
          res.data.body = res.data.body.replace(/<img /, '<img class="img-fluid" ');
          if(res.data.body.indexOf('[not-available-in-demo]') > -1) {
            this.isSuppressedInDemo = true;
            res.data.body = res.data.body.replace('\[not-available-in-demo\]','');
          }

          //insert blue demo text
          if(res.data.body.includes("[Demo Text]") && res.data.body.includes("[End Demo Text]")){
            res.data.body = res.data.body.replace('\[Demo Text\]',"<div class='card'><div class ='card-body featured'><div class='lead'>");
            res.data.body = res.data.body.replace('\[End Demo Text\]',"</div></div></div>");
          }

          // format differently for transcript text
          if(res.data.body.includes("View transcript")){
            res.data.body += this.formatTranscriptText();
          }
          return res;
        }
      ))
      .subscribe(
        (response) => {
          this.activityItem = response.data;
          this.gettingText = false;
        },
        error => {
          console.log(error);
          this.gettingText = false;
        }
      );
  }

}
