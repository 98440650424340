import { Injectable, Inject } from '@angular/core';
import { ServiceBase } from '@app/core/service-base';
import { Observable } from 'rxjs';
import { OperationResponse } from '../../models/operation-response';
import { DashboardMenu } from './dashboard-menu';


@Injectable({
  providedIn: 'root'
})
export class DashboardMenuService extends ServiceBase {
  private _dashboardMenuUri: string;

  get dashboardMenuUri(): string {
    this._dashboardMenuUri = 'personalisation/dashboardmenu';
    return this._dashboardMenuUri;
  }
  set dashboardMenuUri(theUri: string) {
    this._dashboardMenuUri = theUri;
  }
  public getDashboardMenu(): Observable<OperationResponse<DashboardMenu>> {
    return new Observable<OperationResponse<DashboardMenu>>(subscriber => {
      this.getObjectRemoteStoreFirst(this.dashboardMenuUri, subscriber);
    });
  }
}
