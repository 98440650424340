<h2>Coursera Career Academy</h2>
<div class="card-deck" [ngClass]="{ 'card-deck-compressed': useCompressedLayout }">
  <div class="card coursera-card">
    <div class="card-body">
      <form ngNoForm #CourseraForm target="_target" action="{{formActionUrl}}" method="POST">

        <input name="lis_person_name_full" type="hidden" [value]="name">
        <input name="lis_person_contact_email_primary" type="hidden" [value]="email">

        <input name="oauth_consumer_key" type="hidden" [value]="oauthConsumerKey">
        <input name="oauth_timestamp" type="hidden" [value]="oauthTimestamp">
        <input name="oauth_nonce" type="hidden" [value]="oauthNonce">
        <input name="oauth_signature_method" type="hidden" [value]="oauthSignatureMethod">
        <input name="oauth_signature" type="hidden" [value]="oauthSignature">

        <img src="./assets/img/coursera/coursera-logo.png" class="coursera-logo">
        <p>
          As part of your online studies with Edinburgh Business School, you have complimentary access to over 
          500 courses, projects and Professional Certificates via Coursera Career Academy. 
        </p>
        <p>
          These short micro-credentials are designed by top employers such as Google, Meta, IBM, 
          Microsoft and Salesforce and they complement your studies by providing you with the opportunity 
          to develop industry-aligned skills for a variety of careers. 
        </p>
        <div class="btn-block" *ngIf="!isOffline; else coursera_offline">
          <span class="btn btn-primary btn-min-width disabled" [ngClass]="{ 'disabled': checkingCourseraRegistraton }"
            role="button" aria-label="access coursera career academy" (click)="accessCoursera()">
            Access Coursera Career Academy
          </span>
        </div>
        <ng-template #coursera_offline>
          <ebs-message-simple *ngIf="isOffline" [displayMessage]="'Coursera Career Academy cannot be accessed when offline' "
            [textCssClasses]="'text-info'" [iconCssClasses]="'text-info fa fa-fw fa-exclamation-circle'">
          </ebs-message-simple>
        </ng-template>
      </form>

    </div>
  </div>
</div>