import { QuizStatisticsQuiz } from "./quiz-statistics-quiz";

export class QuizStatisticsModule {
    public statisticsQuizes: Array<QuizStatisticsQuiz> = new Array<QuizStatisticsQuiz>();
    public Number: number;

    public get totalScore(): number {
        let totalScore = 0;
        for (let quiz of this.statisticsQuizes) {
            if (!quiz.isNoQuizInModule) {
                totalScore += quiz.score;
            }
        }
        return totalScore;
    }

    public get averageScore(): number {
        let averageScore = 0;
        averageScore = Math.ceil((this.totalScoreAsDecimal / this.totalNumberOfquizAttempts) * 100);
        if (Number.isNaN(averageScore)) averageScore = 0;
        return averageScore;
    }

    public get totalScoreAsDecimal(): number {
        let totalScore = 0;
        for (let quiz of this.statisticsQuizes) {
            if (quiz.isAttempted && !quiz.isNoQuizInModule)
                totalScore += quiz.scoreAsDecimal;
        }
        return totalScore;
    }

    public get attemptedMessage(): string {
        if (this.totalNumbereOfNotAttempts > 1 && this.hasParticipated) {
            return "(" + this.totalNumbereOfNotAttempts.toString() + " tests not attempted)";
        }
        if (this.totalNumbereOfNotAttempts > 1 && !this.hasParticipated) {
            return this.totalNumbereOfNotAttempts.toString() + " tests not attempted";
        }
        if (this.totalNumbereOfNotAttempts === 1 && this.hasParticipated) {
            return "(" + this.totalNumbereOfNotAttempts.toString() + " test not attempted)";
        }
        if (this.totalNumbereOfNotAttempts === 1 && !this.hasParticipated) {
            return this.totalNumbereOfNotAttempts.toString() + " test not attempted";
        }
        return "";
    }

    public get barStyle(): string {
        return this.averageScore + "%";
    }

    public get totalNumbereOfNotAttempts(): number {
        let totalNumberOfNotAttempts = 0;
        for (let quiz of this.statisticsQuizes) {
            if (!quiz.isAttempted && !quiz.isNoQuizInModule) {
                totalNumberOfNotAttempts++;
            }
        }
        return totalNumberOfNotAttempts;
    }

    public get hasParticipated(): boolean {
        for (let quiz of this.statisticsQuizes) {
            if(quiz.isAttempted)return true;
        }
        return false;
    }

    public get totalNumberOfquizAttempts(): number {
        let totalNumberOfQuizAttempts = 0;
        for (let quiz of this.statisticsQuizes) {
            if (quiz.isAttempted && !quiz.isNoQuizInModule) {
                totalNumberOfQuizAttempts++;
            }
        }
        return totalNumberOfQuizAttempts;
    }

    public get totalAvaliableMarks(): number {
        let totalAvaliableMarks = 0;
        for (let quiz of this.statisticsQuizes) {
            if (quiz.isAttempted && !quiz.isNoQuizInModule)
                totalAvaliableMarks += quiz.availableMarks;
        }
        return totalAvaliableMarks;
    }
}