import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ModalSimpleComponent } from './modal-simple.component';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { ModalButton } from '@app/common/models/modal-button';

@Injectable({
  providedIn: 'root'
})
export class ModalSimpleService {
  bsModalRef: BsModalRef;

  constructor(
    private bsModalService: BsModalService,
  ) { }

  confirm(title: string, message: string, options: ModalButton[]): Observable<string> {
    const initialState = {
      title: title,
      message: message,
      options: options,
      answer: '',
    };
    this.bsModalRef = this.bsModalService.show(ModalSimpleComponent, { initialState, class: 'modal-dialog-centered ' });

    return new Observable<string>(this.getConfirmSubscriber());
  }

  private getConfirmSubscriber() {
    return (observer) => {
      const subscription = this.bsModalService.onHidden.subscribe((reason: string) => {
        observer.next(this.bsModalRef.content.answer);
        observer.complete();
      });

      return {
        unsubscribe() {
          subscription.unsubscribe();
        }
      };
    };
  }
}
