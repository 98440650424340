import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'ebs-progress-bar',
  templateUrl: './progress-bar.component.html',
  styleUrls: ['./progress-bar.component.scss']
})
export class ProgressBarComponent implements OnInit {
  @Input() progressMax = 100;
  @Input() progressAnimate = true;
  @Input() progressValue: number;
  @Input() progressType = 'success';
  @Input() showValueInBar = false;
  @Input() progressWrapperWidth = '90%';

  constructor() { }

  ngOnInit() {
  }

}
