<div class="card" *ngIf="!supportsServiceWorkers" [@enterOrLeave]="!supportsServiceWorkers" style="border-radius: 6px">
  <div class="card-header" style="border: none !important">
    <ebs-message-simple
      [displayMessage]="'Offline mode is not supported by your browser. Please upgrade to a modern browser.' "
      [textCssClasses]="'text-info'" [iconCssClasses]="'text-info fa fa-fw fa-exclamation-circle'">
    </ebs-message-simple>
  </div>
</div>

<div class="row" *ngIf="!hasUserAcceptedTerms && supportsServiceWorkers" [@enterOrLeave]="!hasUserAcceptedTerms">
  <div class="offset-xl-3 offset-lg-1 col-12 col-xl-6 col-lg-10 p-3">
    <ebs-message-simple
      [displayMessage]="'You must accept the terms & conditions and also enable offline mode before accessing the downloads page. Please refresh this screen to reload the pop up'"
      [textCssClasses]="'text-info'" [iconCssClasses]="'text-info fa fa-fw fa-exclamation-circle'"></ebs-message-simple>
  </div>
</div>

<div class="downloads-wrapper" *ngIf="hasUserAcceptedTerms && supportsServiceWorkers"
  [@enterOrLeave]="hasUserAcceptedTerms">
  <div class="mb-2">
    <a [routerLink]="[returnLink]" class="link mb-1"><i
        class="fas fa-chevron-left fa-1x fa-fw"></i>Return to course dashboard </a>
  </div>
  <h1> Manage Offline Modules</h1>

  <div class="card">
    <div class="card-body">
      <div class="alert alert-info beta-version" role="alert">
        <h4>
          <i class="fas fa-fw fa-info-circle fa-lg"></i> Beta Version
        </h4>
        <p>
          Our new offline mode is ready for a test drive, and we are keen to hear your feedback.
          Offline mode enables you to download modules directly to your browser, and run them even when you have no
          internet connection.
        </p>
        <p>Please let us know your thoughts, and feedback:  <a href="mailto:elearningsupport@ebs.hw.ac.uk">elearningsupport@ebs.hw.ac.uk</a></p>
      </div>

      <p class="mb-0">{{para2}} </p>
      <ul>
        <li class="p-2"> {{option1}} </li>
        <li class="p-2"> {{option2}} </li>
      </ul>

      <div class="alert alert-warning beta-version" role="warning" *ngIf="enableVideoDownload()">
      
        <h4>
          <i class="fas fa-fw fa-exclamation-circle fa-lg"></i> Video downloads
        </h4>
        <p>
          To download videos, you must have at least 10% of your storage memory free on your PC / Device or your browser will not download the videos. Your browser manages how much memory it uses and may restrict downloads of large files (and delete these downloads) if your PC / Device is running out of free space.
        </p>        
      </div>


      <div class="pl-2">
        <ebs-offline-message [isOffline]='isOffline' [offlineMessage]='offlineMessage'></ebs-offline-message>
      </div>
      <ebs-downloads-available [courseCode]="courseCode" *ngIf="!isOffline" [currentUser]="currentUser"></ebs-downloads-available>
    </div>
    <div class="row">
      <div class="col-12 offset-md-6 col-md-6" *ngIf="!isOffline">
        <div class="btn btn-light float-right mb-2" (click)="switchClick()">
          Disable offline mode <i class="fas fa-angle-double-right"></i>
        </div>
      </div>
    </div>
  </div>
</div>