import { Component, OnInit, Input } from "@angular/core";
import { PagedResult } from '../../../common/models/paged-result';
import { PostAndReplySearchResultDto } from '../../models/course-activity/post-and-reply-search-result-dto';

@Component({
    selector: 'ebs-faculty-activity',
    templateUrl: './faculty-activity.component.html',
    styleUrls: ['./faculty-activity.component.scss']
})

export class FacultyActivityComponent implements OnInit {
    isFaculty = true;
    
    @Input() courseCode: string;

    @Input() postAndReplySearchResults: PagedResult<PostAndReplySearchResultDto>;

    @Input() loadingData: boolean = true;

    @Input() isDiscussionContent : boolean = false;

    @Input() truncateContentLength: number = 200;

    ngOnInit(): void {
    }
}

