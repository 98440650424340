import { Component, Input, OnInit } from '@angular/core';
import { ConfigurationHelperService } from '@app/common/app-configuration/configuration-helper.service';
import { Attachment } from '../../models/attachment';

@Component({
  selector: 'ebs-attachments',
  templateUrl: './attachments.component.html',
  styleUrls: ['./attachments.component.scss']
})
export class AttachmentsComponent implements OnInit {

  @Input() attachments: Array<Attachment>;

  baseUrl: string;

  constructor(private configurationHelperService: ConfigurationHelperService) { }

  ngOnInit() {
    this.baseUrl = this.configurationHelperService.getApiServerUri();
  }


  hasAttachments():boolean{
    return this.attachments != null && this.attachments.length > 0;    
  }

  getDownloadUri(attachment:Attachment):string{
    let extension = this.getFileExtension(attachment.name);
    return this.baseUrl + `/fileupload/download/${attachment.uploadedFileId}.${extension}`;
  }

  getFileExtension(fileName:string):string{
    if (fileName == null) return 'pdf';

    let splitOfFileName = fileName.split('.');

    if (splitOfFileName.length < 2) return 'pdf';

    return splitOfFileName[1];
  }
}
