<ebs-message-simple [displayMessage]="errorMessage" *ngIf="showError"></ebs-message-simple>

<ebs-tags (tagsToSave)="updateTagsToSave($event)" [taglistHeader]="'Filter by tag:'" [courseCode]="courseCode"
  [canAddTag]="false"></ebs-tags>

<ebs-spinner [spinnerMessage]="'getting forum topics'" *ngIf="loading && !loadingMore"></ebs-spinner>

<ebs-message-simple [displayMessage]="'There are no forums for this course yet. Please add one using the button above.'"
  *ngIf="forums.length === 0 && !loading"></ebs-message-simple>


<div *ngFor="let forum of forums; index as i" class="pl-3 pr-3 pb-0 pt-0">
  <div *ngIf="!reFilter">
    <hr *ngIf="i === 0"> 
    <div id="index{{i}}" class="d-flex flex-row mt-2">
      <div class="click-link header-link">
        <a [routerLink]="[getLink()]" [queryParams]="getQueryParams(forum.discussionId)"
          (click)="routeFromSearchResult(forum.discussionId, i)">{{forum.title}} &nbsp;&nbsp;
          <span *ngIf="forum.isFacultyFavourite" class="fac-fav-header fac-box">
            <i class="far fa-star fac-fav-icon"></i>
            <span class="fac-fav-text"> FACULTY FAVOURITE</span>
          </span>
        </a>
      </div>
    </div>

    <div class="d-flex flex-row">
      <div>
        <div class="d-flex flex-column mt-2 forum-description">
          <span innerHTML="{{forum.description | texttruncate: 200}}"> </span>
        </div>
        <div>
          <div class="d-flex flex-column flex-wrap d-xl-flex flex-xl-row mt-3 mb-1 lh-2">
            <span>
              <span *ngIf="forum.unreadPosts + forum.unreadReplies > 0"
                class="fa fa-circle fa-sm pr-1 unread-indicator"></span>
              {{forum.unreadPosts + forum.unreadReplies}} unread |
              {{forum.totalPosts + forum.totalReplies}} comment<span
                *ngIf="forum.totalPosts + forum.totalReplies != 1">s</span>
            </span>
            <span class="d-none d-xl-block">&nbsp;|&nbsp; </span>
            <span>
              <ebs-user-profile-flat [userCountryFlag]="forum.lastPostPerson.countryOfResidence"
                [nonBoldText]="'Latest activity: '" [userAvatar]="forum.lastPostPerson.photo"
                [isFaculty]="forum.lastPostPerson.isFaculty" [displayName]="forum.lastPostPerson.name"
                [displayDate]="forum.lastPostDate" *ngIf="forum.lastPostPerson">
              </ebs-user-profile-flat>
              <ebs-user-profile-flat [userCountryFlag]="forum.createdByPerson.countryOfResidence"
                [nonBoldText]="'Created by: '" [userAvatar]="forum.createdByPerson.photo"
                [isFaculty]="forum.createdByPerson.isFaculty" [displayName]="forum.createdByPerson.name"
                [displayDate]="forum.dateAndTimeCreated" *ngIf="forum.createdByPerson && !forum.lastPostPerson">
              </ebs-user-profile-flat>
            </span>
          </div>
        </div>
      </div>
    </div>

    <div *ngIf="favouritesCount > 0 && i === favouritesCount - 1" style="margin-bottom: 20px;"></div>
    <hr>
  </div>
</div>

<ebs-spinner [spinnerMessage]="'getting more discussions'" *ngIf="loadingMore"></ebs-spinner>
<!-- Load More -->
<ng-container *ngIf="forums.length > 0 && !loadingMore && !loading">
  <div class="row mt-3" *ngIf="postsLoadedLessThanTotal()">
    <div class="col-12 col-sm-6 offset-sm-3 text-center">
      <span (click)="loadMorePosts()" class="btn btn-light btn-sm">
        <i class="fas fa-arrow-down"></i> Load more
      </span>
    </div>
  </div>
  <div class="row">
    <div class="col-12 col-sm-6 offset-sm-3 text-center loaded-style">
      Loaded {{forums.length}} of {{totalItems }}
    </div>
  </div>
</ng-container>