<div class="row">
<div class="col-12" style="background-color: white;">
    <div  *ngIf="displayAttachments() === true" style="overflow-x: visible">
        <div *ngFor="let uploader of uploaders" >
            <ng-container *ngIf="isNewUpload(uploader.key) === true; else uploadedile">
                <label *ngIf="isUploading == false && uploader.value.queue.length === 0" class="btn btn-sm btn-secondary" #newAttachmentLabel>
                    Add attachment<input class="btn btn-file" type="file" ng2FileSelect [uploader]="uploader.value" class="d-none" />
                </label>
                <span *ngIf="isUploading && uploader.value.queue.length > 0">Uploading...</span>  
                <div class="alert alert-error beta-version" role="error" *ngIf="displayFileIsTooLarge">
                    <p>
                        <i class="fas fa-fw fa-exclamation-circle fa-lg"></i> File is too large.
                    </p>        
                </div>
                                        
                <div *ngIf="isUploading">
                    <div *ngIf="uploader.value.queue.length > 0">
                        <div>
                            <div class="progress mb-3 mt-1">
                                <div class="progress-bar" role="progressbar" attr.aria-valuenow="{{uploader.value.progress}}" attr.aria-valuemin="0" attr.aria-valuemax="100" [ngStyle]="{ 'width': uploader.value.progress + '%' }"></div>
                            </div>
                        </div>
                    </div>
                </div>                        
            </ng-container>
            <ng-template #uploadedile>
                <p>{{uploader.key.name}}
                <span role="button" (click)="deleteAttachment(uploader.key.uploadedFileId)" class="pl-3">
                    <i class="fal fa-trash-alt fa-fw fa-lg link" title="delete attachment"></i>
                </span></p>
            </ng-template>
        </div>
    </div>
</div>
</div>


<div class="row" *ngIf="displayFileUploadFailed">  
    <div class="alert alert-error" role="error" >
        <p>
            <i class="fas fa-fw fa-exclamation-circle fa-lg"></i> {{displayErrorMessage}} {{failedUploadedFileName}}.
        </p>        
    </div>
</div>