import { LearningObject } from "@app/common/models/learning-object";

export class Video extends LearningObject {
    public modifiedOn : Date; 
    public quality : string;
    public type : string;
    public width : number;
    public height : number;
    public link : string;
    public fps : number;
    public size : number;
    public md5 : string; 
    public content : Array<number> = new Array<number>(); //M.S. - not sure about this?
}

export class StorableVideo extends LearningObject {
    public blob : Blob;
}