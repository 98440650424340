import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { ServiceBase } from "@app/core/service-base";
import { OperationResponse } from "@app/common/models/operation-response";
import { CourseDiscussionEngagementStatistics } from "@app/courses/models/discussion/course-discussion-engagement-statistics";

@Injectable({
    providedIn: 'root'
})
export class DiscussionEngagementStatisticsService extends ServiceBase {
    public getModuleDiscussionEngagementStatistics(itemUrl, treatAsOffline): Observable<OperationResponse<CourseDiscussionEngagementStatistics>> {
        var observable: Observable<OperationResponse<CourseDiscussionEngagementStatistics>>

        if (!treatAsOffline) {
            observable = new Observable<OperationResponse<CourseDiscussionEngagementStatistics>>(observer => {
                this.getObjectRemoteStoreFirst(itemUrl, observer);
            });
        } else {
            observable = new Observable<OperationResponse<CourseDiscussionEngagementStatistics>>(observer => {
                this.getObjectFromLocalStore(itemUrl, observer);
            })
        }

        return observable;
    }
}