import { BaseLtiForm } from '@app/common/lti/base-lti-form';

export class KortextLtiForm extends BaseLtiForm {
    constructor(public contextLabel: string,
        public contextTitle: string,
        public customId: string,
        public customPage: string,
        public lisPersonContactEmailPrimary: string,
        public lisPersonNameFull: string,
        public oauthConsumerKey: string,
        public oauthTimestamp: string) {
            super(oauthConsumerKey, oauthTimestamp)
        }
}

