import { DiscussionType } from "./discussion-type";
import { Tag } from "./tag";
import { StorableObject } from "@app/core/storable-object";

export class ForumAdd  extends StorableObject {
    id: string;
    discussionId: string;
    type: DiscussionType;
    title: string;
    description: string;
    tags: Tag[];
    isFacultyFavourite: boolean;
}