import { Injectable } from "@angular/core";
import { ObjectStoreBase } from "@app/core/object-store-base";
import { CourseModulePartials, ModulePartialInfo } from "../models/course-module-partials";
import { Observable } from "rxjs";
import { KeyValuePair } from "@app/common/models/key-value-pair";

@Injectable({
    providedIn: 'root'
  })
export class PartialDownloadsService{
    constructor(private objectStore: ObjectStoreBase){
        
    }

    public markCourseModuleAsPartiallyDownloaded(courseCode:string, moduleId:number, isVideoDownload:boolean): Observable<CourseModulePartials>{
        const uri = this.getUri(courseCode);

        let observable = new Observable<CourseModulePartials>(observer => {
            this.objectStore.get(uri).then(x =>{
                if (!x){
                    x = new CourseModulePartials();
                    x.uri = uri;
                    x.courseCode = courseCode;
                    (x as CourseModulePartials).partialModules = new Array<KeyValuePair<number, ModulePartialInfo>>();
                }
    
                this.addModule(moduleId, isVideoDownload, x as CourseModulePartials);
    
                this.objectStore.addOrUpdate(uri, x).then(x => {
                    observer.next(x as CourseModulePartials);
                    observer.complete();
                }).catch(err => {
                    observer.error(err);
                });
            }).catch(err => {
                observer.error(err);
            });
        });

       return observable;
    }

    public unmarkCourseModuleAsPartiallyDownloaded(courseCode:string, moduleId:number): Observable<CourseModulePartials>{
        const uri = this.getUri(courseCode);

        let observable = new Observable<CourseModulePartials>(observer =>{
            this.objectStore.get(uri).then(x =>{
                if (!x){
                    x = new CourseModulePartials();
                    x.uri = uri;
                    x.courseCode = courseCode;
                    (x as CourseModulePartials).partialModules = new Array<KeyValuePair<number,ModulePartialInfo>>();
                }

                this.removeModule(moduleId, x as CourseModulePartials);

                this.objectStore.addOrUpdate(uri, x).then(x => {
                    observer.next(x as CourseModulePartials);
                    observer.complete();
                }).catch(err => {
                    observer.error(err);
                });
            }).catch(err => {
                observer.error(err);
            });
        });

        return observable;
    }

    public resetTimeForCourseModuleAsPartiallyDownloaded(courseCode:string, moduleId:number): Observable<CourseModulePartials>{
        const uri = this.getUri(courseCode);

        let observable = new Observable<CourseModulePartials>(observer =>{
            this.objectStore.get(uri).then(x =>{
                if (!x){
                    x = new CourseModulePartials();
                    x.uri = uri;
                    x.courseCode = courseCode;
                    (x as CourseModulePartials).partialModules = new Array<KeyValuePair<number,ModulePartialInfo>>();
                }

                this.resetTimeForModule(moduleId, x as CourseModulePartials);

                this.objectStore.addOrUpdate(uri, x).then(x => {
                    observer.next(x as CourseModulePartials);
                    observer.complete();
                }).catch(err => {
                    observer.error(err);
                });
            }).catch(err => {
                observer.error(err);
            });
        });

        return observable;
    }

    public getPartiallyDownloadedModulesForCourse(courseCode:string): Observable<CourseModulePartials>{
        const uri = this.getUri(courseCode);
        let observable = new Observable<CourseModulePartials>(observer =>{
            this.objectStore.get(uri).then(x => {
                if (!x){
                    x = new CourseModulePartials();
                    x.uri = uri;
                    x.courseCode = courseCode;
                }

                observer.next(x as CourseModulePartials);
                    observer.complete();
            }).catch(err => {
                observer.error(err);
            });
        });

        return observable;
    }

    protected getUri(courseCode:string)
    {
        return `course/${courseCode}/partialdownloads`;
    }

    protected addModule(moduleId:number, isVideoDownload:boolean, partials: CourseModulePartials){
        if (partials == null || partials.partialModules == null) return;

        if (partials.partialModules.findIndex(x => x.key === moduleId) > -1) return;

        const moduleInfo = new KeyValuePair<number, ModulePartialInfo>();
        moduleInfo.key = moduleId;
        moduleInfo.value = new ModulePartialInfo();
        moduleInfo.value.date = new Date();
        moduleInfo.value.isVideoDownload = isVideoDownload;

        partials.partialModules.push(moduleInfo);        
    }

    protected removeModule(moduleId:number, partials: CourseModulePartials){
        if (partials == null || partials.partialModules == null) return;

        let index = partials.partialModules.findIndex(x => x.key === moduleId);

        if (index == -1) return;

        partials.partialModules.splice(index, 1);        
    }

    protected resetTimeForModule(moduleId:number, partials: CourseModulePartials){
        if (partials == null || partials.partialModules == null) return;

        let index = partials.partialModules.findIndex(x => x.key === moduleId);

        if (index == -1) return;

        partials.partialModules[index].value.date = new Date();        
    }
}