import { Component, OnInit } from '@angular/core';
import { LearningActivityItemComponentBase } from '@app/courses/models/dynamic-load/learning-activity-item-component-base';
import { GameActivity } from '@app/courses/models/games/game-activity';
import { GameActivityService } from '@app/courses/services/game-activity.service';
import { OnlineStatusService } from '@app/common/online-status/online-status.service';
import { OperationResponse } from '@app/common/models/operation-response';
import { GameActivityMockService } from '@app/common/mock/services/game-activity-mock.service';

@Component({
  selector: 'ebs-game-activity',
  templateUrl: './game-activity.component.html',
  styleUrls: ['./game-activity.component.css']
})
export class GameActivityComponent implements OnInit, LearningActivityItemComponentBase<GameActivity> {
  activityItem: GameActivity;
  canDeactivate(): boolean {
    return true;
  }
  gettingGame:boolean;

  constructor(
     private gameActivityService: GameActivityService, private gameMockActivityService: GameActivityMockService, private onlineStatusService: OnlineStatusService
  ){}


  ngOnInit(): void {
    this.getGameActivity();
  }
  

  getGameActivity(){
    this.gettingGame = true;
    // this.gameActivityService.getGameActivity(this.activityItem.uri, this.onlineStatusService.isOffline())
    this.gameActivityService.getGameActivity(this.activityItem.uri, false)
      .subscribe(
        (response: OperationResponse<GameActivity>) =>{
          this.activityItem = response.data;
          // dissable unity cacheing just now
          this.activityItem.gameConfiguration.cacheControl = '';
          this.gettingGame = false;
        },
        error => {
          console.log(error);
          this.gettingGame = false;
        }
      )
  }
  
  getMockGameActivity(){
    this.gettingGame = true;
    // this.gameActivityService.getGameActivity(this.activityItem.uri, this.onlineStatusService.isOffline())
      this.gameActivityService.getGameActivity(this.activityItem.uri, false)
      .subscribe(
        (response: OperationResponse<GameActivity>) =>{
          this.activityItem = response.data;
          this.gettingGame = false;
        },
        error => {
          console.log(error);
          this.gettingGame = false;
        }
      )
  }
}
