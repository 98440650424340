<!-- <div><i><small>This is from the multiple answer question component</small></i></div> -->
<div class="mb-4 quiz-multiple-choice-checkboxes" >
  <div *ngFor="let option of question.options">      
        <label class="custom-input">
          <span [innerHTML]="option.text"></span>
          <span *ngIf="showFeedback && option.isCorrect" class="icon-gecko-check text-success ml-1"></span>
          <span *ngIf="showFeedback && option.isSelected && !option.isCorrect" class="icon-gecko-delete text-warning ml-1"></span>
          <input (change)="checkboxChanged()" type="checkbox" checked="" [disabled]="showFeedback || isOffline" [(ngModel)]="option.isSelected" name="optcheck">
          <span class="checkmark"></span>
          <span class="text-info small" *ngIf="showFeedback && option.isSelected && option.isCorrect" [innerHTML]="option.commentsHtml"></span>
        </label>       
  </div>
 
  <div ebsTechnicalContent id="correctComments" class="text-info small" *ngIf="showFeedback && answerIsCorrect() && question.correctCommentsHtml!=null" [innerContent]="question.correctCommentsHtml"></div>
  <div ebsTechnicalContent id="incorrectComments" class="text-info small" *ngIf="showFeedback && !answerIsCorrect() && question.incorrectCommentsHtml!=null" [innerContent]="question.incorrectCommentsHtml"></div>
  <div ebsTechnicalContent id="neutralComments" class="text-info small" *ngIf="showFeedback && question.neutralCommentsHtml!=null" [innerContent]="question.neutralCommentsHtml"></div>
</div>
