<div class="text-activity-wrapper">
        <ebs-spinner [spinnerMessage]="'loading..'" [faColour]="'#999'" [boxColour]="'#999'" [faFont]="'2.2rem'" [boxFont]="'1.2rem'" 
        [boxPadding]="'5%'"  *ngIf="gettingText"></ebs-spinner>

    <div *ngIf="!gettingText">
        <ebs-meta-header [sectionHeader]="activityItem.sectionHeader" [linkId]="activityItem.identity" [activityType]="activityItem.metadata.activityType" [activityTitle]="activityItem.name" 
        [activitySize]="activityItem.metadata?.size" *ngIf="activityItem.metadata && activityItem.metadata.activityType"></ebs-meta-header>
        <ebs-meta-header [sectionHeader]="activityItem.sectionHeader" [linkId]="activityItem.identity" [activityType]="''" [activityTitle]="activityItem.name" 
        [activitySize]="activityItem.metadata?.size" *ngIf="activityItem.metadata && !activityItem.metadata.activityType"></ebs-meta-header>

       
        <!-- <div class="card-body" *ngIf="activityItem.body && !isSuppressedInDemo"> -->
        <div class="card-body" *ngIf="activityItem.body">
            <div ebsTechnicalContent [innerContent]="activityItem.body"></div>
        </div>
        <div class="card" *ngIf="activityItem.body && isSuppressedInDemo">
            <div class="card-body featured">
                <p class="lead">This section is not available in the demonstration course. Please take a look at the induction materials
                    (module 0) and the first two modules. We'd love to hear from you if you have any questions, so please <a
                        href="https://ebs.online.hw.ac.uk/contact-us" target="new">get in touch</a>. Or if you’re ready to sign up, <a
                        href="https://ebs.online.hw.ac.uk/apply" target="new">apply now</a>.</p>
                <p><a href="https://ebs.online.hw.ac.uk/contact-us" target="new" class="btn btn-md d-block d-md-inline-block  btn-light mt-3">Get in touch</a>
                    <span class="pr-2">&nbsp;</span>
                    <a href="https://ebs.online.hw.ac.uk/apply" target="new" class="btn btn-md d-block d-md-inline-block  btn-light mt-3">Apply now</a></p>
            </div>
        </div>
    </div>
</div>