<div class="login-wrapper main-content">
  <div class="scrollable full-height-minus-header">
    <div class="container">
    <div class="card-body col-sm-8 offset-sm-2 col-xl-6 offset-xl-3">
    <ebs-spinner class="p-3" [spinnerMessage]="'Loading..'" [faColour]="'#8031A7'" [boxColour]="'#8031A7'"
      *ngIf="!loginLoaded || loadingExternalDocument" [boxPadding]="'5px 15px'"></ebs-spinner>

    <div *ngIf="loginLoaded && !loadingExternalDocument">

      <h2 class="d-none d-xl-block not-modal">Welcome to the Edinburgh Business School Student Portal</h2>
      <h3 class="d-xl-none not-modal">Student Portal</h3>
      <p class="py-1 ebs-text d-none d-xl-block not-modal">
        For support and information regarding your study experience visit
        <a href='https://www.hw.ac.uk/ebs/'>www.hw.ac.uk/ebs</a></p>
      <p class="ebs-text d-xl-none not-modal">
        For support: <a href='https://www.hw.ac.uk/ebs/'>www.hw.ac.uk/ebs</a></p>

      <span *ngIf="isAModalLogin">
        <p><strong class="p-2">Your session has timed out. Please log in again.</strong> </p>
        <ul>
          <li>
            If you were saving data this will <strong>not</strong> have been submitted so please do so again.
          </li>
          <li>
            If you were moving between pages you may need to refresh.
          </li>
        </ul>
      </span>
      <form (submit)="login()">
        <div class="login-form">
          <div class="border-panel">
            <div class="main-div">
              <div class="panel">
                <h3>Log in</h3>
                <p>Please enter your username and password</p>
              </div>
              <div class="form-group">
                <input type="email" name="inputEmail" class="form-control" id="inputEmail" placeholder="Username" autocomplete="username"
                  [(ngModel)]="user.username" aria-label="username input field">
              </div>
              <div class="form-group">
                <input type="password" name="inputPassword" class="form-control" id="inputPassword" autocomplete="current-password"
                  placeholder="Password" [(ngModel)]="user.password" aria-label="password input field">
              </div>
              <ebs-notification-multiple [notifications]="notifications"></ebs-notification-multiple>
              <div class="row">
                <div class="col-12">
                  <div class="d-flex flex-row align-items-center">
                    <button id="loginButton" class="btn btn-primary" [disabled]="isAuthenticating" *ngIf="!isAuthenticating">Log in</button>
                    <ebs-spinner [spinnerMessage]="'authenticating..'" [faColour]="'#000'" [boxColour]="'#000'"
                      *ngIf="isAuthenticating" [boxPadding]="'0 0'"></ebs-spinner>
                  </div>
                </div>
              </div>

              <div class="forgot-link">
                <a [href]="forgottenPasswordLink">Forgotten your password?</a>
              </div>

              <div class="forgot-link">
                <a [href]="forgottenUsernameLink">Forgotten your username?</a>
              </div>
              <p class="pt-3"><small><i>For help, please contact the Student Engagement team at <a class="emailToLink" href="mailto:ebs.enquiries@hw.ac.uk">ebs.enquiries@hw.ac.uk</a>.</i></small></p>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>
</div>
</div>
