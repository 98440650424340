import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { FeedBackQuestion } from '@app/courses/models/quiz/feedback-question';
import { QuizActivityService } from '@app/courses/services/quiz-activity.service';
import { ActivatedRoute } from '@angular/router';
import { UserService } from '@app/common/security/user.service';
import { OperationResponse } from '@app/common/models/operation-response';
import { QuizDetail } from '@app/courses/models/quiz/quiz-detail';
import { NotificationSummary } from '@app/common/models/notification-summary';
import { SeverityLevel } from '@app/common/models/severity-level';
import { OnlineStatusService } from '@app/common/online-status/online-status.service';
import { OnlineStatusType } from '@app/common/online-status/online-status-type.enum';
import { Subscription } from 'rxjs';
import { StaticText } from '@app/common/static-text';
import { UserProfile } from '@app/common/models/user';

@Component({
  selector: 'ebs-feedback-question',
  templateUrl: './feedback-question.component.html',
  styleUrls: ['./feedback-question.component.scss']
})
export class FeedbackQuestionComponent implements OnInit, OnDestroy {
  @Input() question: FeedBackQuestion;
  @Input() activityItemId: number;
  public optionSelectedClasses: Array<string>;
  failedMessage = 'The selected option has failed to load. Please try again';
  isLoading = false;
  optionSaved = false;
  callFailed = false;
  private courseCode: string;
  private personId: string;
  notifications: Array<NotificationSummary> = new Array<NotificationSummary>();
  theSubscription: Subscription;
  isOffline: boolean;
  offlineMessage = StaticText.understandOfflineMessage;
  userProfile: UserProfile;

  constructor(
    private quizActivityService: QuizActivityService,
    private route: ActivatedRoute,
    private userService: UserService,
    private onlineStatusService: OnlineStatusService) {

    this.theSubscription = this.onlineStatusService.status.subscribe(
      event => {
        if (event === OnlineStatusType.OFFLINE) {
          this.isOffline = true;
        } else {
          this.isOffline = false;
          this.getFeedbackOption();
        }
      }
    )
  }

  ngOnInit() {
    this.isLoading = true;
    this.optionSelectedClasses = new Array<string>();

    this.userService.getCurrentUser().subscribe((x: UserProfile) => {
      this.userProfile = x;
      this.personId = x.userAccount.username;
    }, error => {
      console.log(error);
      this.notifications.push(new NotificationSummary('Error returning progress percent', 'Http', SeverityLevel.Error));
    }, () => {
      if (this.userProfile != null) {
        this.route.paramMap.subscribe(params => {
          this.courseCode = params.get('courseCode');
        });
      }

      for (let i = 0; i < this.question.options.length; i++) {
        this.optionSelectedClasses.push('btn btn-outline-primary btn-vote rounded-circle mt-2 collapsed');
      }

      this.onlineStatusService.isOffline().subscribe(x => {
        this.isOffline = x;
        this.getFeedbackOption();
      }, error => {
        this.isOffline = false;
        this.getFeedbackOption();
      });
    });


  }

  getFeedbackOption() {
    this.callFailed = false;
    //if this quiz is feedback get the selected option
    this.isLoading = true;
    this.quizActivityService
      .getQuizDetail(`courses/${this.courseCode}/${this.personId}/${this.activityItemId}/${this.question.id}/1/quiz`, this.isOffline)
      .subscribe(
        (quizDetailResponse: OperationResponse<QuizDetail>) => {
          if (quizDetailResponse.operationSucceeded) {
            (this.question as FeedBackQuestion).selectedOption = quizDetailResponse.data.feedbackOption;
            if (this.question.selectedOption != null && this.question.selectedOption != -1) {
              this.question.options[this.question.selectedOption].isSelected = true;
              this.optionSelectedClasses[this.question.selectedOption] = 'btn btn-outline-primary btn-vote rounded-circle mt-2';
            }
          } else {
            this.notifications = quizDetailResponse.notifications;
            this.callFailed = true;
          }
          this.isLoading = false;
        }, error => {
          this.notifications.push(new NotificationSummary(error, 'unknown', SeverityLevel.Error, 'unknown', 0));
          this.isLoading = false;
          this.callFailed = true;
        }, () => {
        }
      );
  }

  deselect() {
    for (let option of this.question.options) {
      option.isSelected = false;
    }
    for (let i = 0; i < this.optionSelectedClasses.length; i++) {
      this.optionSelectedClasses[i] = 'btn btn-outline-primary btn-vote rounded-circle mt-2 collapsed';
    }
  }

  onSelectionChanged(btn: number) {
    this.deselect();
    this.question.options[btn].isSelected = true;
    this.optionSelectedClasses[btn] = 'btn btn-outline-primary btn-vote rounded-circle mt-2';
    this.saveFeedbackQuiz(btn);
  }

  saveFeedbackQuiz(option: number) {
    this.notifications = new Array<NotificationSummary>();
    this.optionSaved = false;
    let quizDetail = new QuizDetail();
    quizDetail.courseCode = this.courseCode;
    quizDetail.personId = this.personId;
    quizDetail.quizId = this.activityItemId;
    quizDetail.score = option;
    quizDetail.feedbackOption = option;
    quizDetail.quizType = 1;

    //M.S. - for D.C. - the uri is not very clear
    this.quizActivityService
      .setQuizDetail(`courses/${this.courseCode}/${this.personId}/${this.activityItemId}/${this.question.id}/quiz`,
        quizDetail, this.isOffline)
      .subscribe(
        (quizDetailResponse: OperationResponse<QuizDetail>) => {
          if (quizDetailResponse.operationSucceeded) {
            this.optionSaved = true;
            this.isLoading = false;
          } else {
            // do something with the notifications
            // for (let i = 0; i < quizDetailResponse.notifications.length; i++) {
            //   this.failedMessage += ' | ' + quizDetailResponse.notifications[i].message;
            // }
            this.notifications = quizDetailResponse.notifications;
            this.callFailed = true;
          }

        }, error => {
          this.notifications.push(new NotificationSummary(error, 'unknown', SeverityLevel.Error, 'unknown', 0));
          this.callFailed = true;
        }, () => {
        }
      );

  }

  ngOnDestroy(): void {
    this.theSubscription.unsubscribe();
  }

}