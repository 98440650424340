import { Component, OnInit, Input, OnDestroy, EventEmitter, Output } from '@angular/core';
import { PagedRequest } from '@app/common/models/paged-request';
import { PagedResult } from '@app/common/models/paged-result';
import { OperationResponse } from '@app/common/models/operation-response';
import { NotificationSummary } from '@app/common/models/notification-summary';
import { UserService } from '@app/common/security/user.service';
import { UserProfile, UserRole } from '@app/common/models/user';
import { SeverityLevel } from '@app/common/models/severity-level';
import { DiscussionActivity } from '@app/courses/components/discussion-course/models/discussion-activity';
import { ActivityItemType } from '@app/courses/models/activity-item-type';
import { ForumService } from '../../services/forum.service';
import { ModalSimpleService } from '@app/common/components/modal-simple/modal-simple.service';
import { Router, ActivatedRoute, NavigationStart } from '@angular/router';
import { ForumViewed } from '../../models/forum-viewed';
import { OnlineStatusService } from '@app/common/online-status/online-status.service';
import { OnlineStatusType } from '@app/common/online-status/online-status-type.enum';
import { Subscription } from 'rxjs';
import { StaticText } from '@app/common/static-text';
import { NewForum } from '../../models/new-forum';
import { Title } from '@angular/platform-browser';
import { DiscussionPostOrReplyItem } from '@app/courses/components/discussion-course/models/discussion-postorreply-item';
import { ForumStateService } from '../../services/forum-state.service';

@Component({
  selector: 'ebs-forum',
  templateUrl: './forum.component.html',
  styleUrls: ['./forum.component.scss']
})
export class ForumComponent implements OnInit, OnDestroy {
  posts: DiscussionPostOrReplyItem[] = [];
  pagedRequest = new PagedRequest();
  pagedResult = new PagedResult();
  notifications: NotificationSummary[];
  currentUser: UserProfile;
  discussionId: string;
  discussionActivity: DiscussionActivity;
  forumDetails: NewForum = new NewForum();
  @Input() courseCode: string;
  showDeleteError = false; canDelete = false;
  tagList: string;
  returnLink: string;
  loadingForumDetails = false; loadingUserDetails = false;
  isOffline: boolean;
  theSubscription: Subscription;
  forumOfflineMessage = StaticText.forumOfflineMessage;
  noDiscussionIdMessage = '';
  idError = false;
  @Input() isInline = false;
  @Output() returnToSearchClicked = new EventEmitter<boolean>();
  routerEventSubscription: Subscription;

  constructor(
    private titleService: Title,
    private forumService: ForumService,
    private userService: UserService,
    private modalSimpleService: ModalSimpleService,
    private router: Router,
    private route: ActivatedRoute,
    private onlineStatusService: OnlineStatusService,
    private forumStateService: ForumStateService,
  ) {
    this.onlineStatusService.isOffline().subscribe(x => {
      this.isOffline = x;
    }, error => {
      this.isOffline = false;
    });
    this.theSubscription = this.onlineStatusService.status.subscribe(
      event => {
        if (event === OnlineStatusType.OFFLINE) {
          this.isOffline = true;
        }
        if (event === OnlineStatusType.ONLINE) {
          this.isOffline = false;
        }
      }
    );

    this.routerEventSubscription = this.router.events.subscribe((val) => {
      if (val instanceof NavigationStart) {
        if (val.url.indexOf('/-4') > -1 || val.url.indexOf('/-6') > -1) {
          if (this.forumStateService.currentState) this.forumStateService.dontEmitTags = true;
        } else {
          //remove from state
          this.forumStateService.resetState();
        }
      }
    });

  }

  ngOnInit() {
    this.loadingUserDetails = true;
    this.userService.getCurrentUser().subscribe(
      (userProfile: UserProfile) => {
        this.currentUser = userProfile;
        this.canDelete = this.checkIfCanDelete(userProfile.userAccount.roles);
        this.loadingUserDetails = false;
      },
      error => {
        this.loadingUserDetails = false;
        console.log(error);
        this.notifications.push(new NotificationSummary('Discussion: Error getting current user', 'Http', SeverityLevel.Error));
      },
      () => {
        // comes from url
        this.route.queryParams.subscribe(params => {
          if (params && params['discussionId']) {
            this.discussionId = params['discussionId'];
            this.discussionActivity = this.createForum(this.discussionId);
            this.updateDiscussionAsViewed(); // need an id to be able to update!
            this.getForumDetails();
          } else {
            // error as no discussion id
            this.idError = true;
          }
        });

      }
    );
    if(this.forumStateService.fromPost == true){
      this.returnLink = this.router.url.substring(0, this.router.url.lastIndexOf('/') + 1) + '-5';
      this.forumStateService.fromPost = false;
    }else{
      this.returnLink = this.router.url.substring(0, this.router.url.lastIndexOf('/') + 1) + '-4';
    }
    
  }

  ngOnDestroy(): void {
    if (this.theSubscription) {
      this.theSubscription.unsubscribe();
    }

    if (this.routerEventSubscription) {
      this.routerEventSubscription.unsubscribe();
    }
  }

  checkIfCanDelete(roles: UserRole[]) {
    const roleMatch = roles.find(role => (role === UserRole.Faculty) || (role === UserRole.PortalAdministrator));
    if (roleMatch) {
      return true;
    }

    return false;
  }

  updateDiscussionAsViewed() {
    this.forumService.updateDiscussionAsViewed(this.courseCode, this.discussionId).subscribe(
      (updateResponse: OperationResponse<ForumViewed>) => {
        console.log('succeeded updating the discussion as viewed');
      }
    );
  }

  getForumDetails() {
    this.loadingForumDetails = true;
    this.forumService.getDiscussion(this.courseCode, this.discussionId).subscribe(
      (response: OperationResponse<NewForum>) => {
        this.forumDetails = response.data;
        this.forumDetails.description = response.data.description;
        this.forumDetails.title = response.data.title;
        this.titleService.setTitle('Forum: ' + this.forumDetails.title.substring(0, 60));
        this.tagList = Array.from(response.data.tags, x => x.name).join(', ');
        this.loadingForumDetails = false;
      },
      error => {
        this.idError = true;
        if (error.notifications) {
          for (let i = 0; i < error.notifications.length; i++) {
            this.noDiscussionIdMessage += error.notifications[i].message;
            if (i + 1 < error.notifications.length) {
              this.noDiscussionIdMessage += ' | ';
            }
          }
        }
        this.loadingForumDetails = false;
        console.log(error);
      }
    );
  }

  getLink() {
    return this.router.url.substring(0, this.router.url.lastIndexOf('/') + 1) + '-7';
  }

  getQueryParams() {

    const queryParams = { discussionId: this.discussionId };
    return queryParams;
  }

  delete() {
    this.showDeleteError = false;
    this.modalSimpleService.confirm(
      'Delete Discussion',
      'You are about to delete discussion: ' + this.forumDetails.title + '. Are you sure?',
      [{ buttonText: 'Cancel', buttonStyle: 'btn btn-primary float-right btn-block' },
      { buttonText: 'Confirm', buttonStyle: 'btn btn-primary float-right btn-block mt-0 ml-2' }])
      .subscribe((answer) => {
        if (answer === 'Cancel') {

          // this.router.navigate([this.router.url]);
        }
        if (answer === 'Confirm') {
          this.forumService.deleteDiscussion(this.courseCode, this.discussionId)
            .subscribe(
              deleteResponse => {
                if (deleteResponse.operationSucceeded) {

                  // on delete go to to course list
                  if (this.router.url !== '/') {
                    const x = this.router.url.substring(0, this.router.url.lastIndexOf('/') + 1) + '-4';
                    this.router.navigate([x]);
                  }
                } else {
                  this.showDeleteError = true;

                  if (this.router.url !== '/') {
                    const x = this.router.url.substring(0, this.router.url.lastIndexOf('/') + 1) + '-6';
                    this.router.navigate([x]);
                  }
                }
              },
              deleteError => {
                this.showDeleteError = true;
                console.log(deleteError);
              }
            );
        }
      });
  }

  createForum(id) {

    return {
      id: 0,
      name: '',
      url: 'courses/' + this.courseCode + '/discussions/' + id,
      type: ActivityItemType.Discussion,
      availableUpdateVersion: null,
      courseCode: this.courseCode,
      isAwaitingSynchronization: false,
      moduleId: 0,
      sectionId: 0,
      uri: 'courses/' + this.courseCode + '/discussions/' + id,
      version: '',
      identity: '',
      metadata: null,
      number: 0,
      size: 0
    };
  }

  returnToSearch() {
    this.returnToSearchClicked.emit(true);
  }

}
