import { Component, OnInit, Input, EventEmitter, Output, OnDestroy } from '@angular/core';
import { MatchedPairQuestion } from '@app/courses/models/quiz/matched-pair-question';
import { ChoiceOption } from '@app/courses/models/quiz/choice-option';
import { OnlineStatusService } from '@app/common/online-status/online-status.service';
import { OnlineStatusType } from '@app/common/online-status/online-status-type.enum';
import { Subscribable, Subscription } from 'rxjs';
import { StaticText } from '@app/common/static-text';

@Component({
  selector: 'ebs-match-the-pairs-question',
  templateUrl: './match-the-pairs-question.component.html',
  styleUrls: ['./match-the-pairs-question.component.scss']
})
export class MatchThePairsQuestionComponent implements OnInit, OnDestroy {
  @Input() question: MatchedPairQuestion;
  @Input() showFeedback: boolean;
  @Output() notify: EventEmitter<boolean> = new EventEmitter<boolean>();
  public loadingData: boolean;
  theSubscription: Subscription;
  isOffline: boolean;
  offlineMessage = StaticText.quizOfflineMessage;

  constructor(
    private onlineStatusService: OnlineStatusService) {
      this.onlineStatusService.isOffline().subscribe(x => {
          this.isOffline = x;
      }, error=>{
          this.isOffline = false;
      });
    this.theSubscription = this.onlineStatusService.status.subscribe(
      event => {
        this.isOffline = (event === OnlineStatusType.OFFLINE) ? true : false
      }
    )
  }

  ngOnInit() {
    this.loadingData = true;

    // This randomises options order, but keeps the same option order for each subquestion 
    // create sorted array of option texts from first subquestion
    let sortedOptions = new Array<ChoiceOption>();

    if (this.question.subQuestions !== null) {
      sortedOptions = Object.assign(this.question.subQuestions[0].options);
      sortedOptions = this.shuffle(sortedOptions);

      // get each subquestion and create a new temporary array of options
      for (let subQuestion of this.question.subQuestions) {
        let tempOptions = new Array<ChoiceOption>();
        // copy each option into temp array sorted by optiontexts
        for (let i = 0; i < sortedOptions.length; i++) {
          tempOptions[i] = subQuestion.options.filter(x => x.text === sortedOptions[i].text)[0];
        }
        // copy back from temp to sub question options
        subQuestion.options = Object.assign(tempOptions);
      }
    }

    this.question.isComplete = true
    this.loadingData = false;
  }

  markIncomplete() {
    this.notify.emit(true);
  }

  answerIsCorrect() {
    let newQuestion = new MatchedPairQuestion('', '', null);
    newQuestion = Object.assign(newQuestion, this.question);
    return newQuestion.getMark() > 0;
  }

  shuffle(array: Array<ChoiceOption>) {
    var currentIndex = array.length, temporaryValue, randomIndex;

    // While there remain elements to shuffle...
    while (0 !== currentIndex) {

      // Pick a remaining element...
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex -= 1;

      // And swap it with the current element.
      temporaryValue = array[currentIndex];
      array[currentIndex] = array[randomIndex];
      array[randomIndex] = temporaryValue;
    }

    return array;
  }

  ngOnDestroy(): void {
    this.theSubscription.unsubscribe();
  }

}
