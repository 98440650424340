import { StorableObject } from "./storable-object";

export class OfflineModeStatus extends StorableObject{

    constructor(){
        super();
    }

    public isEnabled : boolean;

    public hasAcceptedTermsAndConditions : boolean;
  
}