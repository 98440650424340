import { Injectable, Inject } from '@angular/core';
import { User, UserProfile } from '@app/common/models/user';
import { Func, Delegate } from '@app/core/delegates';
import { OperationResponse } from '@app/common/models/operation-response';

import { AppConfigService } from '@app/common/app-configuration/app-config.service';
import { UserService } from './user.service';
import { StaticText } from '../static-text';
import { OnlineStatusService } from '../online-status/online-status.service';
import { OfflineModeStatusService } from '@app/core/offline-mode-status.service';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {
  [x: string]: any;

  readonly currentUserUri: string = StaticText.currentUserNoSlash;

  constructor(
    @Inject(UserService) private userService: UserService,
    private onlineStatusService: OnlineStatusService,
    private offlineModeStatusService: OfflineModeStatusService
  ) { }

  private getApiLayerPrefix(): string {
    return AppConfigService.settings.apiServer.prefix;
  }

  public logIn(user: User, onCompletion: Func<OperationResponse<UserProfile>, void>, onError: Delegate): void {
    user.uri = this.currentUserUri;
    user.isLoggedOut = false;

    const setCurrentUserObserver = {
      next: (x: OperationResponse<UserProfile>) => {
        onCompletion(x);
      },
      error: (err) => {
        if (onError) { onError(); }
        console.error(err);
      },
      complete: () => console.log('set user complete (login)')
    };

    this.offlineModeStatusService.isEnabled().subscribe(
      isEnabled => {
        this.userService
          .setUser(user, `${this.getApiLayerPrefix()}authenticate/login`, isEnabled)
          .subscribe(setCurrentUserObserver);
      });
  }

  public logOut(user: UserProfile, onCompletion: Func<UserProfile, void> = null, isAModalLogout: boolean = false): void {
    user.uri = this.currentUserUri;
    user.authenticationDetails.isLoggedOut = true;
    user.authenticationDetails.isAuthenticated = false;

    const setCurrentUserObserver = {
      next: (x: OperationResponse<UserProfile>) => {
        onCompletion(x.data);
      },
      error: err => console.error,
      complete: () => console.log('set complete')
    };

    // no point calling the server to logout if we are offline or are not currently authenticcated (ie timed out)
    this.onlineStatusService.isOffline().subscribe(x => {
      if (x === true) {
        this.userService.setUserProfileLocally(true, true, false);
        onCompletion(user);
      } else {
        // check if current user actually exists - cant exist if no offlinemode entry
        this.offlineModeStatusService.isEnabled().subscribe(
          offlineModeLocalObject => {
              this.userService
                .setUserProfile(user, `${this.getApiLayerPrefix()}authenticate/logout`, null, offlineModeLocalObject)
                .subscribe(setCurrentUserObserver);
          }
        );
      }
    }, error => {
      this.userService
        .setUserProfile(user, `${this.getApiLayerPrefix()}authenticate/logout`)
        .subscribe(setCurrentUserObserver);
    });
  }
}
