import { Component, OnInit, Input, OnDestroy, HostListener, Inject } from '@angular/core';
import { NotificationSummary } from '@app/common/models/notification-summary';
import { SeverityLevel } from '@app/common/models/severity-level';
import { DownloadsService } from '@app/downloads/services/downloads.service';
import { ModuleDownloadMonitoring } from '@app/downloads/models/module-download-monitoring';
import { Module } from '@app/courses/models/module';
import { ModuleDeletiondMonitoring } from '@app/downloads/models/module-deletion-monitoring';
import { map } from 'rxjs/operators';
import { flasher } from '@app/common/animations/animations';
import { throwError, Observable, Subscription, interval, concat } from 'rxjs';
import { Helpers } from '@app/common/helpers';
import { OnlineStatusService } from '@app/common/online-status/online-status.service';
import { ApplicationEventDispatcher } from '@app/core/application-event-dispatcher.service';
import { PartialDownloadsService } from '@app/downloads/services/partial-doanloads-tracking.service';
import { DeviceDetectorWrapperService } from '@app/common/device-detector/device-detector-wrappe.servicer';
import { PartialDownload } from '@app/downloads/models/partial-download';
import { ReturnStatement } from '@angular/compiler';
import { ApplicationEventType } from '@app/core/application-event-type';
import { TypedApplicationEvent } from '@app/core/application-event';
import { DownloadRequestInfo } from '@app/downloads/models/download-request-Info';
import { CourseModulePartials, ModulePartialInfo } from '@app/downloads/models/course-module-partials';
import { KeyValuePair } from '@app/common/models/key-value-pair';
import { ConfigurationHelperService } from '@app/common/app-configuration/configuration-helper.service';
import { UserProfile, UserRole } from '@app/common/models/user';

@Component({
  selector: 'ebs-downloads-available',
  templateUrl: './downloads-available.component.html',
  styleUrls: ['./downloads-available.component.scss'],
  animations: [flasher]
})
export class DownloadsAvailableComponent implements OnInit, OnDestroy {
  @Input() currentUser: UserProfile;
  
  canCheckStorage = false;
  avilableModuleDownloads: Module[] = [];
  notifications: NotificationSummary[] = [];
  gettingDownloads = false;
  downloadsError = false;
  message = '';
  @Input() courseCode: string;
  estimate = 0;
  usage = 0;
  removingModule = false;
  downloadingModule = false;
  flashing = 'invisible'
  flashingObs: Subscription;
  flashingDeleteObs: Subscription;
  isItIos = false;
  currentModuleBeingDownloaded: Module;
  courseModulePartials:CourseModulePartials = new CourseModulePartials();
  intervalDownloadFailureCheck;

  constructor(
    private downloadsService: DownloadsService,
    private onlineStatusService: OnlineStatusService, 
    private applicationEventDispatcher:ApplicationEventDispatcher,
    private partialDownloadsService:PartialDownloadsService,
    private configurationHelperService: ConfigurationHelperService,
    @Inject(DeviceDetectorWrapperService) private deviceDetectorWrapperService: DeviceDetectorWrapperService) {
  }

  ngOnInit() {
    //this.isItIos = this.onlineStatusService.isIos();
    let root = this;
    setTimeout(function () {
      root.getAvailableDownloads();
    }, 1000);    

    if ('storage' in navigator && 'estimate' in navigator.storage) {
      this.canCheckStorage = true;
      this.getStorageEstimate();
    } else {
      this.canCheckStorage = false;
    }

    this.cleanupPartialDownloads();

    this.applicationEventDispatcher.downloadEvent.subscribe(event =>{
      if (event.type === ApplicationEventType.ClearPartialModule){      
        const typedEvent : TypedApplicationEvent<PartialDownload> = event as TypedApplicationEvent<PartialDownload>;
        if ( typedEvent == null) return;
        this.cleanupPartialModule(typedEvent.data);
      }

      if (event.type === ApplicationEventType.ResetPartialModule){      
        const typedEvent : TypedApplicationEvent<PartialDownload> = event as TypedApplicationEvent<PartialDownload>;
        if ( typedEvent == null) return;
        this.resetPartialModule(typedEvent.data);
      }

      if(event.type === ApplicationEventType.DownloadRequest){
        const downloadRequestEvent = event as TypedApplicationEvent<DownloadRequestInfo>;

        let message = downloadRequestEvent.data.isInitiated ? 'download request in progress' : 'no download request in progress';
        console.log(message);
      }
    });

    this.intervalDownloadFailureCheck = setInterval(() => { this.checkDownloadInProgress(); }, 4000);     
  }

  enableVideoDownload():boolean{
    
      if (this.currentUser == null) return false;
  
      const canDownoadVid = this.currentUser.userAccount.roles
        .find(x =>  x === UserRole.PortalAdministrator);
  
      return canDownoadVid != null;
  }

  ngOnDestroy(): void {
    if (this.flashingObs)
      this.flashingObs.unsubscribe();

    if (this.flashingDeleteObs)
      this.flashingDeleteObs.unsubscribe();

    clearInterval(this.intervalDownloadFailureCheck);
  }

  getStorageEstimate() {
    const isiOS = this.deviceDetectorWrapperService.isiOS();

    if ( isiOS === true) return;

    navigator.storage.estimate().then(estimate => {
      this.estimate = (estimate.quota / 1048576);
      this.usage = (estimate.usage / 1048576);
      if (this.usage < 0.1)
        this.usage = 0.1

      if (this.estimate < 0.1)
        this.estimate = 0.1
    });
  }

  checkDownloadInProgress() {
    this.partialDownloadsService.getPartiallyDownloadedModulesForCourse(this.courseCode)
    .subscribe((retrievedCourseModulePartials:CourseModulePartials) => {
      if (retrievedCourseModulePartials == null || retrievedCourseModulePartials.partialModules == null ||
        retrievedCourseModulePartials.partialModules.length === 0){
          this.courseModulePartials = new CourseModulePartials();
          this.courseModulePartials.partialModules = new Array<KeyValuePair<number,ModulePartialInfo>>();  
        } else {
          this.courseModulePartials = retrievedCourseModulePartials;

          const currentDate = new Date();
          const minuteCheck = this.configurationHelperService.getDownloadTakesLongerThanExpectedPopupTimeMinutes();
          const minuteCheckInMilliseconds = minuteCheck * 60000;

          let moduleThatStartedDownloadSomeTimeAgo = this.courseModulePartials.partialModules
          .findIndex(x => currentDate.getTime() - x.value.date.getTime() > minuteCheckInMilliseconds);

          if (moduleThatStartedDownloadSomeTimeAgo > -1)
          {
            const partialModule = new PartialDownload();
            partialModule.courseCode = this.courseCode;
            partialModule.moduleId = this.courseModulePartials.partialModules[moduleThatStartedDownloadSomeTimeAgo].key;
            partialModule.isVideoDownload = this.courseModulePartials.partialModules[moduleThatStartedDownloadSomeTimeAgo].value.isVideoDownload;
            this.applicationEventDispatcher.processDownloadHttpRequest(partialModule, ApplicationEventType.SuspiciousModuleDownload);
          }
        }
    },
    err => {    
    }      
    );
  }

  checkIfModuleDownoadMayBeTakingTooLong(moduleId: number):boolean{
    if (this.courseModulePartials == null || this.courseModulePartials.partialModules == null ||
      this.courseModulePartials.partialModules.length === 0){
          return false; 
      }
    
      const currentDate = new Date();
      const minuteCheck = this.configurationHelperService.getDownloadTakesLongerThanExpectedWarningTimeMinutes();
      const minuteCheckInMilliseconds = minuteCheck * 60000;

      let moduleThatStartedDownloadSomeTimeAgo = this.courseModulePartials.partialModules
      .findIndex(x => currentDate.getTime() - x.value.date.getTime() > minuteCheckInMilliseconds);

      if (moduleThatStartedDownloadSomeTimeAgo == -1){
          return false;
      }

      return true;
  }

  getAvailableDownloads() {
    this.gettingDownloads = true;
    // need to get a list of modules and they're availability for this course from indexeddb
    this.downloadsService.getAvailableDownloads(this.courseCode)
      .pipe(map(
        res => {
          res.modules.forEach(
            (mod) => {
              mod.monitor = new ModuleDownloadMonitoring();
              mod.monitor.courseCode = this.courseCode;
              mod.monitor.moduleId = mod.id;

              mod.deleteMonitor = new ModuleDeletiondMonitoring();
              mod.deleteMonitor.courseCode = this.courseCode;
              mod.deleteMonitor.moduleId = mod.id;

              mod.totalContentSize = mod.standardContentSize + mod.videoContentSize;
              // mod.standardContentSize = mod.standardContentSize / 1e+9;
              mod.standardContentSize = mod.standardContentSize / 1048576;
              if (mod.standardContentSize < 0.01)
                mod.standardContentSize = 0.01

              // mod.totalContentSize = mod.totalContentSize / 1e+9;
              mod.totalContentSize = mod.totalContentSize / 1048576;
              if (mod.totalContentSize < 0.01)
                mod.totalContentSize = 0.01
            }
          );
          return res;
        }
      ))
      .subscribe(
        courseStructureResponse => {
          this.downloadsError = false;
          this.avilableModuleDownloads = courseStructureResponse.modules
          this.gettingDownloads = false;
        },
        error => {
          console.log(error);
          this.notifications.push(new NotificationSummary('There has been an error getting the your available module downloads. Please refresh or try later', 'Available Module Downloads Error', SeverityLevel.Error))
          this.downloadsError = true;
          this.gettingDownloads = false;
        }
      )
  }

  removeModule(courseModule: Module, andVideos: boolean) {
    this.removingModule = true;
    courseModule.deleteMonitor.isBeingDeleted = true;
    this.flashingDeleteObs = this.getFlashingObs();
    this.downloadsService
      .deleteModule(courseModule.id, this.courseCode, andVideos, !andVideos, courseModule.deleteMonitor)
      .subscribe(
        x => {
        },
        error => {
          console.error(error)
          this.removingModule = false;
          if (this.flashingDeleteObs)
            this.flashingDeleteObs.unsubscribe();
        },
        () => {
          if (andVideos === false)
            courseModule.isContentDownloaded = false;

          courseModule.isVideoListDownloaded = false;
          courseModule.deleteMonitor.isBeingDeleted = false;
          this.removingModule = false;
          this.getStorageEstimate();
          if (this.flashingDeleteObs)
            this.flashingDeleteObs.unsubscribe();
        });
  }

  getFlashingObs() {
    return interval(750)
      .subscribe(x => {
        this.flashing = (this.flashing == 'visible') ? 'invisible' : 'visible';
      })
  }

  downloadModule(courseModule: Module, andVideos: boolean) {
    this.downloadingModule = true;
    courseModule.monitor.isBeingDownloaded = true;
    this.flashingObs = this.getFlashingObs();
    this.currentModuleBeingDownloaded = courseModule;

    this.partialDownloadsService
    .markCourseModuleAsPartiallyDownloaded(this.courseCode, courseModule.id, false)
    .subscribe(x => {

      this.downloadsService
      .downloadModuleSequentially(courseModule.id, this.courseCode, andVideos, courseModule.monitor)
      .subscribe(
        response => {
        },
        error => {
          courseModule.isContentDownloaded = false;
          courseModule.isVideoListDownloaded = false;
          courseModule.monitor.isBeingDownloaded = false;

          const partialDownloadInfo = new PartialDownload();
          partialDownloadInfo.courseCode = this.courseCode;      
          partialDownloadInfo.moduleId = courseModule.id;
          partialDownloadInfo.isVideoDownload = false;

          this.applicationEventDispatcher.processDownloadHttpRequest(partialDownloadInfo, ApplicationEventType.ModuleDownloadFailure); 

          this.downloadingModule = false;
          this.getStorageEstimate();

          if (this.flashingObs)
            this.flashingObs.unsubscribe();    

          this.currentModuleBeingDownloaded = null;
        },
        () => {
          this.currentModuleBeingDownloaded = null;
          courseModule.isContentDownloaded = true;
          courseModule.monitor.isBeingDownloaded = false;          
          if (courseModule.monitor.videos.length > 0)
            courseModule.isVideoListDownloaded = true;

          this.downloadingModule = false;            
          this.getStorageEstimate();
          
          if (this.flashingObs)
            this.flashingObs.unsubscribe();

          this.partialDownloadsService
            .unmarkCourseModuleAsPartiallyDownloaded(this.courseCode, courseModule.id)
            .subscribe(x => {}, err => {});
        });
    });
  }

  downloadVideoModule(courseModule: Module, andVideos: boolean) {
    this.downloadingModule = true;
    courseModule.monitor.isBeingDownloaded = true;
    this.flashingObs = this.getFlashingObs();
    this.currentModuleBeingDownloaded = courseModule;

    this.partialDownloadsService
    .markCourseModuleAsPartiallyDownloaded(this.courseCode, courseModule.id, true)
    .subscribe(x => {

      this.downloadsService.downloadModuleVideosOnly(courseModule.id, this.courseCode, courseModule.monitor)
      .subscribe(
        response => {
          const partialDownloadInfo = new PartialDownload();
          partialDownloadInfo.courseCode = this.courseCode;
          partialDownloadInfo.moduleId = courseModule.id;
          partialDownloadInfo.isVideoDownload = true;


          
          //this.applicationEventDispatcher.processDownloadHttpRequest(partialDownloadInfo, ApplicationEventType.PartialModuleCompleted);
        },
        error => {
          courseModule.isVideoListDownloaded = false;
          courseModule.monitor.isBeingDownloaded = false;

          const partialDownloadInfo = new PartialDownload();
          partialDownloadInfo.courseCode = this.courseCode;
          partialDownloadInfo.moduleId = courseModule.id;
          partialDownloadInfo.isVideoDownload = true;

          this.applicationEventDispatcher.processDownloadHttpRequest(partialDownloadInfo, ApplicationEventType.ModuleDownloadFailure); 

          this.downloadingModule = false;
          this.getStorageEstimate();

          if (this.flashingObs)
            this.flashingObs.unsubscribe();    

          this.currentModuleBeingDownloaded = null;
        },
        () => {
          this.currentModuleBeingDownloaded = null;
          courseModule.monitor.isBeingDownloaded = false;          
          courseModule.isVideoListDownloaded = true;

          this.downloadingModule = false;            
          this.getStorageEstimate();
          
          if (this.flashingObs)
            this.flashingObs.unsubscribe();

          this.partialDownloadsService
            .unmarkCourseModuleAsPartiallyDownloaded(this.courseCode, courseModule.id)
            .subscribe(x => {}, err => {});
        });
    });
  }

  isUpdateAvailable(download) {
    return download.hasPendingUpdates ? '(update available)' : '';
  }

  showDownloadProgressVideos(courseModule: Module) {
    if (courseModule.monitor.getTotal() > 0
      && courseModule.monitor.videos.length > 0
      && courseModule.monitor.isBeingDownloaded)
      return true

    return false;
  }

  showDownloadProgressContent(courseModule: Module) {
    if (courseModule.monitor.getTotal() > 0
      && courseModule.monitor.videos.length === 0
      && courseModule.monitor.isBeingDownloaded)
      return true

    return false;
  }

  showDeleteProgressVideos(courseModule: Module) {
    if (courseModule.deleteMonitor.getTotal() > 0
      && courseModule.deleteMonitor.videos.length > 0
      && courseModule.deleteMonitor.isBeingDeleted)
      return true

    return false;
  }

  showDeleteProgressContent(courseModule: Module) {
    if (courseModule.deleteMonitor.getTotal() > 0
      && courseModule.deleteMonitor.videos.length === 0
      && courseModule.deleteMonitor.isBeingDeleted)
      return true

    return false;
  }

  showIcons(courseModule: Module) {
    if (!courseModule.deleteMonitor.isBeingDeleted
      && !courseModule.monitor.isBeingDownloaded
      && !this.downloadingModule
      && !this.removingModule)
      return true;

    return false;
  }

  notCurrentlyBeingDeletedOrDownloaded(courseModule: Module) {
    if (!courseModule.monitor.isBeingDownloaded
      && !courseModule.deleteMonitor.isBeingDeleted)
      return true;

    return false;
  }

  isCurrentlyBeingDownloadedOrDeleted(courseModule: Module) {
    if (courseModule.monitor.isBeingDownloaded
      || courseModule.deleteMonitor.isBeingDeleted)
      return true;

    return false;
  }

  anyModuleIsBeingDowloadedOrDeleted() {
    if (this.downloadingModule
      || this.removingModule)
      return true;

    return false;
  }

  isItTooBig(size: number) {
    if (size >= this.estimate)
      return true

    return false;
  }

  weHaveEnoughSpaceToShowContent(courseModule: Module) {
    if (!this.isItTooBig(courseModule.standardContentSize)
      || courseModule.isContentDownloaded)
      return true

    // we dont know so return true
    if (!this.canCheckStorage)
      return true

    return false;
  }

  weHaveEnoughSpaceToShowVideo(courseModule: Module) {
    if (courseModule.videoContentSize > 0
      && (!this.isItTooBig(courseModule.totalContentSize)
        || courseModule.isVideoListDownloaded))
      return true;

    // we dont know so return true
    if (!this.canCheckStorage)
      return true

    return false;
  }

  turnOffTheOtherRows(courseModule: Module) {
    if (!this.isCurrentlyBeingDownloadedOrDeleted(courseModule)
      && this.anyModuleIsBeingDowloadedOrDeleted())
      return true;

    return false;
  }

  cleanupPartialDownloads(){
    this.partialDownloadsService
    .getPartiallyDownloadedModulesForCourse(this.courseCode)
    .subscribe(x => {
      if (x == null || x.partialModules == null) return;

      let deletionList = new Array<Observable<string>>();

      for(let partialModule of x.partialModules){
        deletionList.push(this.deletePartialModule(partialModule.key, this.courseCode, partialModule.value.isVideoDownload));
      }

      const concatenationOfObservables = concat(...deletionList);

      this.downloadingModule = true;

      concatenationOfObservables.subscribe(x => {        
      }, error => {
        this.downloadingModule = false;
      },
      () => { 
        this.downloadingModule = false; 
      });

    });
  }

  cleanupPartialModule(partialDownloadInfo : PartialDownload){
    if (partialDownloadInfo == null) return;

    this.downloadingModule = true;

    this.deletePartialModule(partialDownloadInfo.moduleId, partialDownloadInfo.courseCode, partialDownloadInfo.isVideoDownload)
    .subscribe(x => {        
    }, error => {
      this.downloadingModule = false;
    },
    () => { 
      this.downloadingModule = false; 
    });
  }

  resetPartialModule(partialDownloadInfo : PartialDownload){
    if (partialDownloadInfo == null) return;

    this.partialDownloadsService
    .resetTimeForCourseModuleAsPartiallyDownloaded(this.courseCode, partialDownloadInfo.moduleId)
    .subscribe(x => {}, err => { });
  }


  deletePartialModule(moduleId:number, courseCode: string, removeVideos:boolean):Observable<string>{
    
    const deleteMonitor = new ModuleDeletiondMonitoring();
    
    let deletionProcess = new Observable<string>(observer => {
      this.downloadsService    
          .deleteModule(moduleId,  courseCode, removeVideos, false, deleteMonitor)
          .subscribe(x => {              
            },
            error => {
                observer.error(error);
            },
            ()=>{
              this.partialDownloadsService
              .unmarkCourseModuleAsPartiallyDownloaded(courseCode, moduleId)
              .subscribe(x => {}, 
                err => {
                  observer.error(err);
                },
                ()=>{
                  observer.next(`Module ${moduleId} deleted`);
                  observer.complete();
                });            
            });
    });

    return deletionProcess;
  }

}