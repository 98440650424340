import { Injectable } from '@angular/core';
import { KortextActivity } from '../models/kortext/kortext-activity';
import { Observable } from 'rxjs';
import { ServiceBase } from '@app/core/service-base';
import { OperationResponse } from '@app/common/models/operation-response';

@Injectable({
    providedIn: 'root'
})
export class KortextActivityService extends ServiceBase {

    getKortextActivity(itemUrl, treatAsOffline): Observable<OperationResponse<KortextActivity>> {
        return this.getObject<KortextActivity>(itemUrl, treatAsOffline);
    }
}