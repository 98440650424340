import { LearningObject } from '@app/common/models/learning-object';

export class SectionProgress extends LearningObject {
    constructor(
        public progressModule: number,
        public progressOverall: number,
        public moduleSectionsIncomplete: number,
        public moduleSectionsTotal: number,
        public moduleName: string,
    ) {
        super();
    }

}
