import { Injectable } from "@angular/core";


interface RelatedApplication {
      platform:string;
        url : string;
         id : string;
         version : string;
};

interface InstalledRelatedApps {
    getInstalledRelatedApps(): Promise<Array<RelatedApplication>>;    
}


interface Navigator {
   getInstalledRelatedApps():Promise<Array<RelatedApplication>>;
};

@Injectable({
    providedIn: 'root'
})
export class PersistentCacheManagerService{
    checkIfProgressiveWebAppIsInstalled(){

        if ('getInstalledRelatedApps' in navigator) {
            return (<Navigator><unknown>navigator).getInstalledRelatedApps();
        }
     return null;
// relatedApps.forEach((app) => {
//   console.log(app.id, app.platform, app.url);
// });

    }

    ensureCacheWillNotBeEvicted(){
        var isProgressiveWebAppIsInstalled = this.checkIfProgressiveWebAppIsInstalled();

        if (isProgressiveWebAppIsInstalled == null){
            console.log('Unable to check status of installed PWA');
            this.ensurePersistentCache();
        }
        else {
            isProgressiveWebAppIsInstalled.then((relatedApp) =>{
                if (relatedApp.length === 0){
                    console.log('App has not been installed');
                    this.ensurePersistentCache();
                }
                else{
                    console.log('App has been installed');
                }
            })
        }
    }

    ensurePersistentCache(){
        if (navigator.storage && navigator.storage.persist) {
           navigator.storage.persisted().then((isPersisted)=> {

                console.log(`Persisted storage granted: ${isPersisted}`);
                if (isPersisted !== true){
                    navigator.storage.persist().then((hasbeenGranted)=>{
                        console.log(`Persisted storage permssion has been granted: ${hasbeenGranted}`);
                    })
                } 
           })            
          }
    }
}