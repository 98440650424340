<div *ngIf="isOffline">
  <div class="img-placeholder">
    <img src="../../../../assets/img/placeholder.svg" />
  </div>
  <ebs-offline-message [isOffline]='isOffline' [offlineMessage]='offlineMessage'></ebs-offline-message>
</div>


<ebs-spinner [spinnerMessage]="'loading..'" [faColour]="'#999'" [boxColour]="'#999'" *ngIf="gettingFileIFrameActivity"
  [boxPadding]="'5px 15px'"></ebs-spinner>
<div class="card-body" *ngIf="!gettingFileIFrameActivity && !isOffline">
  <div class="row">
    <div class="col-sm-12 hidden-print">
      <iframe class="embed-responsive-item embed-responsive-16by9 ebs-media-response" title="articulate media iframe"
        [src]="activityItem.sourceUrl | domSanitizerPipe: 'resourceUrl'" allowfullscreen=""></iframe>
    </div>
    <div style="display: none;" class="col-sm-12 d-none d-print-block img-placeholder">
      <img src="../../../../assets/img/placeholder.svg" />
    </div>
  </div>
</div>