import { Component, OnInit, Input, ViewChildren, QueryList } from '@angular/core';
import { DiscussionService } from '../../services/discussion.service';
import { UserProfile } from '@app/common/models/user';
import { UserService } from '@app/common/security/user.service';
import { DiscussionPostOrReplyItem } from '../../models/discussion-postorreply-item';
import { DiscussionActivity } from '@app/courses/components/discussion-course/models/discussion-activity';
import { PagedRequest } from '@app/common/models/paged-request';
import { dateSort } from '@app/common/date-comparer';
import { PagedResult } from '@app/common/models/paged-result';
import { ConfigurationHelperService } from '@app/common/app-configuration/configuration-helper.service';
import { Subscription } from 'rxjs';
import { OnlineStatusService } from '@app/common/online-status/online-status.service';
import { OnlineStatusType } from '@app/common/online-status/online-status-type.enum';
import { NotificationSummary } from '@app/common/models/notification-summary';
import { SeverityLevel } from '@app/common/models/severity-level';
import { OperationResponse } from '@app/common/models/operation-response';
import { ActivatedRoute, Router } from '@angular/router';
import { StaticText } from '@app/common/static-text';
import { CanComponentDeactivate } from '@app/common/security/can-deactivate.guard';
import { WysiwygComponent } from '../wysiwyg/wysiwyg.component';
import { DiscussionType } from '@app/courses/components/discussion-forum/models/discussion-type';
import { userProfile } from '@app/common/mock/data/user-service-mock';

@Component({
  selector: 'ebs-discussion-manager',
  templateUrl: './discussion-manager.component.html',
  styleUrls: ['./discussion-manager.component.scss']
})
export class DiscussionManagerComponent implements OnInit, CanComponentDeactivate {
  discussionId = '';
  @Input() activityItem: DiscussionActivity;
  @Input() courseCode: string;
  @Input() discussionType: DiscussionType = DiscussionType.courseDiscussion;
  @Input() configItemsPerPage: number = 5;
  @ViewChildren(WysiwygComponent) children: QueryList<WysiwygComponent>;
  currentUser: UserProfile
  discussionPostOrReplyItem: DiscussionPostOrReplyItem;
  selectedPost: DiscussionPostOrReplyItem;
  posts: DiscussionPostOrReplyItem[] = [];
  pagedRequest = new PagedRequest(); pagedResult = new PagedResult();
  notifications: NotificationSummary[];
  lastItemId: string;
  totalItems = 0;
  isOffline = false; currentUserLoaded: boolean; firstTime: boolean;
  loadingMorePosts = false; loadingPosts = false; loadingMore = false;
  isQueryStringRelatedToCurrentDiscussion: boolean;
  postId: string; replyId: string; fragment: string;
  theSubscription: Subscription; fragmentSubscription: Subscription;
  offlineMessage = StaticText.discussionOfflineMessage;
  showMore: boolean[] = [];
  isDemoUser = false;

  canDeactivate(): boolean {
    let canBeDeactivated = true;

    this.children.forEach(child => {
      if (!child.canDeactivate()) canBeDeactivated = false;
    });
    return canBeDeactivated
  }

  constructor(
    private userService: UserService,
    private discussionService: DiscussionService,
    private appConfigService: ConfigurationHelperService,
    private onlineStatusService: OnlineStatusService,
    private route: ActivatedRoute,
    private router: Router,) {

    this.theSubscription = this.onlineStatusService.status.subscribe(
      event => {
        this.isOffline = (event === OnlineStatusType.OFFLINE) ? true : false
      }
    )

  }

  ngOnInit() {
    this.setUpLocalVariables()

    this.userService.getCurrentUser().subscribe(
      (theUserProfile: UserProfile) => {
        this.currentUser = theUserProfile;
        this.currentUserLoaded = true;
        this.currentUser.userAccount.roles.find(x => x.toString() == "Demo") ? this.isDemoUser = true : this.isDemoUser = false;
      },
      error => {
        console.log(error);
        this.notifications.push(new NotificationSummary('Discussion: Error getting current user', 'Http', SeverityLevel.Error));
      },
      () => {
        this.firstTime = true;
        this.interrogateQueryString();
      });

  }

  interrogateQueryString() {
    this.parseQueryString().subscribe(params => {
      let areChangesPresent: boolean = false;
      this.isQueryStringRelatedToCurrentDiscussion = false;

      if (params) {
        areChangesPresent = this.postId != params['postId'] || this.replyId != params['replyId'];
        if (this.discussionId == params['discussionId']) {
          this.isQueryStringRelatedToCurrentDiscussion = true;
        }

        this.postId = params['postId'];
        this.replyId = params['replyId'];
        if (this.fragmentSubscription != null) {
          this.fragmentSubscription.unsubscribe();
          this.fragmentSubscription = null;
        }
      }

      if ((this.isQueryStringRelatedToCurrentDiscussion && areChangesPresent) || this.firstTime) {

        if (this.isQueryStringRelatedToCurrentDiscussion && this.displayParticularPostOrReply()) {
          this.getDiscussionPost();
        } else {
          this.requestPosts();
        }

        this.firstTime = false;
      }

      if (this.fragmentSubscription == null && this.isQueryStringRelatedToCurrentDiscussion) {
        this.fragmentSubscription = this.parseFragment().subscribe(
          fragment => {
            this.fragment = fragment;
            if (areChangesPresent === true && this.fragment != null) {
              let root = this;
              setTimeout(() => {
                this.goToAnchor(root)
              }, 1500);
              areChangesPresent = false;
            }
          });
      }
    })
  }

  setUpLocalVariables() {

    // if not passed in as an input param
    if (!this.courseCode)
      this.courseCode = this.activityItem.uri.split('/modules')[0].split('courses/')[1]

    this.lastItemId = null;
    this.discussionId = this.activityItem.uri.split('discussions/')[1];
    this.discussionService.discussionUri = this.activityItem.uri.split('discussions')[0]

  }

  //RHM
  getDiscussionPost() {
    this.discussionService.getPost(this.discussionId, this.postId)
      .subscribe((response) => {
        this.selectedPost = response.data
        this.posts = new Array<DiscussionPostOrReplyItem>();
        this.posts.push(this.selectedPost);
        this.totalItems = 1;
        this.pagedRequest.lastItemId = null;
        this.lastItemId = null;
      });
  }

  goToAnchor(rootComponent: DiscussionManagerComponent): void {
    try {
      if (rootComponent.fragment && (this.discussionType === DiscussionType.courseDiscussion || this.discussionType === DiscussionType.courseForum))
        document.querySelector('#' + rootComponent.fragment).scrollIntoView({ behavior: 'smooth', block: "start", inline: "nearest" });

      if (rootComponent.fragment === '' &&
        (this.discussionType === DiscussionType.courseDiscussion || this.discussionType === DiscussionType.courseForum)) {
        setTimeout(() => {
          document.querySelector('#single-post-filter').scrollIntoView({ behavior: 'smooth', block: "start", inline: "nearest" });
        }, 1000);
      }

    } catch (e) {
      console.log(`anchor ${this.fragment} failed`);
    }
  }

  parseQueryString() {
    return this.route.queryParams;
  }

  parseFragment() {
    return this.route.fragment;
  }


  removeSelectedPost($event) {
    this.lastItemId = null;
    this.pagedRequest.lastItemId = null;
    this.getDiscussionPosts(1, 4, false, true);
  }

  resetParticularPostOrReply() {
    this.selectedPost = null;

    this.postId = null;
    this.replyId = null;
    this.fragment = null;

    let navigationSettings = { relativeTo: this.route, queryParams: {} };

    if (this.discussionType != DiscussionType.courseDiscussion) {
      navigationSettings.queryParams = { discussionId: this.discussionId }
    }

    this.router.navigate([], navigationSettings);
  }

  displayParticularPostOrReply() {
    return ((this.postId) || (this.replyId)) && this.isQueryStringRelatedToCurrentDiscussion;
  }
  //end RHM

  requestPosts() {
    this.onlineStatusService.isOffline().subscribe(
      isOffline => {
        this.isOffline = isOffline;
        this.getDiscussionPosts(1, this.configItemsPerPage, false);
      },
      error => {
        this.isOffline = false;
        this.getDiscussionPosts(1, this.configItemsPerPage, false);
      });
  }

  getDiscussionPosts(currentPage: number, itemsPerPage: number, appends: boolean, resetParticulatPostOrReplyValues: boolean = false) {
    this.pagedRequest.currentPage = currentPage;
    this.pagedRequest.itemsPerPage = itemsPerPage;
    this.pagedRequest.uriFilter = this.activityItem.uri + '/posts'
    this.pagedRequest.sortOperator = dateSort;

    if (this.loadingMore) {
      this.loadingMorePosts = true;
      // G.M. not sure what this is for?? if offline we dont show 
      if (this.isOffline)
        this.pagedRequest.currentPage += 1;
    } else {
      this.loadingPosts = true;
    }

    this.discussionService.getPosts(this.pagedRequest, this.isOffline)
      .subscribe(
        (response: OperationResponse<PagedResult<DiscussionPostOrReplyItem>>) => {
          this.showMore = new Array(response.data.items.length).fill(true);
          this.pagedResult = response.data;

          if (this.pagedRequest.lastItemId == null)
            this.totalItems = this.pagedResult.totalItems;

          this.posts = appends === true
            ? [...this.posts, ...response.data.items]
            : [...response.data.items];

          if (resetParticulatPostOrReplyValues) this.resetParticularPostOrReply();

          this.loadingMorePosts = false;
          this.loadingPosts = false;
        },
        error => {
          this.loadingMorePosts = false;
          this.loadingPosts = false;
          this.notifications.push(new NotificationSummary(error, 'unknown', SeverityLevel.Error, 'unknown', 0))
          console.log(error);
        }
      );
  }

  // find the post and replace it with the new post 
  //- handles updated post or reply (as are the same thing) plus any likes
  handleUpdatedPost(updatedPost: DiscussionPostOrReplyItem) {
    //find post and replace for local UI
    this.posts[this.posts.findIndex(p => p.id === updatedPost.id)] = updatedPost;
    //update indexeddb
    this.discussionService.setObjectInLocalStore(updatedPost, updatedPost.uri).subscribe(
      updatePostResponse => {
      }
    )
  }

  handleNewPost(newPost: DiscussionPostOrReplyItem) {
    // add post to start
    this.posts.unshift(newPost)
    this.totalItems += 1; 

    //update indexeddb
    this.discussionService.setObjectInLocalStore(newPost, newPost.uri).subscribe(
      newPostResponse => {
      }
    )
  }

  handleDeletedPostOrReply(originalPostOrReply: DiscussionPostOrReplyItem) {
    if (originalPostOrReply.replies == null) {
      // if null then is a reply so we just want to update the POST (with the reply already having been removed in the component)
      this.handleUpdatedPost(this.posts.find(p => p.id === originalPostOrReply.postId));
    } else {
      //if has replies then is a POST so we want to delete whole thing
      this.discussionService.deletePostFromLocalStore(originalPostOrReply).subscribe(
        deletePostResponse => {
          this.posts.splice(this.posts.findIndex(p => p.id === originalPostOrReply.id), 1);
          this.totalItems -= 1;
        }
      )
    }
  }

  ////// loading display
  loadMorePosts() {
    this.loadingMore = true;
    // if number of displayed items is 0 (the user has deleted all the posts) then essentially do a refresh
    if (this.posts.length > 0) {
      this.pagedRequest.lastItemId = this.posts[this.posts.length - 1].id;
      this.getDiscussionPosts(1, this.configItemsPerPage, true);
    } else {
      this.lastItemId = null;
      this.getDiscussionPosts(1, this.configItemsPerPage, true);
    }
    this.loadingMore = false;
  }

  postsLoaded() {
    return this.posts && this.posts.length > 0;
  }

  postsLoadedLessThanTotal() {
    return this.posts.length < this.totalItems;
  }

  showFirstPostMessage() {
    return (!this.posts || this.posts.length === 0) && this.pagedResult.totalItems === 0
  }

}
