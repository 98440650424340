import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { Observable, Subscriber, of } from 'rxjs';
import { OnlineStatusService } from '../online-status/online-status.service';
import { User, UserProfile, PersonalDetails } from '../models/user';
import { Func, Delegate } from '@app/core/delegates';
import { ObjectStore } from '@app/core/object-store';
import { ServiceBase } from '@app/core/service-base';
import { OperationResponse } from '@app/common/models/operation-response';
import { ToastrService } from 'ngx-toastr';
import { CookieService } from '../cookies/cookie.service';
import { StaticText } from '../static-text';
import { ServiceBaseHelpers } from '@app/core/service-base-helpers';
import { DataStoreStrategy } from '@app/core/data-store-strategy';
import { ObjectStoreBase } from '@app/core/object-store-base';
import { ConfigurationHelperService } from '../app-configuration/configuration-helper.service';


@Injectable({
    providedIn: 'root'
})
export class UserService extends ServiceBase {

    constructor(
        http: HttpClient,
        objectStore: ObjectStoreBase,
        private onlineStatusService: OnlineStatusService,
        private cookieService: CookieService,
        toastr: ToastrService,
        helpers: ServiceBaseHelpers,
        configurationService:ConfigurationHelperService) {
        super(http, objectStore, toastr, helpers, configurationService);
    }

    public currentUser: UserProfile = null;
    public userEnvironmentUri = StaticText.currentUserNoSlash;

    public getUserFromLocalStore(): Observable<UserProfile> {
        return this.getObjectFromLocalStoreOnly<UserProfile>(StaticText.currentUserNoSlash);
    }

    public getCurrentUser(): Observable<UserProfile> {
        const getCurrentUserObs = new Observable<UserProfile>(subscriber => {
            this.isOffline().subscribe(x => {
                this.getCurrentUserProfile(x).subscribe(y => {
                    subscriber.next(y);
                    subscriber.complete();
                }, err => {
                    subscriber.error(err);
                    subscriber.complete();
                });
            }, error => {
                this.getCurrentUserProfile(false).subscribe(y => {
                    subscriber.next(y);
                    subscriber.complete();
                }, err => {
                    subscriber.error(err);
                    subscriber.complete();
                });
            });

        });

        return getCurrentUserObs;
    }


    protected getCurrentUserProfile(isOffline: boolean): Observable<UserProfile> {

        if (isOffline == null) { isOffline = false; }

        const currentUserObs = new Observable<UserProfile>(subscribe => {
            if (isOffline === true) {
                this.getCurrentUserProfileWhenOffline(subscribe);
            } else {
                this.getCurrentUserProfileWhenOnline(subscribe);
            }
        });

        return currentUserObs;
    }

    protected getCurrentUserProfileWhenOffline(subscriber: Subscriber<UserProfile>): void {
        this.getObjectFromLocalStoreOnly<UserProfile>(this.userEnvironmentUri)
            .subscribe((x: UserProfile) => {
                if (x) {
                    if (this.currentUser == null) {
                        this.currentUser = x;
                    } else {
                        Object.assign(this.currentUser, x);
                    }
                    subscriber.next(x);
                } else {
                    this.currentUser = null;
                    subscriber.error();
                }
                subscriber.complete();
            }, error => {
                this.currentUser = null;
                subscriber.error(error);
                subscriber.complete();
            });
    }

    protected getCurrentUserProfileWhenOnline(subscriber: Subscriber<UserProfile>): void {
        // M.S. even if server fails, there sill be no item with this URI stored locally
        this.getObject<UserProfile>('authenticate/' + this.userEnvironmentUri, false)
            .subscribe(x => {
                if (x && x.operationSucceeded === true && x.data) {
                    if (this.currentUser == null) {
                        this.currentUser = x.data;
                    } else {
                        Object.assign(this.currentUser, x.data);
                    }

                    subscriber.next(x.data);
                } else {
                    this.currentUser = null;
                    subscriber.error();
                }
                subscriber.complete();
            }, error => {
                //this.currentUser = null;
                // subscriber.error(error);                
                // subscriber.complete();
                this.getCurrentUserProfileWhenOffline(subscriber);
            });
    }

    public setUser(user: User, uri: string, enabledOffline: boolean, successCheck: Func<UserProfile, boolean> = null): Observable<OperationResponse<UserProfile>> {
        console.log('user being set')
        user.uri = this.userEnvironmentUri;
        return this.setObjectFromServerThenStoreLocally<User, UserProfile>(user, uri,
            (userRecord: UserProfile) => this.setRetrievedUser(userRecord),
            successCheck,
            enabledOffline,
            true
        );
    }



    protected setRetrievedUser(userRecord: UserProfile): UserProfile {
        if (userRecord == null) { return userRecord; }

        if (userRecord.userAccount !== null) {
            userRecord.userAccount.isWorkingOffline = false;
        }

        userRecord.uri = this.userEnvironmentUri;

        // M.S. - Added this line for the non offline version (temporary code)
        this.currentUser = userRecord;

        return userRecord;
    }

    public setUserProfileLocally(isOffline: boolean, isLoggingOut: boolean = false, settingOfflineMode: boolean = false, isAModalLogoff: boolean = false): void {
        console.log('user setUserProfileLocally')
        this.getCurrentUserProfile(isOffline).subscribe(
            userProfile => {
                userProfile.uri = StaticText.currentUserNoSlash;
                if (isLoggingOut) {
                    userProfile.authenticationDetails.isLoggedOut = true;
                    userProfile.authenticationDetails.isAuthenticated = false;
                }

                //if a modal logoff then we don't have credentials

                this.getObjectFromLocalStoreOnly(StaticText.offlineModeStatus).subscribe(
                    offlineModeLocalObject => {
                        if (offlineModeLocalObject || settingOfflineMode) {
                            this.setObjectInLocalStore<UserProfile>(userProfile, StaticText.currentUserNoSlash).subscribe(
                                response => {
                                    console.log('USER added/updated in indexed db', userProfile);
                                }
                            )
                        }
                    }
                )
            }
        );
    }

    // not sure this is named correctly
    public setUserProfile(user: UserProfile, uri: string, successCheck: Func<UserProfile, boolean> = null, persistLocally : boolean = true): Observable<OperationResponse<UserProfile>> {
        console.log('user setObjectFromServerThenStoreLocally')
        user.uri = this.userEnvironmentUri;
        //dont need to send user to logout and the url in enrollment details is breaking on Azure
        user=null;

        return this.setObjectFromServerThenStoreLocally<UserProfile, UserProfile>(user, uri,
            (userRecord: UserProfile) => this.logOutRetrievedUser(userRecord),
            successCheck, persistLocally
        );
    }

    protected logOutRetrievedUser(userRecord: UserProfile): UserProfile {

        if (userRecord == null || userRecord.userAccount == null || userRecord.authenticationDetails == null) { return userRecord; }

        userRecord.uri = this.userEnvironmentUri;

        userRecord.userAccount.isWorkingOffline = false;
        userRecord.authenticationDetails.isAuthenticated = false;
        userRecord.authenticationDetails.isLoggedOut = true;

        // M.S. - Added this line for the non offline version (temporary code)
        this.currentUser = userRecord;

        return userRecord;
    }

    public setWorkingConnectivityMode(user: UserProfile, isWorkingOffline: boolean): Observable<UserProfile> {
        user.uri = this.userEnvironmentUri;
        user.userAccount.isWorkingOffline = isWorkingOffline;

        return this.setObjectInLocalStore<UserProfile>(user, this.userEnvironmentUri);
    }


    public resetUser(): void {
        this.currentUser = null;
    }

    // M.S. needs adjusted
    public async getCurrentUserAwaitable(treatAsOffline: boolean) {

        if (this.currentUser == null
            || this.currentUser.userAccount == null
            || this.currentUser.userAccount.username == null
            || this.currentUser.userAccount.username === '') {

            if (treatAsOffline) {
                const result = await this.getObjectFromLocalStoreSync<UserProfile>(this.userEnvironmentUri);
                this.currentUser = result;
            } else {
                const result = await this.getObjectFromRemoteStoreSync<UserProfile>('authenticate/' + this.userEnvironmentUri);

                if (result.operationSucceeded && result.data != null) {
                    this.currentUser = result.data;
                }
            }
        }

        return this.currentUser;
    }

    public isOffline(): Observable<boolean> {
        return this.onlineStatusService.isOffline();
    }

    public isWorkingOffline(): Observable<boolean> {
        return this.onlineStatusService.isWorkingOffline();
    }

    async treatCurrentUserAsOffline() {
        let isOffline = await this.isOffline().toPromise();
        return isOffline;
    }

    public currentUserIsAuthenticatedOnRemoteServer(): boolean {

        // console.log('Checking authentication');
        const authenticationExpiryAsString = this.cookieService.get('LearningPlatform_AuthenticationExpiryTime');
        // console.log('Expires UTC:',cookieTimeoutUtc)

        if (authenticationExpiryAsString === null || authenticationExpiryAsString === undefined) {
            return false;
        }
        try {
            const cookieTimeoutUtc = new Date(authenticationExpiryAsString).toISOString();
            const dateNowUtc = new Date().toISOString();
            return cookieTimeoutUtc > dateNowUtc;
        } catch {
            return false;
        }
    }

    getDisplayName(personalDetails: PersonalDetails): string {
        if (personalDetails == null) { return ''; }
        return personalDetails.preferredName + ' ' + personalDetails.familyName;
      }
    
}
