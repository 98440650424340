import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { ServiceBase } from "@app/core/service-base";
import { OperationResponse } from "@app/common/models/operation-response";
import { CourseraLtiForm } from "../models/coursera/coursera-lti-form";
import { LtiResponse } from "@app/common/lti/lti-response";

@Injectable({
    providedIn: 'root'
})
export class CourseraOAuthService extends ServiceBase {

    private readonly uri = "lti/coursera/sign";

    public generateOAuthSignature(baseUri: string, courseraFormValues: CourseraLtiForm) : Observable<OperationResponse<LtiResponse>> {
        return this.postObjectToRemoteStoreOnly<LtiResponse>(courseraFormValues, baseUri + this.uri);
    }
}