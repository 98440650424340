import { Injectable, Inject, OnDestroy } from "@angular/core";
import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router";
import { UserService } from "@app/common/security/user.service";
import { OnlineStatusService } from "../online-status/online-status.service";
import { Subscription } from "rxjs";
import { OnlineStatusType } from "../online-status/online-status-type.enum";



@Injectable()
export class AuthGuardPortalAdministrator implements CanActivate, OnDestroy {
   
    theSubscription: Subscription;
    isOffline: boolean;
    
    constructor(private router: Router, @Inject(UserService) private userService: UserService,
    private onlineStatusService: OnlineStatusService) {
        this.onlineStatusService.isOffline().subscribe(x => {
            this.isOffline = x;
        }, error=>{
            this.isOffline = false;
        });
        this.theSubscription = this.onlineStatusService.status.subscribe(
            event => {
                this.isOffline = (event === OnlineStatusType.OFFLINE) ? true : false
            }
        )
     }
    
    async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot){

        this.isOffline = await this.onlineStatusService.isOffline().toPromise();
        await this.userService.getCurrentUserAwaitable(this.isOffline);
     
        if (this.userService.currentUser != null 
            && this.userService.currentUser.authenticationDetails != null
            && this.userService.currentUser.authenticationDetails.isLoggedOut === false) {
            if (this.userService.currentUser.userAccount.roles.find(x => x.toString() == "PortalAdministrator")){
                return true;
            }
        }

        return false;
    }

    ngOnDestroy(): void {
        this.theSubscription.unsubscribe();
    }
}