import { Component, OnInit } from '@angular/core';
import { ModuleDiscussionEngagementStatistic } from '@app/courses/models/discussion/module-discussion-engagement-statistic';
import { Input } from '@angular/core';
import { DiscussionEngagementStatistic } from '@app/courses/models/discussion/discussion-engagement-statistic';

@Component({
  selector: 'badge',
  templateUrl: './badge.component.html',
  styleUrls: ['./badge.component.scss']
})
export class BadgeComponent implements OnInit {

  @Input()
  public moduleDiscussionEngagementStatistic: ModuleDiscussionEngagementStatistic = new ModuleDiscussionEngagementStatistic();
  public statistic = 0;
  public totalDisucssionNotEngaged = 0;
  public noDiscussion = false;
  public hasNoDiscussionInModule = false;

  messageMapping:
    { [k: string]: string } = { '=0': '', '=1': '1 discussion with no participation', 'other': '# discussions with no participation.' };

  constructor() {
  }

  ngOnInit() {
    this.statistic = this.getEngagementScore(this.moduleDiscussionEngagementStatistic);
    this.noDiscussion = this.totalDisucssionNotEngaged == this.moduleDiscussionEngagementStatistic.discussionEngagementStatisticList.length;
  }

  public getEngagementScore(moduleDiscussionEngagementStatistic: ModuleDiscussionEngagementStatistic): number {
    let self = this;
    var total = 0;
    self.totalDisucssionNotEngaged = 0;
    let includeCount = 0;
    moduleDiscussionEngagementStatistic.discussionEngagementStatisticList.forEach((discussionEngagementStatistic) => {
      if (!discussionEngagementStatistic.isNoDiscussionInModule) {
        var point = self.getPoints(discussionEngagementStatistic);
        if (point == 0) {
          self.totalDisucssionNotEngaged++;
        }
        if (discussionEngagementStatistic.includeInCalculation) {
          includeCount++;
          total += point;
        }
      }else{
        this.hasNoDiscussionInModule = true;
      }
    })
    if (includeCount == 0) includeCount = 1;
    return Number((total / (includeCount * 100)).toFixed(2)) * 100;
  }

  public getPoints(discussionEngagementStatistic: DiscussionEngagementStatistic): number {
    // 1 or more post add: 50 points
    // 1 or more reply’s add: 20 points
    // 2 or more reply’s add another: 20 points
    // 1 or more likes add: 5 points
    // 2 or more likes add another: 5 points
    // Note: Max available = 100 points per discussion

    // var total = 0;
    // if (discussionEngagementStatistic.postCount >= 1) {
    //   total += 50;
    // }
    // if (discussionEngagementStatistic.replyCount == 1) {
    //   total += 20;
    // } else if (discussionEngagementStatistic.replyCount >= 2) {
    //   total += 40;
    // }
    // if (discussionEngagementStatistic.likeCount == 1) {
    //   total += 5;
    // } else if (discussionEngagementStatistic.likeCount >= 2) {
    //   total += 10;
    // }

    var total = 0;
    if (discussionEngagementStatistic.postCount >= 1) {
      total += 50;
    }
    if (discussionEngagementStatistic.replyCount >= 1) {
      total += 40;
    }
    if (discussionEngagementStatistic.likeCount >= 1) {
      total += 10;
    }
    return total;
  }
}
