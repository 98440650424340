import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CollapseModule, TooltipModule } from 'ngx-bootstrap/';
import { CourseMenuSidebarComponent } from '@app/courses/components/course-menu-sidebar/course-menu-sidebar.component';
import { CourseActivitySidebarComponent } from '@app/courses/components/course-activity-sidebar/course-activity-sidebar.component';
import { CourseRoutingModule } from '@app/courses/course-routing.module';
import { ComponentsModule } from '@app/common/components/components.module';
import { CourseContentComponent } from './components/course-content/course-content.component';
import { VideoActivityComponent } from './components/video-activity/video-activity.component';
import { ActivityGroupComponent } from './components/activity-group/activity-group.component';
import { AudioActivityComponent } from './components/audio-activity/audio-activity.component';
import { SectionComponent } from './components/section/section.component';
import { TextActivityComponent } from './components/text-activity/text-activity.component';
import { MultipleChoiceQuestionComponent } from './components/quiz/multiple-choice-question/multiple-choice-question.component';
import { MultipleAnswersQuestionComponent } from './components/quiz/multiple-answers-question/multiple-answers-question.component';
import { MatchThePairsQuestionComponent } from './components/quiz/match-the-pairs-question/match-the-pairs-question.component';
import { NumericalQuestionComponent } from './components/quiz/numerical-question/numerical-question.component';
import { QuizActivityComponent } from './components/quiz/quiz-activity/quiz-activity.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatchThePairsSubquestionComponent } from './components/quiz/match-the-pairs-subquestion/match-the-pairs-subquestion.component';
import { FeedbackQuestionComponent } from './components/quiz/feedback-question/feedback-question.component';
import { SectionProgressComponent } from './components/section-progress/section-progress.component';
import { MediaPlayerComponent } from './components/media-player/media-player.component';
import { ThirdPartyActivityComponent } from './components/third-party-activity/third-party-activity.component';
import { EssayQuestionComponent } from './components/quiz/essay-question/essay-question.component';
import { KortextActivityComponent } from './components/kortext/kortext-activity/kortext-activity.component';
import { EBooksComponent } from './components/ebooks/e-books.component';
import { QuizStatisticsDetailsComponent } from './components/quiz/quiz-statistics-details/quiz-statistics-details.component';
import { QuizStatisticsAverageComponent } from './components/quiz/quiz-statistics-average/quiz-statistics-average.component';
import { DiscussionUserprofileComponent } from './components/discussion-course/components/discussion-userprofile/discussion-userprofile.component';
import { FileIFrameActivityComponent } from './components/file-iframe-activity/file-iframe-activity.component';
import { DiscussionEngagementComponent } from './components/discussion-engagement/discussion-engagement.component'
import { DiscussionEngagementStatisticsDetailsComponent } from './components/discussion-engagement-statistics-details/discussion-engagement-statistics-details.component';
import { BadgeComponent } from './components/badge/badge.component'
import { DownloadsComponent } from '@app/downloads/downloads.component';
import { DownloadsCurrentComponent } from '@app/downloads/components/downloads-current/downloads-current.component';
import { DownloadsAvailableComponent } from '@app/downloads/components/downloads-available/downloads-available.component';
import { PrintableMaterialsComponent } from './components/printable-materials/printable-materials.component';

import { GroupActivityComponent } from './components/group-activity/group-activity.component';
import { ReplyComponent } from './components/reply/reply.component';
import { TextTruncatePipe } from '@app/common/pipes/text-truncate-pipe';
import { DiscussionContentComponent } from './components/discussion-content/discussion-content.component';
import { FacultyActivityComponent } from './components/faculty-activity/faculty-activity.component';
import { ReplyActivityComponent } from './components/reply-activity/reply-activity.component';
import { GameActivityComponent } from './components/game/game-activity/game-activity.component';
import { WebvrDemoComponent } from './components/game/webvr-demo/webvr-demo.component';
import { WebGlDemoComponent } from './components/game/webgl-demo/webgl-demo.component';
import { SupermarketGameComponent } from './components/game/supermarket/supermarket-game/supermarket-game.component';
import { AnalysisComponent } from './components/game/supermarket/analysis/analysis.component';
import { UnityWebGLComponent } from './components/game/unity-web-gl/unity-web-gl.component';
import { UnityWebVrComponent } from './components/game/unity-web-vr/unity-web-vr.component';
import { TagsComponent } from './components/discussion-forum/components/tags/tags.component';
import { ForumListComponent } from './components/discussion-forum/components/forum-list/forum-list.component';
import { ForumAddComponent } from './components/discussion-forum/components/forum-add/forum-add.component';
import { ForumComponent } from './components/discussion-forum/components/forum/forum.component';
import { UserProfileFlatComponent } from './components/discussion-forum/components/user-profile-flat/user-profile-flat.component';
import { EmailSubscriptionComponent } from './components/email-subscription/email-subscription.component';
import { DiscussionLikeComponent } from './components/discussion-course/components/discussion-like/discussion-like.component';
import { DiscussionPostOrReplyItemComponent } from './components/discussion-course/components/discussion-postorreply-item/discussion-postorreply-item.component';
import { DiscussionManagerComponent } from './components/discussion-course/components/discussion-manager/discussion-manager.component';
import { WysiwygComponent } from './components/discussion-course/components/wysiwyg/wysiwyg.component';
import { ForumSearchComponent } from './components/discussion-forum/components/forum-search/forum-search.component';
import { ForumFilterComponent } from './components/discussion-forum/components/forum-filter/forum-filter.component';
import { FileUploadModule } from 'ng2-file-upload';
import { FileUploaderComponent } from './components/discussion-course/components/file-uploader/file-uploader.component';
import { AttachmentsComponent } from './components/discussion-course/components/attachments/attachments.component';

@NgModule({
  imports: [
    CommonModule,
    CourseRoutingModule,
    CollapseModule.forRoot(),
    TooltipModule.forRoot(),
    ComponentsModule,
    FormsModule,
    ReactiveFormsModule,
    FileUploadModule
  ],
  exports: [
    CourseActivitySidebarComponent,
    CourseMenuSidebarComponent,
    CourseContentComponent,
    EBooksComponent,
    DiscussionContentComponent,
  ],
  declarations: [
    CourseActivitySidebarComponent,
    CourseMenuSidebarComponent,
    CourseContentComponent,
    EBooksComponent,
    VideoActivityComponent,
    ActivityGroupComponent,
    AudioActivityComponent,
    SectionComponent,
    TextActivityComponent,
    MultipleChoiceQuestionComponent,
    MultipleAnswersQuestionComponent,
    MatchThePairsQuestionComponent,
    NumericalQuestionComponent,
    QuizActivityComponent,
    MatchThePairsSubquestionComponent,
    FeedbackQuestionComponent,
    SectionProgressComponent,
    MediaPlayerComponent,
    ThirdPartyActivityComponent,
    EssayQuestionComponent,
    KortextActivityComponent,
    QuizStatisticsDetailsComponent,
    QuizStatisticsAverageComponent,
    DiscussionUserprofileComponent,
    FileIFrameActivityComponent,
    DiscussionEngagementComponent,
    DiscussionEngagementStatisticsDetailsComponent,
    BadgeComponent,
    DownloadsComponent,
    DownloadsCurrentComponent,
    DownloadsAvailableComponent,
    PrintableMaterialsComponent,
    FacultyActivityComponent,
    GroupActivityComponent,
    ReplyComponent,
    TextTruncatePipe,
    DiscussionContentComponent,
    ReplyActivityComponent,
    GameActivityComponent,
    WebvrDemoComponent,
    WebGlDemoComponent,
    SupermarketGameComponent,
    AnalysisComponent,
    UnityWebGLComponent,
    UnityWebVrComponent,
    ForumListComponent,
    ForumAddComponent,
    ForumComponent,
    TagsComponent,
    UserProfileFlatComponent,
    DiscussionLikeComponent,
    DiscussionPostOrReplyItemComponent,
    WysiwygComponent,
    FileUploaderComponent,
    DiscussionManagerComponent,
    EmailSubscriptionComponent,
    ForumSearchComponent,
    ForumFilterComponent,
    FileUploaderComponent,
    AttachmentsComponent,
  ]
})
export class CourseNavigationModule { }
