<ebs-spinner [spinnerMessage]="''" [faColour]="'#fff'" [boxColour]="'#fff'" *ngIf="loadingData"
  [boxPadding]="'5px 15px'" [faFont]="'2.2rem'"></ebs-spinner>

<div *ngIf="!loadingData && showError">
  <i class="fa fa-fw fa-lg fa-exclamation-circle"> </i> {{failedMessage}}
</div>

<ul class="list-unstyled" *ngIf="!loadingData && courseStructure">
  <li class="divider button-wrapper d-none d-md-block" (click)="toggleMenuSide(menuStartPosition)" title="Menu toggle">
    <!--Closes and Opens the Sidebar-->
    <button type="button" id="sidebarCollapse" class="btn btn-sidebar-trigger">
      <span class="{{menuStartPosition}}"></span>
      <span class="sr-only">minimise maximise sidebar</span>
    </button>
  </li>

  <!--top links-->
  <!--hide downloads for now-->
  <li *ngFor="let link of courseStructure.links" title="{{link.name}}"
    [className]="link.active ? 'divider active' : 'divider inactive'">
    <a [routerLink]="link.url" *ngIf="!isOffline || link.name === 'Course Dashboard'">
      <span class="nav-label-icon-left fa fa-fw {{link.icon}}"></span>
      <span class="nav-label icon-left space-adjustment"> {{link.name}} </span>
    </a>

    <a  (click)="offlineModal()" *ngIf="isOffline && link.name != 'Course Dashboard'">
      <span class="nav-label-icon-left icon-gecko-unavailable" style="padding-top: 4px;" ></span>
      <span class="nav-label icon-left space-adjustment"> {{link.name}} </span>
    </a>
  </li>

  <!--Module-->
  <li class="nav-title">Learning Modules: ({{calculateCompletePerecent()}}% complete)</li>
  <div>
    <li class="dropdown" *ngFor="let module of courseStructure.modules; let i = index" title="{{module.name}}">
      <button (click)="toggleModule(i, true)" [attr.aria-expanded]="!isCollapsed[i]" class="{{collapsedClass[i]}}">
        <span class="nav-index" *ngIf="!(isOffline && !module.isContentDownloaded) ">{{module.number}}</span>
        <span class="nav-index icon-gecko-unavailable" style="padding-top: 4px;" *ngIf="isOffline && !module.isContentDownloaded"></span>
        <span class="nav-label">
          {{module.name}}
          <ebs-progress-bar [progressWrapperWidth]="'100%'" [progressMax]="module.progressMax"
            [progressValue]="module.progressValue" [progressAnimate]="true" [progressType]="'success'">
          </ebs-progress-bar>
        </span>
      </button>
      <!--section-->
      <ul class="list-unstyled section-list" [@isCollapsedAnimation]="isCollapsed[i]">
        <li *ngFor="let section of module.sections; let j = index" [className]="section.active ? 'active' : 'inactive'">
          <a [routerLink]="['/' + section.uri]" class="btn" role="button" aria-label="link button"
            *ngIf="!isOffline || section.availableOffline">
            <span class="nav-index">{{section.number}}</span>
            <span class="nav-label icon-right" style="white-space: normal;">
              {{section.name}}
              <span class="sidebar-icon icon-gecko-check" *ngIf="section.completed"></span>
            </span>
          </a>

          <a class="btn" (click)="offlineModal()" aria-label="link button disabled"
            *ngIf="isOffline && !section.availableOffline">

            <span class="nav-index" *ngIf="section.availableOffline">{{section.number}}</span>
            <span class="nav-index icon-gecko-unavailable" style="padding-right: 3px;padding-top: 4px;" *ngIf="!section.availableOffline"></span>

            <span class="nav-label icon-right" style="white-space: normal;">
              {{section.name}}
              <span class="sidebar-icon icon-gecko-check" *ngIf="section.completed"></span>
            </span>
          </a>
        </li>
      </ul>
    </li>
  </div>
</ul>