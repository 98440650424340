import { Injectable } from '@angular/core';
import { ServiceBase } from '@app/core/service-base';
import { PagedRequest } from '@app/common/models/paged-request';
import { Observable } from 'rxjs';
import { OperationResponse } from '@app/common/models/operation-response';
import { PagedResult } from '@app/common/models/paged-result';
import { SearchItem } from '../models/search-item';

@Injectable({
  providedIn: 'root'
})
export class ForumSearchService  extends ServiceBase{
  uri: string

  getSearchItems(courseCode: string, pagedRequest: PagedRequest<SearchItem>): Observable<OperationResponse<PagedResult<SearchItem>>> {
    pagedRequest.uriFilter = `search`;
    return this.getPagedObjects<SearchItem>(pagedRequest, false)
  }

 // getSearchItems(courseCode: string, pagedRequest: PagedRequest<SearchItem>): Observable<OperationResponse<PagedResult<SearchItem>>> {
    //   return obsResult
    // }
}
