import { Component, OnInit, Input } from '@angular/core';
import { QuizStatistics } from '@app/courses/models/quiz/quiz-statistics';
import { QuizStatisticsMockService } from '@app/common/mock/services/quiz-statstics-mock.service';
import { Observable } from 'rxjs';
import { ModalSimpleService } from '@app/common/components/modal-simple/modal-simple.service';

@Component({
  selector: 'ebs-quiz-statistics-average',
  templateUrl: './quiz-statistics-average.component.html',
  styleUrls: ['./quiz-statistics-average.component.scss']
})
export class QuizStatisticsAverageComponent implements OnInit {
  @Input() quizStatistics: QuizStatistics;
  bstyle = "width: 25%;";
  constructor(private quizStatisticsMockService: QuizStatisticsMockService, private modalSimpleService: ModalSimpleService) { }

  ngOnInit() { }

  showModal() {
    this.modalSimpleService.confirm("Tests Calculation",
      "<h3>How are your test marks calculated?</h3>" +
      "<p>These marks are a guide to how well you have performed in the tests at your last attempt. Essay questions and reflection activities are not marked, so do not count towards your average test mark.</p>" +
      "<p>These marks do not count towards your final grade</p>" +
      "<h3>Your average test mark</h3>" +
      "<p>Your average test mark shows your performance on the tests you have submitted so far across the course. If you have taken a test multiple times, only your most recent attempt will count.</p>" +
      "<h3>Test mark per module</h3>" +
      "<p>The test mark per module is calculated in the same way as your average test mark but includes only tests within that module.</p>",
      [{ buttonText: 'OK', buttonStyle: 'btn btn-secondary float-right btn-block' }]);
  }
}
