import { Component, OnInit, OnDestroy } from '@angular/core';
import { KortextActivity } from '@app/courses/models/kortext/kortext-activity';
import { OnlineStatusService } from '@app/common/online-status/online-status.service';
import { OnlineStatusType } from '@app/common/online-status/online-status-type.enum';
import { Subscription } from 'rxjs';
import { StaticText } from '@app/common/static-text';

@Component({
  selector: 'ebs-e-books',
  templateUrl: './e-books.component.html',
  styleUrls: ['./e-books.component.scss']
})
export class EBooksComponent implements OnInit, OnDestroy {
  public kortextActivityItem : KortextActivity = new KortextActivity(null, null, null);
  isOffline: boolean;
  theSubscription: Subscription;
  offlineMessage = StaticText.kortextOfflineMessage;

  constructor(private onlineStatusService : OnlineStatusService) 
  { 
    this.onlineStatusService.isOffline().subscribe(x => {
        this.isOffline = x;
    }, error=>{
        this.isOffline = false;
    });
    this.theSubscription = this.onlineStatusService.status.subscribe(
      event => {
        this.isOffline = (event === OnlineStatusType.OFFLINE) ? true : false
      }
    )
  }

  ngOnInit() {
  }
  
  ngOnDestroy(): void {
    if(this.theSubscription)
        this.theSubscription.unsubscribe();
  }
}
