import { Component, OnInit, Input, Inject, ViewChild, OnDestroy, NgZone, ChangeDetectorRef } from '@angular/core';
import { LearningActivityItemComponentBase } from '@app/courses/models/dynamic-load/learning-activity-item-component-base';
import { KortextActivity } from '@app/courses/models/kortext/kortext-activity'
import { KortextActivityService } from '@app/courses/services/kortext-activity.service'
import { OperationResponse } from '@app/common/models/operation-response';
import { ApplicationContext } from '@app/common/current-context/application-context'
import { KortextOAuthService } from '@app/courses/services/kortext-oauth.service';
import { ConfigurationHelperService } from '@app/common/app-configuration/configuration-helper.service';
import { UserService } from '@app/common/security/user.service';
import { KortextLtiForm } from '@app/courses/models/kortext/kortext-lti-form';
import { generate, Subscription } from 'rxjs';
import { OnlineStatusService } from '@app/common/online-status/online-status.service';
import { OnlineStatusType } from '@app/common/online-status/online-status-type.enum';
import { StaticText } from '@app/common/static-text';
import { LtiResponse } from '@app/common/lti/lti-response';

@Component({
  selector: 'ebs-kortext-activity',
  templateUrl: './kortext-activity.component.html',
  styleUrls: ['./kortext-activity.component.scss'],
})
export class KortextActivityComponent implements OnInit,
  LearningActivityItemComponentBase<KortextActivity>, OnDestroy {

  @Input() activityItem: KortextActivity;

  public loadingData = false;
  public disabledButton: boolean = true;

  public customId: string;
  public customPage: string;
  public contextLabel: string;
  public contextTitle: string;

  public formActionUrl: string;
  public email: string;
  public fullName: string;

  public oauthConsumerKey: string;
  public oauthTimestamp: number;
  public oauthSignature: string;

  latestTimestamp: number;
  latestSignature: string;

  private kortextIntervalTimerId: any;

  @ViewChild("KortextForm", { static: false }) kortextForm;
  isOffline: boolean;
  theSubscription: Subscription;
  offlineMessage = StaticText.kortextOfflineMessage;

  constructor(@Inject(KortextActivityService) private kortextActivityService: KortextActivityService,
    @Inject(ApplicationContext) private applicationContext: ApplicationContext,
    @Inject(KortextOAuthService) private kortextOAuthService: KortextOAuthService,
    @Inject(ConfigurationHelperService) private configurationHelperService: ConfigurationHelperService,
    @Inject(UserService) private contextService: UserService, private ngZone: NgZone,
    private changeDetectorRef: ChangeDetectorRef,
    private onlineStatusService: OnlineStatusService
  ) {
   
    
    this.theSubscription = this.onlineStatusService.status.subscribe(
      event => {
        this.isOffline = (event === OnlineStatusType.OFFLINE) ? true : false
      }
    )
  }


  ngOnInit() {
    this.onlineStatusService.isOffline().subscribe(x => {
        this.isOffline = x;
        if (!this.isOffline) {
          this.initialisingKortext();
        }
    }, error=>{
        this.isOffline = false;
        this.initialisingKortext();
    });
  }

  initialisingKortext(){
    this.formActionUrl = this.configurationHelperService.getKortextFormUrl();
    this.oauthConsumerKey = this.configurationHelperService.getKortextOAuthConsumerKey();
    this.email = this.contextService.currentUser.personalDetails.emailAddress;
    this.fullName = `${this.contextService.currentUser.personalDetails.givenNames} ${this.contextService.currentUser.personalDetails.familyName}`;
    this.disabledButton = true;

    //(dc for ms) double negative
    if (this.hasNoBookInfo() === false)
      this.getContent();
    else
      this.getApplicationContext();
  }

  canDeactivate(): boolean {
    return true;
  }
  ngOnDestroy(): void {
    clearInterval(this.kortextIntervalTimerId);
    if (this.theSubscription)
      this.theSubscription.unsubscribe();
  }

  getContent() {
    this.loadingData = true;
    // G.M. this will only be called if we are online so hard code to false
    this.kortextActivityService.getKortextActivity(this.activityItem.uri, false)
      .subscribe(
        (kortextResponse: OperationResponse<KortextActivity>) => {

          if (!kortextResponse.operationSucceeded || !kortextResponse.data) {
            this.kortextOAuthService.notifyApplicationError('You might not be able to read your book online at this time. Please try again later', 'Something went wrong');
            return;
          }

          this.customId = kortextResponse.data.bookId;
          this.customPage = kortextResponse.data.pageNumber ? kortextResponse.data.pageNumber.toString() : null;

          this.applicationContext.getCurrentCourse().subscribe(
            x => {
              if (!x) {
                this.kortextOAuthService.notifyApplicationError('It looks like you haven not selected your current course from the dashboard', 'Something went wrong');
                return;
              }
              this.contextLabel = x.courseCode;
              this.contextTitle = x.courseName;


            }, error => {
              console.log('kortext error curr course');
              this.kortextOAuthService.notifyApplicationError('You might not be able to read your book online at this time. Please try again later', 'Something went wrong');
              this.loadingData = false;
            }, () => {
            }
          );
        }, error => {
          console.log('kortext error kor activity');
          this.loadingData = false;
          this.kortextOAuthService.notifyApplicationError('You might not be able to read your book online at this time. Please try again later', 'Something went wrong');
        }, () => {
        }
      );
  }

  public hasCustomId(): boolean {
    if (this.customId == null || this.customId === '') return false;
    return true;
  }

  public hasCustomPage(): boolean {
    if (this.customPage == null || this.customPage === '') return false;
    return true;
  }

  public setTimeStamp(): void {
    this.latestTimestamp = Math.round(Date.now() / 1000);
  }

  public submitKortextForm(): void {
    if (this.loadingData === true || this.latestSignature == null || this.latestTimestamp == null) {
      this.kortextOAuthService.notifyApplicationError('You might not be able to read your book online at this time. Please try again later', 'Something went wrong');
    }

    this.oauthTimestamp = this.latestTimestamp;
    this.oauthSignature = this.latestSignature;

    this.changeDetectorRef.detectChanges();

    this.kortextForm.nativeElement.submit();
  }

  public completeFormDataWithTimeStampAndSignature(): void {
    this.setTimeStamp();
    this.generateSignature();
  }

  public generateSignature(): void {
    this.loadingData = true;

    let ltiForm = new KortextLtiForm(this.contextLabel, this.contextTitle,
      this.hasCustomId() ? this.customId : null,
      this.hasCustomPage() ? this.customPage : null,
      this.email, this.fullName, this.oauthConsumerKey,
      this.latestTimestamp == null ? "" : this.latestTimestamp.toString());

    this.kortextOAuthService.generateOAuthSignature(this.configurationHelperService.getApiServerUri(), ltiForm)
      .subscribe(
        (response: OperationResponse<LtiResponse>) => {

          if (response.operationSucceeded) {
            this.latestSignature = response.data.signature;
            this.loadingData = false;
            this.submitKortextForm();
          }
          else {
          }
        },
        error => {
          console.error('There has been an error accessing the data service : ' + error, 'Data Access Error');
        }
      );
  }

  public getButtonText(): string {
    return this.hasNoBookInfo() ? 'Get your ebooks' : 'Read online'
  }

  public hasNoBookInfo(): boolean {
    return (this.activityItem.bookId === null && this.activityItem.pageNumber === null && this.activityItem.bookTitle === null);
  }

  public getApplicationContext() {
    this.applicationContext.getCurrentCourse().subscribe(
      x => {
        if (!x) {
          this.kortextOAuthService.notifyApplicationError('It looks like you haven not selected your current course from the dashboard', 'Something went wrong');
          return;
        }
        this.contextLabel = x.courseCode;
        this.contextTitle = x.courseName;


      }, error => {
        this.kortextOAuthService.notifyApplicationError('You might not be able to read your book online at this time. Please try again later', 'Something went wrong');
        this.loadingData = false;
      }, () => {

      }
    );
  }

}