import { BaseLtiForm } from "@app/common/lti/base-lti-form";

export class CourseraLtiForm extends BaseLtiForm {
    constructor(public lisPersonNameFull: string,
        public lisPersonContactEmailPrimary: string,
        public oauthConsumerKey: string,
        public oauthTimestamp: string,
        public oauthNonce: string,
        public oauthSignatureMethod: string) {
            super(oauthConsumerKey, oauthTimestamp)
        }
}