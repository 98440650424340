<div class="Card">
  <div *ngIf="isLoading">
    <h3>The game is loading. Please wait...</h3>
    <p class="card-text">to move keys: WSAD To take an Item:T</p>
  </div>
  <div *ngIf="isAnalysis">
    <app-analysis (nextChallengeEvent)="ChallengeClicked()" [products]="products"></app-analysis>
  </div>
  <ebs-unity-web-gl (unityStartedEvent)="unityStartedEvent($event)" (unityCheckoutEvent)="unityCheckoutEvent($event)"
    #unityView></ebs-unity-web-gl>
</div>