<div class="d-flex flex-row flex-wrap">
  <span class="font-weight-bold">{{boldText}}</span>
  <span>{{nonBoldText}}</span>
  <span class="pl-2 pr-2">
    <div class="user-avatar-container">
      <img alt="user avatar" src="data:image/jpg;base64,{{userAvatar}}" class="avatar-profile rounded-circle"
        *ngIf="userAvatar" />
      <img alt="user avatar" src="../../../../assets/img/avatar-temp.svg" class="avatar-profile rounded-circle"
        *ngIf="!userAvatar" />
    </div>
  </span>
  <span class="pr-1"> {{displayName}} <span *ngIf="isFaculty"> (Faculty) </span> </span>
  <span class="pr-1"> {{displayDate}} </span>
</div>