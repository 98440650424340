import { Component, OnInit } from '@angular/core';
import { Input } from '@angular/core';
import { CourseDiscussionEngagementStatistics } from '@app/courses/models/discussion/course-discussion-engagement-statistics';


@Component({
  selector: 'discussion-engagement-statistics-details',
  templateUrl: './discussion-engagement-statistics-details.component.html',
  styleUrls: ['./discussion-engagement-statistics-details.component.scss']
})

export class DiscussionEngagementStatisticsDetailsComponent implements OnInit {

  @Input()
  public moduleDiscussionEngagementStatistics: CourseDiscussionEngagementStatistics = new CourseDiscussionEngagementStatistics();

  constructor() { }

  ngOnInit() {
  }
}
