import {
  trigger, transition, animate, style, state, keyframes
} from '@angular/animations';

export const transAnimation = trigger('isCollapsedAnimation', [
  state('true', style({
    maxHeight: '0px',
    visibility: 'hidden',
    opacity: 0
  })),
  state('false', style({
    maxHeight: '800px',
    visibility: 'visible',
    opacity: 1
  })),
  transition('* => *', [animate('1000ms ease-in-out')])
]);

export const enterLeave = trigger('showOnEnter', [
  transition(
    ':enter', [
      style({ height: 0, opacity: 0 }),
      animate('500ms', style({ height: '*', opacity: 1 }))
    ]),
  transition(
    ':leave', [
      style({ height: '*', opacity: 1 }),
      animate('500ms', style({ height: 0, opacity: 0 })),
    ])
]);

export const showOpacity = trigger('increaseOpacity', [
  transition(
    ':enter', [
      style({ opacity: 0 }),
      animate('500ms', style({ opacity: 1 }))
    ]),
  transition(
    ':leave', [
      style({ opacity: 1 }),
      animate('500ms', style({ opacity: 0 })),
    ])
]);

export const enterOrLeave = trigger('enterOrLeave', [
  transition(
    ':enter', [
      style({ opacity: 0, transform: 'translateY(-20%)', 'margin-bottom': '20px' }),
      animate('300ms ease-in-out',
        style({ opacity: 1, transform: 'translateY(0)' }))
    ]),
]);

// create own easing cubic-bezier(0.680, -0.550, 0.265, 1.550)
export const offlineTriggered = trigger('offlineTriggered', [
  transition(
    ':enter', [
      style({ opacity: 0 }),
      animate('2000ms ease-in-out', keyframes([
        style({ opacity: 1, offset: 0.2 }),
        style({ opacity: 0, offset: 0.3 }),
        style({ opacity: 1, offset: 0.4 }),
        style({ opacity: 0, offset: 0.5 }),
        style({ opacity: 1, offset: 0.6 }),
        style({ opacity: 0, offset: 0.7 }),
        style({ opacity: 1, offset: 0.8 }),
        style({ opacity: 0, offset: 0.9 }),
        style({ opacity: 1, offset: 1 })
      ]))
    ]),
]);

export const progress = trigger('progress', [
  state('true', style({ opacity: 0, transform: 'translateX(-100%)' })),
  state('false', style({ opacity: 1, transform: 'translateX(0)' })),
  transition('* => *', [animate('1000ms ease-in-out')])
]);

export const pulse = trigger('pulse', [
  transition('* => *', animate(
    '2000ms',
    keyframes([
      style({ transform: 'scale(1)', offset: 0 }),
      style({ transform: 'scale(1.1)', offset: 0.1 }),
      style({ transform: 'scale(1)', offset: 0.2 }),
      style({ transform: 'scale(1)', offset: 0.3 }),
      style({ transform: 'scale(1.1)', offset: 0.4 }),
      style({ transform: 'scale(1)', offset: 0.5 }),
      style({ transform: 'scale(1)', offset: 0.6 }),
      style({ transform: 'scale(1.1)', offset: 0.7 }),
      style({ transform: 'scale(1)', offset: 0.8 }),
      style({ transform: 'scale(1)', offset: 0.9 }),
      style({ transform: 'scale(1)', offset: 1.0 })
    ])
  ))
]);

export const snapshotTransition = trigger('snapshotTransition', [
  state('true', style({
    opacity: '1',
    overflow: 'hidden',
    height: '*'
  })),
  state('false', style({
    opacity: '0',
    overflow: 'hidden',
    height: '70px'
  })),
  transition('true => false', animate('50ms ease-in-out')),
  transition('false => true', animate('50ms ease-in-out'))
]);

export const transformButton = trigger('transformButton', [
  transition(
    ':enter', [
      style({ opacity: 0, transform: 'translateY(-30%)', 'margin-bottom': '20px' }),
      animate('600ms ease-in-out',
        style({ opacity: 1, transform: 'translateY(0)' }))
    ]),
]);

export const flasher = trigger('flasher', [
  state('invisible', style({ opacity: '0', visibility: 'hidden' })),
  state('visible', style({ opacity: '1', visibility: 'visible' })),
  transition('invisible <=> visible', animate('750ms linear'))
])