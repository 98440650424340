<div *ngIf="isOffline">
  <div class="img-placeholder">
    <img src="../../../../assets/img/placeholder.svg" />
  </div>
  <ebs-offline-message [isOffline]='isOffline' [offlineMessage]='offlineMessage'></ebs-offline-message>
</div>

<ebs-spinner [spinnerMessage]="'loading..'" [faColour]="'#999'" [boxColour]="'#999'" *ngIf="getting3rdParty"
  [boxPadding]="'5px 15px'"></ebs-spinner>

<div class="card-body" *ngIf="!getting3rdParty && !isOffline">
  <div class="row">
    <div class="col-sm-12 hidden-print">
      <iframe class="embed-responsive-item embed-responsive-16by9 ebs-media-response  hidden-print" *ngIf="activityItem.videoUrl"
        [src]="activityItem.videoUrl | domSanitizerPipe: 'resourceUrl'" allowfullscreen="" title="3rd party media iframe"></iframe>
    </div>
    <div style="display: none;" class="d-none d-print-block img-placeholder">
      <p>
        <a [href]="activityItem.sanitisedVideoUrl">
          <img src="../../../../assets/img/placeholder.svg" />
        </a>
      </p>
      <div style="display: none;" class="d-none d-print-block">
        <a [href]="activityItem.sanitisedVideoUrl">{{rawUrl}}</a>
      </div>
    </div>
    <div class="col-sm-12 offline-text">
      <ebs-message-simple [iconCssClasses]="'fas fa-exclamation-circle fa-lg'" [textCssClasses]="''" *ngIf="!isOffline"
        [displayMessage]="onlineMessage"></ebs-message-simple>
      <ebs-message-simple [iconCssClasses]="'fas fa-exclamation-circle fa-lg'" [textCssClasses]="''" *ngIf="isOffline"
        [displayMessage]="offlineMessage"></ebs-message-simple>
    </div>
  </div>
</div>