
import { Injectable } from '@angular/core';
import Dexie from 'dexie';

@Injectable()
export class IndexedDbService extends Dexie {
    constructor() {
        super('LearningPlatform');
        this.version(1).stores({
            storableObjects: 'uri, version, courseCode, moduleCode, sectionCode, isAwaitingSynchronization',
        });
    }
}