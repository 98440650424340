import { Component, OnInit, OnDestroy } from '@angular/core';
import { OfflineModeStatusService } from '@app/core/offline-mode-status.service';
import { transformButton } from '@app/common/animations/animations';
import { ApplicationEventDispatcher } from '@app/core/application-event-dispatcher.service';
import { ApplicationEvent } from '@app/core/application-event';
import { ApplicationEventType } from '@app/core/application-event-type';
import { ModalSimpleService } from '../modal-simple/modal-simple.service';
import { UserService } from '@app/common/security/user.service';
import { StaticText } from '@app/common/static-text';
import { CourseNavigationService } from '@app/courses/services/course-navigation.service';
import { ApplicationContext } from '@app/common/current-context/application-context';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { ClearCacheService } from '@app/core/clear-cache.service';
import { ObjectStoreBase } from '@app/core/object-store-base';

@Component({
  selector: 'ebs-offline-mode',
  templateUrl: './offline-mode.component.html',
  styleUrls: ['./offline-mode.component.scss'],
  animations: [transformButton]
})
export class OfflineModeComponent implements OnInit, OnDestroy {
  offlineEventSubscription: Subscription;
  hasAccepted = false;
  offlineEnabled = false;
  disablingOffline = false;
  dialogTitle = 'Delete Confirmation';
  dialogBody = StaticText.offlineDeleteConfirmation;
  currentCourse: string;

  constructor(
    private offlineModeStatusService: OfflineModeStatusService,
    private modalSimpleService: ModalSimpleService,
    private userService: UserService,
    private applicationEventDispatcher: ApplicationEventDispatcher,
    private courseNavigationService: CourseNavigationService,
    private appContext: ApplicationContext,
    private clearCacheService: ClearCacheService,
    private objectStore: ObjectStoreBase,
  ) {

    this.offlineEventSubscription = this.applicationEventDispatcher.offlineModeRelatedEvent.subscribe((event: ApplicationEvent) => {
      if (event.type === ApplicationEventType.IndexedDbCleared) {
        this.disablingOffline = false;
        this.offlineEnabled = false;
        this.applicationEventDispatcher.processOfflineModeRelatedEvent(ApplicationEventType.OfflinePopupClosed);
        //need to get course structure again (if have menu!)
        if (this.currentCourse) {
          this.courseNavigationService.getCourseStructure(this.currentCourse, false).subscribe(
            courseResponse => {
              this.courseNavigationService.setLocalCourseStructure(this.currentCourse, courseResponse.data).subscribe(
                res => {
                }
              )
            },
            err => { console.log('Error getting course structure offline comp: ', err) }
          ) // have to get new from api
        }
      }
    });
  }

  ngOnInit() {
    this.getEnableOfflineStatus();
  }

  ngOnDestroy(): void {
    this.offlineEventSubscription.unsubscribe();
  }

  enableOffline() {
    this.appContext.getCurrentCourse().subscribe(
      currentCourse => {
        this.emptyCache();
        if (!currentCourse) {
          // if we have never visited a course we won't have a course code in indexed db! can only happen on dashboard
          this.enableOfflineMode();
        } else {
          // depending where we are in the application we may need the course structure so we need to reset it or it will cause an error
          this.currentCourse = currentCourse.courseCode
          this.courseNavigationService.getCourseStructure(this.currentCourse, false).subscribe(
            courseResponse => {
              this.courseNavigationService.setLocalCourseStructure(this.currentCourse, courseResponse.data).subscribe(
                res => {
                  this.enableOfflineMode();
                }
              )
            },
            err => {
              console.log('Error getting course structure offline comp: ', err)
              this.enableOfflineMode();
            }
          )
        }
      } // end of appContext.getCurrentCourse
    )
  }

  enableOfflineMode() {
    this.offlineModeStatusService.enable().subscribe(
      response => {
        this.offlineEnabled = response.isEnabled;
        this.applicationEventDispatcher.processOfflineModeRelatedEvent(ApplicationEventType.OfflinePopupClosed);
        this.disablingOffline = false;
        this.offlineEnabled = true;
        this.userService.setUserProfileLocally(false, false, true);
      },
      error => {
        console.log("error enableOffline", error)
      }
    )
  }

  emptyCache() {
    this.objectStore.getAllPrimaryKeys()
      .then((retrievedKeys: string[]) => this.clearCacheService.clearCache2(retrievedKeys, true),
        reason => console.log('Retrieving all cached items failed for: ' + reason))
      .catch(console.error);
  }

  getEnableOfflineStatus() {
    this.offlineModeStatusService.isEnabled().subscribe(
      response => {
        this.offlineEnabled = response;
        this.hasAccepted = response; //these arent mutually exclusive so must be both true or both false
      },
      error => {
        console.log("error getEnableOfflineStatus", error)
      }
    )
  }

  disableOffline() {
    this.appContext.getCurrentCourse().subscribe(
      currentCourseResponse => {
        if (currentCourseResponse) {
          this.currentCourse = currentCourseResponse.courseCode
        }
        this.disablingOffline = true;
        this.offlineModeStatusService.disable();
      },
      error => {
        console.log('Error getting current course: ' + error)
      }
    )
  }

  switchClick() {
    // checkboxes are the opposite    
    if (this.hasAccepted && this.offlineEnabled) {
      this.modalSimpleService.confirm(
        this.dialogTitle,
        this.dialogBody,
        [{ buttonText: 'No', buttonStyle: 'btn btn-secondary float-right btn-block mt-0' }, 
        { buttonText: 'Yes', buttonStyle: 'btn btn-primary float-right btn-block mt-0 ml-2' }])
        .subscribe((answer) => {
          if (answer === 'Yes') {
            this.offlineEnabled = false;
            this.disableOffline();
            console.log('raise event to delete users stuff')
          } else {
            this.hasAccepted = true;
          }
        });
    }
  }
}
