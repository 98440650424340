import { Component, OnInit, Inject, HostListener, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { StaticText } from '@app/common/static-text';
import { UserService } from '@app/common/security/user.service';
import { UserProfile, CourseEnrolment, UserRole } from '@app/common/models/user';
import { OnlineStatusService } from '@app/common/online-status/online-status.service';
import { OnlineStatusType } from '@app/common/online-status/online-status-type.enum';
import { Subscription } from 'rxjs';
import { DownloadsService } from '@app/downloads/services/downloads.service';
import { CourseStructure } from '@app/courses/models/course-structure';
import { Module } from '@app/courses/models/module';
import { ConfigurationHelperService } from '@app/common/app-configuration/configuration-helper.service';
import { DashboardService } from './dashboard.service';
import { ApplicationEventDispatcher } from '@app/core/application-event-dispatcher.service';
import { ApplicationEventType } from '@app/core/application-event-type';
import { Title } from '@angular/platform-browser';
import { ApplicationEvent } from '@app/core/application-event';
import { ModalCourseRegistrationService } from '@app/common/components/course-registration/modal-course-registration.service';

@Component({
  selector: 'ebs-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit, OnDestroy {
  loadingCourses = false; loadingHeaderFooter = false;
  checkingOfflineStatus = true;
  courseModuleLoading = false;
  userProfile: UserProfile;
  isOffline = false;
  theSubscription: Subscription;
  downloadsSubscription: Subscription;
  listOfDownloadedCourses: CourseStructure[] = [];
  courseEnrolments: CourseEnrolment[] = [];
  placeholderLinkBefore: string;
  placeholderLinkAfter: string;
  header: string;
  footer: string;
  useCompressedLayout: boolean;
  userPreferredName: string;
  isDemoUser = false;

  constructor(
    private titleService: Title,
    private router: Router,
    private userService: UserService,
    private onlineStatusService: OnlineStatusService,
    private downloadsService: DownloadsService,
    private configurationHelperService: ConfigurationHelperService,
    private dashboardService: DashboardService,
    private applicationEventDispatcher: ApplicationEventDispatcher,
    private modalcourseRegistrationService: ModalCourseRegistrationService,
  ) {
    this.onlineStatusService.isOffline().subscribe(isOffline => {
      this.isOffline = isOffline;
      if (isOffline) {
        // carry on as normal
        this.checkingOfflineStatus = false;
      } else {
        // redirect to student portal as this is the dashboard for now!
        if (this.configurationHelperService.getStudentDashboardRedirectIsOn()) {
          window.open(this.configurationHelperService.getStudentDashboardUri(), '_self');
        } else {
          this.checkingOfflineStatus = false;
        }
      }
    }, error => {
      this.isOffline = false;
    });

    this.theSubscription = this.onlineStatusService.status.subscribe(
      event => {
        if (event === OnlineStatusType.OFFLINE) {
          this.isOffline = true;
        } else {
          // if going back online and we are on the dashboard we need to redirect to the student portal
          if (this.configurationHelperService.getStudentDashboardRedirectIsOn()) {
            window.open(this.configurationHelperService.getStudentDashboardUri(), '_self');
          }
          this.isOffline = false;
        }
      }
    );

    applicationEventDispatcher.authorisationEvent.subscribe((status: ApplicationEvent) => {
      if (status.type === ApplicationEventType.Authorised) { this.refreshHeaderAndFooter(); }
    });
  }

  ngOnInit() {
    this.titleService.setTitle('Programme dashboard');
    this.loadingCourses = true;
    this.courseModuleLoading = true;
    // this.getPlaceholderContent();
    this.userService.getCurrentUser().subscribe(x => {
      this.userProfile = x;
      this.courseEnrolments = x.enrolmentDetails.courseEnrolments;
      this.sortCourseEnrolments();

      if (x.userAccount !== null && x.userAccount.roles !== null) {
        x.userAccount.roles.find(x => x.toString() == "Demo") ? this.isDemoUser = true : this.isDemoUser = false;
      }
      if (x.personalDetails.preferredName) {
        this.userPreferredName = x.personalDetails.preferredName;
      }
      this.useCompressedLayout = this.courseEnrolments.length > 12;
      this.refreshHeaderAndFooter();

      this.downloadsSubscription = this.downloadsService.getAllCourseStructures().subscribe(
        courseStructureListResponse => {
          if (courseStructureListResponse && courseStructureListResponse.length > 0) {
            this.listOfDownloadedCourses = courseStructureListResponse;
            this.listOfDownloadedCourses.forEach((course) => {
              const index = this.courseEnrolments.findIndex(x => x.courseCode.toLowerCase() === course.courseCode.toLowerCase());
              if (index !== -1) {
                const hasModulesDownloaded = this.workoutNumberOfDownloaded(course.modules);
                hasModulesDownloaded > 0 ? this.courseEnrolments[index].isDownloaded = true : this.courseEnrolments[index].isDownloaded = false;
                this.courseEnrolments[index].numberOfDownloadedModules = hasModulesDownloaded;
                this.courseEnrolments[index].isAvailableOffline = true;
              }
            });
          }
          this.courseModuleLoading = false;
        },
        error => {
          console.log('problem working out which courses have downloaded modules: ', error);
          this.courseModuleLoading = false;
        }
      );

      this.loadingCourses = false;
    },
      (error) => {
        console.log('problem getting current user: ', error);
        this.courseModuleLoading = false;
        this.loadingCourses = false;
      });
  }

  private sortCourseEnrolments(){
    if (!this.courseEnrolments || this.courseEnrolments.length == 0) return;

    this.courseEnrolments.sort((a:CourseEnrolment, b: CourseEnrolment) => {
      if (a.studentIsRegistered && b.studentIsRegistered)
        return 0;
      if (a.studentIsRegistered && !b.studentIsRegistered)
        return -1;
      if (!a.studentIsRegistered && b.studentIsRegistered)
        return 1;

      return 0;
    });
  }

  private refreshHeaderAndFooter() {
    this.loadingHeaderFooter = true;
    this.dashboardService.getDashboardText().subscribe(
      response => {
        this.loadingHeaderFooter = false;
        this.header = response.data.header;
        this.footer = response.data.footer;
      }, error => {
        this.loadingHeaderFooter = false;
        console.log('Problem loading dashboard text: ', error);
      });
  }

  workoutNumberOfDownloaded(courseModules: Module[]): number {
    let count = 0;
    courseModules.forEach(mod => {
      if (mod.isContentDownloaded) {
        count++;
      }
    });

    return count;
  }

  registrationRequiredButtonMessage(enrolment:CourseEnrolment): string {
    if (!enrolment) return '';

    if (enrolment.studentIsRegistered) return '';

    if (enrolment.studentCanRegister) return 'Start studying';

    return 'When can I start studying?'
  }

  registrationRequiredButtonClass(enrolment:CourseEnrolment): string {
    if (!enrolment) return '';

    if (enrolment.studentIsRegistered) return '';

    if (enrolment.studentCanRegister) return 'btn-secondary';

    return 'btn-light';
  }

  registrationRequiredOpenPopup(enrolment:CourseEnrolment){

    if (!enrolment) return;

    this.modalcourseRegistrationService.confirm(enrolment)
    .subscribe((answer:CourseEnrolment[]) => {

      if (answer != null && answer.length == this.courseEnrolments.length){
        this.courseEnrolments = answer;
        this.sortCourseEnrolments();
      }

    }, err => {

    });
  }

  ngOnDestroy(): void {

    if (this.theSubscription) {
      this.theSubscription.unsubscribe();
    }

    if (this.downloadsSubscription) {
      this.downloadsSubscription.unsubscribe();
    }
  }
}

