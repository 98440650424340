import { Component, OnInit, Input, EventEmitter, Output, HostListener, ViewChild } from '@angular/core';
import { showOpacity, enterLeave } from '@app/common/animations/animations';
import { UserRole, UserProfile } from '@app/common/models/user';
import { Helpers } from '@app/common/helpers';
import { NotificationSummary } from '@app/common/models/notification-summary';
import { Subscription } from 'rxjs';
import { DiscussionLike } from '../../models/like';
import { OnlineStatusService } from '@app/common/online-status/online-status.service';
import { OnlineStatusType } from '@app/common/online-status/online-status-type.enum';
import { DiscussionPostOrReplyItem } from '../../models/discussion-postorreply-item';
import { ModalSimpleService } from '@app/common/components/modal-simple/modal-simple.service';
import { SeverityLevel } from '@app/common/models/severity-level';
import { WysiwygComponent } from '../wysiwyg/wysiwyg.component';
import { DiscussionService } from '../../services/discussion.service';

@Component({
  selector: 'ebs-discussion-postorreply-item',
  templateUrl: './discussion-postorreply-item.component.html',
  styleUrls: ['./discussion-postorreply-item.component.scss'],
  animations: [showOpacity, enterLeave]
})
export class DiscussionPostOrReplyItemComponent implements OnInit {
  @Input() discussionPostOrReplyItem: DiscussionPostOrReplyItem;
  @Input() postDetails: DiscussionPostOrReplyItem;
  @Input() defaultPostShowHideStatus = false;
  @Input() isAReply = false;
  @Input() currentUser: UserProfile;
  @Input() postDisplayIndex: number;
  @Output() bubbleReplacePostUpdatedLikeEvent = new EventEmitter<DiscussionPostOrReplyItem>();
  @Output() deletedPostOrReplyEvent = new EventEmitter<DiscussionPostOrReplyItem>();
  @Output() bubbleReplyPostedEvent = new EventEmitter<string>();

  newLike: DiscussionLike;
  dataBeingDeleted = false; postingData = false;
  isItEditMode = false; showReply = false;
  dialogTitle = 'Delete Confirmation'; dialogBody = 'Are you sure you want to proceed?';
  showError = false; isOffline = false;
  isMobile = Helpers.isItMobile(window.innerWidth);
  notifications: NotificationSummary[];
  theSubscription: Subscription;
  @ViewChild(WysiwygComponent, { static: false }) child: WysiwygComponent;
  showMoreInfo = true;
  snapshotText: string;


  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.isMobile = Helpers.isItMobile(window.innerWidth);
  }

  canDeactivate(): boolean {
    if (this.child) {
      if (!this.child.canDeactivate()) return false;
    }
    return true
  };

  constructor(
    private discussionService: DiscussionService,
    private onlineStatusService: OnlineStatusService,
    private modalSimpleService: ModalSimpleService,
  ) {
    this.onlineStatusService.isOffline().subscribe(x => {
      this.isOffline = x;
    }, error => {
      this.isOffline = false;
    });

    this.theSubscription = this.onlineStatusService.status.subscribe(
      event => {
        this.isOffline = (event === OnlineStatusType.OFFLINE) ? true : false
      }
    )
  }

  canDelete(): boolean {
    if (this.currentUser == null) return false;

    const canDeleteRole = this.currentUser.userAccount.roles
      .find(x => x === UserRole.Faculty || x === UserRole.PortalAdministrator);

    return canDeleteRole != null;
  }

  getMyLike(): DiscussionLike {
    if (this.currentUser == null || this.currentUser.userAccount == null) return null;
    if (this.discussionPostOrReplyItem.likedByMe) {
      //find the like
      this.newLike = this.discussionPostOrReplyItem.likes.find(like => like.person.personId === this.currentUser.userAccount.username);
    } else {
      this.newLike = new DiscussionLike();
    }

    this.newLike.likedByMe = this.discussionPostOrReplyItem.likedByMe;
    // if replies don't exist (not empty) then is a reply else a post
    if (this.discussionPostOrReplyItem.replies) {
      this.newLike.postId = this.discussionPostOrReplyItem.id;
    } else {
      this.newLike.replyId = this.discussionPostOrReplyItem.id;
      this.newLike.postId = this.discussionPostOrReplyItem.postId;
    }
    this.newLike.numberOfLikes = this.discussionPostOrReplyItem.likeCount;
    this.newLike.discussionId = this.discussionPostOrReplyItem.discussionId;

    return this.newLike
  }

  ngOnInit() {
    this.workoutSnapshotText()
  }

  workoutSnapshotText() {
    if (this.discussionPostOrReplyItem.content.length > 275) {
      this.snapshotText = this.discussionPostOrReplyItem.content.substring(0, 275) + '... ';
    } else {
      this.snapshotText = this.discussionPostOrReplyItem.content
    }
  }

  handleCancelled(e) {
    this.showReply = false;
  }

  deleteComment() {
    this.dataBeingDeleted = true;
    this.showError = false;
    this.discussionService.deleteComment(this.discussionPostOrReplyItem.uri, this.isOffline).subscribe(
      response => {
        if (!response.operationSucceeded) {
          this.postingData = false;
          this.showError = true;
          this.notifications = response.notifications;
        } else {
          if (this.discussionPostOrReplyItem.replies == null) {
            // is a reply so delete from original post and send original up the chain to update
            this.postDetails.replies.splice(this.postDetails.replies.findIndex(post => post.id === this.discussionPostOrReplyItem.id), 1);
          }
          this.deletedPostOrReplyEvent.emit(this.discussionPostOrReplyItem);
          this.dataBeingDeleted = false;
        }
      },
      error => {
        this.dataBeingDeleted = false;
        this.showError = true;
        this.notifications.push(new NotificationSummary('Deleting comment failed', 'unknown', SeverityLevel.Error, 'unknown', 0))
      },
      () => { }
    );
  }

  confirm() {
    this.modalSimpleService.confirm(
      this.dialogTitle,
      this.dialogBody,
      [{ buttonText: 'No', buttonStyle: 'btn btn-secondary float-right btn-block' },
      { buttonText: 'Yes', buttonStyle: 'btn btn-primary float-right btn-block mt-0 ml-2' }])
      .subscribe((answer) => {
        if (answer === 'Yes') {
          this.deleteComment();
        }
      });
  }

  handleLikeBubbleEvent(updatedLike: DiscussionLike) {
    this.discussionPostOrReplyItem.likes.forEach((theLike, index) => {
      //G.M. as likes create a new like row everytime we need to delete the front end item
      if (!theLike.isActive) {
        this.discussionPostOrReplyItem.likes.splice(index, 1)
      }
    })
    //update the post and send up the chain
    let likeIndex = this.discussionPostOrReplyItem.likes.findIndex(l => l.id === updatedLike.id)
    if (likeIndex > -1) {
      this.discussionPostOrReplyItem.likes[likeIndex] = updatedLike;
    } else {
      this.discussionPostOrReplyItem.likes.push(updatedLike);
    }
    this.discussionPostOrReplyItem.likedByMe = updatedLike.likedByMe;
    this.discussionPostOrReplyItem.likeCount = updatedLike.numberOfLikes;
    this.newLike = updatedLike; //need to replace the like
    this.bubbleReplacePostUpdatedLikeEvent.emit(this.discussionPostOrReplyItem);
  }
}
