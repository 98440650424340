import { StorableObject } from "@app/core/storable-object";
import { KeyValuePair } from "@app/common/models/key-value-pair";

export class CourseModulePartials extends StorableObject {
    partialModules: KeyValuePair<number, ModulePartialInfo>[];    
}

export class ModulePartialInfo{
    date: Date;
    isVideoDownload: boolean;
}