import { NgModule, Optional, SkipSelf, ModuleWithProviders } from '@angular/core';

import { ServiceBase } from '@app/core/service-base';



// See: https://angular.io/guide/ngmodule-faq#coremodule
@NgModule({
    exports: [],
    imports: [],
    providers: [],
    bootstrap: []
})
export class CoreModule {
    constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
        if (parentModule) {
            throw new Error('CoreModule is already loaded. Import it in the AppModule only');
        }
    }

    static forRoot(): ModuleWithProviders<CoreModule> {
        return {
          ngModule: CoreModule,
          providers: [ ServiceBase ]
        };
      }
}
