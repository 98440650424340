import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { DashboardComponent } from './dashboard/dashboard.component';
import { CoursePageComponent } from './course-page/course-page.component';
import { LoginComponent } from '@app/authentication/login/login.component';
import { LoginOfflineComponent } from './authentication/login-offline/login-offline.component';
import { LogoutComponent } from './authentication/logout/logout.component';
import { AuthGuard } from '@app/common/security/auth-guard';
import { CourseAdminComponent } from './admin/components/course-admin/course-admin.component';
import { AuthGuardPortalAdministrator } from './common/security/auth-guard-portal-administrator';
import { SystemNotificationComponent } from './admin/components/system-notification/system-notification.component';
import { CanDeactivateGuard } from './common/security/can-deactivate.guard';

export const routes: Routes = [
  { path: '', redirectTo: 'dashboard', pathMatch: 'full' },
  { path: 'dashboard', component: DashboardComponent, canActivate: [AuthGuard], canDeactivate: [CanDeactivateGuard] },
  { path: 'courses/:courseCode', redirectTo: 'courses/:courseCode/modules/0/sections/0' },
  { path: 'courses/:courseCode/activities/faculty', component: CoursePageComponent, canDeactivate: [CanDeactivateGuard] },
  { path: 'courses/:courseCode/activities', component: CoursePageComponent, canDeactivate: [CanDeactivateGuard] },
  { path: 'courses/:courseCode/modules/:moduleId', component: CoursePageComponent, canActivate: [AuthGuard] },
  { path: 'courses/:courseCode/modules/:moduleId/sections/:sectionId', component: CoursePageComponent, canActivate: [AuthGuard], canDeactivate: [CanDeactivateGuard] },
  { path: 'courses/:courseCode/ebooks', component: CoursePageComponent, canActivate: [AuthGuard] },
  { path: 'authentication', component: LoginComponent },
  { path: 'authentication/login-offline', component: LoginOfflineComponent },
  { path: 'authentication/logout', component: LogoutComponent },
  { path: 'courses/:courseCode/printable-materials', component: CoursePageComponent, canActivate: [AuthGuard] },
  { path: 'course-admin', component: CourseAdminComponent, canActivate: [AuthGuard, AuthGuardPortalAdministrator] },
  { path: 'system-notifications', component: SystemNotificationComponent, canActivate: [AuthGuard, AuthGuardPortalAdministrator] },
];
@NgModule({
  exports: [RouterModule],
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled', anchorScrolling: 'enabled', relativeLinkResolution: 'legacy' })]
})
export class AppRoutingModule { }
