import { OnChanges, Component, OnInit, Input } from '@angular/core';
import { NotificationSummary } from '@app/common/models/notification-summary';
import { Router } from '@angular/router';

@Component({
  selector: 'ebs-notification-multiple',
  templateUrl: './notification-multiple.component.html'
})
export class NotificationMultipleComponent implements OnInit, OnChanges { 
  @Input() notifications: NotificationSummary[];

  constructor(private router:Router) { }

  ngOnInit() {
  }

  ngOnChanges(changes: import("@angular/core").SimpleChanges): void {
    if(this.notifications!=undefined){
      for(let notification of this.notifications){
        if(notification.message==="Sorry - it looks as if you are not logged in or your session has timed out. Please click the 'LOG OUT' button, then log back in."){
          this.router.navigate(['/authentication']);
        }
      }
    }
  }
}
