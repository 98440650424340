import { ReturnStatement } from '@angular/compiler';
import { Injectable } from '@angular/core';
import { OperationResponse } from '@app/common/models/operation-response';
import { CourseEnrolment } from '@app/common/models/user';
import { ServiceBase } from '@app/core/service-base';
import { Observable, throwError } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CourseRegistrationService extends ServiceBase {
  
  public RegisterStudent(courseEnrolment:CourseEnrolment):Observable<OperationResponse<CourseEnrolment>> {

    if (courseEnrolment == null) return throwError('Something went wrong; please try again later.');
    
    let uri = `${this.getApiServerUri().toLowerCase()}courses/${courseEnrolment.courseCode}/register`;
    
    return this.postObjectToRemoteStoreOnly(courseEnrolment, uri);

  }
  
}
