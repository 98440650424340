import { Component, OnInit, Input } from '@angular/core';
import { CourseDiscussionEngagementStatistics } from '@app/courses/models/discussion/course-discussion-engagement-statistics';
import { ModalSimpleService } from '@app/common/components/modal-simple/modal-simple.service';


@Component({
  selector: 'discussion-engagement',
  templateUrl: './discussion-engagement.component.html',
  styleUrls: ['./discussion-engagement.component.scss']
})
export class DiscussionEngagementComponent implements OnInit {
  constructor(private modalSimpleService: ModalSimpleService) { }

  @Input()
  public moduleDiscussionEngagementStatistics: CourseDiscussionEngagementStatistics = new CourseDiscussionEngagementStatistics();
  public statistic = 0;

  //sum of all discussions points awarded / points available.
  ngOnInit() {
    if (this.moduleDiscussionEngagementStatistics) {
       this.moduleDiscussionEngagementStatistics.getMark();
       this.statistic = Number((this.moduleDiscussionEngagementStatistics.totalAwardedScore / this.moduleDiscussionEngagementStatistics.totalScore).toFixed(2));
       
    }
  }

  showModal(){
    this.modalSimpleService.confirm("Discussion Calculation","<h3>How is your discussion participation calculated?</h3> <p>This reflects the extent to which you are participating " +
       "in course discussions with tutors and fellow students. Discussions can help you reflect on a topic, and provide an opportunity to engage with a unique global network of fellow practitioners.</p>" +
       "<p>Your discussion participation is for information only and should not be seen as a predictor of academic achievement (e.g. exam grades).</p>" +
       "<h3>Your discussion participation</h3> <p>The level you are awarded is based on how you are participating in discussions and the number of discussions you have taken part in so far.</p>"+
       "<p>Gold level is awarded for a discussion if you make at least one post or reply to a majority of discussions.</p>" +
       "<p>The other levels (Silver, Bronze and Entry) are based on partial completion of these conditions.</p><p>If you do not participate in a discussion (post, reply or like), you will receive a score" +
       "of zero for that discussion when you mark the relevant section as complete.</p><h3>Discussion participation per module</h3><p>Your discussion participation per module is calculated in the same way" +
       " as your overall discussion participation but includes only discussions within that module.</p>",[{ buttonText: 'OK', buttonStyle: 'btn btn-secondary float-right btn-block' }]);
  }

}
