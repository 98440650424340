import { Component, OnInit, OnDestroy } from '@angular/core';
import { transformButton } from '@app/common/animations/animations';
import { UserService } from '@app/common/security/user.service';
import { StaticText } from '@app/common/static-text';
import { ObjectStoreBase } from '@app/core/object-store-base';
import { CourseEnrolment, UserProfile } from '@app/common/models/user';
import { BsModalRef } from 'ngx-bootstrap';
import { CourseRegistrationService } from './course-registration.service';
import { OperationResponse } from '@app/common/models/operation-response';


@Component({
  selector: 'ebs-course-registration',
  templateUrl: './course-registration.component.html',
  styleUrls: ['./course-registration.component.scss'],
  animations: [transformButton]
})
export class CourseRegistrationComponent implements OnInit, OnDestroy{
  hasErrors: boolean;
  hasAcknowledgedCourseRegistrationTerms = false;
  
  courseEnrolment : CourseEnrolment;

  dialogBody = StaticText.offlineDeleteConfirmation;

  registering : boolean = false;

  answer : CourseEnrolment[];

  constructor(
    private userService: UserService,
    private objectStore: ObjectStoreBase,
    public bsModalRef: BsModalRef,
    private courseRegistrationService : CourseRegistrationService
  ) {    
  }

  ngOnInit() {
    this.hasErrors = false;
  }
  

  isEligibileToRegister(): boolean{
    if (this.courseEnrolment == null 
      || this.courseEnrolment.studentIsRegistered 
      || !this.courseEnrolment.studentCanRegister) 
      return false;
    return true;    
  }

  isForcedManualRegistrationProcessWithNoRestrictions(): boolean{
    if (this.courseEnrolment == null 
      || !this.courseEnrolment.forceManualRegistrationWithNoRestrictions) 
      return false;
    return true;    
  }

  isCourseAvaialbleForRegistration(): boolean {
    if(this.courseEnrolment == null
      || this.courseEnrolment.courseIsNotYetAvailableForRegistration)
     return false;
    return true;
  }

  ngOnDestroy(): void {
  }

  closeButtonText() : string {
    if (this.isEligibileToRegister() === true) 
      return StaticText.closeText;
    return StaticText.cancelText;
  }

  dialogFullTitle() {
    let fullTitle =  `Start Studying ${
    (this.courseEnrolment != null && this.courseEnrolment.courseName != null && this.courseEnrolment.courseName != '')
      ? ': ' + this.courseEnrolment.courseName
      : ''}`;
    return fullTitle;
  }

  refreshCurrentUserAndHidePopup() {
    this.objectStore.remove(StaticText.currentCourseUrlNoSlash)
    .then(() =>{
        //reload current user
        this.userService.getCurrentUser()
        .subscribe( (x:UserProfile) => {
          console.log('Current USer reloaded successfully');

          this.answer = x.enrolmentDetails.courseEnrolments;
          this.closePopup();

        }, err => {
          console.log('Something went wrong whilst reloading Current USer from Indexed DB');
        })
      }).catch(err => {
          console.log('Something went wrong whilst clearing Current USer from Indexed DB');
          console.log(err);
      });
  }
  
  closePopup(){
    this.bsModalRef.hide();
  }

  register(){
    this.courseRegistrationService.RegisterStudent(this.courseEnrolment).subscribe(
      (response:OperationResponse<CourseEnrolment>)=>{
        if (response != null && response.operationSucceeded){
          this.refreshCurrentUserAndHidePopup();
        }
        else{
          this.hasErrors = true;
          console.log('Something went wrong whilst registering the student');
          console.log(response.notifications);
        }
      },
      err => {
        this.hasErrors = true;
        console.log('Something went wrong whilst registering the student')
      }      
    ) 
  }

}
