<h3 class="h5">Discussion Participation</h3>
<div *ngIf="statistic >= 0.25" class="d-flex mt-3 mb-2">
  <img style="height: 51px" src="./../assets/img/badge-gold.png">
  <span class="text-dark ml-2 align-self-center"><strong>Gold</strong></span>
</div>

<div *ngIf="statistic >= 0.1 && statistic < 0.25" class="d-flex mt-3 mb-2">
  <img style="height: 51px" src="./../assets/img/badge-silver.png">
  <span class="text-dark ml-2 align-self-center">
    <strong>Silver</strong>
  </span>
</div>

<div *ngIf="statistic >= 0.05 && statistic < 0.1" class="d-flex mt-3 mb-2">
  <img style="height: 51px" src="./../assets/img/badge-bronze.png">
  <span class="text-dark ml-2 align-self-center">
    <strong>Bronze</strong>
  </span>
</div>

<div *ngIf="statistic < 0.05" class="d-flex mt-3 mb-2">
  <img style="height: 51px" src="./../assets/img/badge-entry.png">
  <span class="text-dark ml-2 align-self-center">
    <strong>Entry</strong>
  </span>
</div>


<p class="mb-2 stats-text">Your participation level within module discussions.</p>
<div class="d-flex flex-row align-items-left">
  <i class="fas fa-fw fa-info-circle fa-2x btn-blue"></i> 
  <a (click)="showModal()" class="hintLink">How was this calculated?</a>
</div>