export enum ApplicationEventType {
    UserConnectivityIssue = 'UserConnectivityIssue',
    ServerUnavailable = 'ServerUnavailable',
    ErrorInResponse = 'ErrorInResponse',
    UnAuthorised = 'UnAuthorised',
    Authorised = 'Authorised',
    Other = 'Other',
    Ok = 'Ok',
    ClearIndexedDb = 'ClearIndexedDb',   
    IndexedDbCleared = 'IndexedDbCleared',   
    OfflinePopupClosed = 'OfflinePopupClosed',
    ModuleDownloaded = "ModuleDownloaded",
    ModuleDeleted = "ModuleDeleted",
    ModuleDownloadFailure = 'ModuleDownloadFailure',
    SuspiciousModuleDownload = 'SuspiciousModuleDownload',
    ClearPartialModule = 'ClearPartialModule',
    PartialModuleCompleted = 'PartialModuleCompleted',
    ResetPartialModule = 'ResetPartialModule',
    DownloadRequest = 'DownloadRequest'
}