import { QuizQuestion } from './quiz-question';
import { ChoiceOption } from './choice-option';

export class OptionsQuestion extends QuizQuestion {
    public options: Array<ChoiceOption>;

    constructor(title: string, questionText: string, options: Array<ChoiceOption>, questionType: string) {
        super(title, questionText, questionType);
        this.options = options;
    }
}
