<div class="modal-header px-4">
  <h4 class="modal-title pull-left">{{title}}</h4>
  <button type="button" class="close pull-right" aria-label="Close" (click)="bsModalRef.hide()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<div class="modal-body px-4" innerHTML="{{message}}"></div>
<div class="modal-footer">
      <ng-container *ngIf="options.length">
          <button *ngFor="let option of options" type="button" class="{{option.buttonStyle}}" 
          (click)="respond(option.buttonText)">{{option.buttonText}}</button>
        </ng-container>
</div>