import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CoursePageComponent } from '@app/course-page/course-page.component';
import { CanDeactivateGuard } from '@app/common/security/can-deactivate.guard';

export const courseRoutes: Routes = [
{ path: '', component: CoursePageComponent, canDeactivate: [CanDeactivateGuard] }
];
@NgModule({
  exports: [RouterModule],
  imports: [RouterModule.forChild(courseRoutes)]
})
export class CourseRoutingModule {
}
