import { DiscussionEngagementStatistic } from './discussion-engagement-statistic';


export class ModuleDiscussionEngagementStatistic {

    public discussionEngagementStatisticList: Array<DiscussionEngagementStatistic> = new Array<DiscussionEngagementStatistic>();
    public totalDisucssionNotEngaged = 0;
    public moduleId: number;

    public getEngagementScore(): number {
        let self = this;
        var total = 0;
        self.totalDisucssionNotEngaged = 0;
        let includeCount = 0;
        self.discussionEngagementStatisticList.forEach((discussionEngagementStatistic) => {
            var temp = new DiscussionEngagementStatistic();
            Object.assign(temp, discussionEngagementStatistic)
            var point = temp.getPoints();
            if (point == 0) {
                self.totalDisucssionNotEngaged++;
            }
            if (temp.includeInCalculation && !temp.isNoDiscussionInModule) {
                includeCount++;
                total += point;
            }
        })
        if(includeCount==0)includeCount = 1;
        return total;
        //return Number((total / (includeCount * 100)).toFixed(2)) * 100;
    }

    public getNumberIncluded():number{
        let includeCount = 0;
        this.discussionEngagementStatisticList.forEach((discussionEngagementStatistic) => 
        {
            var temp = new DiscussionEngagementStatistic();
            Object.assign(temp, discussionEngagementStatistic)
            if (temp.includeInCalculation && !temp.isNoDiscussionInModule) {
                includeCount++;
            }
        })
        return includeCount;
    }
}