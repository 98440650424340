import { NumericalAnswer } from './numerical-answer';
import { QuestionAnswer } from './questionAnswer';

export class NumericalQuestion extends QuestionAnswer {
public answer: NumericalAnswer;
public answers: Array<NumericalAnswer> = new Array<NumericalAnswer>();

    constructor(title: string, questionText: string, answer: NumericalAnswer) {
        super(title, questionText, 'numerical');
        this.answer = answer;

    }


}
