import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationService } from '@app/common/security/authentication.service';
import { UserService } from '@app/common/security/user.service';
import { UserProfile } from '@app/common/models/user';
import { ModalSimpleService } from '@app/common/components/modal-simple/modal-simple.service';
import { DownloadsService } from '@app/downloads/services/downloads.service';
import { OnlineStatusService } from '@app/common/online-status/online-status.service';
import { OnlineStatusType } from '@app/common/online-status/online-status-type.enum';
import { ApplicationEventDispatcher } from '@app/core/application-event-dispatcher.service';
import { ApplicationEventType } from '@app/core/application-event-type';

@Component({
  selector: 'ebs-logout',
  templateUrl: './logout.component.html',
  styleUrls: ['./logout.component.scss']
})
export class LogoutComponent implements OnInit {
  @Input() showIcon = false;
  @Input() modalLogout = false;

  constructor(
    private router: Router,
    private authenticationService: AuthenticationService,
    private userService: UserService,
    private onlineStatusService: OnlineStatusService,
    private applicationEventDispatcher: ApplicationEventDispatcher,
  ) { }

  ngOnInit() { 
    this.logOutUser();
  }

  logOutUser(): void {
    this.userService.getCurrentUser().subscribe((user: UserProfile) => {
      this.authenticationService.logOut(
        user,
        () => {
          this.applicationEventDispatcher.processAuthoriseModeRelatedEvent(ApplicationEventType.UnAuthorised);
          this.workOutRoute();
        });
    });
  }

  workOutRoute() {
    this.onlineStatusService.isOffline().subscribe(x => {
        x === true
        ? this.router.navigate(['/authentication/login-offline'])
        : this.router.navigate(['/authentication']);
    }, error=>{
        this.router.navigate(['/authentication']);
    });
  }
}