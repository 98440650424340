import { Component, OnInit, Input, ViewChild, ViewChildren, QueryList, SimpleChanges, OnChanges } from '@angular/core';
import { Section } from '@app/courses/models/section';
import { ActivityGroup } from '@app/courses/models/activity-group';
import { ActivatedRoute } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { LastSectionAccessedService } from '@app/courses/services/last-section-accessed.service';
import { LastSectionAccessed } from '@app/courses/models/last-section-accessed';
import { UserService } from '@app/common/security/user.service';
import { NotificationSummary } from '@app/common/models/notification-summary';
import { SeverityLevel } from '@app/common/models/severity-level';
import { CanComponentDeactivate } from '@app/common/security/can-deactivate.guard';
import { ActivityGroupComponent } from '../activity-group/activity-group.component';
import { CourseNavigationService } from '@app/courses/services/course-navigation.service';
import { OnlineStatusService } from '@app/common/online-status/online-status.service';
import { OnlineStatusType } from '@app/common/online-status/online-status-type.enum';
import { Subscription } from 'rxjs';

@Component({
  selector: 'ebs-section',
  templateUrl: './section.component.html',
  styleUrls: ['./section.component.scss']
})
export class SectionComponent implements OnInit, OnChanges, CanComponentDeactivate {
  @Input() section: Section;
  firstGroup: ActivityGroup;
  activityGroupsWithoutHeader: ActivityGroup[];
  fragment: string;
  scrolled: boolean;
  lastSectionAccessed: LastSectionAccessed;
  notifications: NotificationSummary[] = [];
  showLastAccessError = false;
  @ViewChildren(ActivityGroupComponent) children: QueryList<ActivityGroupComponent>
  @ViewChild(ActivityGroupComponent, { static: false }) child: ActivityGroupComponent
  theSubscription: Subscription;
  isOffline: boolean;

  canDeactivate(): boolean {
    let canBeDeactivated = true;

    this.children.forEach(child => {
      if (!child.canDeactivate()) canBeDeactivated = false;
    });
    return canBeDeactivated;
  }

  constructor(private activeRoute: ActivatedRoute,
    private titleService: Title,
    private userService: UserService,
    private lastSectionAccessedService: LastSectionAccessedService,
    private courseNavService: CourseNavigationService,
    private onlineStatusService: OnlineStatusService,
  ) {

    this.theSubscription = this.onlineStatusService.status.subscribe(
      event => {
        this.isOffline = (event === OnlineStatusType.OFFLINE) ? true : false
      }
    )
  }

  ngOnInit() {
    console.log('NG INIT HIT!!!');
    this.activeRoute.fragment.subscribe(fragment => {
      this.fragment = fragment;
      this.scrolled = false;
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    //section info fudge... the first activity group will 'always' be the section introduction
    console.log('NG CHANGES HIT!!!');
    if (changes.section.currentValue) {
      console.log(this.section);
      this.activityGroupsWithoutHeader = [...changes.section.currentValue.activityGroups];

      this.firstGroup = this.activityGroupsWithoutHeader.shift();
      if (this.section.name) {
        this.titleService.setTitle(this.section.name);
        this.onlineStatusService.isOffline().subscribe(x => {
          this.isOffline = x;
          if (!this.isOffline) {
            this.storeLastSectionAccessed();
          }
        }, error => {
          this.isOffline = false;
          this.storeLastSectionAccessed();
        });
      }
    }
  }

  ngAfterViewChecked(): void {
    if (this.fragment) {
      if (document.querySelector('#' + this.fragment) && !this.scrolled) {
        document.querySelector('#' + this.fragment).scrollIntoView();
        this.scrolled = true;
      }
    }
  }

  storeLastSectionAccessed() {
    this.showLastAccessError = false;
    //course code isnt actually set!
    let ccode = this.section.uri.split('courses/')[1].split('/')[0]
    this.lastSectionAccessed = new LastSectionAccessed();
    this.lastSectionAccessed.uri = 'courses/' + ccode + '/lastsectionaccessed';
    this.lastSectionAccessed.sectionName = this.section.number + ' ' + this.section.name;
    this.lastSectionAccessed.courseCode = ccode;
    this.lastSectionAccessed.sectionUri = this.section.uri;
    this.lastSectionAccessed.dateTimeOfLastSectionAccess = new Date();

    this.lastSectionAccessedService.setLastSectionAccessed(this.lastSectionAccessed, this.isOffline).subscribe(
      response => {
        if (response.operationSucceeded) {
          // we need to do a fake call so as the url so as the get is up to date (only called if in dashboard) in indexeddb
          this.lastSectionAccessedService.getLastSectionAccessed('courses/' + ccode + '/lastsectionaccessed', this.isOffline).subscribe(
            response => {
              //we need to set this locally for offline mode
              this.lastSectionAccessedService.setObjectInLocalStore(response.data, this.lastSectionAccessed.uri).subscribe(
                res => { },
                error => { console.log('error storing last accessed: ' + error) }
              )
            },
            error => { console.log('error getting fake last section accessed') }
          )
        } else {
          this.notifications = response.notifications;
          this.showLastAccessError = true;
        }
      },
      error => {
        console.log(error);
        this.notifications.push(new NotificationSummary('Error storing the last accessed section', 'Http', SeverityLevel.Error))
        this.showLastAccessError = true;
      }
    )
  }

}
