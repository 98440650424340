<h1> Course Forum</h1>

<div class="p-4" *ngIf="isOffline">
  <ebs-offline-message [isOffline]="isOffline" [offlineMessage]="forumOfflineMessage"></ebs-offline-message>
</div>

<div class="card discussion-wrapper forum-list-wrapper" *ngIf="!isOffline">
  <div class="card-body">
    <div>
      <h3 class="mb-3">
        <span> Discuss course topics with your course leader and fellow students </span>
      </h3>

      <div class="row mb-4">
        <div class="col-12 col-md-9">
          <p class="mb-0">The course forum is open to all students to discuss topics or create new ones. Your course leader
            will be active in this forum and will respond as quickly as they can. </p>
        </div>
        <div class="col-12 col-md-3" *ngIf="!isDemoUser">
          <a class="btn btn-primary" [routerLink]="addLink" style="float: right;margin-top: 0.25rem;">
            Create topic
          </a>
        </div>
      </div>

      <ul class="nav nav-tabs mt-2" role="tablist">
        <li class="nav-item">
          <a class="nav-link text-center" [ngClass]="{active: showFilter}" data-toggle="tab" (click)="tabClick(true)"
            role="tab" style="cursor: pointer;">
            <span class="d-xl-none"> Filter</span>
            <span class="d-none d-xl-block">Filter Topics</span>
          </a>
        </li>
        <li class="nav-item">
          <a class="nav-link text-center" [ngClass]="{active: !showFilter}" data-toggle="tab" (click)="tabClick(false)"
            role="tab" style="cursor: pointer;">
            <span class="d-xl-none"> Search</span>
            <span class="d-none d-xl-block">Search Topics</span>
          </a>
        </li>
      </ul>
    </div>
    <div class="tab-content" style="min-height: 800px;">
      <div class="tab-pane mt-3 active show" role="tabpanel" *ngIf="showFilter">
        <ebs-forum-filter [courseCode]="courseCode"></ebs-forum-filter>
      </div>
      <div *ngIf="!firstTime">
        <div class="tab-pane active show" role="tabpanel" *ngIf="!showFilter">
          <ebs-forum-search (returnToSearch)="handleReturnToSearch($event)" (showingInline)="handleShowingInline($event)" [courseCode]="courseCode"></ebs-forum-search>
        </div>
      </div>
    </div>


  </div>
</div>