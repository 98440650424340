<div *ngIf="sectionId === 0">
    <div class="intro">
        <h1>Course Dashboard</h1>
    </div>

    <div class="card" *ngIf="isOffline">
        <div class="card-body">
            <div class="pl-2">
                <ebs-offline-message [isOffline]='isOffline' [offlineMessage]='offlineMessage'></ebs-offline-message>
            </div>
        </div>
    </div>
    <div class="card" *ngIf="isDemoUser || isDemo2User" >
        <div class="card-body featured">
            <div class="row">
                <div class="section-widget course-section-widget col-12 mb-0">
                    <h3 class="card-title h4">Welcome!</h3>
                    <p class=lead>This demonstration course gives you a flavour of online learning at Edinburgh Business
                        School. Try our custom-built platform, access the high-quality materials developed by our team,
                        and get a sense of how flexible our approach is.</p>
                    <p>This is an abridged version of one of our most popular courses. It
                        includes the first two modules and the induction materials (module 0). The full course
                        includes further self-contained modules with videos, activities, practice exams and ebook access.</p>
                    <p>The course dashboard (right here) gives you a snapshot of where you are in the course. It
                        summarises
                        your test scores and participation, and includes links to materials that you can download for
                        offline study.
                    </p>
                    <p class="lead">To get started go to <a [routerLink]="linkToFirstSectionOfCourse">Module 0: About this Course</a>.
                        We'd love to hear from you if you have any questions, so please <a href="https://ebs.online.hw.ac.uk/contact-us"
                            target="new">get in touch</a>. Or if you’re ready to sign up, <a href="https://ebs.online.hw.ac.uk/apply"
                            target="new">apply now</a>.</p>
                    <p><a href="https://ebs.online.hw.ac.uk/contact-us" target="new"
                            class="btn btn-md d-block d-md-inline-block  btn-light mt-3">Get in touch</a>
                        <span class="pr-2">&nbsp;</span>
                        <a href="https://ebs.online.hw.ac.uk/apply" target="new"
                            class="btn btn-md d-block d-md-inline-block  btn-light mt-3">Apply now</a></p>
                </div>
            </div>
        </div>
    </div>

    <div class="card">
        <div class="card-body">
            <div class="row">
                <div class="section-widget course-section-widget col-12 mb-0">
                    <h3 class="card-title h4">Your Progress</h3>
                    <ebs-spinner [spinnerMessage]="'loading progress'" *ngIf="gettingProgress"></ebs-spinner>
                    <ebs-notification-multiple [notifications]="progressNotifications" *ngIf="showProgressError">
                    </ebs-notification-multiple>
                    <span class="p-1" *ngIf="!showProgressError">
                        {{percentComplete}}% complete.
                        <div class="mt-2">
                            <ebs-progress-bar [progressWrapperWidth]="100" [showValueInBar]="false" [progressMax]="100"
                                [progressValue]="percentComplete"></ebs-progress-bar>
                        </div>
                    </span>
                </div>

                <div class="section-widget course-section-widget col-12 border-bottom pb-4 pt-0">
                    <h3 class="h5">Last Section Visited</h3>
                    <ebs-spinner [spinnerMessage]="'loading last section data'" *ngIf="gettingLastAccessed">
                    </ebs-spinner>
                    <div class="p-1" *ngIf="lastAccessed && lastAccessed.sectionUri">
                        <span class="font-italic font-weight-bold">"{{lastAccessed.sectionName}}"</span>
                        <span *ngIf="!isMobile"> when online on
                            {{lastAccessed.dateTimeOfLastSectionAccess | date:'EEE, d MMM y, H:mm'}} </span>
                        <span *ngIf="isMobile"> when online on
                            {{lastAccessed.dateTimeOfLastSectionAccess | date:'MMM d, y'}}
                        </span>
                    </div>
                    <div *ngIf="lastAccessed && lastAccessed.sectionUri === null">You have not accessed any of your
                        learning modules. </div>

                    <a [routerLink]="['/' + lastAccessed.sectionUri]" *ngIf="lastAccessed && lastAccessed.sectionUri"
                        class="btn btn-md d-block d-md-inline-block  btn-primary mt-3">Return to Section</a>

                    <ebs-message-simple [displayMessage]="displayMessage" *ngIf="showLastAccessError && !isOffline">
                    </ebs-message-simple>
                </div>

                <!--STATS COMMENTED OUT FOR RELEASE-->
                <div *ngIf="displayQuizStats"
                    class="col-sm-12 col-12 col-md-6 col-lg-6 section-widget course-section-widget">
                    <ebs-spinner [spinnerMessage]="'loading progress'" *ngIf="quizStatsIsLoading"></ebs-spinner>
                    <ebs-notification-multiple [notifications]="progressNotifications" *ngIf="showProgressError">
                    </ebs-notification-multiple>
                    <div *ngIf="!quizStatsIsLoading">
                        <ebs-quiz-statistics-average [quizStatistics]="quizStatistics"></ebs-quiz-statistics-average>

                        <div id="collapseQuizStats">
                            <ebs-quiz-statistics-details *ngIf="showQuizStatsDetails" [quizStatistics]="quizStatistics">
                            </ebs-quiz-statistics-details>
                        </div>

                        <button (click)="quizStatsToggle()"
                            class="btn btn-block btn-outline-secondary btn-sm collapsed mt-2 d-lg-none" type="button"
                            data-toggle="collapse" data-target="#collapseQuizStats" aria-expanded="false"
                            aria-controls="collapseQuizStats">View {{buttonTextQuiz}}</button>
                    </div>
                </div>
                <div *ngIf="displayStatistics"
                    class="col-sm-12 col-12 col-md-6 col-lg-6 section-widget course-section-widget ">
                    <ebs-spinner [spinnerMessage]="'loading progress'" *ngIf="quizStatsIsLoading"></ebs-spinner>
                    <ebs-notification-multiple [notifications]="progressNotifications" *ngIf="discussionStatsIsLoading">
                    </ebs-notification-multiple>
                    <div *ngIf="!discussionStatsIsLoading">
                        <discussion-engagement
                            [moduleDiscussionEngagementStatistics]="moduleDiscussionEngagementStatistics">
                        </discussion-engagement>
                        <div id="collapseDiscussionStats">
                            <discussion-engagement-statistics-details *ngIf="showDiscussionStatsDetails"
                                [moduleDiscussionEngagementStatistics]="moduleDiscussionEngagementStatistics">
                            </discussion-engagement-statistics-details>
                        </div>

                        <button (click)="discussionStatsToggle()"
                            class="btn btn-block btn-outline-secondary btn-sm collapsed mt-2 d-lg-none" type="button"
                            data-toggle="collapse" data-target="#collapseDiscussionStats" aria-expanded="false"
                            aria-controls="collapseDiscussionStats">View {{buttonText}}</button>
                    </div>
                </div>
                <button *ngIf="displayStatistics || displayQuizStats" (click)="toggleBoth()"
                    class="btn btn-block btn-outline-secondary btn-sm collapsed my-2 mx-4 d-none d-lg-block"
                    type="button" data-toggle="collapse" data-target="#collapseQuizStats, #collapseDiscussionStats"
                    aria-expanded="false" aria-controls="collapseQuizStats, collapseDiscussionStats">View
                    {{buttonText}}</button>

                <ebs-message-simple [iconCssClasses]="'fas fa-exclamation-circle fa-lg'" [textCssClasses]="''"
                    *ngIf="!displayQuizStats && !displayStatistics" [displayMessage]="notAvailableMessage">
                </ebs-message-simple>
            </div>
        </div>

    </div>

    <div class="card">
        <div class="card-body">
            <div class="container">
                <div class="row">
                    <div class="col-sm-12 col-12 col-md-6 col-lg-6 section-widget course-section-widget">
                        <h4>Current Offline Content</h4>
                        <span *ngIf="supportsServiceWorkers">
                            <ebs-spinner [spinnerMessage]="'getting current download list'" *ngIf="!localCourseSet">
                            </ebs-spinner>
                            <ebs-downloads-current [courseCode]="courseId" *ngIf="localCourseSet">
                            </ebs-downloads-current>
                            <ebs-offline-message [isOffline]='isOffline' [offlineMessage]='downloadedOfflineMessage'>
                            </ebs-offline-message>
                            <a [routerLink]="downloadsLink" class="btn btn-primary wrapButtonText"
                                *ngIf="!isOffline && localCourseSet">
                                Manage offline content
                            </a>
                        </span>
                        <div class="card" *ngIf="!supportsServiceWorkers" [@enterOrLeave]="!supportsServiceWorkers"
                            style="border-radius: 6px">
                            <div class="card-header p-0" style="border: none !important">
                                <ebs-message-simple
                                    [displayMessage]="'Offline mode is not supported by your browser. Please upgrade to a modern browser.' "
                                    [textCssClasses]="'text-info'"
                                    [iconCssClasses]="'text-info fa fa-fw fa-exclamation-circle'">
                                </ebs-message-simple>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-12 col-12 col-md-6 col-lg-6 section-widget course-section-widget">
                        <h4>Printable materials</h4>
                        <p>You can print individual modules as PDFs for reviewing offline. </p>
                        <ebs-offline-message [isOffline]='isOffline' [offlineMessage]='printablesOfflineMessage'>
                        </ebs-offline-message>
                        <a [routerLink]="printableMaterialsLink" class="btn btn-primary wrapButtonText"
                            *ngIf="!isOffline">
                            Go to printable materials
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="card">
        <div class="card-body">
            <div class="container">
                <div class="row">
                    <div class="col-sm-12 col-12 col-md-6 col-lg-6 section-widget course-section-widget">
                        <h4>Email Notifications</h4>
                        <p>You can manage which email notifications you receive for discussions and forum topics on this
                            course. </p>
                        <ebs-offline-message [isOffline]='isOffline' [offlineMessage]='printablesOfflineMessage'>
                        </ebs-offline-message>
                        <a [routerLink]="manageSubsLink" class="btn btn-primary wrapButtonText" *ngIf="!isOffline">
                            Manage subscriptions
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<!--content-->
<div *ngIf="sectionId > 0">
    <div *ngIf="!gettingSection" style="margin-bottom: 40px;">
        <!-- <div class="d-none d-print-block">There has been an error when loading some or all of this page</div> -->
        <ebs-message-simple [displayMessage]="errorMessage" *ngIf="errorOccured"></ebs-message-simple>
        <div *ngIf="showOfflineMessage">
            <ebs-offline-message [isOffline]='isOffline' [offlineMessage]='offlineSectionMessage'></ebs-offline-message>
        </div>
        <div *ngIf="!errorOccured && !showOfflineMessage">
            <ebs-section [section]='section'></ebs-section>
            <ebs-section-progress (bubbleMenuUpdateFromProgress)="handleMenuUpdated($event)" [sectionId]="sectionId"
                [showProgress]="sectionIsComplete"></ebs-section-progress>
            <div class="card hidden-print">
                <div class="card-body">
                    <div class="row next-previous-row">
                        <div class="col-6">
                            <button class="btn btn-secondary float-left btn-block" (click)="routePrevious()"
                                *ngIf="!hidePrev">
                                <i class="fa fa-fw fa-chevron-circle-left"></i> &nbsp; {{prevText}} </button>
                        </div>
                        <div class="col-6">
                            <button class="btn btn-primary float-right btn-block" (click)="routeNext()"
                                *ngIf="!hideNext">
                                {{nextText}} &nbsp;<i class="fa fa-fw fa-chevron-circle-right"></i> </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<ebs-e-books *ngIf="sectionId === -1"></ebs-e-books>
<ebs-printable-materials [courseCode]="courseId" *ngIf="sectionId === -2"></ebs-printable-materials>
<ebs-downloads [courseCode]="courseId" *ngIf="sectionId === -3" [currentUser]="currentUser"></ebs-downloads>
<!--Forums-->
<ebs-forum-list [courseCode]="courseId" *ngIf="sectionId === -4"></ebs-forum-list>
<ebs-forum-add [courseCode]="courseId" *ngIf="sectionId === -5"></ebs-forum-add>
<!--ADD-->
<ebs-forum [courseCode]="courseId" *ngIf="sectionId === -6"></ebs-forum>
<ebs-forum-add [formMode]="'edit'" [courseCode]="courseId" *ngIf="sectionId === -7"></ebs-forum-add>
<!--EDIT-->
<!--End Forums-->
<ebs-email-subscription [courseCode]="courseId" *ngIf="sectionId === -8"></ebs-email-subscription>