import { Injectable, Inject } from '@angular/core';
import { ServiceBase } from '@app/core/service-base';
import { OperationResponse } from '@app/common/models/operation-response';
import { of, Subscriber, Observable, throwError, EMPTY } from 'rxjs';
import { CourseNavigationService } from '@app/courses/services/course-navigation.service';
import { StorableObject } from '@app/core/storable-object';
import { CourseStructure } from '@app/courses/models/course-structure';
import { ModuleDownloadMonitoring } from '../models/module-download-monitoring';
import { DownloadResult } from '../models/download-result';
import { ServiceBaseHelpers } from '@app/core/service-base-helpers';
import { ToastrService } from 'ngx-toastr';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Section } from '@app/courses/models/section';
import { ActivityItem } from '@app/courses/models/activity-item';
import { ModuleStructure } from '../models/module-structure';
import { Video, StorableVideo } from '@app/courses/models/video';
import { File } from '@app/courses/models/file';
import { ActivityItemType } from '@app/courses/models/activity-item-type';
import { PagedRequest } from '@app/common/models/paged-request';
import { ModuleDeletiondMonitoring } from '../models/module-deletion-monitoring';
import { DeletionResult } from '../models/deletion-result';
import { LearningObject } from '@app/common/models/learning-object';
import { concat  } from 'rxjs';
import { retry, mergeMap, catchError, shareReplay } from 'rxjs/operators'
import { PagedResult } from '@app/common/models/paged-result';
import { VideoCachingService } from './video-caching.service';
import { SynchronisingDownloadsService } from './synchronising-downloads.service';
import { DeviceDetectorWrapperService } from '@app/common/device-detector/device-detector-wrappe.servicer';
import { ObjectStoreBase } from '@app/core/object-store-base';
import { ConfigurationHelperService } from '@app/common/app-configuration/configuration-helper.service';
import { DiscussionPostOrReplyItem } from '@app/courses/components/discussion-course/models/discussion-postorreply-item';
import { ApplicationEventDispatcher } from '@app/core/application-event-dispatcher.service';
import { PartialDownload } from '../models/partial-download';
import { ApplicationEventType } from '@app/core/application-event-type';

@Injectable({
  providedIn: 'root'
})
export class DownloadsService extends ServiceBase {

  downloadRetries:number = 0;

  private serviceBaseHelpers:ServiceBaseHelpers
  constructor(@Inject(CourseNavigationService) private courseNavigationService: CourseNavigationService,
    private objectStoreService: ObjectStoreBase,
    http: HttpClient,
    toastr: ToastrService,
    helpers: ServiceBaseHelpers,
    @Inject(SynchronisingDownloadsService) private synchronisingDownloadsService: SynchronisingDownloadsService,
    @Inject(VideoCachingService) private videoCahingService: VideoCachingService,
    @Inject(DeviceDetectorWrapperService) private deviceDetectorWrapperService: DeviceDetectorWrapperService,
    configurationService:ConfigurationHelperService,
    private applicationEventDispatcher:ApplicationEventDispatcher
    ) {
      super(http, objectStoreService, toastr, helpers, configurationService);
      this.downloadRetries = configurationService.getDownloadRetry();
      this.serviceBaseHelpers = helpers;
  }


  getCurrentDownloads(courseCode: string): Observable<CourseStructure> {
    return this.courseNavigationService.getLocalCourseStructure(courseCode);
  }

  getAvailableDownloads(courseCode: string): Observable<CourseStructure> {
    return this.courseNavigationService.getLocalCourseStructure(courseCode);
  }

  //TODO: M.S. - write unit tests
  hasDownloads(): Observable<boolean> {

    const hasDownloadsObs = new Observable<boolean>(subscribe => {
      this.getAllCourseStructures().subscribe(courseStructureList => {
        let hasDownloads = false;
        outer_loop:
        for (const courseStructure of courseStructureList) {
          for (const module of courseStructure.modules) {
            if (module.isContentDownloaded === true || module.isVideoListDownloaded === true) {
              hasDownloads = true;
              break outer_loop;
            }
          }
        }
        subscribe.next(hasDownloads);
        subscribe.complete();
      }, error => {
        subscribe.error(error);
        subscribe.complete();
      });
    });

    return hasDownloadsObs;
  }

  //TODO: M.S. - write unit tests
  offlineModulesAvailableForCourse(courseCode: string): Observable<number> {
    const offlineModulesAvailableForCourseObs = new Observable<number>(subscribe => {
      this.courseNavigationService.getLocalCourseStructure(courseCode).subscribe((x: CourseStructure) => {
        let downloadedModulesCount: number = 0
        if (x != null && x.modules != null) {
          for (const module of x.modules) {
            if (module.isContentDownloaded === true || module.isVideoListDownloaded === true) {
              downloadedModulesCount++;
            }
          }
        }
        subscribe.next(downloadedModulesCount);
        subscribe.complete();
      }, error => {
        subscribe.error(error);
        subscribe.complete();
      });
    });
    return offlineModulesAvailableForCourseObs;
  }

  // G.M. temp service until Mirela back
  getAllLocalSections(courseCode: string): Observable<Section[]> {

    const courseSectionListObs = new Observable<Section[]>(subscribe => {

      this.objectStoreService.filter((storableObj: StorableObject) => {
        return storableObj.uri.startsWith(`courses/${courseCode.toLowerCase()}/modules`) && storableObj.uri.split('/').length === 6;
      })
        .then((storableObjs: StorableObject[]) => {
          if (storableObjs == null) {
            subscribe.next(new Array<Section>());
          }
          else {
            subscribe.next(<Section[]>storableObjs);
          }

          subscribe.complete();
        }).catch(error => {
          subscribe.error(error);
          subscribe.complete();
        });
    });

    return courseSectionListObs;
  }
  //TODO: M.S. - write unit tests
  getAllCourseStructures(): Observable<CourseStructure[]> {

    const courseStructureListObs = new Observable<CourseStructure[]>(subscribe => {

      this.objectStoreService.filter((storableObj: StorableObject) => {
        return storableObj.uri.startsWith('courses/') && storableObj.uri.split('/').length === 2;
      })        
        .then((storableObjs: StorableObject[]) => {
          if (storableObjs == null) {
            subscribe.next(new Array<CourseStructure>());
          }
          else {
            subscribe.next(<CourseStructure[]>storableObjs);
          }

          subscribe.complete();
        }).catch(error => {
          subscribe.error(error);
          subscribe.complete();
        });
    });

    return courseStructureListObs;
  }

  deleteModule(moduleId: number, courseCode: string, onlyVideos: boolean, deleteBoth:boolean, deletionMonitoring: ModuleDeletiondMonitoring): Observable<string> {
    if (deletionMonitoring == null) { return of('error'); }

    deletionMonitoring.courseCode = courseCode;
    deletionMonitoring.moduleId = moduleId;

    const isiOS = this.deviceDetectorWrapperService.isiOS();

    const deletionModuleContentObs = new Observable<string>((subscriber: Subscriber<string>) => {
      this.courseNavigationService.getLocalModuleStructure(courseCode, moduleId)
        .subscribe((moduleStructure: ModuleStructure) => {
          if (moduleStructure == null) {
            subscriber.next('Nothing to delete');
            subscriber.complete();
          }
          else {
            deletionMonitoring.createFromModuleStructure(moduleStructure, onlyVideos || deleteBoth);

            if (onlyVideos === false){
              this.deleteModuleItems<Section>('sections', deletionMonitoring, moduleStructure, onlyVideos, isiOS, subscriber);
              this.deleteModuleItems<ActivityItem>('activityItems', deletionMonitoring, moduleStructure, onlyVideos, isiOS, subscriber);
              this.deleteModuleItems<File>('files', deletionMonitoring, moduleStructure, onlyVideos, isiOS, subscriber);
            }
            
            if (onlyVideos === true || deleteBoth === true) {
              this.deleteModuleItems<Video>('videos', deletionMonitoring, moduleStructure, true, isiOS, subscriber);
            }
          }
        }, error => {
          subscriber.error(error);
          subscriber.complete();
        });

    });

    return deletionModuleContentObs;
  }

  deleteModuleOLD(moduleId: number, courseCode: string, includeVideos: boolean, deletionMonitoring: ModuleDeletiondMonitoring): Observable<string> {
    if (deletionMonitoring == null) { return of('error'); }

    deletionMonitoring.courseCode = courseCode;
    deletionMonitoring.moduleId = moduleId;

    const isiOS = this.deviceDetectorWrapperService.isiOS();

    const deletionModuleContentObs = new Observable<string>((subscriber: Subscriber<string>) => {
      this.courseNavigationService.getLocalModuleStructure(courseCode, moduleId)
        .subscribe((moduleStructure: ModuleStructure) => {
          if (moduleStructure == null) {
            subscriber.next('Nothing to delete');
            subscriber.complete();
          }
          else {
            deletionMonitoring.createFromModuleStructure(moduleStructure, includeVideos);

            this.deleteModuleItems<Section>('sections', deletionMonitoring, moduleStructure, includeVideos, isiOS, subscriber);
            this.deleteModuleItems<ActivityItem>('activityItems', deletionMonitoring, moduleStructure, includeVideos, isiOS, subscriber);

            this.deleteModuleItems<File>('files', deletionMonitoring, moduleStructure, includeVideos, isiOS, subscriber);

            if (includeVideos === true) {
              this.deleteModuleItems<Video>('videos', deletionMonitoring, moduleStructure, includeVideos, isiOS, subscriber);
            }
          }
        }, error => {
          subscriber.error(error);
          subscriber.complete();
        });

    });

    return deletionModuleContentObs;
  }

  deleteModuleContentOrVideo(moduleId: number, courseCode: string, onlyVideos: boolean, deletionMonitoring: ModuleDeletiondMonitoring): Observable<string> {
    if (deletionMonitoring == null) { return of('error'); }

    deletionMonitoring.courseCode = courseCode;
    deletionMonitoring.moduleId = moduleId;

    const isiOS = this.deviceDetectorWrapperService.isiOS();

    const deletionModuleContentObs = new Observable<string>((subscriber: Subscriber<string>) => {
      this.courseNavigationService.getLocalModuleStructure(courseCode, moduleId)
        .subscribe((moduleStructure: ModuleStructure) => {
          if (moduleStructure == null) {
            subscriber.next('Nothing to delete');
            subscriber.complete();
          }
          else {
            deletionMonitoring.createFromModuleStructure(moduleStructure, onlyVideos);

            if (onlyVideos === true) {
              this.deleteModuleItems<Video>('videos', deletionMonitoring, moduleStructure, onlyVideos, isiOS, subscriber);
            }
            else{
              this.deleteModuleItems<Section>('sections', deletionMonitoring, moduleStructure, onlyVideos, isiOS, subscriber);
              this.deleteModuleItems<ActivityItem>('activityItems', deletionMonitoring, moduleStructure, onlyVideos, isiOS, subscriber);
              this.deleteModuleItems<File>('files', deletionMonitoring, moduleStructure, onlyVideos, isiOS, subscriber);
            }            
          }
        }, error => {
          subscriber.error(error);
          subscriber.complete();
        });

    });

    return deletionModuleContentObs;
  }

  deleteModuleItems<T extends StorableObject>(itemListName: string, deletionMonitoring: ModuleDeletiondMonitoring,
    moduleStructure: ModuleStructure, onlyVideos: boolean, isiOS:boolean,  subscriber: Subscriber<string>): void {
    if (deletionMonitoring == null) {
      subscriber.error('error, no monitoring progress object');
    }

    const items: Array<DeletionResult> = deletionMonitoring[itemListName];

    if (items == null || items.length === 0) {
      this.checkCompletionOfModuleDeletion(deletionMonitoring, onlyVideos, moduleStructure, subscriber);
      return;
    }

    for (const item of items) {

      let bypassLowerCasingOfUri: boolean = false;
      let uri = item.uri;

      if (item.uri.indexOf('file') > -1) {
        bypassLowerCasingOfUri = true;
        uri = uri.replace('details/', '');//M.S. - this is needed, because of the difference in uri paths for file content and file metadata
      }

      const observable = (isiOS === false &&  itemListName == 'videos')  
      ? this.videoCahingService.deleteFromCache(item.uri)
      : new Observable<OperationResponse<T>>(observer => {       
          this.deleteObjectFromLocalStore(uri, observer, bypassLowerCasingOfUri);       
      }); 
       

      item.hasBeenProcessed = true;

      observable.subscribe(x => {
        if (item.uri.indexOf('file') > -1) {
          bypassLowerCasingOfUri = true;
        }
        if (x && x.operationSucceeded) {
          item.isDeleted = true;
          this.synchronisingDownloadsService.adjustLatestModulestructure(itemListName, item.uri, moduleStructure, false);
        }
        this.checkCompletionOfModuleDeletion(deletionMonitoring, onlyVideos, moduleStructure, subscriber);
      }, error => {
        this.checkCompletionOfModuleDeletion(deletionMonitoring, onlyVideos, moduleStructure, subscriber);
      });
    }
  }

  deleteModuleItemsOLD<T extends StorableObject>(itemListName: string, deletionMonitoring: ModuleDeletiondMonitoring,
    moduleStructure: ModuleStructure, includeVideos: boolean, isiOS:boolean,  subscriber: Subscriber<string>): void {
    if (deletionMonitoring == null) {
      subscriber.error('error, no monitoring progress object');
    }

    const items: Array<DeletionResult> = deletionMonitoring[itemListName];

    if (items == null || items.length === 0) {
      this.checkCompletionOfModuleDeletion(deletionMonitoring, includeVideos, moduleStructure, subscriber);
      return;
    }

    for (const item of items) {

      let bypassLowerCasingOfUri: boolean = false;
      let uri = item.uri;

      if (item.uri.indexOf('file') > -1) {
        bypassLowerCasingOfUri = true;
        uri = uri.replace('details/', '');//M.S. - this is needed, because of the difference in uri paths for file content and file metadata
      }

      const observable = (isiOS === false &&  itemListName == 'videos')  
      ? this.videoCahingService.deleteFromCache(item.uri)
      : new Observable<OperationResponse<T>>(observer => {       
          this.deleteObjectFromLocalStore(uri, observer, bypassLowerCasingOfUri);       
      }); 
       

      item.hasBeenProcessed = true;

      observable.subscribe(x => {
        if (item.uri.indexOf('file') > -1) {
          bypassLowerCasingOfUri = true;
        }
        if (x && x.operationSucceeded) {
          item.isDeleted = true;
          this.synchronisingDownloadsService.adjustLatestModulestructure(itemListName, item.uri, moduleStructure, false);
        }
        this.checkCompletionOfModuleDeletion(deletionMonitoring, includeVideos, moduleStructure, subscriber);
      }, error => {
        this.checkCompletionOfModuleDeletion(deletionMonitoring, includeVideos, moduleStructure, subscriber);
      });
    }
  }

  checkCompletionOfModuleDeletionOLD(deletionMonitoring: ModuleDeletiondMonitoring, includeVideos: boolean,
    moduleStructure: ModuleStructure, subscriber: Subscriber<string>): void {
    const isStopped = subscriber['isStopped']

    if (deletionMonitoring.isCompletelyDeleted() === true && isStopped === false) {
      subscriber.next('deletion of module process is complete');
      this.courseNavigationService
        .setLocalModuleStructure(moduleStructure.courseCode, moduleStructure.moduleId, moduleStructure)
        .subscribe(x => {
          subscriber.next(`Module structure updated with deletions for course: ${moduleStructure.courseCode} and module: ${moduleStructure.moduleId}`);
          if (deletionMonitoring.isCompletelyDeleted()) {
            this.courseNavigationService.updateCourseStructureWithOfflineContentAvailability(null, deletionMonitoring, includeVideos, subscriber);
          }
        }, error => {
          subscriber.next(`Failed to update module structure with latest deletions for course: ${moduleStructure.courseCode} and module: ${moduleStructure.moduleId}`);
          if (deletionMonitoring.isCompletelyDeleted()) {
            this.courseNavigationService.updateCourseStructureWithOfflineContentAvailability(null, deletionMonitoring, includeVideos, subscriber);
          }
        });
    }
  }

  checkCompletionOfModuleDeletion(deletionMonitoring: ModuleDeletiondMonitoring, onlyVideos: boolean,
    moduleStructure: ModuleStructure, subscriber: Subscriber<string>): void {
    const isStopped = subscriber['isStopped']

    const isModuleCompletelyDeleted = onlyVideos === true 
      ? deletionMonitoring.isVideoCompletelyDeleted()
      : deletionMonitoring.isCompletelyDeleted();

    if (isModuleCompletelyDeleted === true && isStopped === false) {
      subscriber.next('deletion of module process is complete');
      this.courseNavigationService
        .setLocalModuleStructure(moduleStructure.courseCode, moduleStructure.moduleId, moduleStructure)
        .subscribe(x => {
          subscriber.next(`Module structure updated with deletions for course: ${moduleStructure.courseCode} and module: ${moduleStructure.moduleId}`);
          if (isModuleCompletelyDeleted === true) {
            if (onlyVideos === true)
              this.courseNavigationService.updateCourseStructureWithOfflineVideoAvailability(null, deletionMonitoring, subscriber);
            else 
              this.courseNavigationService.updateCourseStructureWithOfflineContentAvailability(null, deletionMonitoring, false, subscriber);
          }
        }, error => {
          subscriber.next(`Failed to update module structure with latest deletions for course: ${moduleStructure.courseCode} and module: ${moduleStructure.moduleId}`);
          if (isModuleCompletelyDeleted === true) {
            if (onlyVideos === true)
              this.courseNavigationService.updateCourseStructureWithOfflineVideoAvailability(null, deletionMonitoring, subscriber);
            else 
              this.courseNavigationService.updateCourseStructureWithOfflineContentAvailability(null, deletionMonitoring, false, subscriber);
       
          }
        });
    }
  }

  recursiveVideoObservableChainedSubscriptions(
    currentSubscription : Observable<HttpResponse<Blob>>,
    listOfVideosDownloadObservables : Array<Observable<HttpResponse<Blob>>>,
    downloadMonitoring: ModuleDownloadMonitoring,
    videosModuleStructure:ModuleStructure,
    subscriber: Subscriber<string>){

      currentSubscription.subscribe((httpVideoResponse: HttpResponse<Blob>) => {
          let requestUrl = httpVideoResponse.url;
          this.synchronisingDownloadsService.adjustMonitoringSeq(requestUrl, downloadMonitoring);
          this.checkCompletionOfModuleVideoDownload(downloadMonitoring, videosModuleStructure, subscriber);


          const partialModule = new PartialDownload();
          partialModule.courseCode = videosModuleStructure.courseCode;
          partialModule.moduleId = videosModuleStructure.moduleId;
          partialModule.isVideoDownload = true;
          this.applicationEventDispatcher.processDownloadHttpRequest(partialModule, ApplicationEventType.ResetPartialModule);
        
          console.log('vid ' + requestUrl + ' has been downloaded');
        },
        error => {
          this.checkCompletionOfModuleVideoDownload(downloadMonitoring, videosModuleStructure, subscriber);           
          subscriber.error(error);
      }, () =>{
        if (listOfVideosDownloadObservables.length > 0)
        {
          let nextSubscription = listOfVideosDownloadObservables.pop()
          this.recursiveVideoObservableChainedSubscriptions(nextSubscription, listOfVideosDownloadObservables, 
            downloadMonitoring,videosModuleStructure,subscriber);
        }
        else{
          console.log('all video subscriptions have been processed');
        }
      });
  }
  
  downloadModuleVideosOnly(moduleId: number, courseCode: string, downloadMonitoring: ModuleDownloadMonitoring): Observable<string>{
    if (downloadMonitoring == null) { return of('error'); }

    downloadMonitoring.courseCode = courseCode;
    downloadMonitoring.moduleId = moduleId;

    let videosModuleStructure:ModuleStructure = null;

    const downloadModuleContentObs = new Observable<string>((subscriber: Subscriber<string>) => {
      this.courseNavigationService.getLocalModuleStructure(courseCode, moduleId)
        .subscribe((moduleStructure: ModuleStructure) => {
          if (moduleStructure == null) {
            videosModuleStructure = new ModuleStructure();
            videosModuleStructure.courseCode = courseCode;
            videosModuleStructure.moduleId = moduleId;
          }
          else{
            videosModuleStructure = moduleStructure
          }
        },
        error => {
          subscriber.error(error);
        },
        () =>{
          this.courseNavigationService.getLatestModuletructure(courseCode, moduleId, videosModuleStructure)
          .subscribe((moduleStructureResponse: ModuleStructure) => {
            downloadMonitoring.createFromModuleStructure(moduleStructureResponse, true);
            videosModuleStructure = moduleStructureResponse;
          }, error => {
            subscriber.error(error);
          }, ()=> {
            const listOfVideosDownloadObservables = new Array<Observable<HttpResponse<Blob>>>();
            this.downloadVideosSequentially(downloadMonitoring, subscriber, listOfVideosDownloadObservables);

            console.log('start of download');

            let startingObservable = listOfVideosDownloadObservables.pop();
            this.recursiveVideoObservableChainedSubscriptions(startingObservable, 
              listOfVideosDownloadObservables, downloadMonitoring, videosModuleStructure, subscriber);
          });
        });
    });

    return downloadModuleContentObs;
  }

  downloadModuleVideosOnlyOLD(moduleId: number, courseCode: string, downloadMonitoring: ModuleDownloadMonitoring): Observable<string>{
    if (downloadMonitoring == null) { return of('error'); }

    downloadMonitoring.courseCode = courseCode;
    downloadMonitoring.moduleId = moduleId;

    let videosModuleStructure:ModuleStructure = null;

    const downloadModuleContentObs = new Observable<string>((subscriber: Subscriber<string>) => {
      this.courseNavigationService.getLocalModuleStructure(courseCode, moduleId)
        .subscribe((moduleStructure: ModuleStructure) => {
          if (moduleStructure == null) {
            videosModuleStructure = new ModuleStructure();
            videosModuleStructure.courseCode = courseCode;
            videosModuleStructure.moduleId = moduleId;
          }
          else{
            videosModuleStructure = moduleStructure
          }
        },
        error => {
          subscriber.error(error);
        },
        () =>{
          this.courseNavigationService.getLatestModuletructure(courseCode, moduleId, videosModuleStructure)
          .subscribe((moduleStructureResponse: ModuleStructure) => {
            downloadMonitoring.createFromModuleStructure(moduleStructureResponse, true);
            videosModuleStructure = moduleStructureResponse;
          }, error => {
            subscriber.error(error);
          }, ()=>{
            const listOfVideosDownloadObservables = new Array<Observable<HttpResponse<Blob>>>();
            this.downloadVideosSequentially(downloadMonitoring, subscriber, listOfVideosDownloadObservables);
            const concatenatedVideosObs = concat(...listOfVideosDownloadObservables);

            console.log('start of download');

                concatenatedVideosObs.subscribe((httpVideoResponse: HttpResponse<Blob>) => {
                  let requestUrl = httpVideoResponse.url;

                  this.synchronisingDownloadsService.adjustMonitoringSeq(requestUrl, downloadMonitoring);
                  this.checkCompletionOfModuleVideoDownload(downloadMonitoring, videosModuleStructure, subscriber);
                },
                error => {
                  this.checkCompletionOfModuleVideoDownload(downloadMonitoring, videosModuleStructure, subscriber);           
                  subscriber.error(error);
              }, () =>{
                console.log(videosModuleStructure);
              });

          });
        });
    });

    return downloadModuleContentObs;
  }

  downloadModuleSequentially(moduleId: number, courseCode: string, includeVideos: boolean, downloadMonitoring: ModuleDownloadMonitoring): Observable<string> {
    if (downloadMonitoring == null) { return of('error'); }

    includeVideos = false; // Trying to split out the videos from the mass

    downloadMonitoring.courseCode = courseCode;
    downloadMonitoring.moduleId = moduleId;

    const downloadModuleContentObs = new Observable<string>((subscriber: Subscriber<string>) => {
      this.courseNavigationService.getLocalModuleStructure(courseCode, moduleId)
        .subscribe((moduleStructure: ModuleStructure) => {

          if (moduleStructure == null) {
            moduleStructure = new ModuleStructure();
            moduleStructure.courseCode = courseCode;
            moduleStructure.moduleId = moduleId;
          }

          this.courseNavigationService.getLatestModuletructure(courseCode, moduleId, moduleStructure)
            .subscribe((moduleStructureResponse: ModuleStructure) => {
              downloadMonitoring.createFromModuleStructure(moduleStructureResponse, includeVideos);

              console.log('Total = ' + downloadMonitoring.getTotal());

              const listOfModuleItemsDownloadObservables = new Array<Observable<OperationResponse<LearningObject>>>();
              const listOfDiscussionDownloadObservables = new Array<Observable<OperationResponse<PagedResult<DiscussionPostOrReplyItem>>>>();
              const listOfVideosDownloadObservables = new Array<Observable<HttpResponse<Blob>>>();

              this.downloadModuleItemsSequentially<Section>('sections', downloadMonitoring, subscriber, listOfModuleItemsDownloadObservables);
              this.downloadModuleItemsSequentially<ActivityItem>('activityItems', downloadMonitoring, subscriber, listOfModuleItemsDownloadObservables);
              this.downloadModuleItemsSequentially<File>('files', downloadMonitoring, subscriber, listOfModuleItemsDownloadObservables);
              this.downloadDiscussionItemsSequentially(downloadMonitoring, subscriber, listOfDiscussionDownloadObservables);

              if (includeVideos === true) {
                this.downloadVideosSequentially(downloadMonitoring, subscriber, listOfVideosDownloadObservables);
              }

              const concatenationOfObservables = concat(...listOfModuleItemsDownloadObservables);
              const concatenatedDiscussionsObs = concat(...listOfDiscussionDownloadObservables);
              const concatenatedVideosObs = concat(...listOfVideosDownloadObservables);
              // tslint:disable-next-line: no-trailing-whitespace
            
              const partialModule = new PartialDownload();
              partialModule.courseCode = downloadMonitoring.courseCode;
              partialModule.moduleId = downloadMonitoring.moduleId;
              partialModule.isVideoDownload = false;
              console.log('start of download');

              concatenationOfObservables.subscribe(x => {
                if (x && x.operationSucceeded) {

                  this.synchronisingDownloadsService.adjustMonitoringSeq(x.data.uri, downloadMonitoring);
                  this.synchronisingDownloadsService.adjustLatestModulestructureSeq(x.data.uri, moduleStructureResponse);
                  this.applicationEventDispatcher.processDownloadHttpRequest(partialModule, ApplicationEventType.ResetPartialModule);
                }
                this.checkCompletionOfModuleDownload(downloadMonitoring, includeVideos, moduleStructureResponse, subscriber);
              }, error => {
                this.checkCompletionOfModuleDownload(downloadMonitoring, includeVideos, moduleStructureResponse, subscriber);
                subscriber.error(error);
              },
              () => {                
                  concatenatedDiscussionsObs.subscribe(x => {
                    if (x != null && x.operationSucceeded === true && x.data != null) {

                      this.synchronisingDownloadsService.adjustMonitoringSeq(x.data.uri, downloadMonitoring);
                      this.synchronisingDownloadsService.adjustLatestModulestructure('activityItems', x.data.uri, moduleStructure);
                      console.log(`discussion uri: ${x.data.uri}`);
                      this.applicationEventDispatcher.processDownloadHttpRequest(partialModule, ApplicationEventType.ResetPartialModule);
                    }
                    this.checkCompletionOfModuleDownload(downloadMonitoring, includeVideos, moduleStructureResponse, subscriber);
                  
                  }, error => {
                    this.checkCompletionOfModuleDownload(downloadMonitoring, includeVideos, moduleStructureResponse, subscriber);
                    subscriber.error(error);
                  },
                  () => {
                    if (includeVideos === false){
                      this.checkCompletionOfModuleDownload(downloadMonitoring, includeVideos, moduleStructureResponse, subscriber);
                    } else {
                      concatenatedVideosObs.subscribe((httpVideoResponse: HttpResponse<Blob>) => {
                          let requestUrl = httpVideoResponse.url;

                          this.synchronisingDownloadsService.adjustMonitoringSeq(requestUrl, downloadMonitoring);
                          this.checkCompletionOfModuleDownload(downloadMonitoring, includeVideos, moduleStructureResponse, subscriber);
                          this.applicationEventDispatcher.processDownloadHttpRequest(partialModule, ApplicationEventType.ResetPartialModule);
                        },
                        error => {
                          this.checkCompletionOfModuleDownload(downloadMonitoring, includeVideos, moduleStructureResponse, subscriber);           
                          subscriber.error(error);
                      }, () =>{
                        console.log(moduleStructureResponse);
                      });
                    }
                  });                               
              });
              
            }, error => {
              subscriber.error(error);
            }, () => {
              console.log('end of download');
              console.log(downloadMonitoring);
            
            });
        });
    });

    return downloadModuleContentObs;
  }

  downloadModuleItemsSequentially<T extends StorableObject>(itemListName: string, downloadMonitoring: ModuleDownloadMonitoring,
     subscriber: Subscriber<string>, listOfModuleItemsDownloadObservables: Array<Observable<OperationResponse<LearningObject>>>): void {

    if (downloadMonitoring == null) {
      subscriber.error('error, no monitoring progress object');
    }

    const items: Array<DownloadResult> = downloadMonitoring[itemListName];
    const retries = this.downloadRetries;

    if (items == null || items.length === 0) { return; }

    for (const item of items) {
      if (item.type === ActivityItemType.Discussion) {
        continue;
      }

      const observable = new Observable<OperationResponse<T>>(observer => {
        this.getObjectRemoteStoreFirst(item.uri, observer, this.serviceBaseHelpers.buildInterceptorBypassHeader());
      });
      // .pipe(
      //   mergeMap(data => {
      //     if (data && data.operationSucceeded === false) {
      //       return throwError('Error Occurred.');
      //     }
      //     return of(data);
      //   }),
      //   retry(retries)
      // );

      item.hasBeenProcessed = true;
      listOfModuleItemsDownloadObservables.push(observable);
    }
  }

  downloadDiscussionItemsSequentially(downloadMonitoring: ModuleDownloadMonitoring, subscriber: Subscriber<string>,
    listOfDiscussionDownloadObservables: Array<Observable<OperationResponse<PagedResult<DiscussionPostOrReplyItem>>>>){

      if (downloadMonitoring == null || downloadMonitoring.activityItems == null || downloadMonitoring.activityItems.length < 1) {
        return;
      }

      const retries = this.downloadRetries;

      for (const item of downloadMonitoring.activityItems) {
        if (item.type !== ActivityItemType.Discussion) {continue;}

        const pagedDiscussionsRequest = new PagedRequest<DiscussionPostOrReplyItem>();
        pagedDiscussionsRequest.currentPage = 1;
        pagedDiscussionsRequest.itemsPerPage = 10;
        pagedDiscussionsRequest.uriFilter = item.uri + '/posts';

        const observable = this.getPagedObjects<DiscussionPostOrReplyItem>(pagedDiscussionsRequest, false, this.serviceBaseHelpers.buildInterceptorBypassHeader());
        // .pipe(
        //   mergeMap(data => {
        //     if (data && data.operationSucceeded === false) {
        //       return throwError('Error Occurred.');
        //     }
        //     return of(data);
        //   }),
        //   retry(retries)
        // );

        item.hasBeenProcessed = true;
        listOfDiscussionDownloadObservables.push(observable);
      }
    }

  downloadVideosSequentially(downloadMonitoring: ModuleDownloadMonitoring, subscriber: Subscriber<string>,
    listOfVideosDownloadObservables: Array<Observable<HttpResponse<Blob>>>){
      if (downloadMonitoring == null || downloadMonitoring.videos == null || downloadMonitoring.videos.length < 1) {
        return;
      }
        let isiOS = this.deviceDetectorWrapperService.isiOS();
      
        const retries = this.downloadRetries;

        for (const item of downloadMonitoring.videos) {

          const itemUri = `${item.uri}.mp4${item.accessToken}`;

          let indexedDbUri = `courses${item.uri.split('courses')[1]}.mp4`;

          const observable = new Observable<HttpResponse<Blob>>(observer => {
            
            this.videoCahingService
            .downloadToCache(itemUri, isiOS)
            .subscribe(x => {

              if (isiOS === true){
                let retirevedVideo = new StorableVideo();
                retirevedVideo.blob = x.body;
                retirevedVideo.uri = indexedDbUri;
                
                this.objectStoreService.addOrUpdate(retirevedVideo.uri, retirevedVideo).then(y =>{
                  observer.next(x);
                  observer.complete();
                }, reason =>{
                  observer.error(reason);
                });              
              } else {
                observer.next(x);
                observer.complete();
              }
            }, error => {
              observer.error(error);
            });
          });
          // .pipe(
          //   mergeMap(data => {
          //     if (!data || !data.body) {
          //       return throwError('Error Occurred.');
          //     }
          //     return of(data);
          //   }),
          //   retry(retries)
          // );
          
  
          item.hasBeenProcessed = true;
          listOfVideosDownloadObservables.push(observable);
        }
  }

  checkCompletionOfModuleDownload(downloadMonitoring: ModuleDownloadMonitoring, includeVideos: boolean, moduleStructure: ModuleStructure, subscriber: Subscriber<string>): void {
    const isStopped = subscriber['isStopped'];
   
    if (downloadMonitoring.isCompletelyDownloaded() === true && isStopped === false) {
      //subscriber.next('download module process is complete');
      this.courseNavigationService
        .setLocalModuleStructure(moduleStructure.courseCode, moduleStructure.moduleId, moduleStructure)
        .subscribe(x => {
          //subscriber.next(`Module structure updated with latest downloads for course: ${moduleStructure.courseCode} and module: ${moduleStructure.moduleId}`);
          if (downloadMonitoring.isCompletelyDownloaded()) {
            this.courseNavigationService.updateCourseStructureWithOfflineContentAvailability(downloadMonitoring, null, includeVideos, subscriber);
          }
        }, error => {
          //subscriber.next(`Failed to update module with structure updated with latest downloads for course: ${moduleStructure.courseCode} and module: ${moduleStructure.moduleId}`);
          if (downloadMonitoring.isCompletelyDownloaded()) {
            this.courseNavigationService.updateCourseStructureWithOfflineContentAvailability(downloadMonitoring, null, includeVideos, subscriber);
          }
        });
    }
  }

  checkCompletionOfModuleVideoDownload(downloadMonitoring: ModuleDownloadMonitoring, moduleStructure: ModuleStructure, subscriber: Subscriber<string>): void {
    const isStopped = subscriber['isStopped'];
   
    if (downloadMonitoring.isVideoCompletelyDownloaded() === true && isStopped === false) {
      //subscriber.next('download module process is complete');
      this.courseNavigationService
        .setLocalModuleStructure(moduleStructure.courseCode, moduleStructure.moduleId, moduleStructure)
        .subscribe(x => {
          //subscriber.next(`Module structure updated with latest downloads for course: ${moduleStructure.courseCode} and module: ${moduleStructure.moduleId}`);
          if (downloadMonitoring.isVideoCompletelyDownloaded()) {
            this.courseNavigationService.updateCourseStructureWithOfflineVideoAvailability(downloadMonitoring, null, subscriber);
          }
        }, error => {
          //subscriber.next(`Failed to update module with structure updated with latest downloads for course: ${moduleStructure.courseCode} and module: ${moduleStructure.moduleId}`);
          if (downloadMonitoring.isVideoCompletelyDownloaded()) {
            this.courseNavigationService.updateCourseStructureWithOfflineVideoAvailability(downloadMonitoring, null, subscriber);
          }
        });
    }
  }

  
}

