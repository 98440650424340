<ebs-spinner [spinnerMessage]="'Quiz loading..'" [faColour]="'#fff'" [boxColour]="'#fff'" *ngIf="loadingData"
  [boxPadding]="'5px 15px'"></ebs-spinner>

<div *ngIf="!loadingData && !activityItem">
  <i class="fa fa-fw fa-lg fa-exclamation-circle"> </i> {{failedMessage}}
</div>
<div class="pl-2" *ngIf="activityItem.questions && activityItem.questions[0]?.questionType != 'feedback'">
  <ebs-offline-message [isOffline]='isOffline' [offlineMessage]='offlineMessage'></ebs-offline-message>
</div>

<div *ngIf="!loadingData">
  <div #target></div>
  <div class="card-body">
    <div class="row">
      <div *ngIf="activityItem.quizName" class="col-sm-10">
        <p>{{activityItem.quizName}}</p>
      </div>
    </div>
    <!-- I think "x == null" checks for both undefined and null, so the if can be simplified -->
    <div *ngIf="!activityItem.questions || activityItem.questions === undefined || activityItem.questions.length === 0">
      <ebs-message-simple [iconCssClasses]="'fas fa-exclamation-circle fa-lg'" [textCssClasses]="''" *ngIf="!isOffline"
        [displayMessage]="'This quiz has no questions'"></ebs-message-simple>
    </div>
    <div *ngIf="activityItem && activityItem.questions && activityItem.questions.length > 0">
      <div *ngFor="let question of activityItem.questions">
        <div class="row is-feedback-question" *ngIf="question.questionType == 'feedback'">
          <div class="col-md-12 its">
            <h2 class="card-header">{{question.name}}</h2>
          </div>
        </div>
        <div class="row" *ngIf="question.questionType != 'feedback'">
          <div class="col-md-12">
            <h3>{{question.name}}</h3>
          </div>
        </div>
        <div *ngIf="question.questionType=='essay'">
          <div class="row">
            <div [innerHtml]="question.text | domSanitizerPipe: 'html'" class="col-md-12"></div>
          </div>
          <ebs-essay-question [activityItemId]='activityItem.id' [question]='question'></ebs-essay-question>
        </div>
        <div *ngIf="question.questionType=='multiple-choice'">
          <div class="row">
            <div [innerHtml]="question.text | domSanitizerPipe: 'html'" class="col-md-12"></div>
          </div>
          <ebs-multiple-choice-question [question]='question' (notify)='markIncomplete()' [showFeedback]='showFeedback'>
          </ebs-multiple-choice-question>
          <p></p>
        </div>
        <div *ngIf="question.questionType=='multiple-select'">
          <div class="row">
            <div [innerHtml]="question.text | domSanitizerPipe: 'html'" class="col-md-12"></div>
          </div>
          <ebs-multiple-answers-question [question]='question' (notify)='markIncomplete()'
            [showFeedback]='showFeedback'></ebs-multiple-answers-question>
          <p></p>
        </div>
        <div *ngIf="question.questionType=='paired'">
          <div class="row">
            <div [innerHtml]="question.text | domSanitizerPipe: 'html'" class="col-md-12"></div>
          </div>
          <ebs-match-the-pairs-question (notify)='markIncomplete()' [question]='question' [showFeedback]='showFeedback'>
          </ebs-match-the-pairs-question>
          <p></p>
        </div>
        <div *ngIf="question.questionType=='numerical'">
          <div class="row">
            <div [innerHtml]="question.text | domSanitizerPipe: 'html'" class="col-md-12"></div>
          </div>
          <ebs-numerical-question (notify)='markIncomplete()' [question]='question' [showFeedback]='showFeedback'>
          </ebs-numerical-question>
          <p></p>
        </div>
        <div *ngIf="question.questionType=='feedback'">
          <ebs-feedback-question [activityItemId]='activityItem.id' [question]='question'></ebs-feedback-question>
          <p></p>
        </div>
      </div>
    </div>
    <p></p>
    <div #scoretarget></div>
   
    <hr *ngIf="activityItem && activityItem.questions && activityItem.questions[0].questionType != 'feedback' 
    && activityItem.questions[0].questionType != 'essay'">
    <div *ngIf="activityItem && activityItem.questions && (activityItem.questions.length > 1 || activityItem.questions[0].questionType === 'feedback')">
      <ebs-offline-message [isOffline]='isOffline' [offlineMessage]='offlineMessage'></ebs-offline-message>
    </div>
    <div *ngIf="!isOffline">
      <div class="row">
        <div class="col-12">
          <ebs-notification-multiple [notifications]="notifications" *ngIf="callFailed"></ebs-notification-multiple>
        </div>
      </div>
      <div *ngIf="activityItem && activityItem.questions && activityItem.questions[0].questionType != 'essay' && activityItem.questions[0].questionType!='feedback'" class="d-flex justify-content-between align-items-center">
        <span class="text-info">
          <div [@pulse]="showFeedback" *ngIf="showFeedback" style="font-size: 130%">You scored {{totalScore}} out of
            {{totalPointsAvailable}} points</div> <br>
          <span *ngIf="showFeedback"><a style="cursor:pointer;color: blueviolet"
              (click)="scrollToAnchor(target)"><strong>Review answers</strong></a></span>
        </span>
        <button (click)="restart(target)" [hidden]="!showFeedback" class="btn btn-primary">Take the test again</button>
      </div>
      <!-- <div *ngIf="activityItem && activityItem.questions && activityItem.questions.length > 1 && activityItem.questions[0].questionType != 'feedback' && activityItem.questions[0].questionType != 'essay'" -->
      <div *ngIf="activityItem && activityItem.questions && activityItem.questions[0].questionType != 'essay' > 1 && activityItem.questions[0].questionType != 'feedback' && activityItem.questions[0].questionType != 'essay'"
 
        class="d-flex justify-content-between align-items-center">
        <span class="text-warning">
          <span [ngClass]="{'no-print': true}" *ngIf="!showFeedback && !isComplete">One or more questions are incomplete</span><br>
        </span>
        <!-- <div #scoretarget></div> -->
        <button (click)="submit(scoretarget)" [hidden]="showFeedback" class="btn btn-primary">Submit answers</button>
      </div>
    </div>
  </div>
</div>