import { ChoiceOption } from './choice-option';
import { OptionsQuestion } from './options-question';


export class MultipleChoiceQuestion extends OptionsQuestion {

    constructor(title: string, questionText: string, options: Array<ChoiceOption>) {
        super(title, questionText, options, 'multiple-choice');
    }

    public getMark(): number {
        let score = 0;
        for (let option of this.options) {
            if (option.isCorrect && option.isSelected) {
                score = this.pointsAvailable;
            }
        }

        return score;
    }
}
