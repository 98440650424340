import { Injectable, Inject } from '@angular/core';
import { CurrentCourse } from '@app/common/models/current-course'
import { ServiceBase } from '@app/core/service-base';
import { Observable, Subscriber } from 'rxjs';
import { StaticText } from '../static-text';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { ObjectStore } from '@app/core/object-store';
import { ToastrService } from 'ngx-toastr';
import { ServiceBaseHelpers } from '@app/core/service-base-helpers';
import { CourseAdminService } from '@app/admin/services/course-admin.service';
import { ObjectStoreBase } from '@app/core/object-store-base';
import { ConfigurationHelperService } from '../app-configuration/configuration-helper.service';

@Injectable({
    providedIn: 'root'
})
export class ApplicationContext extends ServiceBase {
    
    constructor(private courseService: CourseAdminService, private router: Router, http: HttpClient,
    objectStore: ObjectStoreBase,
    toastr: ToastrService,
    helpers: ServiceBaseHelpers,
    configurationService:ConfigurationHelperService){
        super(http, objectStore, toastr, helpers, configurationService);
    }

    public courseEnvironmentUri = StaticText.currentCourseUrlNoSlash;

    public getCourseFromLocalStore(): Observable<CurrentCourse> {
        return this.getObjectFromLocalStoreOnly(this.courseEnvironmentUri);
    }

    public callbackToFetchCourseInfoIfIndexedDbFails(uri:string, callBackObserver: Subscriber<CurrentCourse>){
        
        var routeSegments = this.router.parseUrl(this.router.url).root.children.primary;
        var firstRouteSegment = routeSegments.segments.length > 0 ? routeSegments.segments[0].path : null;

        const courseCode = firstRouteSegment === 'courses' ? routeSegments.segments[1].path : null;

        if (courseCode != null){
            const currentCourse = new CurrentCourse();
            currentCourse.courseCode = courseCode.toLowerCase();
            this.courseService.getCourses().subscribe(x => {
                if (x.operationSucceeded === true){
                    const allCourses = x.data;
                    const currentCourseInfo = allCourses.find(y => y.courseCode.toLowerCase() === courseCode);

                    if (currentCourseInfo != null)
                        currentCourse.courseName = currentCourseInfo.courseName;
                } 
                
                callBackObserver.next(currentCourse);
                callBackObserver.complete();
            }, 
            error => {
                callBackObserver.next(currentCourse);
                callBackObserver.complete();
            });
        }
        else {
            callBackObserver.next(null);
            callBackObserver.complete();
        }
        
    }

    public getCurrentCourseFromLocalStoreOrFromCurrentContext(): Observable<CurrentCourse> {

        const observable = new Observable<CurrentCourse>(observer => {

            this.getFromLocalStoreWithErrorCallback(this.courseEnvironmentUri, observer, (uri, callBackObserver) =>{
                this.callbackToFetchCourseInfoIfIndexedDbFails(uri, callBackObserver);              
            });
        });

        return observable;
    }

    public setCourseInLocalStore(currentCourse: CurrentCourse): Observable<CurrentCourse> {
        return this.setObjectInLocalStore(currentCourse, this.courseEnvironmentUri);
    }

    public getCurrentCourse(): Observable<CurrentCourse>{      
        //return this.getCourseFromLocalStore();         
        return this.getCurrentCourseFromLocalStoreOrFromCurrentContext();  
    }

    public setCurrentCourse(courseCode: string, courseName: string):void {
        
        if (!courseCode || courseCode === '') return;
        if (!courseName || courseName === '') return;
 
        const currentCourse = new CurrentCourse();

        currentCourse.courseCode = courseCode;
        currentCourse.courseName = courseName;
        currentCourse.uri = this.courseEnvironmentUri;

        this.setCourseInLocalStore(currentCourse).subscribe(
            x => { 
            },
            error => {
                console.error(error);
                this.notifyApplicationError('Could not save current course in local store', 'Something went wrong');
            }
        );
    }
}