import { Component, OnInit, OnDestroy } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap';
import { ApplicationEventType } from '@app/core/application-event-type';
import { ApplicationEventDispatcher } from '@app/core/application-event-dispatcher.service';
import { ApplicationEvent } from '@app/core/application-event';
import { Subscription } from 'rxjs';

@Component({
  selector: 'ebs-modal-offline',
  templateUrl: './modal-offline.component.html',
  styleUrls: ['./modal-offline.component.scss']
})
export class ModalOfflineComponent implements OnInit, OnDestroy {
  title: string;
  answer = '';
  theSubscription: Subscription

  constructor(
    public bsModalRef: BsModalRef,
    private applicationEventDispatcher: ApplicationEventDispatcher
  ) {
    this.theSubscription = this.applicationEventDispatcher.offlineModeRelatedEvent.subscribe((event: ApplicationEvent) => {
      if (event.type === ApplicationEventType.OfflinePopupClosed) {
      
        this.bsModalRef.hide();
      }
    });
   }

  ngOnInit() { }

  ngOnDestroy(): void {
    this.theSubscription.unsubscribe();
  }
  
}