export class StaticText {
    static iconChevronLeft = 'icon-gecko-chevron-left';
    static iconChevronRight = 'icon-gecko-chevron-right';
    static iconShowLines = 'icon-gecko-show-lines';
    static iconDelete = 'icon-gecko-delete';
    static minimiseCss = 'sidebar-minimised';
    static showActivityCss = 'activity-show';
    static emptyCss = '';
    static moduleButtonClasses = 'btn btn-block btn-nav-dropdown';
    static moduleButtonClassesCollapsed = 'btn btn-block btn-nav-dropdown collapsed';
    static bodyColour = '#5B6770';
    static defaultSpinnerMessage = 'loading your data';
    static defaultSpinnerFont = 1.1;
    static defaultSpinnerPadding = '5%';
    static nextText = 'Next';
    static prevText = 'Previous';
    static progressCongrats = 'Congratulations on completing this section';
    static progressReset = 'Reset status to incomplete';
    static progressHeader = 'How are you progressing?';
    static progressIncompleteTextLine1 = 'To help keep track of your overall progress, please confirm when you have completed this section.';
    static progressIncompleteTextLine2 = `Don't worry you will be to reset this if you make a mistake.`;
    static thirdPartyMessageOnline = '3rd party content may be unavailable in your region and may contain ads.';
    static thirdPartyMessageOffline = 'This 3rd party content is only available online.';
    static currentUserUrl = '/currentuser';
    static currentUserNoSlash = 'currentuser';
    static currentCourseUrl = '/currentcourse';
    static isWorkingOffline = 'isworkingoffline';
    static currentCourseUrlNoSlash = 'currentcourse';
    static progressStatusError = 'There has been an error updating the status of this section. Please try later ';
    static discussionOfflineMessage = 'Discussions are currently only available when connected to the internet.';
    static quizOfflineMessage = 'Offline Mode: Answers can only be saved when online.';
    static kortextOfflineMessage = 'Offline Mode: The link to Kortext is only available when online. Please go to Kortext app if you have downloaded the ebook.';
    static courseraOfflineMessage = 'Offline Mode: The link to Coursera is only available when online.';
    static progressOfflineMessage = 'Offline Mode: Progress can only be saved when online.';
    static understandOfflineMessage = `Offline Mode: Answers can only be saved when online.`;
    static sectionOfflineMessage = `Offline Mode: This section is not currently available in offline mode as you haven't downloaded it.`;
    static OfflineMessage = 'Offline Mode: Answers can only be saved when online.';
    static adminOfflineMessage = 'Offline Mode: Admin functionality is unavailable offline.';
    static printablesOfflineMessage = 'Offline Mode: The ability to download printable materials is unavailable offline.';
    static downloadedOfflineMessage = 'Offline Mode: The ability to download content is unavailable offline.';
    static downloadsOfflineMessage = 'Offline Mode: Download functionality is unavailable offline.';
    static dashboardOfflineMessage = 'Some features of your Student Portal may be unavailable offline.';
    static dashboardOfflineNotAvailableMessage = 'This course is not available for offline access via your student portal, please download the course text via PDF or ebook format when you have internet access.';
    static dashboardOfflineNotDownloadedMessage = 'No course modules have been downloaded for offline viewing on this device.';
    static courseDashboardOfflineMessage = 'This page may be out of date, you must connect to the internet to see your latest dashboard information.';
    static discussionOfflineReplyToOwnPost = 'In offline mode you cannot reply to your own post';
    static fileIFrameMessageOnline = 'File IFrame content may be unavailable in your region.';
    static fileIFrameMessageOffline = 'Offline Mode: Interactive content is unavailable while online.';
    static gdprMessage = 'When you post a message, your name will be published against the post. Optionally, your photo and a country of residence will also be published depending on your preference settings.';
    static downloadsPara1 = `Your learning modules are available for download for your usage in advance of when you don't have an internet connection or don't want to use your internet connection (e.g. your mobile data).`
    static downloadsPara2 = `Use the options below to manage your download modules on your PC or mobile. This means you can access them when you’re offline, and also helps you to make the most efficient use of your mobile data. Please note:`
    static downloadsOption1 = `Offline modules will not include third-party learning materials (e.g. YouTube videos and links to a third party websites). You must go online to access these. Offline modules do include the videos created by Edinburgh Business School.`
    static downloadsOption2 = `If this PC or mobile is also used by other students using this platform, any downloads you have will be lost if they login.`
    static downloadsOption3 = `We recommend that you synchronise regularly. Your course dashboard will alert you if you have info that needs synchronised.`
    static offlineModeStatus = `offlinemodestatus`;
    static offlineDeleteConfirmation = `All your offline mode modules for all your courses on this PC/device will be deleted along with the information stored on this PC/device to enable your offline mode, i.e. your programme of study, photo, name and country of residence. \n\nAre you sure you want to proceed?`;
    static serverError = 'There has been an error connecting to the server. ';
    static noInternetError = 'No internet connection could be found. ';
    static errorWorkOffline = 'Work offline';
    static errorClose = 'Close';
    static errorTryAgain = 'Try again';
    static downloadContinue = 'Continue downloading';
    static downloadRestart = 'Start over';
    static activityStreamOfflineMessage = 'Your activity stream is currently only available when connected to the internet. ';
    static forumOfflineMessage = 'The course forum is only available when you are online / connected to the internet';
    static subsOfflineMessage = 'Subscriptions are only available when you are online / connected to the internet';
    static noDiscussionIdMessage = 'This page is unavailable without a discussion ID';
    static noTag = 'Please select a tag or add a new one';
    static postAndReplies = 'Post and replies';
    static postAndRepliesFaculty = 'Post and replies from Faculty';
    static downloadFailedMessage = 'Sorry - we could not download your course materials. \n\n Please check your internet connection and ensure you have plenty of free storage space. (Your device may require you to have significantly more free space than the size of your download.)';
    static suspiciousDownloadMessage = 'You started downloading this module more than 10 minutes ago. You are either on a slow internet connection or your device has insufficient space to complete the download. Note: you must have at least 10% free space on your  PC / Device or your browser will not download the videos.';
    static demoUserModalHeader = 'Demo User Information';
    static demoUserModalBody = 'Sorry this function is unavailable for demo users';
    static closeText = 'Close';
    static cancelText = 'Cancel';
}
