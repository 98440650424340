import { Component, OnInit, HostListener, OnDestroy } from '@angular/core';
import { SystemNotificationService } from '@app/admin/services/system-notification.service';
import { SystemNotifications } from '@app/admin/models/system-notifications';
import { SystemNotification } from '@app/admin/models/system-notification';
import { OperationResponse } from '@app/common/models/operation-response';
import { StaticText } from '@app/common/static-text';
import { Subscription } from 'rxjs';
import { OnlineStatusService } from '@app/common/online-status/online-status.service';
import { OnlineStatusType } from '@app/common/online-status/online-status-type.enum';
import { NotificationSummary } from '@app/common/models/notification-summary';
import { SeverityLevel } from '@app/common/models/severity-level';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'ebs-system-notification',
  templateUrl: './system-notification.component.html',
  styleUrls: ['./system-notification.component.scss'],
  providers: [SystemNotificationService]
})
export class SystemNotificationComponent implements OnInit, OnDestroy {
  public systemNotificationList: SystemNotifications;
  public isLoading = false;
  failedMessage = 'The notifications have failed to load. Please try again';
  counter = 99;
  spinnerMessage = "Checking for notifications";
  isOffline: boolean;
  theSubscription: Subscription;
  adminOfflineMessage = StaticText.adminOfflineMessage;
  intervalId: any;
  paused: boolean;
  operationFailed = false;

  constructor(
    private titleService: Title,
    private systemNotificationService: SystemNotificationService,
    private onlineStatusService: OnlineStatusService,
  ) {
    this.onlineStatusService.isOffline().subscribe(x => {
      this.isOffline = x;
    }, error => {
      this.isOffline = false;
    });
    this.theSubscription = this.onlineStatusService.status.subscribe(
      event => {
        if (event === OnlineStatusType.OFFLINE) {
          this.isOffline = true;
          this.stopTheInterval();
        }

        if (event === OnlineStatusType.ONLINE) {
          this.isOffline = false;
          this.initialiseNotifications();
        }
      }
    )
  }

  ngOnInit() {
    if (!this.isOffline)
      this.initialiseNotifications();

    this.titleService.setTitle('System notifications');
  }

  pauseUpdates(pause: boolean) {
    if (pause) {
      this.paused = true;
      this.stopTheInterval();
    } else {
      this.paused = false;
      this.initialiseNotifications();
    }

  }

  hardDelete(){
    this.isLoading = true;
    this.operationFailed = false;
    //this will only happen when online so just hard code treatasoffline to false
    this.systemNotificationService.getNotifications("notifications/hardDelete", false)
      .subscribe((notificationsResponse: OperationResponse<SystemNotifications>) => {
        if (notificationsResponse.operationSucceeded) {
          this.systemNotificationList = notificationsResponse.data;
        } else {
          this.operationFailed = true;
          for (let i = 0; i < notificationsResponse.notifications.length; i++) {
            this.failedMessage += ' | ' + notificationsResponse.notifications[i].message;
          }
        }
        this.isLoading = false;
      }, error => {
        this.isLoading = false;
      })
  }

  softDelete(){
    this.isLoading = true;
    this.operationFailed = false;
    //this will only happen when online so just hard code treatasoffline to false
    this.systemNotificationService.getNotifications("notifications/softDelete", false)
      .subscribe((notificationsResponse: OperationResponse<SystemNotifications>) => {
        if (notificationsResponse.operationSucceeded) {
          this.systemNotificationList = notificationsResponse.data;
        } else {
          this.operationFailed = true;
          for (let i = 0; i < notificationsResponse.notifications.length; i++) {
            this.failedMessage += ' | ' + notificationsResponse.notifications[i].message;
          }
        }
        this.isLoading = false;
      }, error => {
        this.isLoading = false;
      })
  }

  initialiseNotifications() {
    this.getNotifications();

    this.intervalId = setInterval(() => {
      this.counter = this.counter - 1;
      this.getNotifications();
      if (this.counter === 0) this.counter = 99;
    }, 8000)
  }

  getNotifications() {
    this.isLoading = true;
    this.operationFailed = false;
    //this will only happen when online so just hard code treatasoffline to false
    this.systemNotificationService.getNotifications("notifications", false)
      .subscribe((notificationsResponse: OperationResponse<SystemNotifications>) => {
        if (notificationsResponse.operationSucceeded) {
          this.systemNotificationList = notificationsResponse.data;
        } else {
          this.operationFailed = true;
          for (let i = 0; i < notificationsResponse.notifications.length; i++) {
            this.failedMessage += ' | ' + notificationsResponse.notifications[i].message;
          }
        }
        this.isLoading = false;
      }, error => {
        this.isLoading = false;
      })
  }

  stopTheInterval() {
    clearInterval(this.intervalId);
  }

  ngOnDestroy(): void {
    this.stopTheInterval();

    if (this.theSubscription)
      this.theSubscription.unsubscribe();
  }
}