import { StorableObject } from "@app/core/storable-object";
import { Person } from "./person";

export class DiscussionLike extends StorableObject {
    id: string;
    clientModifiedAt: Date
    postId: string;
    replyId: string; //null if post
    person: Person;
    discussionId: string; //FE only for event bubble
    numberOfLikes: number; //FE only for event bubble
    likedByMe: boolean; //FE only for event bubble
    isActive: boolean // same as above really
}