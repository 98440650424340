import { ForumListItem } from "./forum";
import { PagedResult } from "@app/common/models/paged-result";
import { PagedRequest } from "@app/common/models/paged-request";
import { Tag } from "./tag";

export class FilterState {
    clickedItemIndex: number;
    pagedResults: PagedResult<ForumListItem> = new PagedResult<ForumListItem>();
    pagedRequest: PagedRequest<ForumListItem> = new PagedRequest<ForumListItem>();
    currentTags: Tag[] = [];
    allTags: Tag[] = [];
}