import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { Router, NavigationStart } from '@angular/router';
import { OnlineStatusService } from '@app/common/online-status/online-status.service';
import { OnlineStatusType } from '@app/common/online-status/online-status-type.enum';
import { Subscription } from 'rxjs';
import { StaticText } from '@app/common/static-text';
import { Title } from '@angular/platform-browser';
import { ForumStateService } from '../../services/forum-state.service';
import { UserService } from '@app/common/security/user.service';
import { UserProfile } from '@app/common/models/user';

@Component({
  selector: 'ebs-forum-list',
  templateUrl: './forum-list.component.html',
  styleUrls: ['./forum-list.component.scss']
})
export class ForumListComponent implements OnInit, OnDestroy {
  @Input() courseCode: string;
  addLink: string;
  theSubscription: Subscription;
  forumOfflineMessage = StaticText.forumOfflineMessage;
  showFilter = true;
  isOffline = false;
  showingSearchInline = false;
  routerEventSubscription: Subscription;
  isDemoUser = true;

  constructor(
    private titleService: Title,
    private router: Router,
    private onlineStatusService: OnlineStatusService,
    private forumStateService: ForumStateService,
    private userService: UserService,
  ) {
    this.onlineStatusService.isOffline().subscribe(x => {
      this.isOffline = x;
    }, error => {
      this.isOffline = false;
    });
    this.theSubscription = this.onlineStatusService.status.subscribe(
      event => {
        if (event === OnlineStatusType.OFFLINE) {
          this.isOffline = true;
        }
        if (event === OnlineStatusType.ONLINE) {
          this.isOffline = false;
        }
      }
    );

    this.routerEventSubscription = this.router.events.subscribe((val) => {
      if (val instanceof NavigationStart) {
        if (val.url.indexOf('/-4') > -1 || val.url.indexOf('/-6') > -1) {
        } else {
          //remove from state
          this.forumStateService.resetState();
        }
      }
    });

    this.userService.getCurrentUser()
      .subscribe((userProfileObs: UserProfile) => {
        userProfileObs.userAccount.roles.find(x => x.toString() == "Demo") ? this.isDemoUser = true : this.isDemoUser = false;
      }, error => {
        console.log(error);
      });

  }

  ngOnInit() {
    if (this.forumStateService.currentState && this.forumStateService.currentlySearching) this.showFilter = false

    this.addLink = this.router.url.substring(0, this.router.url.lastIndexOf('/') + 1) + '-5';
    this.titleService.setTitle('Course forum');
  }

  ngOnDestroy(): void {
    if (this.theSubscription) {
      this.theSubscription.unsubscribe();
    }

    if (this.routerEventSubscription) {
      this.routerEventSubscription.unsubscribe();
    }
  }

  tabClick(show) {
    this.showFilter = show;
    if (show) {
      this.forumStateService.currentlySearching = false;
    } else {
      this.forumStateService.currentlySearching = true;
    }
  }

}