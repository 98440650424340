<div class="pl-2">
  <ebs-offline-message [isOffline]='isOffline' [offlineMessage]='offlineMessage'></ebs-offline-message>
</div>
<div class="card-body" *ngIf="!isOffline">
    <form ngNoForm #KortextForm target="Kortext"  action="{{formActionUrl}}" method="POST">
  
      <input name="lis_person_contact_email_primary" type="hidden" [value]="email">
      <input name="lis_person_name_full" type="hidden" [value]="fullName">
  
      <ng-container *ngIf="hasCustomId()">
        <input name="custom_id" type="hidden" [value]="customId">
      </ng-container>
  
      <ng-container *ngIf="hasCustomPage()">
        <input name="custom_page" type="hidden" [value]="customPage">
      </ng-container>
  
      <input name="context_label" type="hidden" [value]="contextLabel">
      <input name="context_title" type="hidden" [value]="contextTitle">
  
      <input name="oauth_consumer_key" type="hidden" [value]="oauthConsumerKey">
      <input name="oauth_timestamp"  type="hidden" [value]="oauthTimestamp">
      <input name="oauth_signature"  type="hidden" [value]="oauthSignature">
      <span class="d-none d-print-block"><i class="fas fa-exclamation-circle fa-fw"></i> Please go to your student portal to interact with your eBook account</span>
      <button type="button" class="btn btn-primary" (click)="completeFormDataWithTimeStampAndSignature()" >{{getButtonText()}}</button>
    </form>
  </div>