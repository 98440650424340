import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SpinnerComponent } from './spinner/spinner.component';
import { ProgressBarComponent } from './progress-bar/progress-bar.component';
import { ProgressbarModule } from 'ngx-bootstrap/progressbar';
import { AlertModule } from 'ngx-bootstrap/alert';
import { ModalModule } from 'ngx-bootstrap';
import { NotificationComponent } from './notification/notification.component';
import { NotificationMultipleComponent } from './notification/notification-multiple.component';
import { EditorModule } from '@tinymce/tinymce-angular';
import { FormsModule } from '@angular/forms';
import { NextPreviousComponent } from './next-previous/next-previous.component';
import { MessageSimpleComponent } from './message-simple/message-simple.component';
import { MetaHeaderComponent } from './meta-header/meta-header.component';
import { StickyHeaderComponent } from './sticky-header/sticky-header-component';
import { DomSanitizerPipe } from '../pipes/dom-sanitizer-pipe';
import { TechnicalContentDirective } from '../content-rendering/technical-content.directive';
import { OfflineModeComponent } from './offline-mode/offline-mode.component';
import { OfflineMessageComponent } from './offline-message/offline-message.component';
import { SearchComponent } from './search/search.component';

@NgModule({
  imports: [
    CommonModule,
    ProgressbarModule.forRoot(),
    AlertModule.forRoot(),
    ModalModule.forRoot(),
    EditorModule,
    FormsModule,
  ],
  declarations: [
    SpinnerComponent,
    ProgressBarComponent,
    NotificationComponent,
    NotificationMultipleComponent,
    NextPreviousComponent,
    MessageSimpleComponent,
    MetaHeaderComponent,
    StickyHeaderComponent,
    DomSanitizerPipe,
    TechnicalContentDirective,
    OfflineModeComponent,
    OfflineMessageComponent,
    SearchComponent,
  ],
  exports: [
    SpinnerComponent,
    ProgressBarComponent,
    NotificationComponent,
    NotificationMultipleComponent,
    NextPreviousComponent,
    MessageSimpleComponent,
    MetaHeaderComponent,
    StickyHeaderComponent,
    CommonModule,
    EditorModule,
    DomSanitizerPipe,
    TechnicalContentDirective,
    OfflineModeComponent,
    OfflineMessageComponent,
    SearchComponent,
  ]
})
export class ComponentsModule { }
