
<!-- <div style="width:960px; height:600px; text-align: center" id="game"> -->
  <div  id="game">
    <div id="gameContainer" class="unity-container"></div>
</div>

<div id="instruction">
    <div id="novr" class="panel center">
        <h3>You&rsquo;ll need a <a href="https://webvr.rocks/">WebVR-enabled browser</a> and VR headset to fully enjoy
            this experience.</h3>
        <p><img src="./assets/webvr/mousedrag.png" width="70" alt="Click-and-drag your mouse"></p>
        <p>In the meantime, <strong><em>click and drag</em></strong> to have a look around!</p>
        <p>
            <button class="confirm">Continue</button>
        </p>
    </div>
</div>

<button [style.display]="buttonStyle == true ? 'block' : 'none'" id="entervr" value="Enter VR"></button>

<div id="performance" data-enabled="false"></div>