import { StorableObject } from "@app/core/storable-object";
import { Person } from "./person";
import { DiscussionLike } from "./like";
import { Attachment } from "./attachment";

export class DiscussionPostOrReplyItem extends StorableObject {
    id: string;
    postId: string; //if it is a reply it will have a parent (postid)
    discussionId: string;
    courseCode: string;
    content: string;
    person: Person;
    clientModifiedAt: Date;
    likes: Array<DiscussionLike> = new Array<DiscussionLike>();
    likeCount: number;
    isMyEntry: boolean;
    likedByMe: boolean;
    myLikedId: string;
    replies: Array<DiscussionPostOrReplyItem> = new Array<DiscussionPostOrReplyItem>();
    attachments: Array<Attachment> = new Array<Attachment>();
}