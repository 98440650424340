import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { NotificationSummary } from '@app/common/models/notification-summary';
import { PlatformLocation } from '@angular/common';

@Component({
  selector: 'ebs-webvr-demo',
  templateUrl: './webvr-demo.component.html',
  styleUrls: ['./webvr-demo.component.scss']
})
export class WebvrDemoComponent implements OnInit {
  @Input() gameConfiguration;
  ngOnInit(): void {
    this.load("WebVR");
  }
  @ViewChild('unityView', { static: false }) unityView;
  baseUrl: string;
  project: string;
  title="EBS Unity";
  notifications: Array<NotificationSummary> = new Array<NotificationSummary>();
  showSaved: boolean;

  constructor(platformLocation: PlatformLocation) {
    const location = (platformLocation as any).location;
    this.baseUrl = location.origin + location.pathname;
    console.log('baseUrl', this.baseUrl);
  }

  load(name: string) {
    this.project = name;
    // this.unityView.loadProject(`../../../assets/WebVR/WebVR.json`);
  }

  send(objectName: string, methodName: string, messageValue: string) {
    this.showSaved = false;
    this.unityView.sendMessageToUnity(objectName, methodName, messageValue);
  }

  saveState(){
    this.send('GameViewModel','SaveState','');
    this.showSaved = true;
  }

}
