<ebs-search [hideInput]= "hideInput" (searchText)="handleSearchText($event)"></ebs-search>

<ebs-spinner [spinnerMessage]="'getting search items'" *ngIf="gettingSearchItems"></ebs-spinner>
<div>
  <ng-container *ngIf="!gettingSearchItems">
    <div *ngFor="let item of searchItems; index as i" class="pl-3 pr-3 pb-0 pt-0">
      <hr *ngIf="i === 0">
      <div class="d-flex flex-row mt-2">
        <div id="index{{i}}" class="click-link header-link">
          <a [routerLink]="[getLink(item.uri)]" [queryParams]="getQueryParams(item.uri)"  (click)="routeFromSearchResult(item.uri, i)"
             [fragment]="getFragment()">{{item.displayTitle}} </a>
        </div>
      </div>
      <div class="d-flex flex-column">
        <div class="text-muted">{{item.summary}}</div>
        <div class=" mt-2 forum-description">
          <span innerHTML="{{item.content | texttruncate: 200}}"> </span>
        </div>
      </div>
      <hr>
    </div>
  </ng-container>

  <ebs-spinner [spinnerMessage]="'getting more search results'" *ngIf="loadingMore"></ebs-spinner>
  <!-- Load More -->
  <ng-container *ngIf="searchItems.length > 0">
    <div class="row mt-3" *ngIf="!noItemsReturned && !itemsLessThanItemsPerPage">
      <div class="col-12 col-sm-6 offset-sm-3 text-center">
        <span (click)="loadMoreSearchItems()" class="btn btn-light btn-sm">
          <i class="fas fa-arrow-down"></i> Load more
        </span>
      </div>
    </div>
  </ng-container>
  <ebs-message-simple [iconCssClasses]="'text-info fa fa-fw fa-exclamation-circle'" [textCssClasses]="'text-info'"
    [displayMessage]="'There are no more search results.'" *ngIf="thereAreNoMore()"></ebs-message-simple>
    <p></p>
</div>