import { QuizQuestion } from './quiz-question';

export class QuestionAnswer extends QuizQuestion {
    
    constructor(title: string, questionText: string, questionType:string) {
        super(title, questionText, questionType);
    }

    public getMark(): number {
        return this.score;
    }
}
