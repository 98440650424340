import { Component, OnInit, Input } from '@angular/core';
import { Alerts } from '@app/common/models/alerts';
import { SeverityLevel } from '@app/common/models/severity-level';

@Component({
  selector: 'ebs-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss']
})
export class NotificationComponent implements OnInit {
  @Input() severityLevel: SeverityLevel;
  @Input() alertMessage: string;
  @Input() dismissible: boolean;
  @Input() timeout: number;
  alertIcon: string;
  type: string;

  constructor() { }

  ngOnInit() {
    switch (this.severityLevel) {
      case SeverityLevel.Error: {
        this.type = 'danger';
        break;
      }
      case SeverityLevel.Query: {
        this.type = 'success';
        break;
      }
      case SeverityLevel.Warning: {
        this.type = 'warning';
        break;
      }
      case SeverityLevel.Info: {
        this.type = 'info';
        break;
      }
      default: {
        this.type = 'success';
        break;
      }
    }

    this.alertIcon = Alerts[this.type];
  }
}
