import { Component, Input, OnInit, Output, EventEmitter, OnDestroy, OnChanges, SimpleChanges, AfterViewInit } from '@angular/core';
import { transAnimation } from '@app/common/animations/animations';
import { StaticText } from '@app/common/static-text';
import { Subscription, Observable } from 'rxjs';
import { Helpers } from '@app/common/helpers';
import { CourseNavigationService } from '@app/courses/services/course-navigation.service';
import { CourseStructure } from '@app/courses/models/course-structure';
import { OperationResponse } from '@app/common/models/operation-response';
import { Router, ActivatedRoute } from '@angular/router';
import { map, first } from 'rxjs/operators';
import { ApplicationContext } from '@app/common/current-context/application-context';
import { UserService } from '@app/common/security/user.service';
import { StateService } from '@app/core/state.service';
import { OnlineStatusService } from '@app/common/online-status/online-status.service';
import { OnlineStatusType } from '@app/common/online-status/online-status-type.enum';
import { ModalSimpleService } from '@app/common/components/modal-simple/modal-simple.service';
import { DownloadsService } from '@app/downloads/services/downloads.service';
import { Section } from '@app/courses/models/section';
import { NotificationSummary } from '@app/common/models/notification-summary';

@Component({
  selector: 'ebs-course-menu-sidebar',
  templateUrl: './course-menu-sidebar.component.html',
  styleUrls: ['./course-menu-sidebar.component.scss'],
  animations: [transAnimation]
})
export class CourseMenuSidebarComponent implements OnInit, OnDestroy, OnChanges {
  @Input() courseCode: string;
  @Input() isItLeftIcon: boolean;
  @Input() updatePosition;
  @Input() menuUpdate: boolean;
  @Output() tellParentCloseMenu = new EventEmitter<boolean>();
  @Output() tellParentMobileMenuClick = new EventEmitter<boolean>();
  @Output() tellParentCloseActivity = new EventEmitter<boolean>();
  @Output() menuNoConnection = new EventEmitter<{ showError: boolean, errorText: string }>();
  @Output() passBackCourseNameAndLink = new EventEmitter<{ name: string, link: string }>();
  @Output() sectionChange = new EventEmitter<boolean>();
  menuStartPosition: string;
  isCollapsed: boolean[];
  collapsedClass: string[];
  showRight = false;
  loadingData = false;
  leftIcon = StaticText.iconChevronLeft;
  rightIcon = StaticText.iconChevronRight;
  moduleClass = StaticText.moduleButtonClasses;
  moduleClassCollapsed = StaticText.moduleButtonClassesCollapsed;
  courseStructure: CourseStructure = new CourseStructure('1', [], []);
  courseSub: Subscription;
  oldIndex: number;
  failedMessage = 'The menu has failed to load. Please try again';
  moduleId: number;
  routeChecked = false;
  initialUrl: string;
  isOffline = false;
  isASectionCall = false;
  showError = false;
  moduleLength = 0;
  theSubscription: Subscription;
  localSub: Subscription;
  keepCurrentIndex = false;

  constructor(
    private courseNavigationService: CourseNavigationService,
    private router: Router,
    private appContext: ApplicationContext,
    private userService: UserService,
    private route: ActivatedRoute,
    private stateService: StateService,
    private modalSimpleService: ModalSimpleService,
    private onlineStatusService: OnlineStatusService,
    private downloadsService: DownloadsService) {

    this.onlineStatusService.isOffline().subscribe(x => {
      this.isOffline = x;
    }, error => {
      this.isOffline = false;
    });

    this.theSubscription = this.onlineStatusService.status.subscribe(
      event => {
        if (event === OnlineStatusType.OFFLINE) {
          this.getSynchronisedCourseData();
          this.isOffline = true;
        }
        if (event === OnlineStatusType.ONLINE) {
          this.isOffline = false;
        }
      }
    )
  }

  ngOnInit() {
    this.route.paramMap.subscribe(
      params => {
        let secId = +params.get('sectionId');
        if (secId && secId > 0) {
          this.isASectionCall = true;
        } else {
          this.isASectionCall = false;
          if (secId == -4 || secId == -5 || secId == -6 || secId == -7) {
            this.keepCurrentIndex = true;
          }
          this.runForNonSection();
        }
        this.stateService.authSectionChange = true;
        this.sectionChange.emit(true);
        if (this.moduleLength === 0) {
          this.getSynchronisedCourseData();
        } else {
          this.sectionClick(this.router.url, true, true);
        }
      }
    );

  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.isItLeftIcon) {
      this.menuStartPosition = this.leftIcon;
    } else {
      this.menuStartPosition = this.rightIcon;
    }

    for (const propName in changes) {
      if (propName === 'menuUpdate') {
        // has been marked as complete
        const chng = changes[propName];
        if (chng.currentValue) {
          this.getOutlineFromIndexDB();
        }
      }
    }
  }

  getSynchronisedCourseData() {
    this.loadingData = true;
    this.showError = false;
    this.failedMessage = '';

    this.localSub = this.courseNavigationService.getLocalCourseStructure(this.courseCode).subscribe(
      response => {
        this.getLatestCourseData(response);
      },
      error => {
        console.log('error accessing indexeded db: ', error);
        this.getLatestCourseData(null);
      });
  }

  getLatestCourseData(localCourseData: CourseStructure) {
    this.courseSub = this.courseNavigationService.getCourseStructure(this.courseCode, this.isOffline)
      .subscribe(
        (courseResponse: OperationResponse<CourseStructure>) => {
          if (courseResponse.operationSucceeded) {
            this.synchroniseCourseData(localCourseData, courseResponse.data, null);
          }
          else {
            this.synchroniseCourseData(localCourseData, null, courseResponse.notifications);
          }
        }, error => {
          this.synchroniseCourseData(localCourseData, null, null);
        });
  }

  synchroniseCourseData(localCourseData: CourseStructure, latestCourseData: CourseStructure, notifications: NotificationSummary[]) {

    if (localCourseData == null && latestCourseData == null) {

      if (notifications) {
        for (let i = 0; i < notifications.length; i++) {
          this.failedMessage += ' | ' + notifications[i].message;
        }
      }
      else {
        this.failedMessage = 'There has been a problem loading the course. Please try later.'
      }

      this.menuNoConnection.emit({ showError: true, errorText: 'There has been a problem loading the course. Please try later.' })
      this.loadingData = false;
      this.showError = true;
      return;
    }

    const synchronisedCourseData = this.copyLocalDownloadInfoToLatestCourseStructure(localCourseData, latestCourseData);

    this.courseNavigationService.setLocalCourseStructure(this.courseCode, synchronisedCourseData).subscribe(
      res => { }, err => { },
      () => {
        this.moduleLength = synchronisedCourseData.modules.length;
        this.isCollapsed = new Array(this.moduleLength).fill(true);
        this.collapsedClass = new Array(this.moduleLength).fill(this.moduleClassCollapsed);
        this.courseStructure = synchronisedCourseData;

        this.passBackCourseNameAndLink.emit({ name: this.courseStructure.name, link: this.courseStructure.uri });

        if (this.isASectionCall) {
          //only do this if a section url
          this.sectionClick(this.router.url, true, true);
        } else {
          this.runForNonSection();
        }

        this.showError = false;
        this.loadingData = false;
      }
    )
  }

  copyLocalDownloadInfoToLatestCourseStructure(localCourseData: CourseStructure, latestCourseData: CourseStructure): CourseStructure {
    if (localCourseData == null || latestCourseData == null) {
      return latestCourseData != null ? latestCourseData : localCourseData;
    }

    for (let latestModule of latestCourseData.modules) {
      const localModule = localCourseData.modules.find(x => x.id === latestModule.id);

      if (localModule == null) continue;

      latestModule.isContentDownloaded = localModule.isContentDownloaded;
      latestModule.isVideoListDownloaded = localModule.isVideoListDownloaded;
      latestModule.hasPendingUpdates = localModule.hasPendingUpdates;
      latestModule.hasPendingVideoUpdates = localModule.hasPendingVideoUpdates;

      for (let latestSection of latestModule.sections) {
        const localSection = localModule.sections.find(x => x.id === latestSection.id);

        if (localSection == null) continue;

        latestSection.version = localSection.version;
        latestSection.availableUpdateVersion = localSection.availableUpdateVersion;
        latestSection.availableOffline = localSection.availableOffline;
      }

    }

    return latestCourseData;
  }

  runForNonSection() {
    if (Helpers.isItMobile(window.innerWidth)) {
      this.tellParentMobileMenuClick.emit(true);
    }
    let currentIndex = this.courseStructure.links.findIndex(x => x.active === true);
    //reset the highlights as will show
    this.resetSectionHighlights();
    this.resetLinkHighlights(); //need to do this as was previously doing this anytime section id != 0... now only doing it when > 0
    //highlight the link 
    let linkIndex = this.courseStructure.links.findIndex(x => x.url === this.router.url);
    if (linkIndex > -1) {
      this.courseStructure.links[linkIndex].active = true;
    } else {
      // GM: if the section ID is -4 to -7 then keep current (as forums pages) else remove highlight as we have a non-menu page
      // not very extendable of course - if we add more links then we should change this (to what i'm not entriely sure)
      if (this.courseStructure.links && this.courseStructure.links.length > 0) {
        if (this.keepCurrentIndex) {
          if (currentIndex > -1)
            this.courseStructure.links[currentIndex].active = true;
        } else {
          this.courseStructure.links[0].active = true;
        }

      }
    }
  }

  getOutlineFromIndexDB() {
    this.appContext.getCurrentCourse().subscribe(
      responseCourseInfo => {
        this.courseNavigationService.getObjectFromLocalStoreOnly<CourseStructure>('courses/' + responseCourseInfo.courseCode).subscribe(
          responseCourseOutline => {
            this.courseStructure = responseCourseOutline;
          }
        );
      }
    );
  }

  createActiveFlags(sectionId) {
    this.courseStructure.modules.forEach((mod) => {
      mod.sections.forEach(section => {
        section.active = false;
        if (section.id === sectionId) {
          section.active = true;
        }
      });
    });
  }

  resetLinkHighlights() {
    this.courseStructure.links.forEach(link => {
      link.active = false;
    });
  }

  resetSectionHighlights() {
    this.courseStructure.modules.forEach(mod => {
      mod.sections.forEach(section => {
        section.active = false;
      })
    });
  }

  sectionClick(sectionUrl: string, differentModule: boolean, firstTime: boolean) {
    let moduleId = +sectionUrl.split('modules/', 2)[1].split('/', 1)[0];
    let sectionId = 0;
    if (sectionUrl.indexOf('#') > -1) {
      sectionId = +sectionUrl.split('sections/', 2)[1].split('#', 1)[0];
    } else {
      sectionId = +sectionUrl.split('sections/', 2)[1].split('/', 1)[0];
    }

    if (sectionId != 0) {
      // need to toggle module if module is different
      if (differentModule) {
        this.toggleModule(this.courseStructure.modules.findIndex(mod => mod.id === moduleId), false);
      }
      // if mobile then close menu
      if (Helpers.isItMobile(window.innerWidth)) {
        this.tellParentMobileMenuClick.emit(true);
      }
      this.createActiveFlags(sectionId);
      //only want to to this if is a proper section ie > 0 otherwise links wont highlight properly
      if (sectionId > 0) {
        this.resetLinkHighlights();
      }
    }
  }

  ngOnDestroy(): void {
    if (this.courseSub) {
      this.courseSub.unsubscribe();
    }

    if (this.localSub) {
      this.localSub.unsubscribe();
    }

    if (this.theSubscription) {
      this.theSubscription.unsubscribe();
    }

  }

  toggleMenuSide(icon: string) {
    if (icon === this.rightIcon) {
      // open
      this.menuStartPosition = this.leftIcon;
      this.tellParentCloseMenu.emit(false);
      // if we are between mobile and large desktop then close activity
      if (Helpers.isItBetweenMobileAndDesktop(window.innerWidth)) {
        this.tellParentCloseActivity.emit(true);
      }
    } else {
      // close
      this.menuStartPosition = this.rightIcon;
      this.tellParentCloseMenu.emit(true);
    }
  }

  toggleModule(index: number, moduleClick: boolean) {
    // close old
    if (this.oldIndex && (this.oldIndex !== index)) {
      this.isCollapsed[this.oldIndex] = true;
      this.collapsedClass[this.oldIndex] = this.moduleClassCollapsed;
    }

    // open new if not the same
    if (this.oldIndex !== index) {
      this.resetAllModules();
      this.isCollapsed[index] = false;
      this.collapsedClass[index] = this.moduleClass;
    }

    // special case when its the same

    //cant click on a section when it is closed
    // may actually just want to close the module if open but only want to do this if its a module click (not a section clickF)
    if ((this.oldIndex === index) && (this.isCollapsed[index] === false) && moduleClick) {
      this.isCollapsed[index] = true;
      this.collapsedClass[index] = this.moduleClassCollapsed;
    } else if ((this.oldIndex === index) && (this.isCollapsed[index] === true)) {
      this.isCollapsed[index] = false;
      this.collapsedClass[index] = this.moduleClass;
    }

    // set old index
    this.oldIndex = index;
  }

  resetAllModules() {
    for (let i = 0; i < this.isCollapsed.length; i++) {
      this.isCollapsed[i] = true;
    }
  }

  calculateCompletePerecent() {
    return Math.round(this.courseStructure.sectionsComplete / this.courseStructure.sectionsInTotal * 100);
  }

  setOfflineStatus() {
    this.downloadsService.getAllLocalSections(this.courseCode).subscribe(
      (sectionsInLocalDb: Section[]) => {
        sectionsInLocalDb.forEach(localSection => {
          this.courseStructure.modules.forEach(mod => {
            let index = mod.sections.findIndex(x => x.id === localSection.id)
            if (index > -1) {
              mod.sections[index].availableOffline = true;
            } else {
              mod.sections[index].availableOffline = false;
            }
          });
        })
      }
    )
  }

  resetForOnlineStatus() {
    // when online all the sections are avilable
    this.courseStructure.modules.forEach(mod => {
      mod.sections.forEach(section => {
        section.availableOffline = true;
      })
    });
  }

  offlineModal() {
    this.modalSimpleService.confirm(
      'Running in Offline Mode',
      'Offline Mode: you must connect to the internet to access this page. You will now be redirected to the course dashboard.',
      [{ buttonText: 'Ok', buttonStyle: 'btn btn-primary float-right  btn-block ' }])
      .subscribe((answer) => {
        //redirect to the course dashboard
        this.router.navigate([`/courses/${this.courseCode}/modules/0/sections/0`]);
      });
  }

}
