import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { TagsService } from '../../services/tags.service';
import { OperationResponse } from '@app/common/models/operation-response';
import { Tag } from '../../models/tag';
import { ForumStateService } from '../../services/forum-state.service';

@Component({
  selector: 'ebs-tags',
  templateUrl: './tags.component.html',
  styleUrls: ['./tags.component.scss']
})
export class TagsComponent implements OnInit {
  tagList: Tag[] = [];
  tagValue: string;
  activeTagList: Tag[] = [];
  duplicateMessage = 'This tag already exists or is empty';
  tagExists = false;
  loadingTags = false;
  @Input() courseCode: string;
  @Input() canAddTag = true;
  @Output() tagsToSave = new EventEmitter<Tag[]>();
  @Input() taglistHeader = 'Select tag(s):';
  @Input() tagsMarkedAsActive: Tag[] = [];
  errorMessage: string;
  showError = false;
  tagCharacterLimit = 50;

  constructor(
    private tagsService: TagsService,
    private forumStateService: ForumStateService,
  ) { }

  ngOnInit() {
    // do we need to check for empty tag list?
    if (this.forumStateService.currentState && this.forumStateService.filterState.allTags.length > 0) {
      this.tagsMarkedAsActive = this.forumStateService.filterState.currentTags
      this.setUpTags(this.forumStateService.filterState.allTags)
    } else {
      this.getTags();
    }
  }

  getTags() {
    this.loadingTags = true;
    this.showError = false;
    this.tagsService.getTagList(this.courseCode).subscribe(
      (tagsResponse: OperationResponse<Tag[]>) => {

        if (!tagsResponse.operationSucceeded) {
          this.showError = true;
          this.errorMessage = 'Error getting tag list';
        } else {
          this.setUpTags(tagsResponse.data)
          if (this.forumStateService.currentState) this.forumStateService.filterState.allTags = tagsResponse.data
        }
        this.loadingTags = false;
        this.forumStateService.dontEmitTags = false;
      },
      error => {
        this.showError = true;
        this.errorMessage = 'Error getting tag list';
        console.log('Error getting tag list', error)
        this.loadingTags = false;
      }
    )
  }

  setUpTags(responseData) {
    this.tagList = responseData.sort((a, b) => a.name.localeCompare(b.name));
    this.tagsMarkedAsActive.forEach(t => {
      this.tagList.find(x => x.name === t.name).selected = true;
      this.toggleActive(t);
    })
    this.forumStateService.dontEmitTags = false;
  }

  addTag(val: string) {
    let newTag: Tag = new Tag();
    newTag.name = val;
    if (!newTag.name.replace(/\s/g, '').length) {
      this.tagExists = true;
      this.duplicateMessage = 'Tag name must have more than spaces'
    } else {
      newTag.name = newTag.name.trim();
      //check for duplicates  
      const alreadyExists = this.tagList.find(x => x.name === newTag.name);
      if (!alreadyExists) {
        this.tagList.push(newTag);
        this.activeTagList.push(newTag);
        newTag.selected = true;
        this.tagExists = false;
      } else {
        this.tagExists = true;
        this.duplicateMessage = 'This tag already exists.';
      }
      this.tagsToSave.emit(this.activeTagList);
    }
  }

  removeFromActiveList(tag) {
    let index = this.activeTagList.findIndex(x => x.name == tag.name);
    if (index > -1) {
      this.activeTagList.splice(index, 1)
      tag.selected = false;
    }
    // don't emit if using state as will refire load
    if (!this.forumStateService.dontEmitTags) this.tagsToSave.emit(this.activeTagList);
  }

  addToActiveList(tag: Tag) {
    this.activeTagList.push(tag)
    tag.selected = true;
    // don't emit if using state as will refire load
    if (!this.forumStateService.dontEmitTags) this.tagsToSave.emit(this.activeTagList);
  }

  toggleActive(tag: Tag) {
    // not being found when already there
    const alreadyExists = this.activeTagList.find(x => x.name == tag.name);
    if (alreadyExists) {
      this.removeFromActiveList(tag);
    } else {
      this.addToActiveList(tag);
    }
  }

  clearTags() {
    this.activeTagList.forEach(tag => {
      this.removeFromActiveList(tag);
    }
    )
    // don't emit if using state as will refire load
    if (!this.forumStateService.dontEmitTags) this.tagsToSave.emit(this.activeTagList);
  }
}
