export class StorableObject implements IStorableObject {
    uri: string;
    version: string;
    courseCode: string;
    moduleId: number;
    sectionId: number;
    isAwaitingSynchronization: boolean;
}

export interface IStorableObject {
    uri: string;
    version: string;
    courseCode: string;
    moduleId: number;
    sectionId: number;
    isAwaitingSynchronization: boolean;
}