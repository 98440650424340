import { QuizQuestion } from './quiz-question';
import { MatchedPairSubQuestion } from './matched-pair-subquestion';

export class MatchedPairQuestion extends QuizQuestion {
    public subQuestions: Array<MatchedPairSubQuestion>;


    constructor(title: string, questionText: string, subQuestions: Array<MatchedPairSubQuestion>) {
        super(title, questionText, 'paired');
        this.subQuestions = subQuestions;
    }

    public getMark(): number {
        // at the minute if any incorrect get 0 marks
        for (let subQuestion of this.subQuestions) {
            let newQuestion = new MatchedPairSubQuestion('', null);
            newQuestion = Object.assign(newQuestion, subQuestion);
            var mark = newQuestion.getMark();
            if(mark==0){
                return 0;
            }
        }
        return this.pointsAvailable;
    }
}
