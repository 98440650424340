<div class="offlinemode-wrapper">
  <div class="card">
    <p class="card-header" *ngIf="!offlineEnabled"> Enable Offline Mode  </p>
    <p class="card-header" *ngIf="offlineEnabled"> Disable Offline Mode  </p>
    <div class="card-body">
      <div  *ngIf="!offlineEnabled">
      <p>
        Offline mode enables you to download course modules to this device, for use when you don't have an internet
        connection.
      </p>
      <p>
        If you enable this mode, the system will store your details locally on this device to enable the offline mode
        to run.
      </p>
      <p>Once enabled, you will be able to access download modules when you have no internet connection.</p>
    </div>
    <div  *ngIf="offlineEnabled">
      <P>
          Offline mode has been enabled on this device. Your programme of study, photo, name and country of residence is stored on this PC/device along with any modules you have selected for offline access.
      </P>
    </div>
      <ul class="list-unstyled"  *ngIf="!hasAccepted">
        <li class="list-group-item">
          <div class="row">
            <div class="col-8 col-sm-10">
              I understand that the system will be storing my programme of study, photo, name and country of residence
              on this PC/device to enable access to the modules offline.
            </div>
            <div class="col-4 col-sm-2" style="text-align: center;">
              <label class="switch">
                <input type="checkbox" [checked]="hasAccepted" (change)="hasAccepted = !hasAccepted"
                  (click)="switchClick()">
                <span class="slider round"></span>
              </label>
            </div>
          </div>
        </li>
      </ul>
    </div>

    <div class="row">
      <div class="col-12">
        <div [@transformButton]="!offlineEnabled" class="btn btn-success text-white float-right mr-2"
          (click)="enableOffline()" *ngIf="!offlineEnabled && hasAccepted">
          Enable offline mode now <i class="fas fa-angle-double-right"></i>
        </div>
        <div *ngIf="disablingOffline" class="float-right">
          <ebs-spinner [spinnerMessage]="''"></ebs-spinner>
        </div>
        <div [@transformButton]="offlineEnabled" class="btn btn-light float-right mr-2" (click)="switchClick()"
          *ngIf="offlineEnabled && !disablingOffline">
          Disable offline mode <i class="fas fa-angle-double-right"></i>
        </div>
      </div>
    </div>
  </div>
</div> 