import { Component, OnInit, HostListener, SimpleChanges } from '@angular/core';
import { UserService } from '@app/common/security/user.service';
import { UserProfile } from '@app/common/models/user';
import { Router, Event, NavigationEnd, ActivatedRoute, NavigationStart } from '@angular/router';
import { Helpers } from '@app/common/helpers';
import { ConfigurationHelperService } from '@app/common/app-configuration/configuration-helper.service';
import { ModalOfflineService } from '../modal-offline/modal-offline.service';
import { OnlineStatusService } from '@app/common/online-status/online-status.service';
import { OnlineStatusType } from '@app/common/online-status/online-status-type.enum';
import { offlineTriggered } from '@app/common/animations/animations';
import { ToastrService } from 'ngx-toastr';
import { SwManagerService } from '@app/common/service-workers/sw-manager.service';
import { OfflineModeStatusService } from '@app/core/offline-mode-status.service';
import { IAppConfig } from '@app/common/models/app-config';
import { HeaderLinks } from './header-links';

@Component({
    selector: 'ebs-page-header',
    templateUrl: './page-header.component.html',
    styleUrls: ['./page-header.component.scss'],
    animations: [offlineTriggered]
})
export class PageHeaderComponent implements OnInit {
    supportsServiceWorkers = true;
    userLoggedOff: boolean;
    headerLinks: HeaderLinks;
    userProfile: UserProfile;
    avatar: string;
    displayMenu: boolean;
    displayOfflineMenu: boolean;
    preferredName: string;
    isMobile = Helpers.isItMobile(window.innerWidth);
    headerLoading = false;
    isOffline = false;
    noInternetConnection = false;
    isWorkingOffline: boolean = false;
    isGoOfflineEvent: boolean = false;
    isTheLoginScreen: boolean;

    constructor(
        private userService: UserService,
        private configurationHelperService: ConfigurationHelperService,
        private modalOfflineService: ModalOfflineService,
        private onlineStatusService: OnlineStatusService,
        private toastr: ToastrService,
        private router: Router,
        private swManagerService: SwManagerService,
        private offlineModeStatusService: OfflineModeStatusService,
        private activatedRoute: ActivatedRoute
    ) {

        this.onlineStatusService.status.subscribe(
            event => {
                let displayNotification = this.isOffline === false && event === OnlineStatusType.OFFLINE;
                this.isOffline = (event === OnlineStatusType.OFFLINE) ? true : false;

                if (displayNotification)
                    this.displayOfflineNoticeIfAppropriate();
            }
        );

        this.onlineStatusService.internetConnectionStatus.subscribe(
            event => {
                this.noInternetConnection = (event === OnlineStatusType.OFFLINE) ? true : false;;
            }
        );

        this.offlineModeStatusService.offlineModeEnabledStatus.subscribe(x => {
            this.displayOfflineMenu = x;
        }, error => { });
        
        // how do 
        this.router.events.subscribe((val) => {
            if (val instanceof NavigationStart) {
              if (val.url.indexOf("authentication") > -1) {
                this.isTheLoginScreen = true
              }else{
                this.isTheLoginScreen = false
              }
            }
          });
    }

    goOnline() {
        this.onlineStatusService.removeIsWorkingOffline().subscribe(x => {
            this.isWorkingOffline = false;
        }, error => { });
    }

    goOffline() {
        this.onlineStatusService.setIsWorkingOffline().subscribe(x => {
            this.isWorkingOffline = true;
        }, error => { });
    }

    private readonly defaultAvatar = '../../../../../assets/img/avatar-temp.svg';

    private displayOfflineNoticeIfAppropriate() {
        if (this.isOffline && window.location.href.indexOf('authentication') < 0) {
            this.toastr.info('You are working offline. You can access course content that you have downloaded to this device.', 'Working offline.', {
                timeOut: 10000
            });
        }
    }

    @HostListener('window:resize', ['$event'])
    onResize(event) {
        this.isMobile = Helpers.isItMobile(window.innerWidth);
    }

    ngOnInit() {
        this.headerLoading = true;
        this.avatar = this.defaultAvatar;
        this.headerLinks = this.configurationHelperService.getHeaderLinks();

        if (this.swManagerService.checkBrowserSupportsServiceWorkers()) {
            this.supportsServiceWorkers = true;
        } else {
            this.supportsServiceWorkers = false;
        }

        this.onlineStatusService.isOffline().subscribe(x => {
            this.isOffline = x;
            this.displayOfflineNoticeIfAppropriate();
        }, error => {
            this.isOffline = false;
        }, () => {
            this.router.events.subscribe((event: Event) => {
                if (event instanceof NavigationEnd) {
                    this.resetMenu();
                }
            });

            this.onlineStatusService.isWorkingOffline().subscribe(x => {
                this.isWorkingOffline = x;
            }, error => {
                this.isWorkingOffline = false;
            }, () => {
                if (!this.isOffline)
                    this.noInternetConnection = false;
                else
                    this.noInternetConnection = !this.isWorkingOffline;

                this.offlineModeStatusService.isEnabled().subscribe(x => {
                    this.displayOfflineMenu = x;
                    if (((this.activatedRoute.snapshot['_routerState'].url.indexOf('dashboard') > 0) || (this.activatedRoute.snapshot['_routerState'].url === '/') ) && (this.configurationHelperService.getStudentDashboardRedirectIsOn())) {
                        this.displayOfflineMenu = false;
                    }

                }, error => {
                    this.displayOfflineMenu = false;
                }, () => {
                    this.headerLoading = false;
                });
            });
        });
    }

    private resetMenu() {
        this.setCurrentUser();

    }

    private setCurrentUser() {
        this.userService.getCurrentUser().subscribe(theUserProfile => {
            this.userProfile = theUserProfile;
            this.displayMenu = false;
            this.avatar = this.defaultAvatar;

            if (this.userProfile && this.userProfile.authenticationDetails != null
                && (this.userProfile.authenticationDetails.isAuthenticated || this.isOffline)) {
                //let root = this;
                //setTimeout(function () {
                this.displayMenu = true;
                //}, 1000);
                const photograph = this.userProfile.personalDetails != null ? this.userProfile.personalDetails.photograph : null;
                if (photograph != null && photograph.data != null && photograph.data !== '') {
                    this.avatar = `data:image/${photograph.type};base64,${photograph.data}`;
                }
            }
            this.headerLoading = false;
        },
            () => {
                this.headerLoading = false;
            },
            () => {
                // Never show the menu on the login page.
                if (this.router.url.indexOf('authentication') > 0) {
                    this.displayMenu = false;
                }

                if ((this.router.url.indexOf('dashboard') > 0) && (this.configurationHelperService.getStudentDashboardRedirectIsOn())) {
                    this.displayMenu = false;
                }
            }
        );
    }

    popUpOffline() {
        this.modalOfflineService.confirm('Offline Options')
            .subscribe((answer) => {

            });
    }
}
