import { DeviceDetectorService } from "ngx-device-detector";
import { Injectable, Inject } from "@angular/core";


@Injectable({
    providedIn: 'root',    
})
export class DeviceDetectorWrapperService{
    constructor(@Inject(DeviceDetectorService) private deviceService: DeviceDetectorService){        
    }


    isiOS():boolean{
        return this.deviceService.os.indexOf('iOS') > -1 || this.deviceService.os.indexOf('Mac') > -1;
    }

}