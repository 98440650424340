import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'ebs-message-simple',
  templateUrl: './message-simple.component.html'
})
export class MessageSimpleComponent implements OnInit {
  @Input() displayMessage = 'An error has occurred, please try again later.';
  @Input() iconCssClasses = 'text-danger fa fa-fw fa-exclamation-circle';
  @Input() textCssClasses = 'text-danger';
  @Input() displayOnRight = true;
  @Input() wrapperCssClasses = 'p-1';

  constructor() { }

  ngOnInit() {
  }

}
