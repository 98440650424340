import { Comparer } from "@app/core/delegates";
import { QueryType } from "./query-type";

export class PagedRequest<T>{
    public currentPage: number;
    public itemsPerPage: number;
    public lastItemId: string;
    public uriFilter: string;
    public searchCriteria: string;
    public sortOperator: Comparer<T>;
    public courseCode: string;
    public searchPhrase: string;

    public getUriFilter(): string {
        return this.uriFilter.toLowerCase();
    }

    public getRequestFilter(): string {

        let resultedUriFilter = this.getUriFilter();

        let queryString = '';

        if (this.currentPage > 0)
            queryString += `currentPage=${this.currentPage}`;

        if (this.itemsPerPage > 0)
            queryString += `${this.handleAmpersand(queryString)}itemsPerPage=${this.itemsPerPage}`;

        if (this.lastItemId != null && this.lastItemId !== '')
            queryString += `${this.handleAmpersand(queryString)}lastItemId=${this.lastItemId}`;

        if (this.searchCriteria != null && this.searchCriteria !== '')
            queryString += `${this.handleAmpersand(queryString)}searchCriteria=${this.searchCriteria}`;

        if (this.courseCode != null)
            queryString += `${this.handleAmpersand(queryString)}courseCode=${this.courseCode}`;

        if (this.searchPhrase != null && this.searchPhrase !== '')
            queryString += `${this.handleAmpersand(queryString)}searchPhrase=${this.searchPhrase}`;

        return (queryString != null && queryString !== '') ? `${resultedUriFilter}?${queryString}` : resultedUriFilter;
    }

    protected handleAmpersand(query: string): string {
        return (query == null || query === '') ? '' : '&';
    }
}