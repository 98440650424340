import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

if (environment.production) {
  enableProdMode();
  test();
}

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.log(err));

function test() {
  const a = 7;
  const b = 9;
  const sum = a + b;
}
