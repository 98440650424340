import { Component, OnInit, Input, Output, EventEmitter, OnDestroy } from '@angular/core';
import { enterOrLeave, progress } from '@app/common/animations/animations';
import { StaticText } from '@app/common/static-text';
import { SectionProgressService } from '@app/courses/services/section-progress.service';
import { SectionProgress } from '@app/courses/models/section-progress';
import { OperationResponse } from '@app/common/models/operation-response';
import { CourseStructure } from '@app/courses/models/course-structure';
import { ApplicationContext } from '@app/common/current-context/application-context';
import { Section } from '@app/courses/models/section';
import { SectionCompleteStatus } from '@app/courses/models/section-complete-status';
import { OnlineStatusService } from '@app/common/online-status/online-status.service';
import { ActivatedRoute } from '@angular/router';
import { Subscribable, Subscription } from 'rxjs';
import { OnlineStatusType } from '@app/common/online-status/online-status-type.enum';

@Component({
  selector: 'ebs-section-progress',
  templateUrl: './section-progress.component.html',
  styleUrls: ['./section-progress.component.scss'],
  animations: [enterOrLeave, progress]
})
export class SectionProgressComponent implements OnInit, OnDestroy {
  @Input() showProgress: boolean;
  @Input() sectionId: number;
  @Output() bubbleMenuUpdateFromProgress = new EventEmitter<number>();
  courseOutline: CourseStructure;
  progressData: SectionProgress = new SectionProgress(0, 0, 0, 0, 'Initialize');
  resetStatus = StaticText.progressReset;
  sectionCongrats = StaticText.progressCongrats;
  progressHeader = StaticText.progressHeader;
  progressIncompleteTextLine1 = StaticText.progressIncompleteTextLine1;
  isIEOrEdge: boolean;
  currentSection: Section;
  errorMessage = StaticText.progressStatusError;
  errorOccured = false;
  errorLocation = '';
  courseCode = '';
  theSubscription: Subscription;
  isOffline: boolean;
  offlineMessage = StaticText.progressOfflineMessage;
  paramSubscription: Subscription;

  constructor(
    private route: ActivatedRoute,
    private sectionProgressService: SectionProgressService,
    private applicationContext: ApplicationContext,
    private onlineStatusService: OnlineStatusService
  ) {

    this.theSubscription = this.onlineStatusService.status.subscribe(
      event => {
        this.isOffline = (event === OnlineStatusType.OFFLINE) ? true : false
      }
    )
  }

  ngOnInit() {
    this.paramSubscription = this.route.paramMap.subscribe(params => {
      this.courseCode = params.get('courseCode');
      this.onlineStatusService.isOffline().subscribe(x => {
        this.isOffline = x;
        this.updateProgress();
      }, error => {
        this.isOffline = false;
        this.updateProgress();
      });
    });
    this.isIEOrEdge = this.onlineStatusService.isIEOrEdge();
  }

  updateProgress() {
    // this needs to go local as relies on calculations made by the menu when it gets the course outline hardcode true
    this.sectionProgressService.getObject<CourseStructure>('courses/' + this.courseCode, true).subscribe(
      responseCourseOutline => {
        this.courseOutline = responseCourseOutline.data;
        if (!this.isOffline && this.courseOutline != null) {
          this.applicationContext.setCurrentCourse(this.courseCode, this.courseOutline.name); //reset current course
          this.courseOutline.modules.forEach(mod => {
            mod.sections.forEach(section => {
              if (section.id === this.sectionId) {
                this.updateProgressInfo(mod, section);
              }
            })
          })
        }
      },
      error => {
        console.log('There has been an error getting the course outline from indexeddb for the progress component')
      }
    );
  }

  animateProgressItems() {
    const originalModule = this.progressData.progressModule;
    const originalOverall = this.progressData.progressOverall;
    this.progressData.progressModule = 0;
    this.progressData.progressOverall = 0;
    const root = this;
    setTimeout(function () {
      root.progressData.progressModule = originalModule;
      root.progressData.progressOverall = originalOverall;
      if (document.querySelector('#progress-complete-id')) {
        document.querySelector('#progress-complete-id').scrollIntoView({ block: 'end', behavior: "smooth" });
      }
    }, 500);
  }

  markAsComplete(isItComplete: boolean) {
    window.scroll(0, 0);
    this.errorOccured = false;
    this.applicationContext.setCurrentCourse(this.courseCode, this.courseOutline.name); //reset current course incase we are in a different tab
    this.sectionProgressService.markSectionAsComplete(this.currentSection.id, isItComplete, this.currentSection.uri + '/sectioncompletestatus', this.courseOutline.courseCode)
      .subscribe(
        (response: OperationResponse<SectionCompleteStatus>) => {
          // mark section as complete, update section progress, update overall progress
          if (!response.operationSucceeded) {
            this.errorLocation = 'Marking section as complete may not have worked.'
            this.errorOccured = true;
          } else {
            this.courseOutline.modules.forEach(mod => {
              mod.sections.forEach(section => {
                section.active = false; // temp bug fix for menu not showing correct active
                if (section.id === this.sectionId) {
                  section.completed = isItComplete;
                  section.active = true;
                  this.courseOutline.sectionsComplete += isItComplete === true ? 1 : -1;
                  this.updateProgressInfo(mod, section);

                  this.sectionProgressService.setObjectInLocalStore(section, section.uri).subscribe(
                    res => {  },
                    error => {
                      console.log('There has been an error UPDATING the section in indexeddb for the progress component')
                    }
                  );
                }
              });
              mod.progressValue = mod.sections.filter(section => section.completed === true).length / mod.sections.length * 100;
            });

            this.sectionProgressService.setObjectInLocalStore(this.courseOutline, this.courseOutline.uri).subscribe(
              res => { },
              error => {
                console.log('There has been an error updating the course outline in indexeddb for the progress component')
              }
            );
            this.showProgress = isItComplete;
            this.animateProgressItems();
            this.bubbleMenuUpdateFromProgress.emit(new Date().getTime()); //unique value so as menu updates itself
          }

        },
        error => {
          this.errorLocation = 'Marking section as complete may not have worked. '
          this.errorOccured = true;
          console.log('mark section as complete failed');
          console.log(error);
        }
      );
  }

  updateProgressInfo(mod, section) {
    this.progressData.moduleName = mod.name;
    this.progressData.progressModule = mod.progressValue;
    this.progressData.moduleSectionsIncomplete = mod.sections.filter(x => x.completed === false).length;
    this.progressData.moduleSectionsTotal = mod.sections.length;
    this.currentSection = section;
  }

  getProgressValue() {
    return Math.round((this.progressData.moduleSectionsTotal - this.progressData.moduleSectionsIncomplete) / this.progressData.moduleSectionsTotal * 100);
  }

  getOverallProgress() {
    return Math.round((this.courseOutline.sectionsComplete / this.courseOutline.sectionsInTotal) * 100)
  }

  ngOnDestroy(): void {
    if (this.theSubscription) this.theSubscription.unsubscribe();
    if (this.paramSubscription) this.paramSubscription.unsubscribe();
  }

}
