import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'ebs-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss']
})
export class SearchComponent implements OnInit {
  @Output() searchText = new EventEmitter<string>();
  @Input() searchResultCount = 0;
  @Input() searchTotalCount = 0;
  @Input() parentSearchHasComplete = false;
  @Input() hideInput:boolean = false;
  searchValue = '';
  searchValueDisplay = '';
  searchCharacterLimit = 50;

  constructor() { }

  ngOnInit() {   }

  searchClicked() {
    this.searchText.emit(this.searchValue);
    this.searchValueDisplay = this.searchValue;
  }

  isDisabled() {
    return !this.searchValue || this.searchValue === '' || (this.searchValue.length > this.searchCharacterLimit)
  }

  isGreaterThanMax() {
    return this.searchValue && (this.searchValue.length > this.searchCharacterLimit)
  }
}
