<div class="p-4" *ngIf="isOffline">
  <ebs-offline-message [isOffline]='isOffline' [offlineMessage]='adminOfflineMessage'></ebs-offline-message>
</div>

<div class="course-admin-wrapper main-content scrollable full-height-minus-header" *ngIf="!isOffline">
  <div class="card">
    <div class="card-header">
      <div class="row">
        <div class="col-12 d-flex">
          <button *ngIf="!paused" class="btn btn-light mr-2" (click)="pauseUpdates(true)">Pause
            auto refresh</button>
          <button *ngIf="paused" class="btn btn-light mr-2" (click)="pauseUpdates(false)">Restart
            auto refresh</button>
          <!-- <button class="btn btn-light mr-2" (click)="hardDelete()">Hard delete all notifications</button> -->
          <button class="btn btn-light mr-2" (click)="softDelete()">Delete all notifications</button>
          
          <!-- <ebs-spinner *ngIf="!paused" [spinnerMessage]="spinnerMessage" [boxPadding]="'5px'"></ebs-spinner> -->
        </div>
      </div>
    </div>

    <div class="card-body">
      <div class="row">
        <div class="col-12">
          <div class="table-responsive">
            <table class="table">
              <thead>
                <tr class="header-row">
                  <th style="min-width:120px;">Code</th>
                  <th style="max-width: 50%">Message</th>
                  <th>Severity</th>
                  <th>Location</th>
                  <th>Timestamp</th>
                </tr>
              </thead>
              <ebs-spinner *ngIf="isLoading" [spinnerMessage]="spinnerMessage" [boxPadding]="'15px'"></ebs-spinner>
              <tbody  *ngIf="!isLoading">
                <tr *ngFor="let systemNotification of systemNotificationList" class="body-row">
                  <td>{{systemNotification.courseCode}}</td>
                  <td>{{systemNotification.message}}</td>
                  <td>{{systemNotification.severityLevel}}</td>
                  <td>{{systemNotification.location}}</td>
                  <td>{{systemNotification.dateAndTime | date:'MMM dd, HH:mm'}}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>

    </div>

    <div class="card-footer" *ngIf="operationFailed">
      <div class="row">
        <div class="col-12">
          {{failedMessage}}
        </div>
      </div>
    </div>

  </div>
</div>