import { Component, OnInit, Input, Inject, ViewContainerRef, ViewChild, ComponentFactoryResolver, NgModuleRef } from '@angular/core';
import { ActivityGroup } from '@app/courses/models/activity-group';
import { ActivityItemType } from '@app/courses/models/activity-item-type';
import { SectionService } from '@app/courses/services/section.service';
import { LearningActivityItem } from '@app/courses/models/dynamic-load/learning-activity-item';
import { ActivityItem } from '@app/courses/models/activity-item';
import { LearningActivityItemComponentBase } from '@app/courses/models/dynamic-load/learning-activity-item-component-base';
import { CanComponentDeactivate } from '@app/common/security/can-deactivate.guard';

@Component({
  selector: 'ebs-activity-group',
  templateUrl: './activity-group.component.html',
  styleUrls: ['./activity-group.component.scss']
})
export class ActivityGroupComponent implements OnInit, CanComponentDeactivate {
  @Input() activityGroup: ActivityGroup;
  ActivityType = ActivityItemType;
  @Input() isSectionHeader = false;
  groupCss = 'card';
  @ViewChild('learningActivitiesContainer', { read: ViewContainerRef, static: true }) learningActivitiesContainer;
  childcomponents : Array<LearningActivityItemComponentBase<ActivityItem>>;

  constructor(private componentFactoryResolver: ComponentFactoryResolver,
    @Inject(SectionService) private sectionService: SectionService) { }

  ngOnInit() {
    const learningActivityItems = this.getLearningActivityItems();
    this.groupCss = (this.isSectionHeader === true)  ? 'section-header' :  'card'; // section info fudge
    if (this.checkWhetherThisIsFeedbackActivityGroup()) {
      this.groupCss += ' hidden-print'; // Make sure feedback activities are hidden for the print version.
    }
    this.childcomponents = new  Array<LearningActivityItemComponentBase<ActivityItem>>();
    this.loadComponents(learningActivityItems);
  }
  
  canDeactivate(): boolean {
    let canDeactivatefunctionRefsExecutionResult = true;

    this.childcomponents.forEach(x => {
      if (x != undefined){
        let result = x.canDeactivate();
        if (result === false)
          canDeactivatefunctionRefsExecutionResult = false;
      }
    });

    return canDeactivatefunctionRefsExecutionResult;
  }

  public getLearningActivityItems(): Array<LearningActivityItem> {
    const items = new Array<LearningActivityItem>();

    if (this.activityGroup === undefined || this.activityGroup === null) { return items; }
    if (this.activityGroup.activityItems === undefined || this.activityGroup.activityItems === null) { return items; }

    this.activityGroup.activityItems.forEach((item: ActivityItem, index: number) => {
      item.courseCode = this.sectionService.courseCode;
      item.moduleId = this.sectionService.moduleId;
      item.sectionId = this.sectionService.sectionId;
      const componentType = this.sectionService.getActivityItemComponent(item.type);
      items.push(new LearningActivityItem(componentType, item));
    });

    return items;
  }

  public checkWhetherThisIsFeedbackActivityGroup(): boolean {
    if (this.activityGroup === undefined || this.activityGroup === null) { return false; }
    if (this.activityGroup.activityItems === undefined || this.activityGroup.activityItems === null) { return false; }
    if (this.activityGroup.activityItems.length === 0 || this.activityGroup.activityItems.length > 1) { return false; }
    return this.activityGroup.activityItems[0].type === ActivityItemType.Feedback;
  }

  public loadComponents(learningActivityItems: Array<LearningActivityItem>): void {
    if (learningActivityItems === undefined || learningActivityItems === null || learningActivityItems.length === 0) { return; }

    learningActivityItems.forEach((item: LearningActivityItem, index: number) => {
      const componentFactory = this.componentFactoryResolver.resolveComponentFactory(item.component);
      const viewContainerRef = this.learningActivitiesContainer;
      const componentRef = viewContainerRef.createComponent(componentFactory);
      (<LearningActivityItemComponentBase<ActivityItem>>componentRef.instance).activityItem = item.activityItem;

      this.childcomponents.push((<LearningActivityItemComponentBase<ActivityItem>>componentRef.instance));
    });
  }

}
