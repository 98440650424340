import { Injectable } from '@angular/core';
import { ServiceBase } from '@app/core/service-base';
import { Observable } from 'rxjs';
import { OperationResponse } from '@app/common/models/operation-response';
import { PagedRequest } from '@app/common/models/paged-request';
import { PostAndReplySearchResultDto } from '../models/course-activity/post-and-reply-search-result-dto';
import { PagedResult } from '@app/common/models/paged-result';
import { UserActivitySearchResultDto } from '../models/course-activity/user-activity-search-result-dto';
import { UserActivityLastVisitDto } from '../models/course-activity/user-activity-last-visit-dto';


@Injectable({
  providedIn: 'root'
})
export class CourseActivitySideBarService extends ServiceBase {
  getFacultyActivity(request: PagedRequest<PostAndReplySearchResultDto>): Observable<OperationResponse<PagedResult<PostAndReplySearchResultDto>>> {
    return this.getPagedObjects<PostAndReplySearchResultDto>(request, false);
  }

  getLatestUserActivity(request: PagedRequest<UserActivitySearchResultDto>): Observable<OperationResponse<PagedResult<UserActivitySearchResultDto>>> {
    return this.getPagedObjects<UserActivitySearchResultDto>(request, false);
  }

  setUserActivityAsViewed(url: string, courseCode: string): Observable<OperationResponse<UserActivityLastVisitDto>> {
    return this.postObjectToRemoteStoreOnly(courseCode, this.getApiServerUri() + url);
  }

  getUserActivityLastViewed(url: string): Observable<OperationResponse<UserActivityLastVisitDto>> {
    return this.getObjectFromRemoteStoreOnly<UserActivityLastVisitDto>(url);
  }
}
