import { Injectable } from '@angular/core';
import { ServiceBase } from '@app/core/service-base';
import { LastSectionAccessed } from '../models/last-section-accessed';
import { Observable } from 'rxjs';
import { OperationResponse } from '@app/common/models/operation-response';
import { DataStoreStrategy } from '@app/core/data-store-strategy';

@Injectable({
  providedIn: 'root'
})
export class LastSectionAccessedService extends ServiceBase {

  getLastSectionAccessed(uri: string, treatAsOffline: boolean): Observable<OperationResponse<LastSectionAccessed>> {
    return this.getObject(uri, treatAsOffline);
  }

  setLastSectionAccessed(lastSectionAccessed: LastSectionAccessed, treatAsOffline: boolean): Observable<OperationResponse<LastSectionAccessed>> {
    return this.setObjectInAvailableStores(lastSectionAccessed, lastSectionAccessed.uri, treatAsOffline)
  }
}
