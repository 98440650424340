import { Component, OnInit, Input, QueryList, ViewChildren, OnDestroy } from '@angular/core';
import { QuizActivity } from '@app/courses/models/quiz/quiz-activity';
import { QuizActivityService } from '@app/courses/services/quiz-activity.service';
import { LearningActivityItemComponentBase } from '@app/courses/models/dynamic-load/learning-activity-item-component-base';
import { OperationResponse } from '@app/common/models/operation-response';
import { MultipleChoiceQuestion } from '@app/courses/models/quiz/multiple-choice-question';
import { MultipleSelectQuestion } from '@app/courses/models/quiz/multiple-select-question';
import { MatchedPairQuestion } from '@app/courses/models/quiz/matched-pair-question';
import { NumericalQuestion } from '@app/courses/models/quiz/numerical-question';
import { map } from 'rxjs/operators';
import { ActivatedRoute } from '@angular/router';
import { UserService } from '@app/common/security/user.service';
import { QuizDetail } from '@app/courses/models/quiz/quiz-detail';
import { NotificationSummary } from '@app/common/models/notification-summary';
import { SeverityLevel } from '@app/common/models/severity-level';
import { pulse } from '@app/common/animations/animations';
import { EssayQuestionComponent } from '../essay-question/essay-question.component';
import { Subscription } from 'rxjs';
import { OnlineStatusService } from '@app/common/online-status/online-status.service';
import { OnlineStatusType } from '@app/common/online-status/online-status-type.enum';
import { StaticText } from '@app/common/static-text';
import { UserProfile } from '@app/common/models/user';

@Component({
  selector: 'ebs-quiz-activity',
  templateUrl: './quiz-activity.component.html',
  styleUrls: ['./quiz-activity.component.scss'],
  animations: [pulse]
})
export class QuizActivityComponent implements OnInit, LearningActivityItemComponentBase<QuizActivity>, OnDestroy {
  @Input() activityItem: QuizActivity;
  public showFeedback: boolean;
  public totalScore = 0;
  public numberOfQuestions = 0;
  public isComplete: boolean;
  public totalPointsAvailable: number;
  private courseCode: string;
  private personId: string;
  private sectionId: string;
  failedMessage = 'The quiz has failed to load. Please try again';
  loadingData = true;
  notifications: Array<NotificationSummary> = new Array<NotificationSummary>();
  scrollElement: any;
  @ViewChildren(EssayQuestionComponent) children: QueryList<EssayQuestionComponent>;
  theSubscription: Subscription;
  isOffline: boolean;
  offlineMessage = StaticText.quizOfflineMessage;

  constructor(private quizActivityService: QuizActivityService, private route: ActivatedRoute,
    private userService: UserService,
    private onlineStatusService: OnlineStatusService) {


    this.theSubscription = this.onlineStatusService.status.subscribe(
      event => {
        this.isOffline = (event === OnlineStatusType.OFFLINE) ? true : false
      }
    )
  }

  ngOnInit() {
    this.userService.getCurrentUser().subscribe((x: UserProfile) => {
      this.personId = x.userAccount.username;
    }, error => {
      console.log(error);
      this.notifications.push(new NotificationSummary('Error returning progress percent', 'Http', SeverityLevel.Error));
    }, () => {
      this.route.paramMap.subscribe(params => {
        this.courseCode = params.get('courseCode');
        this.sectionId = params.get('sectionId');
      });
      this.onlineStatusService.isOffline().subscribe(x => {
        this.isOffline = x;
        this.getQuizData();
      }, error => {
        this.isOffline = false;
        this.getQuizData();
      });
    });
  }

  canDeactivate(): boolean {
    let result = true;
    this.children.forEach(child => {
      if (!child.canDeactivate()) {
        result = false;
      }
    });

    return result;
  }

  getQuizData() {
    this.notifications = new Array<NotificationSummary>();
    this.quizActivityService.getQuizActivity(this.activityItem.uri, this.isOffline)
      .pipe(map(
        // get rid of nulls
        res => {
          const newArray = res.data.questions.filter(question => {
            return question != null;
          });
          res.data.questions = newArray;
          return res;
        }
      ))
      .subscribe(
        (quizResponse: OperationResponse<QuizActivity>) => {
          if (quizResponse.operationSucceeded) {
            this.activityItem = quizResponse.data;
          } else {
            this.notifications = quizResponse.notifications;
          }
          this.loadingData = false;
        }, error => {
          this.notifications.push(new NotificationSummary(error, 'unknown', SeverityLevel.Error, 'unknown', 0));
          this.loadingData = false;
        }, () => {

        }
      );
  }

  restart($element) {
    this.notifications = new Array<NotificationSummary>();
    this.getQuizData();
    this.isComplete = false;
    this.totalScore = 0;
    this.numberOfQuestions = 0;
    this.showFeedback = false;
    this.scrollToAnchor($element);
  }

  scrollToAnchor($element) {
     $element.scrollIntoView({ block: "start", inline: "nearest" });
  }

  scrollToAnchorCenter($element) {
     $element.scrollIntoView({ block: "center", inline: "nearest" });
  }


  submit($element) {
    this.notifications = new Array<NotificationSummary>();
    this.scrollElement = $element;
    let quizDetail = new QuizDetail();
    this.markQuestions();
    quizDetail.courseCode = this.activityItem.courseCode;
    quizDetail.personId = this.personId;
    quizDetail.quizId = this.activityItem.id;
    quizDetail.score = this.totalScore;
    quizDetail.availableMarks = this.totalPointsAvailable;
    quizDetail.quizType = 0;
    quizDetail.sectionId = +this.sectionId;


    this.activityItem.quizType = 0;
    this.activityItem.score = this.totalScore;
    //M.S. - for D.C. - the uri is not very clear
    this.quizActivityService
      .setQuizDetail(`courses/${this.courseCode}/${this.personId}/${this.activityItem.id}/${this.activityItem.id}/quiz`,
        quizDetail, this.isOffline).subscribe(
          (quizScoreResponse: OperationResponse<QuizDetail>) => {
            if (quizScoreResponse.operationSucceeded) {
              this.scrollToAnchorCenter(this.scrollElement);
            } else {
              this.notifications = quizScoreResponse.notifications;
            }
          }, error => {
            this.notifications.push(new NotificationSummary(error, 'unknown', SeverityLevel.Error, 'unknown', 0));
          }
        );
  }


  markIncomplete() {
    this.isComplete = true;
    for (const question of this.activityItem.questions) {
      if (!question.isComplete) {
        this.isComplete = false;
      }
      if (question.questionType === 'paired') {
        const subQuestions = (question as MatchedPairQuestion).subQuestions;
        for (const subQuestion of subQuestions) {
          if (!subQuestion.isComplete) {
            this.isComplete = false;
          }
        }
      }
    }
  }

  markQuestions() {
    this.numberOfQuestions = 0;
    this.totalPointsAvailable = 0;
    for (const question of this.activityItem.questions) {

      // this stuff here because I am losing the getMark method on the Activity Item returned from observable

      switch (question.questionType) {
        case 'feedback':
          return;
        case 'multiple-choice':
          let mcQuestion = new MultipleChoiceQuestion('', '', null);
          mcQuestion = Object.assign(mcQuestion, question);
          this.totalScore += mcQuestion.getMark();
          this.numberOfQuestions += 1;
          this.totalPointsAvailable += mcQuestion.pointsAvailable;
          break;
        case 'multiple-select':
          let msQuestion = new MultipleSelectQuestion('', '', null);
          msQuestion = Object.assign(msQuestion, question);
          this.totalScore += msQuestion.getMark();
          this.numberOfQuestions += 1;
          this.totalPointsAvailable += msQuestion.pointsAvailable;
          break;
        case 'paired':
          let mpQuestion = new MatchedPairQuestion('', '', null);
          mpQuestion = Object.assign(mpQuestion, question);
          this.totalScore += mpQuestion.getMark();
          this.numberOfQuestions += (question as MatchedPairQuestion).subQuestions.length;
          this.totalPointsAvailable += mpQuestion.pointsAvailable;
          break;
        case 'numerical':
          let nQuestion = new NumericalQuestion('', '', null);
          nQuestion = Object.assign(nQuestion, question);
          this.totalScore += nQuestion.getMark();
          this.numberOfQuestions += 1;
          this.totalPointsAvailable += nQuestion.pointsAvailable;
          break;

      }
      this.showFeedback = true;
    }
  }

  ngOnDestroy(): void {
    this.theSubscription.unsubscribe();
  }

}
