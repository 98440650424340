import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { CourseRegistrationComponent } from './course-registration.component';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { ModalButton } from '@app/common/models/modal-button';
import { CourseEnrolment } from '@app/common/models/user';

@Injectable({
  providedIn: 'root'
})
export class ModalCourseRegistrationService {
  bsModalRef: BsModalRef;

  constructor(
    private bsModalService: BsModalService,
  ) { }

  confirm(enrolment: CourseEnrolment): Observable<CourseEnrolment[]> {
    const initialState = {
      courseEnrolment: enrolment,
      answer: new Array<CourseEnrolment>(),
    };

    this.bsModalRef = this.bsModalService.show(CourseRegistrationComponent, 
      { initialState, class: 'modal-dialog-centered', backdrop: 'static' });

    return new Observable<CourseEnrolment[]>(this.getConfirmSubscriber());
  }

  private getConfirmSubscriber() {
    return (observer) => {
      const subscription = this.bsModalService.onHidden.subscribe((reason: string) => {
        observer.next(this.bsModalRef.content.answer);
        observer.complete();
      });

      return {
        unsubscribe() {
          subscription.unsubscribe();
        }
      };
    };
  }
}
