<ebs-spinner [spinnerMessage]="'loading tags'" *ngIf="loadingTags"></ebs-spinner>

<div *ngIf="!loadingTags">
  <div class="tag-container" style="border-radius:6px">
    <div class="form-control" style="border: none;">
      <h4 style="margin-bottom: 0;">{{taglistHeader}} </h4>
      <div (click)="toggleActive(tag)" class="mr-2 mt-3 btn btn-light tag" [ngClass]="{
          'btn-success' : tag.selected, 
          'btn-light' : !tag.selected }" *ngFor="let tag of tagList">
          <i class="fal fa-fw fa-tag" *ngIf="!tag.selected"></i><i class="fas fa-fw fa-tag" *ngIf="tag.selected"></i> {{tag.name}}
      </div>
    </div>
  </div>
  <p class="text-warning p-2" *ngIf="tagList.length === 0 && !showError">No tags have yet been added for this course.
  </p>
  <div class="row">
    <div class="col-12">
      <div class="mt-2" style="border-radius:6px;" *ngIf="canAddTag">
        <div class="input-group pt-2 pr-2 pl-2 mb-3">
          <input type="text" [(ngModel)]="tagValue" class="form-control" placeholder="type new tag here"
            aria-label="single or multiple words" aria-describedby="input-tag" maxlength="tagCharacterLimit">
          <div class="input-group-append" (click)="addTag(tagValue); $event.preventDefault()" style="cursor: pointer;">
            <span class="input-group-text btn btn-primary ml-2" id="input-tag" 
            *ngIf="tagValue && !(tagValue.length > tagCharacterLimit)" >Add Tag</span>
          </div>
        </div>
        <p class="text-warning" *ngIf="tagExists">{{duplicateMessage}}</p>
        <p class="text-warning" *ngIf="tagValue && (tagValue.length > tagCharacterLimit)">Tags must be {{tagCharacterLimit}} characters or less</p>
      </div>
      <div>
        <ebs-message-simple [displayMessage]="errorMessage" *ngIf="showError"></ebs-message-simple>
      </div>
    </div>
  </div>
</div>