import { Injectable } from '@angular/core';
import { FileIFrameActivity } from '../models/file-iframe-activity';
import { ServiceBase } from '@app/core/service-base';
import { Observable } from 'rxjs';
import { OperationResponse } from '@app/common/models/operation-response';

@Injectable({
  providedIn: 'root'
})
export class FileIFrameActivityService extends ServiceBase {
  getFileIFrameActivity(itemUrl, treatAsOffline): Observable<OperationResponse<FileIFrameActivity>> {
    return this.getObject<FileIFrameActivity>(itemUrl, treatAsOffline);
  }
}
