import { BrowserModule } from '@angular/platform-browser';
import { NgModule, APP_INITIALIZER } from '@angular/core';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

import { CoreModule } from './core/core.module';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { CoursePageComponent } from './course-page/course-page.component';
import { CourseNavigationModule } from './courses/course-navigation.module';
import { IndexedDbService } from '@app/core/indexed-db.service';
import { ObjectStore } from '@app/core/object-store';
import { ObjectStoreBase } from '@app/core/object-store-base';
import { UserService } from '@app/common/security/user.service';
import { OnlineStatusService } from '@app/common/online-status/online-status.service';
import { LoginComponent } from '@app/authentication/login/login.component';
import { LoginOfflineComponent } from './authentication/login-offline/login-offline.component';
import { PageHeaderComponent } from '@app/common/components/page-header/page-header.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AuthGuard } from '@app/common/security/auth-guard';
import { ComponentsModule } from './common/components/components.module';
import { AuthenticationService } from '@app/common/security/authentication.service';
import { AppConfigService } from '@app/common/app-configuration/app-config.service';
import { initializeAppConfig } from '@app/common/app-configuration/app-config.factory';
import { ToastrModule } from 'ngx-toastr';
import { NgScrollbarModule } from 'ngx-scrollbar';
import { EditorModule } from '@tinymce/tinymce-angular';
import { TabsModule, BsDropdownModule, TooltipModule } from 'ngx-bootstrap';
import { ModalSimpleComponent } from './common/components/modal-simple/modal-simple.component';
import { SectionService } from '@app/courses/services/section.service';
import { CourseAdminComponent } from './admin/components/course-admin/course-admin.component';
import { ConfigurationHelperService } from '@app/common/app-configuration/configuration-helper.service';
import { LogoutComponent } from './authentication/logout/logout.component';
import { ClearCacheService } from './core/clear-cache.service';
import { CookieService } from './common/cookies/cookie.service';
import { SwManagerService } from './common/service-workers/sw-manager.service';
import { AuthGuardPortalAdministrator } from './common/security/auth-guard-portal-administrator';
import { SystemNotificationComponent } from './admin/components/system-notification/system-notification.component'
import { HttpRequestInterceptor } from './core/http-request-interceptor';
import { ModalLoginComponent } from './common/components/modal-login/modal-login.component';
import { ModalOfflineComponent } from './common/components/modal-offline/modal-offline.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { DeviceDetectorService } from 'ngx-device-detector';
import { PageNotFoundComponent } from './common/components/page-not-found/page-not-found.component';
import { TopMenuComponent } from './common/components/top-menu/top-menu.component';
import { DashboardMenuComponent } from './common/components/dashboard-menu/dashboard-menu.component';
import { CourseRegistrationComponent } from './common/components/course-registration/course-registration.component';
import { CourseraRegistrationModalComponent } from './coursera-registration/components/coursera-registration-modal/coursera-registration-modal.component';
import { CourseraLtiComponent } from './coursera-registration/components/couraera-lti/coursera-lti.component';

// export function bypassesIndexedDB(): boolean{
//     let queryString = location.search.substring(1); 
//     return queryString.indexOf('bypassIndexedDB') > -1;
// }

@NgModule({
  declarations: [
    AppComponent,
    DashboardComponent,
    CoursePageComponent,
    LoginComponent,
    ModalSimpleComponent,
    CourseAdminComponent,
    LogoutComponent,
    PageHeaderComponent,
    SystemNotificationComponent,
    ModalLoginComponent,
    ModalOfflineComponent,
    CourseRegistrationComponent,
    CourseraRegistrationModalComponent,
    CourseraLtiComponent,
    LoginOfflineComponent,
    PageNotFoundComponent,
    TopMenuComponent,
    DashboardMenuComponent
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    CoreModule,
    FormsModule,
    BrowserAnimationsModule,
    ComponentsModule,
    ToastrModule.forRoot(),
    NgScrollbarModule,
    TabsModule.forRoot(),
    BsDropdownModule.forRoot(),
    EditorModule,
    ServiceWorkerModule.register('connectus-ngsw-worker.js', { enabled: environment.production }),
    AppRoutingModule,
    CourseNavigationModule
  ],
  providers: [HttpClient, IndexedDbService, CookieService, UserService, OnlineStatusService, SectionService, DeviceDetectorService,
    AuthGuard, AuthGuardPortalAdministrator, AuthenticationService, AppConfigService, ConfigurationHelperService, ClearCacheService, SwManagerService,
    { provide: APP_INITIALIZER, useFactory: initializeAppConfig, deps: [AppConfigService], multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: HttpRequestInterceptor, multi: true },
    //{ provide : ObjectStoreBase,  useClass: location.search.indexOf('bypassIndexedDB') > -1 ? ObjectStoreBase : ObjectStore, multi: false}   

    { provide: 'BYPASS_INDEXEDDB', useValue: (location.search.indexOf('bypassIndexedDB') > -1) },
    {
      provide: ObjectStoreBase,
      useFactory: (BYPASS_INDEXEDDB) => BYPASS_INDEXEDDB ? new ObjectStoreBase() : new ObjectStore(new IndexedDbService()),
      deps: ['BYPASS_INDEXEDDB'],
      multi: false
    },
    {provide: 'googleTagManagerId', useValue: "GTM-5W6STHS"}

  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
