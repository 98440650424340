import { Component, OnInit, Input, OnChanges, SimpleChanges, EventEmitter, Output, ViewChild, HostListener, OnDestroy } from '@angular/core';
import { Section } from '@app/courses/models/section';
import { SectionService } from '@app/courses/services/section.service';
import { StaticText } from '@app/common/static-text';
import { QuizStatistics } from '@app/courses/models/quiz/quiz-statistics';
import { QuizStatisticsService } from '@app/courses/services/quiz-statistics.service';
import { OperationResponse } from '@app/common/models/operation-response';
import { QuizStatisticsModule } from '@app/courses/models/quiz/quiz-statistics-module';
import { QuizStatisticsQuiz } from '@app/courses/models/quiz/quiz-statistics-quiz';
import { DiscussionEngagementStatisticsService } from '@app/courses/services/discussion-engagement-statstics.service';
import { CourseDiscussionEngagementStatistics } from '@app/courses/models/discussion/course-discussion-engagement-statistics';
import { Router } from '@angular/router';
import { map, delay, retryWhen, take } from 'rxjs/operators';
import { CourseNavigationService } from '@app/courses/services/course-navigation.service';
import { CourseStructure } from '@app/courses/models/course-structure';
import { NotificationSummary } from '@app/common/models/notification-summary';
import { SeverityLevel } from '@app/common/models/severity-level';
import { LastSectionAccessedService } from '@app/courses/services/last-section-accessed.service';
import { LastSectionAccessed } from '@app/courses/models/last-section-accessed';
import { CanComponentDeactivate } from '@app/common/security/can-deactivate.guard';
import { SectionComponent } from '../section/section.component';
import { Helpers } from '@app/common/helpers';
import { StateService } from '@app/core/state.service';
import { OnlineStatusService } from '@app/common/online-status/online-status.service';
import { OnlineStatusType } from '@app/common/online-status/online-status-type.enum';
import { Subscription } from 'rxjs';
import { enterOrLeave } from '@app/common/animations/animations';
import { SwManagerService } from '@app/common/service-workers/sw-manager.service';
import { UserService } from '@app/common/security/user.service';
import { UserProfile, UserRole } from '@app/common/models/user';
import { Title } from '@angular/platform-browser';
import { ForumListComponent } from '../discussion-forum/components/forum-list/forum-list.component';

@Component({
  selector: 'ebs-course-content',
  templateUrl: './course-content.component.html',
  styleUrls: ['./course-content.component.scss'],
  animations: [enterOrLeave]
})
export class CourseContentComponent implements OnInit, OnChanges, CanComponentDeactivate, OnDestroy {
  @Input() sectionId: number;
  @Input() courseId = '';
  @Input() moduleId: number;
  @Input() discussionId: number;
  @Input() postId: number;
  @Input() replyId: number;
  @Output() sectionChangedUrl = new EventEmitter<{ url: string, differentModule: boolean }>();
  @Output() bubbleMenuUpdateFromProgressContent = new EventEmitter<number>();
  nextText = StaticText.nextText;
  prevText = StaticText.prevText;
  section: Section;
  gettingSection: boolean;
  errorOccured: boolean;
  errorMessage = 'An error has occurred loading the section, please try again later.';
  sectionIsComplete = false;
  hideNext = false;
  hidePrev = false;
  showQuizStatsDetails = false;
  showDiscussionStatsDetails = false;
  buttonText = 'more';
  buttonTextQuiz = 'more';
  quizStatistics: QuizStatistics = new QuizStatistics();
  moduleDiscussionEngagementStatistics: CourseDiscussionEngagementStatistics = new CourseDiscussionEngagementStatistics();
  isLoading = true;
  quizStatsIsLoading: boolean = true;
  discussionStatsIsLoading: boolean = true;
  courseStructure: CourseStructure = new CourseStructure('1', [], []);
  percentComplete = 0;
  progressNotifications: NotificationSummary[];
  showProgressError = false;
  gettingProgress = false;
  showLastAccessError = false;
  gettingLastAccess = false;
  lastAccessed: LastSectionAccessed;
  lastAccessNotifications: NotificationSummary[] = [];
  isMobile = Helpers.isItMobile(window.innerWidth);
  @ViewChild(SectionComponent, { static: false }) child: SectionComponent;
  downloadsLink: string;
  printableMaterialsLink: string;
  manageSubsLink: string;
  linkToFirstSectionOfCourse: string;
  theSubscription: Subscription;
  isOffline: boolean;
  offlineMessage = StaticText.courseDashboardOfflineMessage;
  offlineSectionMessage = StaticText.sectionOfflineMessage;
  printablesOfflineMessage = StaticText.printablesOfflineMessage;
  downloadedOfflineMessage = StaticText.downloadedOfflineMessage;
  showOfflineMessage = false;
  localCourseSet = false;
  displayMessage = 'unable to get last page visited link';
  notAvailableMessage = 'Quiz and engagement statistics are not currently available, please try again';
  supportsServiceWorkers = true;
  displayStatistics = true;
  displayQuizStats = true;
  currentUser: UserProfile;
  isDemoUser = false;
  isDemo2User = false;

  private personId: string;

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.isMobile = Helpers.isItMobile(window.innerWidth);
  }

  constructor(
    private titleService: Title,
    private sectionService: SectionService,
    private quizStatisticsService: QuizStatisticsService,
    private discussionEngagementStatisticsService: DiscussionEngagementStatisticsService,
    private router: Router,
    private courseNavigationService: CourseNavigationService,
    private lastAccessedService: LastSectionAccessedService,
    private stateService: StateService,
    private onlineStatusService: OnlineStatusService,
    private swManagerService: SwManagerService,
    private userService: UserService,
  ) {

    if (this.swManagerService.checkBrowserSupportsServiceWorkers()) {
      this.supportsServiceWorkers = true;
    } else {
      this.supportsServiceWorkers = false;
    }
    this.theSubscription = this.onlineStatusService.status.subscribe(
      event => {
        if (event === OnlineStatusType.OFFLINE) {
          this.isOffline = true;
        }

        if (event === OnlineStatusType.ONLINE) {
          this.isOffline = false;
        }
      }
    )
  }

  ngOnInit() {

    this.isLoading = true;
    this.userService.getCurrentUser().subscribe((x: UserProfile) => {
      this.personId = x.userAccount.username;
      this.currentUser = x;
      this.currentUser.userAccount.roles.find(x => x.toString() == "Demo") ? this.isDemoUser = true : this.isDemoUser = false;
      this.currentUser.userAccount.roles.find(x => x.toString() == "Demo2") ? this.isDemo2User = true : this.isDemo2User = false;
    }, error => {
      console.log(error);
    }, () => {
      this.quizStatsIsLoading = true;

      this.discussionStatsIsLoading = true;

      this.sectionService.courseCode = this.courseId;
      this.downloadsLink = this.router.url.substring(0, this.router.url.lastIndexOf('/') + 1) + '-3';
      this.printableMaterialsLink = this.router.url.substring(0, this.router.url.lastIndexOf('/') + 1) + '-2';
      this.manageSubsLink = this.router.url.substring(0, this.router.url.lastIndexOf('/') + 1) + '-8';

      this.onlineStatusService.isOffline().subscribe(x => {
        this.isOffline = x;
        this.getQuizStatistics();
        this.getDiscussionEngagementStatistics();
        this.getLinkToFirstSectionOfCourse();
      }, error => {
        this.isOffline = false;
        this.getQuizStatistics();
        this.getDiscussionEngagementStatistics();
      });
    });
  }

  canDeactivate(): boolean {
    if (this.child) {
      return this.child.canDeactivate();
    }
    return true;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.isOffline != null) {
      this.propChanges(changes);
    }
    else {
      this.onlineStatusService.isOffline().subscribe(x => {
        this.isOffline = x;
        this.propChanges(changes);
      }, error => {
        this.isOffline = false;
        this.propChanges(changes);
      });
    }
  }

  propChanges(changes: SimpleChanges): void {
    for (const propName in changes) {

      if (propName === 'sectionId') {
        const chng = changes[propName];
        this.sectionService.sectionId = this.sectionId;

        if (this.sectionId > 0) {
          this.getSection(chng.currentValue);
        }
        if (this.sectionId === 0) {
          this.titleService.setTitle('Course dashboard');
          // we need the menu to load first the first time -- adding a delay on the sub didn't work
          let root = this;
          setTimeout(function () {
            root.getProgress();
          }, 2000);

          this.getQuizStatistics();
          this.getDiscussionEngagementStatistics();
          this.getLastAccessed();
        }
      }
      if (propName === 'moduleId') {
        this.sectionService.moduleId = this.moduleId;
      }
    }
  }

  getQuizStatistics() {
    this.isLoading = true;
    if (this.personId == null) return;
    this.quizStatisticsService.getQuizStatistics(`courses/${this.courseId}/${this.personId}/quizstatistics`, this.isOffline)
      .subscribe(
        (quizStatisticsResponse: OperationResponse<QuizStatistics>) => {
          if (quizStatisticsResponse.operationSucceeded) {
            // this.quizStatistics = quizStatisticsResponse.data;
            this.quizStatistics = this.copyQuizStatistics(quizStatisticsResponse.data);
          } else {
            // alert("not OK");
          }
          this.quizStatsIsLoading = false;
          this.isLoading = false;
          this.displayQuizStats = true;
        }, error => {
          // alert(error);
          this.quizStatsIsLoading = false;
          this.isLoading = false;
          this.displayQuizStats = false;
        }
      );
  }
  // GM: we need separate spinners for quiz and discussion stats
  getDiscussionEngagementStatistics() {
    this.discussionStatsIsLoading = true;
    if (this.personId == null) return;
    this.discussionEngagementStatisticsService.getModuleDiscussionEngagementStatistics(`courses/${this.courseId}/${this.personId}/discussionstatistics`, this.isOffline)
      .subscribe(
        (response: OperationResponse<CourseDiscussionEngagementStatistics>) => {
          if (response.operationSucceeded) {
            Object.assign(this.moduleDiscussionEngagementStatistics, response.data);
          }
          this.isLoading = false;
          this.discussionStatsIsLoading = false;
          this.displayStatistics = true;

        }, error => {
          this.isLoading = false;
          this.discussionStatsIsLoading = false;
          this.displayStatistics = false;

        });
  }

  copyQuizStatistics(objToCopy: QuizStatistics): QuizStatistics {
    let newStatistics = new QuizStatistics();
    newStatistics = Object.assign(newStatistics, objToCopy);
    newStatistics.quizStatisticsModules = [];
    for (const oldModule of objToCopy.quizStatisticsModules) {
      newStatistics.quizStatisticsModules.push(this.copyQuizStatisticsModules(oldModule));
    }
    return newStatistics;
  }

  copyQuizStatisticsModules(objToCopy: QuizStatisticsModule): QuizStatisticsModule {
    let newModule = new QuizStatisticsModule;
    newModule = Object.assign(newModule, objToCopy);
    newModule.statisticsQuizes = [];
    for (const oldQuiz of objToCopy.statisticsQuizes) {
      newModule.statisticsQuizes.push(this.copyQuizStatisticsQuizes(oldQuiz));
    }
    return newModule;
  }

  copyQuizStatisticsQuizes(objToCopy: QuizStatisticsQuiz): QuizStatisticsQuiz {
    let newQuiz = new QuizStatisticsQuiz;
    newQuiz = Object.assign(newQuiz, objToCopy);
    return newQuiz;
  }

  quizStatsToggle() {
    this.showQuizStatsDetails = !this.showQuizStatsDetails;
    if (this.showQuizStatsDetails) {
      this.buttonTextQuiz = 'less';
    } else {
      this.buttonTextQuiz = 'more';
    }
  }

  discussionStatsToggle() {
    this.showDiscussionStatsDetails = !this.showDiscussionStatsDetails;
    if (this.showDiscussionStatsDetails) {
      this.buttonText = 'less';
    } else {
      this.buttonText = 'more';
    }
  }

  toggleBoth() {
    this.quizStatsToggle();
    this.discussionStatsToggle();
  }

  routePrevious() {
    const prevUrl = this.section.previousSectionUri;
    if (prevUrl && (prevUrl !== '')) {
      this.router.navigate([prevUrl]);
    }
  }

  routeNext() {
    const nextUrl = this.section.nextSectionUri;
    if (nextUrl && (nextUrl !== '')) {
      this.router.navigate([nextUrl]);
    }
  }

  handleMenuUpdated(e: number) {
    this.bubbleMenuUpdateFromProgressContent.emit(e);
  }

  getProgress() {
    this.showProgressError = false;
    this.gettingProgress = true;
    // change this to use the version inserted by the menu or we will override
    this.courseNavigationService.getLocalCourseStructure(this.courseId)
      .subscribe(
        response => {
          if (response) {
            this.courseStructure = response
            this.percentComplete = Math.round((response.sectionsComplete / response.sectionsInTotal) * 100);
            this.localCourseSet = true;
          } else {
            this.showProgressError = true;
          }
          this.gettingProgress = false;
        },
        error => {
          console.log(error);
          this.progressNotifications.push(new NotificationSummary('Error returning progress percent', 'Http', SeverityLevel.Error));
          this.showProgressError = true;
          this.gettingProgress = false;
        }
      );
  }

  getLastAccessed() {
    this.showLastAccessError = false;
    this.gettingLastAccess = true;
    // pass uri and section
    this.lastAccessedService.getLastSectionAccessed('courses/' + this.courseId + '/lastsectionaccessed', this.isOffline).pipe(map(
      res => {
        if (res.data && res.data.dateTimeOfLastSectionAccess) {
          res.data.dateTimeOfLastSectionAccess = new Date(res.data.dateTimeOfLastSectionAccess.toString() + 'Z'); // aint localised otherwise!
        }
        return res;
      })
    ).subscribe(
      response => {
        if (response.operationSucceeded) {
          this.showLastAccessError = false;
          this.lastAccessed = response.data;
        } else {
          this.lastAccessNotifications = response.notifications;
          if (this.isOffline) {
            this.showLastAccessError = false;
          } else {
            this.showLastAccessError = true;
          }

        }
        this.gettingLastAccess = false;
      },
      error => {
        console.log(error);
        this.lastAccessNotifications.push(new NotificationSummary('There has been an error getting the last accessed section please try later', 'Last Access Error', SeverityLevel.Error));
        this.showLastAccessError = true;
        this.gettingLastAccess = false;
      }
    );
  }

  getSection(sectionId: number) {
    this.gettingSection = true;
    this.errorOccured = false;
    this.showOfflineMessage = false;

    this.sectionService.getSectionSkeleton(this.courseId, sectionId, this.moduleId, this.isOffline)
      .subscribe(
        sectionResponse => {
          // G.M. if operation succeeded false and isoffline then page not available for offline mode
          if (!sectionResponse.operationSucceeded && this.isOffline) {
            // show offline message
            this.showOfflineMessage = true;
          } else {
            this.section = sectionResponse.data;
          }

          this.stateService.authSectionChange = false;
          this.gettingSection = false;
          this.sectionIsComplete = this.section.completed;
          this.hideNext = this.section.nextSectionUri ? false : true;
          this.hidePrev = this.section.previousSectionUri ? false : true;
          this.errorOccured = false;
        },
        error => {
          this.stateService.authSectionChange = true; // we havent loaded the section so we need to let the login popup know this so it can reload
          this.gettingSection = false;
          this.errorOccured = true;
          this.section = null;
        }
      );
  }

  getLinkToFirstSectionOfCourse() {
    this.isLoading = true;
    this.courseNavigationService.getCourseStructure(this.courseId, this.isOffline).subscribe((c: OperationResponse<CourseStructure>) => {
      const firstModule = c.data.modules[0];
      this.linkToFirstSectionOfCourse = `/courses/${this.courseId}/modules/${firstModule.id}/sections/${firstModule.sections[0].id}`;
      this.isLoading = false;
    }, error => {
      console.log(`Failed to get URL for first section of course ${error}`);
      this.isLoading = false;
    });
  }

  ngOnDestroy(): void {
    this.theSubscription.unsubscribe();
  }


  // ifLastAccessedDoesntExist() {
  //   if (this.lastAccessed) {
  //     return false;
  //   } else {
  //     return true;
  //   }
  // }

  // ifLastAccessedDoesExist() {
  //   if (this.lastAccessed) {
  //     return this.lastAccessed.sectionUri && this.lastAccessed.sectionUri.length > 0
  //   } else {
  //     return false;
  //   }
  // }

  // ifFirstTimeLastAccessed(){
  //   if (this.lastAccessed) {
  //     if (this.lastAccessed.sectionUri === null){
  //       return true
  //     }else{
  //       false
  //     }
  //   } else{
  //     return false
  //   }
  // }
}