import { ChoiceOption } from './choice-option';

export class MatchedPairSubQuestion {
    public questionText: string;
    public options: Array<ChoiceOption>;
    public isComplete = false;

    constructor(questionText: string, options: Array<ChoiceOption>) {
        this.questionText = questionText;
        this.options = options;
    }

    public getMark(): number {
        let score = 0;
        for (let option of this.options) {
            if (option.isCorrect && option.isSelected) {
                score++;
            }
        }
        return score;
    }
}
