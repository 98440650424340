   <div *ngIf="!loadingData">
        <div class="hidden-print quiz-match-the-pairs-input">
            <div *ngFor="let subQuestion of question.subQuestions">
                <ebs-match-the-pairs-subquestion [identifier]="subQuestion.id" (notify)='markIncomplete()'
                    [showFeedback]='showFeedback' [question]='subQuestion'></ebs-match-the-pairs-subquestion>
                <hr>
            </div>
    
            <div class="mb-4">
                <div ebsTechnicalContent class="text-info small"
                    *ngIf="showFeedback && answerIsCorrect() && question.correctCommentsHtml !=null"
                    [innerContent]="question.correctCommentsHtml">
                </div>
                <div ebsTechnicalContent class="text-info small"
                    *ngIf="showFeedback && !answerIsCorrect() && question.incorrectCommentsHtml !=null"
                    [innerContent]="question.incorrectCommentsHtml">
                </div>
                <div ebsTechnicalContent class="text-info small" *ngIf="showFeedback && question.neutralCommentsHtml!=null"
                    [innerContent]="question.neutralCommentsHtml">
                </div>
            </div>
        </div>
    
        <div class="d-none d-print-block table-responsive match-the-pairs">
            <table class="table">
                <tr>
                    <td style="width: 50%">
                        <ul>
                            <li *ngFor="let subQuestion of question.subQuestions">{{subQuestion.questionText}}</li>
                        </ul>
                    </td>
                    <td style="width: 50%">
                        <ul *ngIf="question?.subQuestions != null && question.subQuestions.length > 0">
                            <li *ngFor="let option of question.subQuestions[0].options">{{option.text}}</li>
                        </ul>
                    </td>
                </tr>
            </table>
        </div>
    
    </div>