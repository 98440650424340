import { DiscussionPostOrReplyItem } from "@app/courses/components/discussion-course/models/discussion-postorreply-item";

export function dateSort(commentA: DiscussionPostOrReplyItem, commentB: DiscussionPostOrReplyItem) {
    var a = new Date(commentA.clientModifiedAt);
    var b = new Date(commentB.clientModifiedAt);

    // var utcDateA = Date.UTC(a.getFullYear(), a.getMonth() + 1, a.getDate());
    // var utcDateB = Date.UTC(b.getFullYear(), b.getMonth() + 1, b.getDate());

    // if (utcDateA > utcDateB)
    //     return -1;
    // else if (utcDateA < utcDateB)
    //     return 1;
    // else
    //     return 0;
    if (a > b)
        return -1;
    else if (a < b)
        return 1;
    else
        return 0;
}
