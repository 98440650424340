import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { LearningActivityItemComponentBase } from '@app/courses/models/dynamic-load/learning-activity-item-component-base';
import { ThirdPartyActivity } from '@app/courses/models/thirdparty-activity';
import { ThirdPartyActivityService } from '@app/courses/services/third-party-activity.service';
import { StaticText } from '@app/common/static-text';
import { OperationResponse } from '@app/common/models/operation-response';
import { OnlineStatusService } from '@app/common/online-status/online-status.service';
import { OnlineStatusType } from '@app/common/online-status/online-status-type.enum';
import { Subscription } from 'rxjs';

@Component({
  selector: 'ebs-third-party',
  templateUrl: './third-party-activity.component.html',
  styleUrls: ['./third-party-activity.component.scss']
})
export class ThirdPartyActivityComponent implements OnInit, LearningActivityItemComponentBase<ThirdPartyActivity>, OnDestroy {  
  @Input() activityItem: ThirdPartyActivity;
  @Input() isOffline: boolean;
  rawUrl: string;
  offlineMessage = StaticText.thirdPartyMessageOffline;
  onlineMessage = StaticText.thirdPartyMessageOnline;
  getting3rdParty: boolean;
  theSubscription: Subscription;

  constructor(private thirdPartyService: ThirdPartyActivityService,
    private onlineStatusService: OnlineStatusService) {
     
    
    this.theSubscription = this.onlineStatusService.status.subscribe(
      event => {
        this.isOffline = (event === OnlineStatusType.OFFLINE) ? true : false
      }
    )
  }

  ngOnInit() {
    this.onlineStatusService.isOffline().subscribe(x => {
        this.isOffline = x;
        this.getData();
    }, error=>{
        this.isOffline = false;
        this.getData();
    });    
  }
  
  canDeactivate(): boolean {
    return true;
  }

  ngOnDestroy(): void {
    if (this.theSubscription)
      this.theSubscription.unsubscribe()
  }

  getData() {
    this.getting3rdParty = true;
    this.thirdPartyService.getThirdPartyActivity(this.activityItem.uri, this.isOffline)
      .subscribe(
        (response: OperationResponse<ThirdPartyActivity>) => {
          this.activityItem = response.data;
          this.rawUrl = response.data.videoUrl;
          this.getting3rdParty = false;
        },
        error => {
          console.log('Third Part component error :' + error);
          this.getting3rdParty = false;
        }
      );
  }
}
