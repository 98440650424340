import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { OperationResponse } from '@app/common/models/operation-response';
import { GameActivity } from '../models/games/game-activity';
import { ServiceBase } from '@app/core/service-base';
import { GameState } from '../models/games/game-state';

@Injectable({
  providedIn: 'root'
})
export class GameActivityService extends ServiceBase {

  getGameActivity(itemUrl: string, treatAsOffline: boolean): Observable<OperationResponse<GameActivity>>{
    return this.getObject<GameActivity>(itemUrl,treatAsOffline);
  }

  setGameState(itemUrl: string, gameState: GameState, treatAsOffline: boolean): Observable<OperationResponse<GameState>> {
    return this.setObjectInAvailableStores(gameState, itemUrl, treatAsOffline); 
  }

  getGameState(itemUrl: string, treatAsOffline: boolean): Observable<OperationResponse<GameState>>{
    return this.getObject<GameState>(itemUrl,treatAsOffline);
  }
}
