import { Component, OnInit, Input, ViewChild, OnDestroy, ChangeDetectorRef, AfterViewInit, Inject } from '@angular/core';
import { VideoActivity } from '@app/courses/models/video-activity';
import { LearningActivityItemComponentBase } from '@app/courses/models/dynamic-load/learning-activity-item-component-base';
import { MediaPlayerComponent } from '../media-player/media-player.component';
import { MediaActivityService } from '@app/courses/services/media-activity.service';
import { OperationResponse } from '@app/common/models/operation-response';
import { OnlineStatusService } from '@app/common/online-status/online-status.service';
import { OnlineStatusType } from '@app/common/online-status/online-status-type.enum';
import { Subscription, Observable } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { NotificationSummary } from '@app/common/models/notification-summary';
import { SeverityLevel } from '@app/common/models/severity-level';
import { VideoCachingService } from '@app/downloads/services/video-caching.service';
import { DeviceDetectorWrapperService } from '@app/common/device-detector/device-detector-wrappe.servicer';
import { StorableVideo } from '@app/courses/models/video';
import { ObjectStoreBase } from '@app/core/object-store-base';

import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'ebs-video-activity',
  templateUrl: './video-activity.component.html',
  styleUrls: ['./video-activity.component.scss']
})
export class VideoActivityComponent implements OnInit, OnDestroy, AfterViewInit, LearningActivityItemComponentBase<VideoActivity> {

  @Input() activityItem: VideoActivity;
  @ViewChild(MediaPlayerComponent, { static: false }) child;
  public videoSourceUrl: string;
  loadingData = false;
  failedMessage = 'The media has failed to load. Please try again';
  public videoUrl: string;
  videoThumbNail: string;
  public isOffline : boolean;
  theSubscription: Subscription;
  private courseCode: string;
  public videoId:string = '';
  notifications: Array<NotificationSummary> = new Array<NotificationSummary>();

  offlineVideoUrl : string;
  offlineIosVideoBlobUrl : string;

  cachedVideo : StorableVideo = null;

  downloadedVideoContent: ArrayBuffer;

  constructor(private mediaActivityService: MediaActivityService,
    private onlineStatusService: OnlineStatusService,
    private route: ActivatedRoute,
    private videoCachingService : VideoCachingService,
    private deviceDetectorWrapperService: DeviceDetectorWrapperService,
    private objectStore: ObjectStoreBase,
    private cdr: ChangeDetectorRef,
    @Inject(HttpClient) private http: HttpClient
    ) {
      this.theSubscription = this.onlineStatusService.status.subscribe(event => {
        if (event === OnlineStatusType.OFFLINE)
          this.isOffline = true;

        if (event === OnlineStatusType.ONLINE) {
          this.isOffline = false;
        }
      }, error=> {

      });
    }



    ngOnDestroy(): void {
      if (this.theSubscription)
        this.theSubscription.unsubscribe();
    }

    ngAfterViewInit(): void {
      this.cdr.detectChanges();
    }

  ngOnInit() {

    //this.downloadFile();

    this.onlineStatusService.isOffline().subscribe(x => {
        this.isOffline = x;
    }, error=>{
        this.isOffline = false;
    }, ()=>{
      this.getMediaData();
    });
    this.route.paramMap.subscribe(params => {
      this.courseCode = params.get('courseCode');
    }).unsubscribe();
  }

  canDeactivate(): boolean {
    return true;
  }

  //Gets the media activity then uses download service to get the media file as bytes
  getMediaData() {
    this.loadingData = true;
    this.notifications = new Array<NotificationSummary>();
    this.mediaActivityService.getMediaActivity(this.activityItem.uri, this.isOffline).subscribe(
      (mediaResponse: OperationResponse<VideoActivity>) => {
        if (mediaResponse.operationSucceeded) {
          this.activityItem = mediaResponse.data;

          if (this.isiOS() === true) {
            this.getVideo();
          }
          else {
            this.videoCachingService.retrieveCachedVideoUrl(this.getVideoUri()).subscribe(x =>{
              this.offlineVideoUrl = x;
            });
          }
        } else {
          this.notifications = mediaResponse.notifications;
          for (let i = 0; i < mediaResponse.notifications.length; i++) {
            this.failedMessage += ' | ' + mediaResponse.notifications[i].message;
          }
        }
      }, error => {
        this.notifications.push(new NotificationSummary(error, 'unknown', SeverityLevel.Error, 'unknown', 0))
        this.loadingData = false;
      }, () => {

        this.videoId = this.activityItem.videoUrl.split('/video/')[1];

        this.videoUrl = this.activityItem.videoUrl + '?transparent=0';
        this.videoUrl = this.activityItem.videoUrl;

        this.videoThumbNail = this.activityItem.thumbnail;
        this.loadingData = false;
      }
    );
  }

  isiOS():boolean{
    return this.deviceDetectorWrapperService.isiOS();
  }

  getVideoUri():string{
    if (this.activityItem == null || this.courseCode == null) {
      return '';
    }

    let videoId = this.activityItem.videoUrl.replace('https://player.vimeo.com/video','');

    return 'courses/' + this.courseCode + '/videos'+  videoId + ".mp4";
  }

  hasValidOfflineUri():boolean{
    return this.offlineVideoUrl != null && this.offlineVideoUrl != '' && this.offlineVideoUrl!="not found in cache";
  }

  retrieveVideoKeyFromIndexedDB(videoUri:string):Observable<string>{
    const retrieveVideoUriObs = new Observable<any>(observer => {
      this.objectStore.getAllPrimaryKeys().then(allKeys => {
        if (allKeys && allKeys.length>0){
          const videoURL = allKeys.find(x => x.indexOf(videoUri) > -1);

          observer.next(videoURL);
          observer.complete();
        }
        else {
          observer.next(null);
          observer.complete();
        }
      }, reason => {
        observer.error(reason);
        observer.complete();
      })
    });

    return retrieveVideoUriObs;
  }

  getVideo(){
    // this.retrieveVideoKeyFromIndexedDB(this.getVideoUri()).subscribe(x =>
    //   {
        let x = this.getVideoUri();
        if (x != null){
            this.objectStore.get(x).then( video => {
              this.cachedVideo = <StorableVideo>video;
              if (this.offlineIosVideoBlobUrl == null){
                // this.offlineIosVideoBlobUrl = this.getBlobUrl();
                // this.cdr.detectChanges();

                this.blobToArrayBuffer(this.cachedVideo.blob)
                .then((videoContent: ArrayBuffer) => {
                  this.downloadedVideoContent = videoContent;

                  this.offlineIosVideoBlobUrl = this.getBlobUrlHardCoded();
                
                  this.cdr.detectChanges();
                  //console.log('download complete', res);
                });

                
              }
            }, reason => {
              this.cachedVideo = null;
              //this.cdr.detectChanges();
            });
        } else {
          this.cachedVideo = null;
          //this.cdr.detectChanges();
        }
      //}
    //)
  }

  getBlobUrl(): string {
    console.log('Blob URL generation has been hit');

    if (this.cachedVideo == null) return null;

    //const blob = new Blob([this.cachedVideo.blob], { type: 'video/mp4' });
   
    const blobUrl = URL.createObjectURL(this.cachedVideo.blob);
    
    return blobUrl;
  }

  hasCachedVideo():boolean{
    console.log('this.offlineIosVideoBlobUrl is undefined or null = ' + (this.offlineIosVideoBlobUrl == null));
    return this.offlineIosVideoBlobUrl != null || this.offlineIosVideoBlobUrl != null;
  }




  downloadFile() {
    return this.http
      .get('/assets/videos/orange.mp4', {
        //responseType: "arrayBuffer"
        responseType: 'blob' as 'json',
      })
      .subscribe((res:ArrayBuffer) => {
        console.log('start download:', res);
        this.blobToArrayBuffer(res).then((videoContent: ArrayBuffer) => {
          this.downloadedVideoContent = videoContent;
          console.log('download complete', res);
        });
      }, error => {
        console.log('download error:', JSON.stringify(error));
      }, () => {
        console.log('Completed file download.');
      });
  }

  blobToArrayBuffer(blob) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.addEventListener('loadend', (e) => {
        resolve(reader.result);
      });
      reader.addEventListener('error', reject);
      reader.readAsArrayBuffer(blob);
    });
  }

  getBlobUrlHardCoded(): string {

    const blob = new Blob([this.downloadedVideoContent], { type: 'video/mp4' });

    const blobUrl = URL.createObjectURL(blob);
    return blobUrl;
  }
}