import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'texttruncate'
})

export class TextTruncatePipe implements PipeTransform {
    transform(value: string, arg1: string, arg2: string): string {
        const limit = arg1 ? Number(arg1) : 100;
        const trail = arg2 ? arg2 : '...';
        return value.length > limit ? value.substring(0, limit) + trail : value;
    }
}