export enum ActivityItemType {
    Text = 'Text',
    Audio = 'Audio',
    Video= 'Video',
    EBookLink = 'EBookLink',
    Discussion = 'Discussion',
    Quiz = 'Quiz',
    ThirdParty = 'ThirdParty',
    Feedback = 'Feedback',
    Essay = 'Essay',
    Kortext = 'Kortext',
    FileIFrame = 'FileIFrame',
    Game = 'Game'
}
