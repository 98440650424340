<ebs-spinner [spinnerMessage]="'Feedback loading..'" [faColour]="'#fff'" [boxColour]="'#fff'" *ngIf="isLoading"
    [boxPadding]="'5px 15px'"></ebs-spinner>
<p></p>
    
<div class="section-widget user-feedback is-feedback-question hidden-print" *ngIf="!isLoading && !isOffline">

    <div class="d-flex justify-content-around align-items-end mb-4">
        <span class="text-center">
            {{question.options[0].text}}<br>
            <button id="button1" (click)="onSelectionChanged(0)" [class]="optionSelectedClasses[0]" type="button" data-toggle="collapse" data-target="#response_not_clear" aria-expanded="false" aria-controls="response_not_clear" aria-label="feedback button not clear">
                <span class="icon-gecko-delete"></span>
            </button>
        </span>
        <span class="text-center">
            {{question.options[1].text}}<br>     
            <button id="button2" (click)="onSelectionChanged(1)" [class]="optionSelectedClasses[1]" type="button" data-toggle="collapse" data-target="#response_not_sure" aria-expanded="false" aria-controls="response_not_sure" aria-label="feedback button not sure"><span class="icon-gecko-minus"></span></button>
        </span>
        <span class="text-center">
             {{question.options[2].text}}<br>
             <button id="button3" (click)="onSelectionChanged(2)" [class]="optionSelectedClasses[2]" type="button" data-toggle="collapse" data-target="#response_clear" aria-expanded="false" aria-controls="response_clear" aria-label="feedback button response clear"><span class="icon-gecko-check"></span></button>
        </span>
        <span class="text-center">
             {{question.options[3].text}}<br>
             <button id="button4" (click)="onSelectionChanged(3)" [class]="optionSelectedClasses[3]" type="button" data-toggle="collapse" data-target="#response_very_clear" aria-expanded="true" aria-controls="response_very_clear" aria-label="feedback button very clear"><span class="icon-gecko-light"></span></button>
        </span>
    </div>

    <div class="row">
        <div class="col-12">
            <!-- <ebs-message-simple [displayMessage]="errorMessage" *ngIf="showError"></ebs-message-simple> -->
            <ebs-notification-multiple [notifications]="notifications"  *ngIf="callFailed"></ebs-notification-multiple>
        </div>
    </div> 
    
    
    <div *ngIf="question.options[0].isSelected" class="collapse show" id="response_not_clear">
        <p [innerHTML] = "question.options[0].commentsHtml | domSanitizerPipe: 'html'">
    </div>

    <div *ngIf="question.options[1].isSelected" class="collapse show" id="response_not_sure">
        <p [innerHTML] = "question.options[1].commentsHtml | domSanitizerPipe: 'html'">
    </div>

    <div *ngIf="question.options[2].isSelected" class="collapse show" id="response_clear">
        <p [innerHTML] = "question.options[2].commentsHtml | domSanitizerPipe: 'html'">
    </div>

    <div *ngIf="question.options[3].isSelected" class="collapse show" id="response_very_clear">
        <p [innerHTML] = "question.options[3].commentsHtml | domSanitizerPipe: 'html'">
    </div>
</div>