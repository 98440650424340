import { Injectable, Inject } from '@angular/core';
import { IndexedDbService } from '@app/core/indexed-db.service';
import Dexie from 'dexie';
import { StorableObject } from '@app/core/storable-object';
import { ObjectStoreBase } from './object-store-base';
import { IndexedDbFilter } from './delegates';



@Injectable()
export class ObjectStore extends ObjectStoreBase{

    table: Dexie.Table<StorableObject, string>;

    constructor(@Inject(IndexedDbService) private indexedDbService: IndexedDbService) {
        super();
        this.table = this.indexedDbService.table('storableObjects');
    }

    public get(uri) {
        return this.table.get(uri);
    }

    public getAll() {
        return this.table.toArray();
    }

    public getAllPrimaryKeys() {
        return this.table.where('uri').notEqual('').primaryKeys();
    }

    public getAllBy(uriFilter:string){
        return this.table.where('uri').startsWithIgnoreCase(uriFilter).toArray();
    }

    public add(data) {
        return this.table.add(data);
    }

    public filter(delegate: IndexedDbFilter<StorableObject>) : Dexie.Promise<StorableObject[]>{
        return this.table.filter(delegate).toArray();
    }

    public update(uri, data) {
        return this.table.update(uri, data);
    }

    public addOrUpdate(uri: string, data: StorableObject): Promise<StorableObject> {

        return this.table.get(uri).then((x): Promise<StorableObject> => {
            if (x === undefined || x == null) {
                return this.table.add(data).then(y => Promise.resolve(data));
            }

            return this.table.update(uri, data).then(y => Promise.resolve(data));
        });
    }

    public remove(uri) {
        return this.table.delete(uri);
    }

}
