<div class="intro discussion-wrapper">
    <h1 *ngIf="isFacultyActivities()">Posts and replies from faculty</h1>
    <h1 *ngIf="!isFacultyActivities()">Posts and replies</h1>
    <div>
        <ebs-offline-message [isOffline]='isOffline' [offlineMessage]='offlineMessage'></ebs-offline-message>
        <div *ngIf="!isOffline">

            <div *ngIf="!isFacultyActivities()">
                <p class="mt-2">Replies to any post you have participated in.</p>
            </div>

            <div *ngIf="isFacultyActivities()">
                <div class="card">
                    <ebs-faculty-activity [truncateContentLength]="truncateContentLength"
                        [isDiscussionContent]="isDiscussionContent" [courseCode]="courseCode"
                        [postAndReplySearchResults]="facultyPostAndReplySearchResults"
                        [loadingData]="loadingFacultyPostAndReplyData"></ebs-faculty-activity>

                    <ebs-spinner [spinnerMessage]="'getting more faculty post and replies'"
                        *ngIf="loadingMoreFacultyPosts">
                    </ebs-spinner>

                    <div class="row mt-3" *ngIf="!isLastFacultyPostAndReplyPage() && !isOffline">
                        <div class="col-12 col-sm-6 offset-sm-3 text-center">
                            <span (click)="loadMoreFacultyPostAndReply($event)" class="btn btn-light btn-sm">
                                <i class="fas fa-arrow-down"></i> View more faculty replies
                            </span>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12 col-sm-6 offset-sm-3 text-center loaded-style">
                            Loaded {{facultyPostAndReplySearchResults.length}} of {{totalFacultyItems }}
                        </div>
                    </div>
                </div>
            </div>
            <div *ngIf="!isFacultyActivities()">
                <div class="card">

                    <ebs-group-activity [truncateContentLength]="truncateContentLength"
                        [isDiscussionContent]="isDiscussionContent" [courseCode]="courseCode"
                        [postAndReplySearchResults]="postAndReplySearchResults" [loadingData]="loadingPostAndReplyData">
                    </ebs-group-activity>
                    <ebs-spinner [spinnerMessage]="'getting more replies'" *ngIf="loadingMorePosts">
                    </ebs-spinner>
                    <div class="row mt-3" *ngIf="!isLastPostAndReplyPage() && !isOffline">
                        <div class="col-12 col-sm-6 offset-sm-3 text-center">
                            <span (click)="loadMorePostAndReply($event)" class="btn btn-light btn-sm">
                                <i class="fas fa-arrow-down"></i> View more replies
                            </span>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12 col-sm-6 offset-sm-3 text-center loaded-style">
                            Loaded {{postAndReplySearchResults.length}} of {{totalItems}}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>