<div class="mb-4 quiz-multiple-choice-radios" >
    <div *ngFor="let option of question.options">
        <label class="custom-input">
            <span [innerHTML]="option.text"></span>
            <span *ngIf='showFeedback && option.isCorrect' class="icon-gecko-check text-success ml-1"></span>
            <span *ngIf='showFeedback && isSelected(option.id) && !option.isCorrect'
                class="icon-gecko-delete text-warning ml-1"></span>
            <input type="radio" [disabled]="showFeedback || isOffline" [name]="question.id" (change)="onSelectionChanged(option)"
                name="radio">
            <span class="radiobtn"></span>
            <span class="text-info small" *ngIf="showFeedback && isSelected(option.id)">
                <div ebsTechnicalContent [innerContent]="option.commentsHtml" id="correctComments"></div>
            </span>
        </label>
    </div>
    <div ebsTechnicalContent id="correctComments" *ngIf="showFeedback && isCorrect && question.correctCommentsHtml != null"
        [innerContent]="question.correctCommentsHtml" class="text-info small"></div>
    <div ebsTechnicalContent id="incorrectComments" [innerContent]="question.incorrectCommentsHtml"
        class="text-info small" *ngIf="showFeedback && !isCorrect && question.incorrectCommentsHtml !=null"></div>
    <div ebsTechnicalContent id="neutralComments" [innerContent]="question.neutralCommentsHtml" class="text-info small"
        *ngIf="showFeedback && question.neutralCommentsHtml !=null"></div>
</div>