<hr>
<h3 class="h6">Participation per module</h3>
<table class="table table-sm table-borderless stats-table shadow-none">
  <tbody>
    <tr *ngFor="let moduleDiscussionEngagementStatistic of moduleDiscussionEngagementStatistics.moduleDiscussionEngagementStatisticsList">
      <th style="padding-top: 10px">
        <small>{{moduleDiscussionEngagementStatistic.discussionEngagementStatisticList[0].number}}</small>
      </th>
      <td style="padding-top: 10px; width: 95%">
        <badge  [moduleDiscussionEngagementStatistic]="moduleDiscussionEngagementStatistic"></badge>
      </td>
    </tr>
  </tbody>
</table>