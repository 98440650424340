import { QuizStatistics } from "@app/courses/models/quiz/quiz-statistics";
import { Injectable } from "@angular/core";
import { Observable, of } from "rxjs";
import { QuizStatisticsQuiz } from "@app/courses/models/quiz/quiz-statistics-quiz";
import { QuizStatisticsModule } from "@app/courses/models/quiz/quiz-statistics-module";

@Injectable({
    providedIn: 'root'
  })
  export class QuizStatisticsMockService{
      private quizStatistics:QuizStatistics = new QuizStatistics();

      public getQuizFeedbackStatistics(): Observable<QuizStatistics>{
        var quizStatisticsQuiz = new QuizStatisticsQuiz();
        quizStatisticsQuiz.quizId = 1;
        quizStatisticsQuiz.score = 10;
        quizStatisticsQuiz.availableMarks = 10;
        quizStatisticsQuiz.isAttempted = true;
        
        var quizStatisticsQuiz2 = new QuizStatisticsQuiz();
        quizStatisticsQuiz2.quizId = 2;
        quizStatisticsQuiz2.score = 4;
        quizStatisticsQuiz2.availableMarks = 10;
        quizStatisticsQuiz2.isAttempted = true;

        var quizStatisticsQuiz3 = new QuizStatisticsQuiz();
       
        quizStatisticsQuiz3.isAttempted = false;

        var quizStatisticsModule = new QuizStatisticsModule();

        quizStatisticsModule.statisticsQuizes.push(quizStatisticsQuiz);
        quizStatisticsModule.statisticsQuizes.push(quizStatisticsQuiz2);
        quizStatisticsModule.statisticsQuizes.push(quizStatisticsQuiz3);

        var quizStatisticsModule2 = new QuizStatisticsModule();
        var quizStatisticsQuiz4 = new QuizStatisticsQuiz();
        quizStatisticsQuiz4.quizId = 1;
        quizStatisticsQuiz4.score = 18;
        quizStatisticsQuiz4.availableMarks = 20;
        quizStatisticsQuiz4.isAttempted = true;
        
        var quizStatisticsQuiz5 = new QuizStatisticsQuiz();
        quizStatisticsQuiz5.quizId = 2;
        quizStatisticsQuiz5.score = 4;
        quizStatisticsQuiz5.availableMarks = 15;
        quizStatisticsQuiz5.isAttempted = true;

        quizStatisticsModule2.statisticsQuizes.push(quizStatisticsQuiz4);
        quizStatisticsModule2.statisticsQuizes.push(quizStatisticsQuiz5);


        this.quizStatistics.quizStatisticsModules.push(quizStatisticsModule);
        this.quizStatistics.quizStatisticsModules.push(quizStatisticsModule2);

        return of(
            this.quizStatistics
        );
      }
  }