import { Component, Input, OnInit } from '@angular/core';
// import { UnityLoader } from 'src/assets/unity/UnityLoader.js';
// import { UnityProgress } from 'src/assets/unity/UnityProgress.js';
import { GameConfiguration } from '@app/courses/models/games/game-configuration';

declare var window: any;

@Component({
  selector: 'ebs-unity-web-vr',
  templateUrl: './unity-web-vr.component.html',
  //styleUrls: ['./webvr.css']
  styleUrls: ['./webvr.css','./unity-web-vr.component.css']
})
export class UnityWebVrComponent implements OnInit {
  @Input() gameConfiguration: GameConfiguration;
  unityInstance: any;
  @Input() appLocation: String;
  @Input() appWidth: String;
  @Input() appHeight: String;
  path:string;

  buttonStyle: boolean = false;

  constructor() { }

  ngOnInit() {
    // window['UnityLoader'] = UnityLoader;
    // window['UnityProgress'] = UnityProgress;
    //window['receiveMessageFromUnity'] = this.receiveMessageFromUnity("bob");
    this.path = "/config/WebVR.json";
    // this.path = "../../../games/WebVR/WebVR.json";
    if (this.path) {
      this.loadProject(this.path);
    }
    this.loadScript();
  }

  public loadProject(path) {
    let json = JSON.stringify(this.gameConfiguration);
    let file = new File([json],'game.json');
    // this.unityInstance = UnityLoader.instantiate('gameContainer', URL.createObjectURL(file), {
    //   Module: {
    //     webglContextAttributes: {
    //       preserveDrawingBuffer: true
    //     }
    //   },
    //   onProgress: this.unityProgress
    // });
    window.gameInstance = this.unityInstance;
    this.buttonStyle = true;
  }

  public unityProgress(unityInstance, progress) {
    if (!unityInstance.progress) {
      document.dispatchEvent(new CustomEvent('UnityProgressStart'));
    }
    if (progress === 1) {
      document.dispatchEvent(new CustomEvent('UnityLoaded'));
     }
  }

  public sendMessageToUnity(objectName: string, methodName: string, messageValue: string) {
    console.log('sendMessageToUnity', objectName, methodName, messageValue);
    this.unityInstance.SendMessage(objectName, methodName, messageValue);
  }

  public receiveMessageFromUnity(messageValue: string) {
    alert(messageValue);
    console.log('receiveMessageFromUnity', messageValue);
  }

  public loadScript() {
    let body = <HTMLDivElement>document.body;
    let script = document.createElement('script');
    script.innerHTML = '';
    script.type = 'text/javascript';
    script.src = './assets/webvr/gl-matrix-min.js';
    body.appendChild(script);

    let script2 = document.createElement('script');
    script2.innerHTML = '';
    script2.type = 'text/javascript';
    script2.src = './assets/webvr/webvr-polyfill.min.js';
    body.appendChild(script2);

    let script3 = document.createElement('script');
    script3.innerHTML = '';
    script3.type = 'text/javascript';
    script3.src = './assets/webvr/webvr.js';
    body.appendChild(script3);

    let script4 = document.createElement('script');
    script4.innerHTML = '';
    script4.type = 'text/javascript';
    script4.src = './assets/webvr/telemetry.js';
    body.appendChild(script4);
  }

}
