import { Component, OnInit, Input, Output, EventEmitter, OnDestroy } from '@angular/core';
import { MultipleChoiceQuestion } from '@app/courses/models/quiz/multiple-choice-question';
import { ChoiceOption } from '@app/courses/models/quiz/choice-option';
import { OnlineStatusService } from '@app/common/online-status/online-status.service';
import { OnlineStatusType } from '@app/common/online-status/online-status-type.enum';
import { Subscription } from 'rxjs';
import { StaticText } from '@app/common/static-text';

@Component({
  selector: 'ebs-multiple-choice-question',
  templateUrl: './multiple-choice-question.component.html',
  styleUrls: ['./multiple-choice-question.component.scss']
})
export class MultipleChoiceQuestionComponent implements OnInit, OnDestroy {
  @Input() question: MultipleChoiceQuestion;
  @Input() showFeedback: boolean;
  @Output() notify: EventEmitter<boolean> = new EventEmitter<boolean>();
  public isCorrect = false;
  public selectedId: number;
  theSubscription: Subscription;
  isOffline: boolean;
  offlineMessage = StaticText.quizOfflineMessage;


  constructor(private onlineStatusService: OnlineStatusService) {
    this.onlineStatusService.isOffline().subscribe(x => {
        this.isOffline = x;
    }, error=>{
        this.isOffline = false;
    });
    this.theSubscription = this.onlineStatusService.status.subscribe(
      event => {
        this.isOffline = (event === OnlineStatusType.OFFLINE) ? true : false
      }
    )
  }

  ngOnInit() {
    if (this.question.options != null) {
      for (var i = 0; i < this.question.options.length; i++) {
        this.question.options[i].id = i;
      } this.question.options = this.shuffle(this.question.options);
    }
  }

  shuffle(array: Array<ChoiceOption>) {
    var currentIndex = array.length, temporaryValue, randomIndex;

    // While there remain elements to shuffle...
    while (0 !== currentIndex) {

      // Pick a remaining element...
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex -= 1;

      // And swap it with the current element.
      temporaryValue = array[currentIndex];
      array[currentIndex] = array[randomIndex];
      array[randomIndex] = temporaryValue;
    }

    return array;
  }

  public isSelected(selectedId: number) {
    return this.selectedId == selectedId;
  }

  onSelectionChanged(option: ChoiceOption) {
    option.isSelected = true;
    this.selectedId = option.id;
    this.isCorrect = option.isCorrect;
    this.question.isComplete = true;
    this.notify.emit(true);
  }

  ngOnDestroy(): void {
    this.theSubscription.unsubscribe();
  }

}