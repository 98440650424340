<img *ngIf="statistic>=80" src="./assets/img/badge-gold-sm.png">
<small *ngIf="statistic>=80">
  <strong>&nbsp;Gold</strong>
</small>
<img *ngIf="statistic>=70 && statistic<79" src="./assets/img/badge-silver-sm.png">
<small *ngIf="statistic>=70 && statistic<79">
  <strong>&nbsp;Silver</strong>
</small>
<img *ngIf="statistic>=50 && statistic<69" src="./assets/img/badge-bronze-sm.png">
<small *ngIf="statistic>=50 && statistic<69">
  <strong>&nbsp;Bronze</strong>
</small>
<img *ngIf="statistic>0 && statistic<50" src="./assets/img/badge-entry-sm.png">
<small *ngIf="statistic>0 && statistic<50">
  <strong>&nbsp;Entry</strong>
</small>

<small *ngIf="!noDiscussion && totalDisucssionNotEngaged>0"> ({{totalDisucssionNotEngaged | i18nPlural: messageMapping}}) </small>
<small *ngIf="noDiscussion && !hasNoDiscussionInModule">{{totalDisucssionNotEngaged}} discussions with no participation</small>
<small *ngIf="hasNoDiscussionInModule">No discussions in this module</small>
