import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { DiscussionLike } from '../../models/like';
import { DiscussionService } from '../../services/discussion.service';
import { ModalSimpleService } from '@app/common/components/modal-simple/modal-simple.service';
import { UserProfile } from '@app/common/models/user';

@Component({
  selector: 'ebs-discussion-like',
  templateUrl: './discussion-like.component.html',
  styleUrls: ['./discussion-like.component.scss']
})
export class DiscussionLikeComponent implements OnInit {
  @Input() passedInlike = new DiscussionLike(); // pass this in instead of properties
  @Input() isMyPostOrReply: boolean;
  @Output() updateLikeEvent = new EventEmitter<DiscussionLike>();
  @Input() currentUser: UserProfile;
  showError = false; isOffline = false; updatingLike = false;
  errorMessage: string;
  isDemoUser = false;

  constructor(
    private discussionService: DiscussionService,
    private modalSimpleService: ModalSimpleService, ) {

  }

  ngOnInit() {
    this.currentUser.userAccount.roles.find(x => x.toString() == "Demo") ? this.isDemoUser = true : this.isDemoUser = false;
  }

  clickedLike() {
    if (this.isDemoUser) {
      this.showDemoUserModal();
      return
    }

    this.updatingLike = true;
    this.passedInlike.clientModifiedAt = new Date();
    if (this.passedInlike) {

    }

    if (this.passedInlike.likedByMe) {
      this.passedInlike.isActive = false;
      this.unlikeComment();
    } else {
      this.passedInlike.isActive = true;
      this.likeComment();
    }
  }

  likeComment() {
    this.discussionService.likeComment(this.passedInlike, false).subscribe(
      response => {
        this.handleResponse(response.data);
        this.passedInlike.likedByMe = true;
        this.passedInlike.numberOfLikes += 1;
        this.updateLikeEvent.emit(this.passedInlike);
      },
      error => {
        this.showError = true;
        this.errorMessage = 'Error in liking this post';
        this.updatingLike = false;
      }
    )
  }

  unlikeComment() {
    this.discussionService.unlikeComment(this.passedInlike, false).subscribe(
      response => {
        this.handleResponse(response.data);
        this.passedInlike.likedByMe = false;
        this.passedInlike.numberOfLikes -= 1;
        this.updateLikeEvent.emit(this.passedInlike);
      },
      error => {
        this.showError = true;
        this.errorMessage = 'Error in unliking this post';
        this.updatingLike = false;
      }
    )
  }

  handleResponse(responseLike: DiscussionLike) {
    this.passedInlike.person = responseLike.person;
    this.passedInlike.id = responseLike.id;
    this.updatingLike = false;
  }

  showDemoUserModal() {
    this.modalSimpleService.confirm(
      'Demo User Information',
      'Sorry this function is unavailable for demo users',
      [{ buttonText: 'OK', buttonStyle: 'btn btn-primary btn-block' }])
      .subscribe((answer) => {
        if (answer === 'OK') {
        }
      });
  }
}

