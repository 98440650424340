import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ServiceBase } from '@app/core/service-base';
import { OperationResponse } from '@app/common/models/operation-response';
import { KortextLtiForm } from '../models/kortext/kortext-lti-form';
import { LtiResponse } from '@app/common/lti/lti-response';

@Injectable({
    providedIn: 'root'
})
export class KortextOAuthService extends ServiceBase {

    private readonly uri = 'lti/kortext/sign';

    public generateOAuthSignature(baseUri:string, kortextFormValues: KortextLtiForm): Observable<OperationResponse<LtiResponse>> {
        return this.postObjectToRemoteStoreOnly<LtiResponse>(kortextFormValues, baseUri + this.uri);
    }
}