import { Component, OnInit, Input, HostListener } from '@angular/core';
import { Helpers } from '@app/common/helpers';
import { timeagoTransform } from '@app/common/timeago-transform';
import { UserService } from '@app/common/security/user.service';
import { UserProfile, UserRole } from '@app/common/models/user';

@Component({
  selector: 'ebs-discussion-userprofile',
  templateUrl: './discussion-userprofile.component.html'
})
export class DiscussionUserprofileComponent implements OnInit {
  @Input() userAvatar: string;
  @Input() userCountryFlag: string;
  @Input() showUserCountryFlag: boolean; // not implemented as data is currently rubbish (95% of students = false)
  @Input() showUserAvatar: boolean; // not implemented as data is currently rubbish (95% of students = false)
  @Input() displayName: string;
  @Input() displayDate: string;
  @Input() isFaculty: boolean;
  currentUser = new UserProfile();
  isMobile = Helpers.isItMobile(window.innerWidth);

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.isMobile = Helpers.isItMobile(window.innerWidth);
  }

  constructor( ) { }

  ngOnInit() {
    if (this.userCountryFlag) {
      this.userCountryFlag = '../../../../assets/img/flags/' + this.userCountryFlag.toLowerCase() + '.png'
    } else {
      this.userCountryFlag = null;
    }

    if (!this.displayName) {
      this.displayName = 'name unavailable';
    }

    this.displayDate = timeagoTransform(this.displayDate);
  }

}
