import { ConfigurationHelperService } from '@app/common/app-configuration/configuration-helper.service';
import { Observable } from 'rxjs';
import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse, HttpRequest, HttpEventType, HttpEvent } from '@angular/common/http';
import { OperationResponse } from '@app/common/models/operation-response';
import { DeviceDetectorWrapperService } from '@app/common/device-detector/device-detector-wrappe.servicer';
import { StringLiterals } from '@app/common/string-literals';
import { tap, last, catchError, map } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
  })
export class VideoCachingService{

    constructor(@Inject(HttpClient) private http: HttpClient, 
    private configurationHelperService: ConfigurationHelperService){
    }

    downloadToCache(videoUri:string, bypassServiceWorkers: boolean = false):Observable<HttpResponse<Blob>>{
        let httpHeaders = new HttpHeaders({
            'Access-Control-Expose-Headers': 'x-ms-meta-videoUri'
         });
         
        httpHeaders = httpHeaders.set(StringLiterals.interceptorBypassHeaderKey, "true");

        if (bypassServiceWorkers)
            httpHeaders = httpHeaders.set("ngsw-bypass", "true");

        httpHeaders = httpHeaders.set(StringLiterals.interceptorDownloadHeaderKey, "true");

        return this.http.get<Blob>(this.configurationHelperService.getVideoSourcePrefix() + videoUri, {
             responseType: 'blob' as 'json',
             headers : httpHeaders,
             observe : 'response',
           withCredentials: true, });
    }

    // downloadToCache2(videoUri:string, bypassServiceWorkers: boolean = false):Observable<HttpResponse<Blob>>{
    //     let httpHeaders = new HttpHeaders({
    //         'Access-Control-Expose-Headers': 'x-ms-meta-videoUri'
    //      });
         
    //     httpHeaders = httpHeaders.set(StringLiterals.interceptorBypassHeaderKey, "true");

    //     if (bypassServiceWorkers)
    //         httpHeaders = httpHeaders.set("ngsw-bypass", "true");

    //     httpHeaders = httpHeaders.set(StringLiterals.interceptorDownloadHeaderKey, "true");


    //     const req = new HttpRequest('GET', this.configurationHelperService.getVideoSourcePrefix() + videoUri, {
    //         responseType: 'blob' as 'json',
    //         headers : httpHeaders,     
    //         observe : 'event',       
    //         reportProgress: true,
    //         withCredentials: true
    //       });

    //     // return this.http.get<Blob>(this.configurationHelperService.getVideoSourcePrefix() + videoUri, {
    //     //      responseType: 'blob' as 'json',
    //     //      headers : httpHeaders,
    //     //      observe : 'response',
    //     //    withCredentials: true, });

    //     return this.http.request<Blob>(req).pipe(
    //         map(event => this.getEventMessage(event, videoUri)),
    //         tap(message => console.log(message)),
    //         last(), // return last (completed) message to caller
    //         catchError((x,y) => {console.log(`error downloading ${videoUri}`); return null;})
    //       ) as Observable<HttpResponse<Blob>>;
    // }

    // private getEventMessage(event: HttpEvent<any>, videoURL: string) {
    //     switch (event.type) {
    //       case HttpEventType.Sent:
    //         return `Dowloading file "${videoURL}".`;
      
    //       case HttpEventType.UploadProgress:
    //         // Compute and show the % done:
    //         const percentDone = Math.round(100 * event.loaded / event.total);
    //         return `File "${videoURL}" is ${percentDone}% uploaded.`;
      
    //       case HttpEventType.Response:
    //         return `File "${videoURL}" was completely uploaded!`;
      
    //       default:
    //         return `File "${videoURL}" surprising upload event: ${event.type}.`;
    //     }
    //   }

    deleteFromCache(videoUri:string):Observable<OperationResponse<any>>{
        const deleteObs = new Observable<OperationResponse<any>>(observer => {
            const operationResponse :OperationResponse<boolean> 
                    = new OperationResponse<boolean>(true, null, null);

            caches.open(this.configurationHelperService.getVideoCacheKey())
            .then((c) => {
                c.keys().then((keys) => {
                  const cacheKey = keys
                  .filter((p) => p.url.includes(this.configurationHelperService.getVideoSourcePrefix() + videoUri));

                  if (cacheKey.length < 1){
                    observer.next(operationResponse);
                    observer.complete();
                  } else {
                    const keySearched = cacheKey[0];                  
                    
                    c.delete(keySearched.url)
                    .then((res) => {
                        observer.next(operationResponse);
                        observer.complete();
                        })
                    .catch((reason:any) => {
                        operationResponse.data = reason;
                        operationResponse.operationSucceeded = false;
                        observer.next(operationResponse);
                        observer.complete();
                    });
                }                   
              }).catch((reason:any) => { 
                    operationResponse.data = reason;
                    observer.next(operationResponse);
                    observer.complete();
                });
            });
        });

        return deleteObs;
    }

    retrieveCachedVideoUrl(videoUri):Observable<string>{
        const retrieveVideoUriObs = new Observable<any>(observer => {
            caches.open(this.configurationHelperService.getVideoCacheKey())
            .then((c) => {
                c.keys().then((keys) => {
                  const cacheKey = keys
                  .filter((p) => p.url.includes(this.configurationHelperService.getVideoSourcePrefix() + videoUri));

                  if (cacheKey.length < 1){
                    observer.next('not found in cache');
                  } else {
                    const keySearched = cacheKey[0];                  
                    observer.next(keySearched.url);
                }         
                observer.complete();          
              }).catch((reason:any) => { 
                    observer.next('not found in cache');
                    observer.complete();
                });
            });
        });

        return retrieveVideoUriObs;
    }

    deleteVideoCache():Observable<OperationResponse<any>>{
        const deleteObs = new Observable<OperationResponse<any>>(observer => {
            const operationResponse :OperationResponse<boolean> 
                    = new OperationResponse<boolean>(true, null, null);

            caches.open(this.configurationHelperService.getVideoCacheKey())
            .then((c) => {
                c.keys().then((keys) => {

                  let allPromises: Promise<boolean>[] = new Array<Promise<boolean>>();
              
                  if (keys.length < 1){
                    observer.next(operationResponse);
                    observer.complete();
                  } else {
                    
                    for(let key of keys){
                        allPromises.push(c.delete(key.url));
                    }


                    Promise.all(allPromises)
                    .then(() => {
                        observer.next(operationResponse);
                        observer.complete();
                    }).catch(err => {
                        operationResponse.data = err;
                        operationResponse.operationSucceeded = false;
                        observer.next(operationResponse);
                        observer.complete();
                    });
                }                   
              }).catch((reason:any) => { 
                    operationResponse.data = reason;
                    observer.next(operationResponse);
                    observer.complete();
                });
            });
        });

        return deleteObs;
    }
}