import { HttpClient, HttpResponse, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Observable, throwError, Subscriber } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Inject, Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { OperationResponse } from '@app/common/models/operation-response';
import { AppConfigService } from '@app/common/app-configuration/app-config.service';
import { NotificationSummary } from '@app/common/models/notification-summary';
import { SeverityLevel } from '@app/common/models/severity-level';
import { StorableObject, IStorableObject } from './storable-object';
import { Func, Convertor, DelegateFunc } from '@app/core/delegates';
import { PagedRequest } from '@app/common/models/paged-request';
import { PagedResult } from '@app/common/models/paged-result';
import { DataStoreStrategy } from './data-store-strategy';
import { PendingDeletion } from './pending-deletions';
import { ServiceBaseHelpers } from './service-base-helpers';
import { ObjectStoreBase } from './object-store-base';
import { ConfigurationHelperService } from '@app/common/app-configuration/configuration-helper.service';
import { KeyValuePair } from '@app/common/models/key-value-pair';

@Injectable()
export class ServiceBase {

    constructor(
        @Inject(HttpClient) private http: HttpClient,
        //@Inject(ObjectStoreBase) 
        private objectStore: ObjectStoreBase,
        private toastr: ToastrService,
        @Inject(ServiceBaseHelpers) private helpers: ServiceBaseHelpers,
        private configurationService:ConfigurationHelperService
    ) {
    }

    /**
     * Retrieves the server base uri from the app settings
     */
    protected getApiServerUri(): string {
        return this.configurationService.getApiServerUri();
    }

    /**
    * Gets a list of storable object. Depending on the treatAsOffline flag, the local or remote store might be used.
    * Once fetched from the remote store, the list items are then individually saved in the local store for future use.
    */
    public getObjects<T extends StorableObject>(uriFilter: string, treatAsOffline: boolean): Observable<OperationResponse<T[]>> {
        uriFilter = uriFilter.toLowerCase();

        const observable = new Observable<OperationResponse<T[]>>(observer => {
            if (treatAsOffline === false) {
                this.getObjectsFromRemoteStore<T>(uriFilter, observer);
            } else {
                this.getObjectsFromLocalStore<T>(uriFilter, observer);
            }
        });
        return observable;
    }

    protected getObjectsFromLocalStore<T extends StorableObject>(uriFilter: string, observer: Subscriber<OperationResponse<T[]>>): void {
        this.objectStore.getAllBy(uriFilter)
            .then((storableObjects: StorableObject[]) => {

                let filteredStorableObjects = this.helpers.filterStorableObjectByRootUri(uriFilter, storableObjects);

                observer.next(<OperationResponse<T[]>>new OperationResponse(true, filteredStorableObjects, []));
                observer.complete();
            },
                rejected => {
                    this.toastr.error('There has been an error accessing the object store. Please try again later.', 'Something went wrong');
                    observer.error(rejected);
                    observer.complete();
                });
    }

    protected getObjectsFromRemoteStore<T extends StorableObject>(uriFilter: string, observer: Subscriber<OperationResponse<T[]>>): void {
        const urlFilter = this.getApiServerUri() + uriFilter;

        this.http.get<OperationResponse<T[]>>(urlFilter, { withCredentials: true })
            .pipe(catchError(this.handleHttpError))
            .subscribe(
                (response: OperationResponse<T[]>) => {

                    if (response.operationSucceeded === false) {
                        console.log('getObjectsFromRemoteStore', response);
                        this.toastr.error('You may not be viewing the most recent information', 'Something went wrong');
                        this.getObjectsFromLocalStore(uriFilter, observer);
                    }
                    else {
                        if (response.data === null) {
                            response.data = new Array<T>();
                        }

                        response.data.forEach(object => {
                            this.objectStore.addOrUpdate(object.uri, object)
                                .then(x => {

                                },
                                    rejected => {

                                    });
                        });

                        observer.next(<OperationResponse<T[]>>response);
                        observer.complete();
                    }
                },
                error => {
                    console.log('getObjectsFromRemoteStore', error);
                    this.toastr.error('You may not be viewing the most recent information', 'Something went wrong');
                    this.getObjectsFromLocalStore(uriFilter, observer);
                }
            );
    }

    /**
    * Gets a paged list of storable object based on a paged request. Depending on the treatAsOffline flag, the local or remote store might be used.
    * Once fetched from the remote store, the list items are then individually saved in the local store for future use.
    */
    public getPagedObjects<T extends StorableObject>(request: PagedRequest<T>, 
        treatAsOffline: boolean, headers: HttpHeaders = null): Observable<OperationResponse<PagedResult<T>>> {

        const observable = new Observable<OperationResponse<PagedResult<T>>>(observer => {
            if (treatAsOffline === false) {
                this.getPagedObjectsFromRemoteStore<T>(request, observer, headers);
            } else {
                this.getPagedObjectsFromLocalStore<T>(request, observer);
            }
        });
        return observable;
    }

    protected getPagedObjectsFromLocalStore<T extends StorableObject>(request: PagedRequest<T>, observer: Subscriber<OperationResponse<PagedResult<T>>>): void {
        console.log('get paged objects local', request)
        let uri = request.getUriFilter();
        this.objectStore.getAllBy(uri)
            .then((storableObjects: T[]) => {

                let filteredStorableObjects = this.helpers.filterStorableObjectByRootUri(uri, storableObjects);

                let result = new PagedResult<T>();
                result.fromExistingArrayBasedOnRequest(request, filteredStorableObjects);

                observer.next(<OperationResponse<PagedResult<T>>>new OperationResponse(true, result, []));
                observer.complete();
            },
                rejected => {
                    this.toastr.error('There has been an error accessing the object store. Please try again later.', 'Something went wrong');
                    observer.error(rejected);
                    observer.complete();
                });
    }

    protected getPagedObjectsFromRemoteStore<T extends StorableObject>(request: PagedRequest<T>, 
        observer: Subscriber<OperationResponse<PagedResult<T>>>, 
        httpHeaders: HttpHeaders = null): void {
        const urlFilter = this.getApiServerUri() + request.getRequestFilter();


        this.http.get<OperationResponse<PagedResult<T>>>(urlFilter, { headers: httpHeaders, withCredentials: true })
            .pipe(catchError(this.handleHttpError))
            .subscribe(
                (response: OperationResponse<PagedResult<T>>) => {
                    if (response.operationSucceeded === false || response.data == null) {
                        this.toastr.error('You may not be viewing the most recent information', 'Something went wrong');
                        this.getPagedObjectsFromLocalStore(request, observer);
                    }
                    else {
                        if (response.data.items == null) {
                            response.data.items = new Array<T>();
                        }
                        response.data.items.forEach(object => {
                            this.objectStore.addOrUpdate(object.uri, object)
                                .then(x => { }, rejected => { });
                        });

                        observer.next(<OperationResponse<PagedResult<T>>>response);
                        observer.complete();
                    }
                },
                error => {
                    console.log('getPagedObjectsFromRemoteStore', error);
                    this.toastr.error('You may not be viewing the most recent information', 'Something went wrong');
                    this.getPagedObjectsFromLocalStore(request, observer);
                }
            );
    }

    /**
     * 
     */
    public deleteObjectFromAvailableStores<T extends StorableObject>
        (uri: string, treatAsOffline: boolean): Observable<OperationResponse<T>> {
        uri = uri.toLowerCase();


        const observable = new Observable<OperationResponse<T>>(observer => {

            //M.S. - do not remove these comments, this is provisional work for go live, we will be needing this when working for OFFLINE MODE)
            /*if (treatAsOffline === false) {*/

            this.deleteObjectRemoteFirst<T>(uri, observer);

            /*} else {

                this.markObjectAsPendingDeletion<T>(uri, observer);
            }*/
        });

        return observable;
    }

    protected deleteObjectRemoteFirst<T extends StorableObject>(uri: string, observer: Subscriber<OperationResponse<T>>): void {
        uri = uri.toLowerCase();

        this.http.post<OperationResponse<T>>(this.getApiServerUri() + uri + '/delete', {}, { withCredentials: true })
            .pipe(catchError(this.handleHttpError))
            .subscribe(
                (response: OperationResponse<T>) => {
                    if (response.operationSucceeded === false) {
                        //M.S. - do not remove these comments, this is provisional work for go live, we will be needing this when working for OFFLINE MODE)
                        //this.markObjectAsPendingDeletion(uri, observer);

                        this.toastr.info('Unable to remove item at this time', 'Something went wrong');
                    }
                    else {
                        //M.S. - do not remove these comments, this is provisional work for go live, we will be needing this when working for OFFLINE MODE)
                        //this.synchroniseDeletedObject(uri, observer);
                    }

                    observer.next(response);
                    observer.complete();
                },
                error => {
                    //M.S. - do not remove these comments, this is provisional work for go live, we will be needing this when working for OFFLINE MODE)
                    //this.markObjectAsPendingDeletion(uri, observer);

                    this.toastr.error('Unable to remove item at this time', 'Something went wrong');
                    observer.error(error);
                    observer.complete();
                }
            );
    }

    protected deleteObjectFromLocalStore<T extends StorableObject>(uri: string, observer: Subscriber<OperationResponse<T>>, bypassLowerCasingOfUri : boolean = false): void {

        if (bypassLowerCasingOfUri === false) 
            uri = uri.toLowerCase();
        else {
            uri = uri;
        }

        this.objectStore.remove(uri).then((value: void) => {
            const result = new OperationResponse<T>(true, null, []);

            observer.next(result);
            observer.complete();
        },
            err => {
                this.toastr.error('Unable to remove item at this time', 'Something went wrong');
                console.log('FILED TO DELETE FROM INDEXED DB ' + uri);
                console.log(err);
                observer.error(err);
            });

    }

    protected markObjectAsPendingDeletion<T extends StorableObject>(uri: string, observer: Subscriber<OperationResponse<T>>): void {
        uri = uri.toLowerCase();

        this.objectStore.get(this.helpers.pendingDeletionUri)
            .then((storableObject: PendingDeletion) => {

                const pendingDeletionObject: PendingDeletion = storableObject == null
                    ? new PendingDeletion(this.helpers.pendingDeletionUri)
                    : <PendingDeletion>storableObject;

                this.helpers.addDeletedObject(uri, pendingDeletionObject.awaitingDeletionSynchronization);

                this.synchronisePendingDeletionObject<T>(uri, pendingDeletionObject, observer);
            },
                err => {
                    this.toastr.info('Unable to remove item at this time', 'Something went wrong');

                    observer.error(err);
                    observer.complete();
                });
    }

    protected synchronisePendingDeletionObject<T extends StorableObject>(uri: string, pendingDeletion: PendingDeletion, observer: Subscriber<OperationResponse<T>>): void {
        this.objectStore.addOrUpdate(this.helpers.pendingDeletionUri, pendingDeletion)
            .then((x: StorableObject) => {
                this.deleteObjectFromLocalStore<T>(uri, observer);
            }, onRejacted => {
                this.toastr.info('Unable to remove item at this time', 'Something went wrong');

                //observer.error(onRejacted); M.S. - trying to avoid a crashing application
                const result = new OperationResponse<T>(true, null, [])
                observer.next(result);

                observer.complete();
            });
    }

    protected synchroniseDeletedObject<T extends StorableObject>(uri: string, observer: Subscriber<OperationResponse<T>>)
    : void {
        uri = uri.toLowerCase();
        this.objectStore.get(this.helpers.pendingDeletionUri)
            .then((storableObject: PendingDeletion) => {

                const pendingDeletionObject = storableObject == null
                    ? new PendingDeletion(this.helpers.pendingDeletionUri)
                    : <PendingDeletion>storableObject;

                this.helpers.synchronizeDeletedObject(uri, pendingDeletionObject.awaitingDeletionSynchronization);

                this.synchronisePendingDeletionObject<T>(uri, pendingDeletionObject, observer);
            },
                err => {
                    this.toastr.info('Unable to remove item at this time', 'Something went wrong');

                    observer.error(err);
                    observer.complete();
                });
    }

    /**
     * Gets a storable object by various strategies. The default strategy is LocalFirst
     * @param uri 
     * @param fetchStrategy 
     */
    public getObject<T extends StorableObject>(uri: string, treatAsOffline: boolean): Observable<OperationResponse<T>> {
        uri = uri.toLowerCase();

        const observable = new Observable<OperationResponse<T>>(observer => {
            if (!treatAsOffline)
                //this.getObjectRemoteStoreFirst<T>(uri, observer);
                this.getObjectFromRemoteStore<T>(uri, observer); // G.M. comment out until the downloads code is written otherwise cant test anything!
            else
                //this.getObjectLocalStoreFirst<T>(uri, observer);
                this.getObjectFromLocalStore<T>(uri, observer);
        });

        return observable;
    }

    /**
    * Gets a storable object. If the object is in the local store, the local version is used.
    * Otherwise, the object is fetched from the remote store, then saved in the local store for future use.
    */
    protected getObjectLocalStoreFirst<T extends StorableObject>(uri: string, observer: Subscriber<OperationResponse<T>>): void {

        // Try to get the storable object from IndexedDb first.
        this.objectStore.get(uri).then((storableObject: StorableObject) => {
            if (storableObject != null) {
                observer.next(<OperationResponse<T>>new OperationResponse(true, storableObject, []));
                observer.complete();
            } else {
                // Get the storable object from the server.
                this.http.get<OperationResponse<T>>(this.getApiServerUri() + uri, { withCredentials: true })
                    .pipe(catchError(this.handleHttpError))
                    .subscribe(
                        (response: OperationResponse<T>) => {
                            response.data != null ?
                                response.data.uri = uri :
                                this.toastr.error(
                                    'No data has been found for url ' + uri, 'API Server No Data');

                            observer.next(<OperationResponse<T>>response);
                            observer.complete();

                            if (response.operationSucceeded !== false && response.data != null)
                                this.objectStore.add(response.data);
                        },
                        error => {
                            this.toastr.error('There has been an error accessing the data service : ' + error, 'Data Access Error');
                            observer.error(error);
                            observer.complete();
                        }
                    );
            }
        }, error => {
            this.toastr.error('There has been an error accessing the object store. Please try again later.', 'Object Store Error');
            observer.error(error);
            observer.complete();
        });
    }

    protected getObjectRemoteStoreFirst<T extends StorableObject>(uri: string, observer: Subscriber<OperationResponse<T>>,
        httpHeaders: HttpHeaders = null): void {
        
        this.http.get<OperationResponse<T>>(this.getApiServerUri() + uri, {  headers : httpHeaders, withCredentials: true })
            .pipe(catchError(this.handleHttpError))
            .subscribe(
                (response: OperationResponse<T>) => {
                    if (response.operationSucceeded === false || response.data == null) {
                        this.getObjectFromLocalStore(uri, observer);
                    }
                    else {
                        if(response.data.uri != null){
                            // AM - The update to Angular 13 and the subsequent update of Dexie from v2 to v3
                            // have resulted in this if statement. Dexie was otherwise throwing warnings about unhandled
                            // rejections when the response.data.uri was null as it is when retrieving discussion statistics.
                            // This was cross checked against UAT when the message does not appear but the key value is null
                            // as seen is the updated branch
                            this.objectStore.addOrUpdate(response.data.uri, response.data)
                            .then(x => { }, rejected => { });
                        }

                        observer.next(<OperationResponse<T>>response);
                        observer.complete();
                    }
                },
                error => {
                    this.getObjectFromLocalStore(uri, observer);
                }
            );
    }

    protected getObjectFromRemoteStore<T extends StorableObject>(uri: string, observer: Subscriber<OperationResponse<T>>): void {
        uri = uri.toLowerCase();

        this.http.get<OperationResponse<T>>(this.getApiServerUri() + uri, { withCredentials: true })
            .pipe(catchError(this.handleHttpError))
            .subscribe(
                (response: OperationResponse<T>) => {
                    if (response.operationSucceeded === false || response.data == null) {
                        observer.error(<OperationResponse<T>>response);
                    }
                    else {                       
                        observer.next(<OperationResponse<T>>response);                        
                    }

                    observer.complete();
                },
                error => {
                    observer.error(error);
                    observer.complete();
                }
            );
    }

    protected getObjectFromLocalStore<T extends StorableObject>(uri: string, observer: Subscriber<OperationResponse<T>>): void {
        uri = uri.toLowerCase();

        this.objectStore.get(uri).then((storableObject: T) => {
            const result = storableObject != null
                ? new OperationResponse<T>(true, storableObject, [])
                : new OperationResponse<T>(false, null, []);

            if (result.operationSucceeded === true) {
                // G.M. turn off this toastr for the moment as not correct if offline
                // this.toastr.info('You may not be viewing the most recent information', 'Something went wrong');
                console.log('getObjectFromLocalStore', result);
            }

            observer.next(result);
            observer.complete();
        },
            err => {
                observer.error(err);
                observer.complete();
            });
    }

    public getFromLocalStoreWithErrorCallback<T extends StorableObject>(uri: string,
        observer: Subscriber<T>,
        errorCallback: DelegateFunc<String, Subscriber<T>>
    ): void {
        uri = uri.toLowerCase();

        this.objectStore.get(uri).then((storableObject: T) => {
            const result = storableObject;
            if (result == null && errorCallback != null) {
                errorCallback(uri, observer);
            }
            else {
                observer.next(result);
                observer.complete();
            }
        },
            err => {
                if (errorCallback != null) {
                    errorCallback(uri, observer);
                }
                else {
                    observer.error(err);
                    observer.complete();
                }
            });
    }

    /**
     * Retrieves a storable object from the local store only
     * @param uri 
     */
    public getObjectFromLocalStoreOnly<T extends IStorableObject>(uri: string): Observable<T> {
        uri = uri.toLowerCase();
        const observable = new Observable<T>(observer => {
            // Try to get the storable object from IndexedDb first.
            this.objectStore.get(uri).then((storableObject: StorableObject) => {
                if (storableObject != null) {
                    console.log(`Returning env object (${uri}) from IndexedDb.`);
                } else {
                    console.log(`Storable object doesn't exist (${uri})`);
                }
                // Send the storable object to the subscriber.
                observer.next(<T>storableObject);
                observer.complete();
            },
                err => {
                    observer.error(err);
                    observer.complete();
                });
        });
        return observable;
    }


    /**
     * Retrieves a storable object from the remote store only
     * @param uri 
     */
    //TODO: M.S. - write unit tests
    public getObjectFromRemoteStoreOnly<T extends IStorableObject>(uri: string): Observable<OperationResponse<T>> {
        uri = uri.toLowerCase();
        return this.http.get<OperationResponse<T>>(this.getApiServerUri() + uri, { withCredentials: true })
            .pipe(catchError(this.handleHttpError));
    }

    /**
    * This awaitable get object from local store is used in the AuthGuard.canActivateMethod and requires completion.
    */
    public async getObjectFromLocalStoreSync<T extends StorableObject>(uri: string) {
        uri = uri.toLowerCase();
        const result = await this.objectStore.get(uri);
        return <T>result;
    }

    public async getObjectFromRemoteStoreSync<T extends StorableObject>(uri: string) {
        uri = uri.toLowerCase();

        const result = await this.http.get<OperationResponse<T>>(this.getApiServerUri() + uri, { withCredentials: true })
            .pipe(catchError(this.handleHttpError)).toPromise();

        return <OperationResponse<T>>result;
    }


    /**
    * Sets a storable object in available stores; the desired strategy is depending on the treatAsOffline flag.
    * If offline, objects are temporarily stored in the local store (as awaiting further synchronization).
    * If online, objects are set in remote store; if this succeeds objects are then stored locally as synchronized; if remote set fails, 
    * objects are temporarily stored in the local store (as awaiting further synchronization).
    */
    public setObjectInAvailableStores<T extends StorableObject>
        (data: T, uri: string, treatAsOffline: boolean): Observable<OperationResponse<T>> {
        uri = uri.toLowerCase();
        const url = this.getApiServerUri() + uri;

        const observable = new Observable<OperationResponse<T>>(observer => {
            //M.S. - do not remove these comments, this is provisional work for go live, we will be needing this when working for OFFLINE MODE)

            if (treatAsOffline === false) {
                this.setObjectInRemoteStore(data, url, observer);
            } else {
                this.setObjectTemporarilyInLocalStore(data, uri, observer);
            }
        });

        return observable;
    }

    protected setObjectInRemoteStore<T extends StorableObject>(data: T, uri: string, observer: Subscriber<OperationResponse<T>>): void {


        if (data === undefined || data === null || uri === undefined || uri === null) {
            observer.error('Storable item or URI cannot be null or undefined');
            observer.complete();
            return;
        }
        uri = uri.toLowerCase();

        this.http.post<OperationResponse<T>>(uri, data, { withCredentials: true })
            .pipe(catchError(this.handleHttpError))
            .subscribe(
                (response: OperationResponse<T>) => {

                    if (response && response.operationSucceeded) {
                        if (response.data) {
                            // response.data.uri = data.uri;
                            data.uri = response.data.uri;
                        }
                        response.data.isAwaitingSynchronization = false;

                        //M.S. - do not remove these comments, this is provisional work for go live, we will be needing this when working for OFFLINE MODE)
                        // this.objectStore.addOrUpdate(data.uri, response.data)
                        //     .then(x => {
                        //         observer.next(<OperationResponse<T>>response);
                        //         observer.complete();
                        //     },
                        //         rejected => {
                        //             observer.error(rejected);
                        //             observer.complete();
                        //         });


                    } else {

                        //M.S. - do not remove these comments, this is provisional work for go live, we will be needing this when working for OFFLINE MODE)
                        // observer.next(<OperationResponse<T>>response);
                        // observer.complete();
                        console.log(response)
                        this.toastr.error('Unable to perform save operation', 'Something went wrong');
                    }

                    observer.next(<OperationResponse<T>>response);
                    observer.complete();
                },
                error => {
                    //M.S. - do not remove these comments, this is provisional work for go live, we will be needing this when working for OFFLINE MODE)
                    //this.setObjectTemporarilyInLocalStore(data, data.uri, observer);

                    observer.error(error);
                    observer.complete();
                });
    }

    protected setObjectTemporarilyInLocalStore<T extends StorableObject>
        (data: T, uri: string, observer: Subscriber<OperationResponse<T>>): void {

        if (data === undefined || data === null || uri === undefined || uri === null) {
            observer.error('Storable item or URI cannot be null or undefined');
            observer.complete();
            return;
        }
        uri = uri.toLowerCase();
        data.isAwaitingSynchronization = true;

        this.objectStore.addOrUpdate(uri, data)
            .then(x => {
                const notifications = new Array<NotificationSummary>();
                notifications.push(new NotificationSummary('Item has been stored in local cache and is awaiting synchronization',
                    null, SeverityLevel.Info));

                const result = new OperationResponse<T>(true, data, notifications);

                observer.next(<OperationResponse<T>>result);
                observer.complete();
            },
                rejected => {
                    //observer.error(rejected);                   
                    //M.S. - Code to avoid propagating IndexedDB error
                    const result = new OperationResponse<T>(true, data, []);
                    observer.next(<OperationResponse<T>>result);
                    observer.complete();
                });
    }

    /**
    * Stores objects in the local store only.
    */
    public setObjectInLocalStore<T extends IStorableObject>(data: T, uri: string): Observable<T> {
        const observable = new Observable<T>(observer => {
            data.uri = uri.toLowerCase();
            this.objectStore.addOrUpdate(data.uri, data).then(x => {
                observer.next(data);
                observer.complete();
            },
                err => {
                    //observer.error(err);
                    //M.S. - Code to avoid propagating IndexedDB error
                    observer.next(data);
                    observer.complete();
                });
        });

        return observable;
    }

    /**
    * Sends an object to the server, then stores it locally.
    * @param uri The URI to use when posting data to the API layer.
    * @param convertor If provided, this is used to convert the object returned from the API layer to the correct shape for the Angular app.
    * @param successCheck Used to determine whether the POST to the API layer should be considered
    * successful. If not provided, we check the operationSucceeded property of the operation response.
    */
    public setObjectFromServerThenStoreLocally<K extends StorableObject, T extends StorableObject>(data: K, uri: string,
        convertor: Convertor<T> = null, successCheck: Func<T, boolean> = null, persistLocally:boolean = false, isLoggingIn: boolean = false): Observable<OperationResponse<T>> {
        uri = uri.toLowerCase();
        const observable = new Observable<OperationResponse<T>>(observer => {

            this.http.post<OperationResponse<T>>(uri, data, {
                observe: 'response',
                withCredentials: true
            })
                .pipe(catchError(this.handleHttpError))
                .subscribe((response: HttpResponse<OperationResponse<T>>) => {

                    const body = response.body;
                    let result = body.data;

                    if (convertor) {
                        result = convertor(result);
                    }

                    const operationIsSuccessful = successCheck
                        ? successCheck(result)
                        : body.operationSucceeded;

                   
                    if (persistLocally === true && operationIsSuccessful === true) {
                        this.objectStore.addOrUpdate(isLoggingIn === true ? result.uri : data.uri, isLoggingIn === true ? result : data)
                            .then(x => {
                                // Send the storable object to the subscriber.
                                observer.next(<OperationResponse<T>>body);
                                observer.complete();
                            },
                                err => {
                                    observer.error(err);
                                    observer.complete();
                                });
                    } else { 

                        observer.next(<OperationResponse<T>>body);
                        observer.complete();
                    }

                },
                    error => {
                        observer.error(error);
                        observer.complete();
                    });
        });

        return observable;
    }

    /**
     * Simple post to the server
     * @param data 
     * @param uri 
     */
    public postObjectToRemoteStoreOnly<T>(data: any, uri: string): Observable<OperationResponse<T>> {

        if (data === undefined || data === null || uri === undefined || uri === null) {
            console.error('Data or URI cannot be null or undefined');
            return null;
        }
        uri = uri.toLowerCase();
        return this.http.post<OperationResponse<T>>(uri, data, { withCredentials: true })
            .pipe(catchError(this.handleHttpError))
    }

    /**
     * Handles Http errors
     * @param error 
     */
    public handleHttpError(error: HttpErrorResponse) {
        console.log(error);
        if (error.error instanceof ErrorEvent) {
            // A client-side or network error occurred. Handle it accordingly.
            console.error('An error occurred:', error.error.message);
        } else {
            // The backend returned an unsuccessful response code.
            // The response body may contain clues as to what went wrong.
            console.error(
                `Backend returned code ${error.status}; ` +
                `error body was: ${error.error}`);
        }
        // return an observable with a user-facing error message.
        return throwError(
            'Something went wrong; please try again later.');
    }

    /**
     * Sends system notification via toastr
     * @param message 
     * @param title 
     */
    public notifyApplicationError(message: string, title: string) {
        this.toastr.error(message, title);
    }

  
}
